import React, { useState } from 'react';
import { Typography, Grid, Box, Link, TextField, MenuItem, Button, Checkbox, FormGroup, FormControlLabel } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import CourseSectionsTable from './components/tables/CourseSectionsTable';
import EnrolledUsersTable from './components/tables/EnrolledUsersTable';
import BlockIcon from '@mui/icons-material/Block';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import Indicator from './components/Indicator';
import Modal from './components/Modal';
import { specialComponentCSV, enrolledUsersCSV } from '../utils/csvDownload';
import { formatCourseIntoTable, formatUsersIntoSelector } from '../utils/formatters';
import { updateUserEnrollment } from '../requests/cursos/updateUserEnrollment';
import { emitOrRevokeCertificate } from '../requests/cursos/emitOrRevokeCertificate';
import { formatTimestamp } from '../utils/formatters';
import { api } from '../API';
import DownloadCSVEnrolledUsersForm from './components/forms/DownloadCSVEnrolledUsersForm';
import { downloadSpecialComponent } from '../requests/cursos/downloadSpecialComponent';

const Cursos = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    users,
    courses,
    setCourses
}) => {

    const [selectedSection, setSelectedSection] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showSeguimientoModal, setShowSeguimientoModal] = useState(false);
    const [specialComponentsModal, setSpecialComponentsModal] = useState(false);
    const [showEnrollModal, setShowEnrollModal] = useState(false);
    const [showUnenrollModal, setShowUnenrollModal] = useState(false);
    const [showApproveModal, setShowApproveModal] = useState(false);
    const [showRevokeModal, setShowRevokeModal] = useState(false);
    const [showDownloadCSVModal, setShowDownloadCSVModal] = useState(false);

    const [csvFirstName, setCsvFirstName] = useState(true);
    const [csvLastName, setCsvLastName] = useState(true);
    const [csvCompleted, setCsvCompleted] = useState(true);
    const [csvCertificate, setCsvCertificate] = useState(true);
    const [csvExcel, setCsvExcel] = useState(true);

    const [formLoading, setFormLoading] = useState(false);
    const [selectedEnrollUser, setSelectedEnrollUser] = useState("none");

    const [specialComponentExcel, setSpecialComponentExcel] = useState(false);
    const [specialComponent, setSpecialComponent] = useState("none");
    const [specialComponentUser, setSpecialComponentUser] = useState("none");
    const [specialComponentsLoading, setSpecialComponentsLoading] = useState(false);

    const handleOpenCreateModal = () => {
        setShowCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setShowCreateModal(false);
    }

    const handleOpenInfoModal = (section) => {
        setSelectedSection(section);
        setShowInfoModal(true);
    }

    const handleCloseInfoModal = () => {
        setSelectedSection(null);
        setShowInfoModal(false);
    }

    const handleOpenEditModal = (section) => {
        setSelectedSection(section);
        setShowEditModal(true);
    }

    const handleCloseEditModal = () => {
        setSelectedSection(null);
        setShowEditModal(false);
    }

    const handleOpenSeguimientoModal = (section) => {
        setSelectedSection(section);
        setShowSeguimientoModal(true);
    }

    const handleCloseSeguimientoModal = () => {
        setSelectedSection(null);
        setShowSeguimientoModal(false);
    }

    const handleOpenSpecialComponentsModal = (section) => {
        setSelectedSection(section);
        setSpecialComponentsModal(true);
    }

    const handleCloseSpecialComponentsModal = () => {
        setSelectedSection(null);
        setSpecialComponent("none");
        setSpecialComponentUser("none");
        setSpecialComponentsModal(false);
    }

    const handleOpenEnrollModal = () => {
        setShowEnrollModal(true);
    }

    const handleCloseEnrollModal = () => {
        setShowEnrollModal(false);
    }

    const handleOpenUnenrollModal = (user) => {
        setSelectedUser(user);
        setShowUnenrollModal(true);
    }

    const handleCloseUnenrollModal = () => {
        setSelectedUser(null);
        setShowUnenrollModal(false);
    }

    const handleOpenApproveModal = (user) => {
        setSelectedUser(user);
        setShowApproveModal(true);
    }

    const handleCloseApproveModal = () => {
        setSelectedUser(null);
        setShowApproveModal(false);
    }

    const handleOpenRevokeModal = (user) => {
        setSelectedUser(user);
        setShowRevokeModal(true);
    }

    const handleCloseRevokeModal = () => {
        setSelectedUser(null);
        setShowRevokeModal(false);
    }

    const handleOpenDownloadCSVModal = () => {
        setShowDownloadCSVModal(true);
    }

    const handleCloseDownloadCSVModal = () => {
        setShowDownloadCSVModal(false);
    }

    const handleUpdateUserEnrollment = async (action, username) => {
        setFormLoading(true);
        const response = await updateUserEnrollment(api, action, username, selectedSection.course_id);
        if (response.data) {
            if (action === "enroll") {
                handleCloseEnrollModal();
            } else {
                handleCloseUnenrollModal();
            }
            handleCloseInfoModal();
            const result_courses = response.data;
            for (let course of Object.keys(result_courses)) {
                for (let section of Object.keys(result_courses[course]["sections"])) {
                    if (section !== "0") {
                        if (result_courses[course]["sections"][section].data) {
                            result_courses[course]["sections"][section].data["enrolled"] = result_courses[course]["sections"][section].data["enrolled"].filter((user) => users.find((u) => u.username === user && u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"));
                            result_courses[course]["sections"][section].data["approved"] = result_courses[course]["sections"][section].data["approved"].filter((user) => users.find((u) => u.username === user && u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"));
                        }
                    }
                }
            }
            setCourses(response.data);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Usuario ${action === "enroll" ? "inscrito!" : "desinscrito!"}`, "text": `El usuario ${username} ha sido ${action === "enroll" ? "inscrito" : "desinscrito"} correctamente.` } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": `¡Error!`, "text": `No se pudo ${action === "enroll" ? "inscribir" : "desinscribir"} al usuario: ${response.error}` } });
        }
        setFormLoading(false);
    }

    const handleEmitOrRevokeCertificate = async (action, username) => {
        setFormLoading(true);
        const response = await emitOrRevokeCertificate(api, action, username, selectedSection.course_id);
        if (response.data) {
            if (action === "emit") {
                handleCloseApproveModal();
            }
            if (action === "revoke") {
                handleCloseRevokeModal();
            }
            handleCloseInfoModal();
            const result_courses = response.data;
            for (let course of Object.keys(result_courses)) {
                for (let section of Object.keys(result_courses[course]["sections"])) {
                    if (section !== "0") {
                        if (result_courses[course]["sections"][section].data) {
                            result_courses[course]["sections"][section].data["enrolled"] = result_courses[course]["sections"][section].data["enrolled"].filter((user) => users.find((u) => u.username === user && u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"));
                            result_courses[course]["sections"][section].data["approved"] = result_courses[course]["sections"][section].data["approved"].filter((user) => users.find((u) => u.username === user && u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"));
                        }
                    }
                }
            }
            setCourses(response.data);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Certificado ${action === "emit" ? "emitido!" : "revocado!"}`, "text": `El certificado del usuario ${username} ha sido ${action === "emit" ? "emitido" : "revocado"} correctamente.` } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": `¡Error!`, "text": `No se pudo ${action === "emit" ? "emitir" : "revocar"} el certificado: ${response.error}` } });
        }
        setFormLoading(false);
    }

    const handleDownloadCSV = () => {
        let downloadOptions = {
            "csvFirstName": csvFirstName,
            "csvLastName": csvLastName,
            "csvCompleted": csvCompleted,
            "csvCertificate": csvCertificate
        }
        enrolledUsersCSV(true, users, selectedSection, downloadOptions);
        handleCloseDownloadCSVModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleDownloadSpecialComponent = async () => {
        setSpecialComponentsLoading(true);
        const response = await downloadSpecialComponent(api, specialComponent, specialComponentUser, selectedSection.course_id);
        if (response.data) {
            if (response.data.length > 0) {
                specialComponentCSV(specialComponentExcel, specialComponent, specialComponentUser, response.data);
                setAlertSuccess(true);
                setAlerts({ ...alerts, "success": { "title": `¡Respuestas descargadas!`, "text": `Se han descargado correctamente las respuestas correspondientes al usuario y componente especial seleccionado.` } });
            } else {
                setAlertWarning(true);
                setAlerts({ ...alerts, "warning": { "title": `¡Sin respuesta!`, "text": `El usuario seleccionado no cuenta con una respuesta para el componente especial seleccionado.` } });
            }
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": `¡Error!`, "text": `No se pudo obtener el componente especial: ${response.error}` } });
        }
        setSpecialComponentsLoading(false);
    }

    return (
        <Box>
            <Grid spacing={2} container display={"flex"} justifyContent={"center"} alignItems={"center"}>
                <Grid item xs={2}>
                    <Indicator title="Cursos" number={
                        Object.keys(courses).length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de cursos en RedFID Aprendizaje Profesional."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Secciones" number={
                        Object.values(courses).reduce((acc, course) => acc + Object.keys(course["sections"]).filter(section => section !== "0").length, 0)
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de secciones de cursos en RedFID Aprendizaje Profesional."
                    }></Indicator>

                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Inscripciones" number={
                        Object.values(courses).reduce((acc, course) => acc + Object.values(course["sections"]).filter(section => section !== "0" && section.data).reduce((sum, section) => sum + section.data["enrolled"].length, 0), 0)
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de inscripciones de formadores en secciones de cursos de RedFID Aprendizaje Profesional."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title="Certificados" number={
                        Object.values(courses).reduce((acc, course) => acc + Object.values(course["sections"]).filter(section => section !== "0" && section.data).reduce((sum, section) => sum + section.data["approved"].length, 0), 0)
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de certificados emitidos a formadores aprobados en secciones de cursos de RedFID Aprendizaje Profesional."
                    }></Indicator>
                </Grid>
            </Grid>
            {Object.keys(courses).map((course) =>
                <Grid key={course}>
                    <Grid columnSpacing={2} container justifyContent={"space-between"}>
                        <Grid item xs={12}>
                            <CourseSectionsTable
                                name={courses[course]["name"]}
                                course={formatCourseIntoTable(courses[course])}
                                handleOpenCreateModal={handleOpenCreateModal}
                                handleOpenInfoModal={handleOpenInfoModal}
                                handleOpenEditModal={handleOpenEditModal}
                                handleOpenSeguimientoModal={handleOpenSeguimientoModal}
                                handleOpenSpecialComponentsModal={handleOpenSpecialComponentsModal}
                            ></CourseSectionsTable>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Modal
                open={showCreateModal}
                handleClose={handleCloseCreateModal}
                title={"Crear sección"}
                onClickMainButton={handleCloseCreateModal}
                mainButtonText={"OK"}
                width={'md'}
                cancelButton={true}
                content={
                    <>
                        <Typography variant='p' style={{ "textAlign": "center" }}>{"todo"}</Typography>
                    </>
                }
            ></Modal>
            {selectedSection &&
                <>
                    <Modal
                        open={showInfoModal}
                        handleClose={handleCloseInfoModal}
                        title={selectedSection["data"]["name"] + " (" + selectedSection["data"]["course_id"].split("+")[2] + ")"}
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText={"OK"}
                        hideMainButton={true}
                        width={'lg'}
                        cancelButton={false}
                        content={
                            <>
                                <Grid container spacing={2} display={"flex"} justifyContent={"space-between"}>
                                    <Grid item xs={12} md={7}>
                                        <Typography variant='p'>
                                            <b>Portada: </b> <a target="_blank" rel="noreferrer" style={{ "color": "#40b4ba" }} href={process.env.REACT_APP_CURSOS_URL + "/courses/" + selectedSection["data"]["course_id"] + "/about"}>{process.env.REACT_APP_CURSOS_URL + "/courses/" + selectedSection["data"]["course_id"] + "/about"}</a>
                                        </Typography>
                                        <Typography variant='p' m="20px 0">
                                            <b>Descripción: </b> {selectedSection["data"]["short_description"]}
                                        </Typography>
                                        <Typography variant='p' m="20px 0">
                                            <b>Vídeo promocional: </b>
                                            {(selectedSection["data"]["media"] && selectedSection["data"]["media"]["course_video"] && selectedSection["data"]["media"]["course_video"]["uri"]) ?
                                                <Link href={selectedSection["data"]["media"]["course_video"]["uri"].replace("http://", "https://")} target="_blank" sx={{ "color": "#40b4ba" }}>{selectedSection["data"]["media"]["course_video"]["uri"].replace("http://", "https://")}</Link>
                                                : "—"}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={5}>
                                        {selectedSection["data"]["media"] && selectedSection["data"]["media"]["image"] && selectedSection["data"]["media"]["image"]["raw"] && selectedSection["data"]["media"]["image"]["raw"] !== "" &&
                                            <Box
                                                component="img"
                                                src={selectedSection["data"]["media"]["image"]["raw"]}
                                                alt={selectedSection["data"]["name"]}
                                                sx={{ "width": "100%", "height": "auto" }}
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                <Grid container display={"flex"} justifyContent={"space-between"} mt={"10px"}>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant='p'>
                                            <b>Inicio del curso: </b> {formatTimestamp(selectedSection["data"]["start"])}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant='p' textAlign={{ "xs": "left", "md": "center" }}>
                                            <b>Fin del curso: </b> {formatTimestamp(selectedSection["data"]["end"])}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant='p' textAlign={{ "xs": "left", "md": "right" }}>
                                            <b>Tasa de aprobación: </b> {selectedSection["data"]["approved"].length} de {selectedSection["data"]["enrolled"].length} ({ selectedSection["data"]["enrolled"].length === 0 ? "—" : Math.round(selectedSection["data"]["approved"].length / selectedSection["data"]["enrolled"].length * 100)}%)
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <EnrolledUsersTable
                                    users={users}
                                    courseSection={selectedSection}
                                    handleOpenEnrollModal={handleOpenEnrollModal}
                                    handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                                    handleOpenUnenrollModal={handleOpenUnenrollModal}
                                    handleOpenApproveModal={handleOpenApproveModal}
                                    handleOpenRevokeModal={handleOpenRevokeModal}
                                ></EnrolledUsersTable>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={showDownloadCSVModal}
                        handleClose={handleCloseDownloadCSVModal}
                        title={"Descargar datos"}
                        onClickMainButton={handleDownloadCSV}
                        mainButtonText={"Descargar"}
                        width={'md'}
                        cancelButton={true}
                        startIcon={<DownloadIcon />}
                        content={
                            <>
                                <Typography variant="p" mb={"20px"}>
                                    Se descargará un archivo en formato .csv con la información de todos los formadores inscritos en esta versión del curso.
                                    Por favor, seleccione los campos que desea incluir:
                                </Typography>
                                <DownloadCSVEnrolledUsersForm
                                    csvFirstName={csvFirstName}
                                    setCsvFirstName={setCsvFirstName}
                                    csvLastName={csvLastName}
                                    setCsvLastName={setCsvLastName}
                                    csvCompleted={csvCompleted}
                                    setCsvCompleted={setCsvCompleted}
                                    csvCertificate={csvCertificate}
                                    setCsvCertificate={setCsvCertificate}
                                    csvExcel={csvExcel}
                                    setCsvExcel={setCsvExcel}
                                ></DownloadCSVEnrolledUsersForm>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={showEnrollModal}
                        handleClose={handleCloseEnrollModal}
                        title={"Inscribir formador"}
                        onClickMainButton={() => handleUpdateUserEnrollment("enroll", selectedEnrollUser)}
                        mainButtonText={"Inscribir"}
                        mainButtonDisabled={selectedEnrollUser === "none" || formLoading}
                        startIcon={<AddCircleOutlineIcon />}
                        width={'md'}
                        cancelButton={true}
                        content={
                            <>
                                <Typography variant='p' style={{ "textAlign": "justify" }} mb={"20px"}>
                                    Por favor seleccione un formador para inscribir en la versión <b>{selectedSection["data"]["course_id"].split("+")[2]}</b> del curso <b>{selectedSection["data"]["name"]}</b>:
                                </Typography>
                                <Box sx={{ "textAlign": "center", "marginBottom": "20px" }}>
                                    <TextField
                                        select
                                        sx={{ "width": "300px" }}
                                        label="Formador"
                                        variant="outlined"
                                        value={selectedEnrollUser}
                                        onChange={(e) => {
                                            setSelectedEnrollUser(e.target.value)
                                        }}
                                        disabled={formLoading}
                                        SelectProps={{
                                            MenuProps: {
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 300,
                                                    },
                                                }
                                            }
                                        }}
                                    >
                                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                        {formatUsersIntoSelector(users.filter(u => u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1")).filter(u => !selectedSection.data.enrolled.includes(u.username)).sort((a, b) => a.label.localeCompare(b.label)).map(user => (
                                            <MenuItem key={user.username} value={user.username}>{user.lastName + ", " + user.firstName}</MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                                <Typography variant='p' style={{ "textAlign": "justify" }} mb={"20px"}>
                                    {"El formador tendrá acceso al curso. En caso de haber estado inscrito anteriormente en esta misma sección, recuperará su avance."}
                                </Typography>
                            </>
                        }
                    ></Modal>
                    {selectedUser &&
                        <>
                            <Modal
                                open={showUnenrollModal}
                                handleClose={handleCloseUnenrollModal}
                                title={"Desinscribir usuario"}
                                onClickMainButton={() => handleUpdateUserEnrollment("unenroll", selectedUser.username)}
                                mainButtonText={"Desinscribir"}
                                startIcon={<BlockIcon />}
                                mainButtonDisabled={formLoading}
                                width={'md'}
                                cancelButton={true}
                                content={
                                    <>
                                        <Typography variant='p' textAlign={"justify"} mb={"20px"}>
                                            ¿Está segur@ que desea desinscribir al usuario seleccionado de la versión <b>{selectedSection["data"]["course_id"].split("+")[2]}</b> del
                                            curso <b>{selectedSection["data"]["name"]}</b>?
                                        </Typography>
                                        <Typography variant='p' textAlign={"center"} mb={"20px"}>
                                            <b>{selectedUser["firstName"]} {selectedUser["lastName"]} ({selectedUser["username"]}) </b>
                                        </Typography>
                                        <Typography variant='p' textAlign={"justify"} mb={"20px"}>
                                            El usuario perderá acceso al curso, sin embargo, en caso de re-inscribirse (ya sea por cuenta propia o mediante Administración), sus datos y avance estarán disponibles en la plataforma.
                                        </Typography>
                                    </>
                                }
                            ></Modal>
                            <Modal
                                open={showApproveModal}
                                handleClose={handleCloseApproveModal}
                                title={"Aprobar usuario"}
                                onClickMainButton={() => handleEmitOrRevokeCertificate("emit", selectedUser.username)}
                                mainButtonText={"Aprobar"}
                                startIcon={<CheckCircleIcon />}
                                mainButtonDisabled={formLoading}
                                width={'md'}
                                cancelButton={true}
                                content={
                                    <>
                                        <Typography variant='p' textAlign={"justify"} mb={"20px"}>
                                            ¿Está segur@ que desea aprobar al usuario seleccionado en la versión <b>{selectedSection["data"]["course_id"].split("+")[2]}</b> del
                                            curso <b>{selectedSection["data"]["name"]}</b>?
                                        </Typography>
                                        <Typography variant='p' textAlign={"center"} mb={"20px"}>
                                            <b>{selectedUser["firstName"]} {selectedUser["lastName"]} ({selectedUser["username"]}) </b>
                                        </Typography>
                                        <Typography variant='p' textAlign={"justify"} mb={"20px"}>
                                            Se emitirá un certificado al usuario, indicando que ha completado exitosamente el curso.
                                        </Typography>
                                    </>
                                }
                            ></Modal>
                            <Modal
                                open={showRevokeModal}
                                handleClose={handleCloseRevokeModal}
                                title={"Reprobar usuario"}
                                onClickMainButton={() => handleEmitOrRevokeCertificate("revoke", selectedUser.username)}
                                mainButtonText={"Reprobar"}
                                startIcon={<CancelIcon />}
                                mainButtonDisabled={formLoading}
                                width={'md'}
                                cancelButton={true}
                                content={
                                    <>
                                        <Typography variant='p' textAlign={"justify"} mb={"20px"}>
                                            ¿Está segur@ que desea reprobar al usuario seleccionado en la versión <b>{selectedSection["data"]["course_id"].split("+")[2]}</b> del
                                            curso <b>{selectedSection["data"]["name"]}</b>?
                                        </Typography>
                                        <Typography variant='p' textAlign={"center"} mb={"20px"}>
                                            <b>{selectedUser["firstName"]} {selectedUser["lastName"]} ({selectedUser["username"]}) </b>
                                        </Typography>
                                        <Typography variant='p' textAlign={"justify"} mb={"20px"}>
                                            Se anulará el certificado emitido al usuario.
                                        </Typography>
                                    </>
                                }
                            ></Modal>
                        </>
                    }
                    <Modal
                        open={showEditModal}
                        handleClose={handleCloseEditModal}
                        title={"Editar sección"}
                        onClickMainButton={handleCloseEditModal}
                        mainButtonText={"OK"}
                        hideMainButton={true}
                        width={'sm'}
                        cancelButton={false}
                        content={
                            <>
                                <Typography variant='p' mb={"20px"}>Para editar el contenido de una sección de un curso, debe accederse a Studio y hacer los cambios desde allí:</Typography>
                                <Typography variant='p' textAlign={"center"} mb={"20px"}><Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_STUDIO_URL + "/course/" + selectedSection["data"]["course_id"]}>{process.env.REACT_APP_STUDIO_URL + "/course/" + selectedSection["data"]["course_id"]}</Link></Typography>
                                <Typography variant='p' m={"20px 0"}>En el caso de querer editar información general del curso (descripción, imagen de portada, vídeo introductorio, etc.), y para definir fechas de inicio y término, se debe acceder a la siguiente sección de Studio:</Typography>
                                <Typography variant='p' textAlign={"center"} mb={"20px"}><Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_STUDIO_URL + "/settings/details/" + selectedSection["data"]["course_id"]}>{process.env.REACT_APP_STUDIO_URL + "/settings/details/" + selectedSection["data"]["course_id"]}</Link></Typography>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={showSeguimientoModal}
                        handleClose={handleCloseSeguimientoModal}
                        title={"Seguimiento de sección"}
                        onClickMainButton={handleCloseSeguimientoModal}
                        mainButtonText={"OK"}
                        hideMainButton={true}
                        width={'sm'}
                        cancelButton={false}
                        content={
                            <>
                                <Typography variant='p' mb={"20px"}>Para hacer seguimiento de la sección, por favor acceda a la plataforma:</Typography>
                                <Typography variant='p' style={{ "textAlign": "center" }}><Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_CURSOS_URL + "/courses/" + selectedSection["data"]["course_id"] + "/student_completion"}>{process.env.REACT_APP_CURSOS_URL + "/courses/" + selectedSection["data"]["course_id"] + "/student_completion"}</Link></Typography>
                            </>
                        }
                    ></Modal>
                    <Modal
                        open={specialComponentsModal}
                        handleClose={handleCloseSpecialComponentsModal}
                        title={"Componentes especiales"}
                        onClickMainButton={handleCloseSpecialComponentsModal}
                        mainButtonText={"OK"}
                        hideMainButton={true}
                        width={'md'}
                        cancelButton={false}
                        content={
                            <>
                                <Grid container columnSpacing={2}>
                                    <Grid item xs={12}>
                                        <Typography variant='p' mb={"20px"}>
                                            Para descargar las respuestas de un componente especial de un usuario inscrito en esta sección, por favor seleccione el componente y el usuario:
                                        </Typography>
                                    </Grid>
                                    <Grid item mt={"10px"} xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Componente"
                                            variant="outlined"
                                            value={specialComponent}
                                            disabled={specialComponentsLoading}
                                            onChange={(e) => setSpecialComponent(e.target.value)}
                                        >
                                            <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                            {Object.keys(selectedSection["special_components"]).map((key) => (
                                                <MenuItem key={key} value={key}>{selectedSection["special_components"][key]["detail"]["name"]}</MenuItem>
                                            ))}
                                        </TextField>
                                    </Grid>
                                    <Grid item mt={"10px"} xs={12} sm={4}>
                                        <TextField
                                            fullWidth
                                            select
                                            label="Usuario"
                                            variant="outlined"
                                            value={specialComponentUser}
                                            disabled={specialComponentsLoading}
                                            onChange={(e) => setSpecialComponentUser(e.target.value)}
                                            SelectProps={{
                                                MenuProps: {
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: 300,
                                                        },
                                                    }
                                                }
                                            }}
                                        >
                                            <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                                            {selectedSection["data"]["enrolled"]
                                                .map(user => formatUsersIntoSelector(users).find(u => u.username === user))
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                                .map(user => (
                                                    <MenuItem key={user.username} value={user.username}>{user.label}</MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </Grid>
                                    <Grid item mt={"10px"} xs={12} sm={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                                        <Button onClick={handleDownloadSpecialComponent} disabled={specialComponent === "none" || specialComponentUser === "none" || specialComponentsLoading} variant="green">Descargar</Button>
                                    </Grid>
                                    <Grid item xs={12} display="flex" justifyContent="center" mt={"20px"}>
                                        <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={specialComponentExcel}
                                                        onChange={
                                                            (e) => {
                                                                setSpecialComponentExcel(e.target.checked);
                                                            }
                                                        }
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />
                                                } sx={{ "color": '#646464' }} label="Descargar en formato Excel (.xlsx)" />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    ></Modal>
                </>
            }
        </Box>
    )
}

export default Cursos;
