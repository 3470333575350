import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Box, Typography, Link, Chip, Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import CancelIcon from '@mui/icons-material/Cancel';


const TalleresYWebinarsTable = ({ 
    talleresYWebinars,
    events,
    handleOpenCreateModal,
    handleOpenStatsModal,
    handleOpenDownloadCSVModal,
    handleOpenInfoModal,
    handleOpenEditModal,
    handleOpenSuspendModal,
    handleOpenDeleteModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu tallerWebinar={params.row} />
            )
        },
        { 
            field: 'priority',
            headerName: 'Prioridad', 
            align: 'center', 
            disableColumnMenu: true,
            headerAlign: 'center', 
            width: 120, 
            valueFormatter: (params, row) => parseInt(row.priority) 
        },
        { 
            field: 'title', 
            headerName: 'Título', 
            align: 'left', 
            disableColumnMenu: true, 
            headerAlign: 'left', 
            flex: 1 
        },
        { 
            field: 'kind', 
            headerName: 'Tipo', 
            align: 'center', 
            disableColumnMenu: true, 
            headerAlign: 'center', 
            width: 100, 
            valueFormatter: (params, row) => row.kind[0].toUpperCase() + row.kind.slice(1), 
            renderCell: (params) => <Chip variant={params.row.kind === "taller" ? "green" : "orange"} label={params.row.kind[0].toUpperCase() + params.row.kind.slice(1)} /> 
        },
        {
            field: 'published',
            headerName: 'Publicado',
            width: 120,
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.published
                        ? <CheckCircleIcon sx={{ color: "#088408" }} />
                        : "—"
                    }
                </Box>
            )
        },
        { 
            field: 'video_url', 
            headerName: 'URL', 
            align: 'center', 
            disableColumnMenu: true, 
            headerAlign: 'center', 
            width: 350, 
            sortable: false,
            renderCell: (params) => <Link href={params.row.video_url} target="_blank"><Typography variant='a'>{params.row.video_url}</Typography></Link> 
        },
        { 
            field: 'viewCount',
            headerName: 'Vistas', 
            align: 'center', 
            disableColumnMenu: true, 
            headerAlign: 'center',
            sortComparator: (v1, v2) => v2 - v1,
            width: 120 
        },
        { 
            field: 'attendants',
            headerName: 'Asistentes',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 120,
            valueGetter: (params, row) => {
                let twEvent = events.find(event => event.associated === row.id);
                return twEvent ? twEvent.attendants.length : "—";
            }
        }
    ];
    
    const ActionsMenu = ({ tallerWebinar }) => {
        const [anchorEl, setAnchorEl] = useState(null);
    
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
    
        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {handleOpenInfoModal(tallerWebinar)}}><InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada</MenuItem>
                    <MenuItem onClick={() => handleOpenEditModal(tallerWebinar)}><EditIcon sx={{ "marginRight": "10px" }} /> Editar</MenuItem>
                    {tallerWebinar.published ? (
                        <MenuItem onClick={() => handleOpenSuspendModal(tallerWebinar)}><VisibilityOffIcon sx={{ "marginRight": "10px" }} /> Ocultar</MenuItem>
                    ) : (
                        <MenuItem onClick={() => handleOpenSuspendModal(tallerWebinar)}><RemoveRedEyeIcon sx={{ "marginRight": "10px" }} /> Activar</MenuItem>
                    )}
                    <MenuItem onClick={() => handleOpenDeleteModal(tallerWebinar)}><DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar</MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Talleres y webinars</Typography>
                <Box>
                    <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={handleOpenStatsModal}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={talleresYWebinars}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'priority', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default TalleresYWebinarsTable;
