import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Link, Menu, MenuItem, Typography, Box } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import BoltIcon from '@mui/icons-material/Bolt';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { formatTimestamp } from '../../../utils/formatters';

const CourseSectionsTable = ({
    name,
    course,
    handleOpenCreateModal,
    handleOpenInfoModal,
    handleOpenEditModal,
    handleOpenSeguimientoModal,
    handleOpenSpecialComponentsModal
}) => {
    const columns = [

        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu section={params.row.section} />
            )
        },
        { 
            field: 'sectionName', 
            disableColumnMenu: true, 
            headerName: 'Sección', 
            flex: 1, 
            align: 'left',
            headerAlign: 'left',
            sortComparator: (v1, v2) => v2.localeCompare(v1),
            renderCell: (params) => params.row.section.data ? <Link color={"#40b4ba"} href={`https://cursos.redfid.cl/courses/${params.row.section.data.course_id}/course/`} target="_blank">{params.row.sectionName}</Link> : "" 
        },
        { 
            field: 'sectionNumberOfEnrolled', 
            disableColumnMenu: true, 
            headerName: 'Inscritos', 
            width: 100, 
            align: 'center', 
            headerAlign: 'center' 
        },
        { 
            field: 'sectionNumberOfApproved', 
            disableColumnMenu: true, 
            headerName: 'Aprobados', 
            width: 100, 
            align: 'center', 
            headerAlign: 'center' 
        },
        {
            field: 'sectionApprovalRate',
            disableColumnMenu: true,
            headerName: 'Tasa de aprobación',
            width: 150,
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params, row) => row.sectionNumberOfEnrolled > 0 ? `${(row.sectionNumberOfApproved / row.sectionNumberOfEnrolled * 100).toFixed(2)}%` : "0%"
        },
        { 
            field: 'sectionStartDate', 
            disableColumnMenu: true, 
            headerName: 'Inicio', 
            width: 200, align: 'center', 
            headerAlign: 'center', 
            valueGetter: (params, row) => row.section.data ? formatTimestamp(row.sectionStartDate) : "" 
        },
        { 
            field: 'sectionEndDate', 
            disableColumnMenu: true, 
            headerName: 'Término', 
            width: 200, 
            align: 'center', 
            headerAlign: 'center', 
            valueGetter: (params, row) => row.section.data ? formatTimestamp(row.sectionEndDate) : "" 
        },
    ];


    const ActionsMenu = ({ section }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {section.data && [
                        <MenuItem key="info" onClick={() => handleOpenInfoModal(section)}>
                            <InfoIcon sx={{ marginRight: "10px" }} /> Información detallada
                        </MenuItem>,
                        <MenuItem key="edit" onClick={() => handleOpenEditModal(section)}>
                            <EditIcon sx={{ marginRight: "10px" }} /> Editar sección
                        </MenuItem>,
                        <MenuItem key="seguimiento" onClick={() => handleOpenSeguimientoModal(section)}>
                            <BoltIcon sx={{ marginRight: "10px" }} /> Seguimiento
                        </MenuItem>,
                        <MenuItem key="special" onClick={() => handleOpenSpecialComponentsModal(section)}>
                            <AssignmentIcon sx={{ marginRight: "10px" }} /> Componentes especiales
                        </MenuItem>
                    ]}

                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>{name}</Typography>
                <Box>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={course}
                columns={columns}
                pageSize={25}
                pageSizeOptions={[10, 25, 50, 100]}
                hideFooter
                initialState={{
                    sorting: {
                        sortModel: [],
                    },
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default CourseSectionsTable;
