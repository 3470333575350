import React, { useState, useEffect } from 'react';
import { Grid, Link, List, ListItem, ListItemAvatar, Avatar, Box, Typography, IconButton, Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { formatFileSize } from '../../utils/formatters';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import InfoIcon from '@mui/icons-material/Info';
import CircleIcon from '@mui/icons-material/Circle';
import keycloakIcon from '../../assets/img/icon_keycloak.png';
import redfidCelesteIcon from '../../assets/img/redfid_celeste.png';
import discourseIcon from '../../assets/img/icon_discourse.png';
import nicIcon from '../../assets/img/icon_nic.png';
import openedxIcon from '../../assets/img/icon_openedx.png';
import driveIcon from '../../assets/img/icon_drive.png';
import googleIcon from '../../assets/img/icon_google.png';


const StatusIndicator = ({
    apiRedfid,
    loadingApiRedfid,
    domainExpiration,
    loadingDomainExpiration,
    lastBackup,
    loadingLastBackup,
    driveData,
    loadingDriveData,
    courses,
    loadingCourses,
    recursos,
    activities,
    recursosDocentes,
    loadingRecursos,
}) => {

    const [keycloakStatus, setKeycloakStatus] = useState("loading");
    const [keycloakMessage, setKeycloakMessage] = useState("Cargando información de Keycloak...");
    const [apiStatus, setApiStatus] = useState("loading");
    const [apiMessage, setApiMessage] = useState("Cargando información de la API de RedFID...");
    const [recursosStatus, setRecursosStatus] = useState("loading");
    const [recursosMessage, setRecursosMessage] = useState("Cargando información de la API de Recursos...");
    const [homeStatus, setHomeStatus] = useState("loading");
    const [homeMessage, setHomeMessage] = useState("Cargando información del Home...");
    const [discourseStatus, setDiscourseStatus] = useState("loading");
    const [discourseMessage, setDiscourseMessage] = useState("Cargando información de Discourse...");
    const [recursosFrontStatus, setRecursosFrontStatus] = useState("loading");
    const [recursosFrontMessage, setRecursosFrontMessage] = useState("Cargando información de Recursos...");
    const [lmsStatus, setLmsStatus] = useState("loading");
    const [lmsMessage, setLmsMessage] = useState("Cargando información de Open edX (LMS)...");
    const [studioStatus, setStudioStatus] = useState("loading");
    const [studioMessage, setStudioMessage] = useState("Cargando información de Open edX (Studio)...");
    const [gmailStatus, setGmailStatus] = useState("loading");
    const [gmailMessage, setGmailMessage] = useState("Cargando información de Google Workspace...");
    const [expirationStatus, setExpirationStatus] = useState("loading");
    const [expirationMessage, setExpirationMessage] = useState("Cargando información de la expiración del dominio...");
    const [driveStatus, setDriveStatus] = useState("loading");
    const [driveMessage, setDriveMessage] = useState("Cargando información del uso de Drive...");
    const [backupStatus, setBackupStatus] = useState("loading");
    const [backupMessage, setBackupMessage] = useState("Cargando información del último respaldo...");

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            maxWidth: 300,
            color: "#646464",
            fontSize: theme.typography.pxToRem(12),
            fontFamily: "Avenir Regular",
            textAlign: 'justify',
            padding: '15px',
            border: '1px solid #dadde9',
        },
    }));

    useEffect(() => {
        if (recursos && activities && recursosDocentes && !loadingRecursos) {
            setRecursosStatus("ready");
            setRecursosMessage("La API de Recursos está funcionando correctamente.");
        } else if (loadingRecursos) {
            setRecursosStatus("loading");
            setRecursosMessage("Cargando información de la API de Recursos...");
        } else {
            setRecursosStatus("error");
            setRecursosMessage("Error al cargar información de la API de Recursos. Por favor contacte al administrador.");
        }
    }, [recursos, activities, recursosDocentes, loadingRecursos]);


    useEffect(() => {
        setKeycloakStatus("ready");
        setKeycloakMessage("Keycloak está funcionando correctamente.");
        if (apiRedfid && !loadingApiRedfid) {
            setApiStatus("ready");
            setApiMessage("La API de RedFID está funcionando correctamente.");
            let gmailService = apiRedfid["gmail"];
            let driveService = apiRedfid["drive"];
            let calendarService = apiRedfid["calendar"];
            let reportsService = apiRedfid["reports"];
            var googleMessage = "";
            if (!gmailService) {
                googleMessage += "\nEl servicio de Gmail no está disponible.";
            }
            if (!driveService) {
                googleMessage += "\nEl servicio de Google Drive no está disponible.";
            }
            if (!calendarService) {
                googleMessage += "\nEl servicio de Google Calendar no está disponible.";
            }
            if (!reportsService) {
                googleMessage += "\nEl servicio de Google Reports no está disponible.";
            }
            if (googleMessage === "") {
                setGmailStatus("ready");
                setGmailMessage("La integración con Google Workspace está funcionando correctamente.");
            } else if (!gmailService && !driveService && !calendarService && !reportsService) {
                setGmailStatus("error");
                setGmailMessage("Ningún servicio de Google Workspace está disponible. Por favor contacte al administrador.");
            } else {
                setGmailStatus("loading");
                setGmailMessage("Google Workspace se encuentra accesible, pero algunos servicios no están disponibles. Por favor contacte al administrador." + googleMessage);
            }
            if (driveService) {
                if (driveData && !loadingDriveData) {
                    setDriveStatus("ready");
                    setDriveMessage("La información de Drive cargó exitosamente.");
                } else if (loadingDriveData) {
                    setDriveStatus("loading");
                    setDriveMessage("Cargando información de Drive...");
                } else {
                    setDriveStatus("error");
                    setDriveMessage("Error al cargar información de Drive. Por favor contacte al administrador.");
                }
            }
            let homeService = apiRedfid["home"];
            if (homeService) {
                setHomeStatus("ready");
                setHomeMessage("El Home está funcionando correctamente.");
            } else {
                setHomeStatus("error");
                setHomeMessage("No se pudo conectar con el Home. Por favor contacte al administrador.");
            }
            let comunidadesService = apiRedfid["comunidades"];
            if (comunidadesService) {
                setDiscourseStatus("ready");
                setDiscourseMessage("La integración con Discourse está funcionando correctamente.");
            } else {
                setDiscourseStatus("error");
                setDiscourseMessage("No se pudo conectar con Discourse. Por favor contacte al administrador.");
            }
            let recursosFrontService = apiRedfid["recursos_front"];
            if (recursosFrontService) {
                setRecursosFrontStatus("ready");
                setRecursosFrontMessage("La integración con el front-end de RedFID Recursos está funcionando correctamente.");
            } else {
                setRecursosFrontStatus("error");
                setRecursosFrontMessage("No se pudo conectar con el front-end de RedFID Recursos. Por favor contacte al administrador.");
            }
            if (courses && !loadingCourses) {
                setStudioStatus("ready");
                setStudioMessage("Open edX (Studio) está funcionando correctamente.");
                var certs;
                try {
                    certs = courses[Object.keys(courses)[0]]["sections"]["2"]["data"]["certificates"];
                } catch (e) {
                    certs = false;
                }
                if (certs) {
                    setLmsStatus("ready");
                    setLmsMessage("Open edX (LMS) está funcionando correctamente.");
                } else {
                    setLmsStatus("loading");
                    setLmsMessage("Open edX (LMS) se encuentra accesible, pero la RedFID edX API no. No se podrá acceder a todos los datos de los cursos. Por favor contacte al administrador.");
                }
            } else if (loadingCourses) {
                setLmsStatus("loading");
                setLmsMessage("Cargando información de la API de Open edX (LMS)...");
                setStudioStatus("loading");
                setStudioMessage("Cargando información de la API de Open edX (Studio)...");
            } else {
                setLmsStatus("error");
                setLmsMessage("Error al cargar información de la API de Open edX (LMS). Por favor contacte al administrador.");
                setStudioStatus("error");
                setStudioMessage("Error al cargar información de la API de Open edX (Studio). Por favor contacte al administrador.");
            }
            if (domainExpiration && !loadingDomainExpiration) {
                setExpirationStatus("ready");
                setExpirationMessage("La información del dominio cargó exitosamente.");
            } else if (loadingDomainExpiration) {
                setExpirationStatus("loading");
                setExpirationMessage("Cargando información del dominio...");
            } else {
                setExpirationStatus("error");
                setExpirationMessage("Error al cargar información del dominio. Por favor contacte al administrador.");
            }
            if (lastBackup && !loadingLastBackup) {
                setBackupStatus("ready");
                setBackupMessage("La información del último respaldo cargó exitosamente.");
            } else if (loadingLastBackup) {
                setBackupStatus("loading");
                setBackupMessage("Cargando información del último respaldo...");
            } else {
                setBackupStatus("error");
                setBackupMessage("Error al cargar información del último respaldo. Por favor contacte al administrador.");
            }
        } else if (loadingApiRedfid) {
            setApiStatus("loading");
            setApiMessage("Conectando con la API de RedFID...");
        } else {
            setApiStatus("error");
            setApiMessage("No se pudo conectar con la API de RedFID. Por favor contacte al administrador.");
            setLmsStatus("error");
            setLmsMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setStudioStatus("error");
            setStudioMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setExpirationStatus("error");
            setExpirationMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setBackupStatus("error");
            setBackupMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setGmailStatus("error");
            setGmailMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setDriveStatus("error");
            setDriveMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setHomeStatus("error");
            setHomeMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setDiscourseStatus("error");
            setDiscourseMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
            setRecursosFrontStatus("error");
            setRecursosFrontMessage("Este servicio depende de la API de RedFID, que se encuentra inaccesible. Por favor contacte al administrador.");
        }
    }, [
        apiRedfid, 
        loadingApiRedfid,
        domainExpiration, 
        loadingDomainExpiration,
        lastBackup, 
        loadingLastBackup,
        courses, 
        loadingCourses,
        driveData,
        loadingDriveData,
    ]);


    return (
        <Grid container columnSpacing={2} display={"flex"} justifyContent={"center"}>
            <Grid item xs={12} sm={6} md={3}>
                <List sx={{ bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} alt={"Keycloak"} src={keycloakIcon}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Keycloak"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Keycloak"}</Typography>
                                            {"Keycloak es un software de código abierto que proporciona servicios de identidad y acceso, basado en estándares como OAuth 2.0, OpenID Connect y SAML. En el caso de RedFID, se encarga de administrar los usuarios y la autenticación en las plataformas."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Keycloak"}</Typography>
                                            {keycloakMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (keycloakStatus === "loading" ? "gold" : keycloakStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_ISSUER} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_ISSUER}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={redfidCelesteIcon} alt={"API RedFID"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"API RedFID"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"API RedFID"}</Typography>
                                            {"La API de RedFID es un servicio web desarrollado localmente que realiza labores de integración y administración de los distintos ambientes de RedFID."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip

                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"API RedFID"}</Typography>
                                            {apiMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (apiStatus === "loading" ? "gold" : apiStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_API_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_API_URL}</Typography></Link>
                            </Box>
                        </Box>

                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={redfidCelesteIcon} alt={"API Recursos"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"API Recursos"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"API Recursos"}</Typography>
                                            {"La API de Recursos es un servicio web desarrollado localmente que administra los recursos académicos y el material docente de RedFID Recursos."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"API Recursos"}</Typography>
                                            {recursosMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (recursosStatus === "loading" ? "gold" : recursosStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_RECURSOS_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_RECURSOS_URL}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={redfidCelesteIcon} alt={"Home"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Home"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Home"}</Typography>
                                            {"El Home de RedFID es una plataforma desarrollada localmente."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Home"}</Typography>
                                            {homeMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (homeStatus === "loading" ? "gold" : homeStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_HOME_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_HOME_URL}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={discourseIcon} alt={"Discourse"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Discourse"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Discourse"}</Typography>
                                            {"Discourse es una plataforma de foros de discusión en línea de código abierto. En el caso de RedFID, el ambiente de Comunidades consiste en una implementación de Discourse."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Discourse"}</Typography>
                                            {discourseMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (discourseStatus === "loading" ? "gold" : discourseStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_COMUNIDADES_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_COMUNIDADES_URL}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={redfidCelesteIcon} alt={"Recursos"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Recursos"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Recursos"}</Typography>
                                            {"El ambiente de Recursos de RedFID es una plataforma desarrollada localmente, que permite la gestión de recursos académicos y material docente."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Recursos"}</Typography>
                                            {recursosFrontMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (recursosFrontStatus === "loading" ? "gold" : recursosFrontStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_RECURSOS_FRONT_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_RECURSOS_FRONT_URL}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={openedxIcon} alt={"Open edX (LMS)"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Open edX (LMS)"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Open edX (LMS)"}</Typography>
                                            {"Open edX es una plataforma de aprendizaje en línea de código abierto, desarrollada por edX y la Universidad de Harvard. En el caso de RedFID, el ambiente de Aprendizaje Profesional consiste en una implementación de Open edX, que integra los cursos en línea, talleres, webinars y cápsulas."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Open edX (LMS)"}</Typography>
                                            {lmsMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (lmsStatus === "loading" ? "gold" : lmsStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_CURSOS_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_CURSOS_URL}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={openedxIcon} alt={"Open edX (Studio)"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Open edX (Studio)"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Open edX (Studio)"}</Typography>
                                            {"Open edX Studio es la interfaz de administración de cursos de Open edX, que permite la creación y edición de cursos en línea."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Open edX (Studio)"}</Typography>
                                            {studioMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (studioStatus === "loading" ? "gold" : studioStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={process.env.REACT_APP_STUDIO_URL} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_STUDIO_URL}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={googleIcon} alt={"Google Workspace"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Google Workspace"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Google Workspace"}</Typography>
                                            {"Google Workspace es una suite de aplicaciones de productividad y colaboración en la nube, desarrollada por Google. En el caso de RedFID, se utilizan los servicios de Gmail, Calendar y Drive."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Google Workspace"}</Typography>
                                            {gmailMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (gmailStatus === "loading" ? "gold" : gmailStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={"mailto:" + process.env.REACT_APP_GMAIL_ACCOUNT} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{process.env.REACT_APP_GMAIL_ACCOUNT}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={nicIcon} alt={"NIC Chile"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Expiración del dominio"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Expiración del dominio"}</Typography>
                                            {"NIC Chile es el servicio que administra los dominios .cl en Chile."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Expiración del dominio"}</Typography>
                                            {expirationMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (expirationStatus === "loading" ? "gold" : expirationStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={"https://www.nic.cl/registry/Whois.do?d=redfid.cl"} target="_blank"><Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>{domainExpiration ? domainExpiration : "—"}</Typography></Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"borderRadius": "0"}} src={driveIcon} alt={"Google Drive"}>
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Almacenamiento en Drive"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Almacenamiento en Drive"}</Typography>
                                            {"RedFID cuenta con un almacenamiento de 2 TB en Google Drive, que se utiliza para las unidades compartidas de los grupos en Comunidades, y para los correos electrónicos @redfid.cl."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Almacenamiento en Drive"}</Typography>
                                            {driveMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (driveStatus === "loading" ? "gold" : driveStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Link href={"https://drive.google.com"} target="_blank">
                                    <Typography mb={0} fontSize={"0.85em"} variant={"a"} fontWeight={"bold"}>
                                        {driveData ? formatFileSize(driveData["storageQuota"]["storageQuota"]["usage"]) + " (" + (100*parseInt(driveData["storageQuota"]["storageQuota"]["usage"])/parseInt(driveData["storageQuota"]["storageQuota"]["limit"])).toString().slice(0, 4) + "%)" : "—"}
                                    </Typography>
                                </Link>
                            </Box>
                        </Box>
                    </ListItem>
                    <ListItem>
                        <ListItemAvatar>
                            <Avatar sx={{"backgroundColor": "#40b4ba"}}>
                                <SettingsBackupRestoreIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <Box ml={"-10px"}>
                            <Typography ml={"10px"} mb={"-10px"} fontWeight={"bold"}>
                                {"Último respaldo"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Último respaldo"}</Typography>
                                            {"Los datos de la plataforma se respaldan automáticamente todos los días a las 00:00."}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                            <Box display={"flex"} alignItems={"center"}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Último respaldo"}</Typography>
                                            {backupMessage}
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton ><CircleIcon sx={{ fontSize: "0.6em", color: (backupStatus === "loading" ? "gold" : backupStatus === "ready" ? "green" : "red") }}></CircleIcon></IconButton>
                                </HtmlTooltip>
                                <Typography mb={0} fontSize={"0.85em"} variant={"a"} sx={{"cursor": "inherit"}} fontWeight={"bold"}>{lastBackup ? lastBackup : "—"}</Typography>
                            </Box>
                        </Box>
                    </ListItem>
                </List>
            </Grid>
        </Grid>
    );
}

export default StatusIndicator;