import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";


const DownloadCSVUniversitiesForm = ({
    csvUniversityName,
    setCsvUniversityName,
    csvUniversityMembers,
    setCsvUniversityMembers,
    csvUniversityMembersList,
    setCsvUniversityMembersList,
    csvExcel,
    setCsvExcel
}) => {

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="Código de universidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvUniversityName}
                                onChange={
                                    (e) => {
                                        setCsvUniversityName(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Nombre de universidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvUniversityMembers}
                                onChange={
                                    (e) => {
                                        setCsvUniversityMembers(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Miembros de universidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvUniversityMembersList}
                                onChange={
                                    (e) => {
                                        setCsvUniversityMembersList(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Lista de miembros de universidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={
                                    (e) => {
                                        setCsvExcel(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );
}

export default DownloadCSVUniversitiesForm;