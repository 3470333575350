import React from 'react';
import { Grid, TextField, MenuItem, Autocomplete, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { formatUsersIntoSelector } from '../../../utils/formatters';

const CreateGroupForm = ({
    users,
    groupName,
    setGroupName,
    groupPublicAdmission,
    setGroupPublicAdmission,
    groupPublicExit,
    setGroupPublicExit,
    groupAllowMembershipRequests,
    setGroupAllowMembershipRequests,
    groupVisibilityLevel,
    setGroupVisibilityLevel,
    groupOwner,
    setGroupOwner,
    errors,
    setErrors,
    formLoading
}) => {

    return (
        <>
            <Grid container spacing={2} justifyContent={"center"}>
                <Grid item xs={12} md={6}>
                    <TextField
                        fullWidth
                        label="Nombre del grupo"
                        variant="outlined"
                        value={groupName}
                        onChange={(e) => {
                            setGroupName(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, groupName: "" }))
                        }}
                        error={!!errors.groupName}
                        helperText={errors.groupName}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} md={3}>
                    <TextField
                        fullWidth
                        select
                        label="Visibilidad"
                        variant="outlined"
                        value={groupVisibilityLevel}
                        onChange={(e) => {
                            setGroupVisibilityLevel(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, groupVisibilityLevel: "" }))
                        }}
                        error={!!errors.groupVisibilityLevel}
                        helperText={errors.groupVisibilityLevel}
                        disabled={formLoading}
                    >
                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="0">Público</MenuItem>
                        <MenuItem value="1">Semi-público</MenuItem>
                        <MenuItem value="2">Privado</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Autocomplete
                        value={groupOwner}
                        onChange={(event, newValue) => {
                            setGroupOwner(newValue != null ? { email: newValue.email, label: newValue.label, username: newValue.username, firstName: newValue.firstName, lastName: newValue.lastName } : null);
                            setErrors({ ...errors, groupOwner: '' });

                        }}
                        id="tags-outlined"
                        options={formatUsersIntoSelector(users)}
                        ChipProps={{ variant: "orange" }}
                        getOptionLabel={(u) => u.label + " (" + u.username + ")"}
                        getOptionKey={(u) => u.username}
                        isOptionEqualToValue={(option, value) => { return option.username === value.username }}
                        defaultValue={undefined}
                        disabled={formLoading}
                        filterSelectedOptions
                        noOptionsText="No se encontraron usuarios."
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Propietario del grupo"
                                placeholder="Propietario del grupo"
                                error={!!errors.groupOwner}
                                helperText={errors.groupOwner}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} display={"flex"} flexDirection={"column"}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(groupVisibilityLevel === "2" || groupAllowMembershipRequests) ? false : groupPublicAdmission}
                                    onChange={(e) => setGroupPublicAdmission(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } sx={{ "color": '#646464' }} disabled={groupVisibilityLevel === "2" || groupAllowMembershipRequests} label="Acceso libre" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={groupPublicExit}
                                    onChange={(e) => setGroupPublicExit(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } sx={{ "color": '#646464' }} label="Salida libre" />
                    </FormGroup>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={(groupVisibilityLevel === "2" || groupPublicAdmission) ? false : groupAllowMembershipRequests}
                                    onChange={(e) => setGroupAllowMembershipRequests(e.target.checked)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            } sx={{ "color": '#646464' }} disabled={groupVisibilityLevel === "2" || groupPublicAdmission} label="Permitir solicitudes de membresía" />
                    </FormGroup>
                </Grid>
            </Grid>
        </>
    );
}

export default CreateGroupForm;
