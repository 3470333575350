import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";


const DownloadCSVUsersForm = ({
    csvFirstName,
    setCsvFirstName,
    csvLastName,
    setCsvLastName,
    csvEmail,
    setCsvEmail,
    csvAlternateEmail,
    setCsvAlternateEmail,
    csvProfileImage,
    setCsvProfileImage,
    csvGender,
    setCsvGender,
    csvIsFormador,
    setCsvIsFormador,
    csvIsStaff,
    setCsvIsStaff,
    csvIsAdmin,
    setCsvIsAdmin,
    csvUniversity,
    setCsvUniversity,
    csvRegion,
    setCsvRegion,
    csvCareer,
    setCsvCareer,
    csvActive,
    setCsvActive,
    csvCreatedAt,
    setCsvCreatedAt,
    csvEvents,
    setCsvEvents,
    csvTotalEvents,
    setCsvTotalEvents,
    csvCoursesMetrics,
    setCsvCoursesMetrics,
    csvCapsulasMetrics,
    setCsvCapsulasMetrics,
    csvDiscourseMetrics,
    setCsvDiscourseMetrics,
    csvRecursosMetrics,
    setCsvRecursosMetrics,
    csvExcel,
    setCsvExcel
}) => {


    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="Nombre de usuario" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvFirstName}
                                onChange={
                                    (e) => {
                                        setCsvFirstName(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Nombre" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvLastName}
                                onChange={
                                    (e) => {
                                        setCsvLastName(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Apellido" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvEmail}
                                onChange={
                                    (e) => {
                                        setCsvEmail(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Email" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvAlternateEmail}
                                onChange={
                                    (e) => {
                                        setCsvAlternateEmail(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Email alternativo" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvProfileImage}
                                onChange={
                                    (e) => {
                                        setCsvProfileImage(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Foto de perfil" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGender}
                                onChange={
                                    (e) => {
                                        setCsvGender(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Género" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvIsFormador} 
                                onChange={
                                    (e) => {
                                        setCsvIsFormador(e.target.checked); 
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="¿Formador?" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup> 
                    <FormControlLabel
                        control={
                            <Checkbox   
                                checked={csvIsStaff}
                                onChange={
                                    (e) => {    
                                        setCsvIsStaff(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="¿Staff?" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup> 
                    <FormControlLabel
                        control={
                            <Checkbox   
                                checked={csvIsAdmin}
                                onChange={
                                    (e) => {    
                                        setCsvIsAdmin(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="¿Administrador?" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvUniversity}
                                onChange={
                                    (e) => {
                                        setCsvUniversity(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Universidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvRegion}
                                onChange={
                                    (e) => {
                                        setCsvRegion(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Región" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCareer}
                                onChange={
                                    (e) => {
                                        setCsvCareer(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Programa" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvActive}
                                onChange={
                                    (e) => {
                                        setCsvActive(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Activo/suspendido" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCreatedAt}
                                onChange={
                                    (e) => {
                                        setCsvCreatedAt(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Fecha de creación" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvEvents}
                                onChange={
                                    (e) => {
                                        setCsvEvents(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Total de asistencia a eventos" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTotalEvents}
                                onChange={
                                    (e) => {
                                        setCsvTotalEvents(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Eventos asistidos" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCoursesMetrics}
                                onChange={
                                    (e) => {
                                        setCsvCoursesMetrics(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Resumen de cursos" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCapsulasMetrics}
                                onChange={
                                    (e) => {
                                        setCsvCapsulasMetrics(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Resumen de cápsulas" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvDiscourseMetrics}
                                onChange={
                                    (e) => {
                                        setCsvDiscourseMetrics(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Resumen de Comunidades" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvRecursosMetrics}
                                onChange={
                                    (e) => {
                                        setCsvRecursosMetrics(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Resumen de Recursos" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={
                                    (e) => {
                                        setCsvExcel(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );

}

export default DownloadCSVUsersForm;