import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Menu, MenuItem, Typography, Box } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import { formatDateFromUnix } from '../../../utils/formatters';


const UserActivityTable = ({
    keycloakEvents,
    comunidadesEvents,
    recursosEvents,
    loadingEvents,
    handleOpenActivityCsvModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });
    const [allEvents, setAllEvents] = useState(null);

    const columns = [
        {
            field: 'time',
            headerName: 'Fecha',
            align: 'center',
            sortable: true,
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 200,
            sortComparator: (v1, v2) => v2 - v1,
            valueFormatter: (params, row) => formatDateFromUnix(row.time),
            renderCell: (params) => formatDateFromUnix(params.row.time)
        },
        {
            field: 'platform',
            headerName: 'Plataforma',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 200
        },
        {
            field: 'eventType',
            headerName: 'Tipo',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            flex: 1
        }

    ];

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Actividad</Typography>
                <Box>
                    <IconButton onClick={handleOpenActivityCsvModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    useEffect(() => {
        if (keycloakEvents && comunidadesEvents && recursosEvents && !loadingEvents) {
            const allEvents = keycloakEvents.concat(comunidadesEvents).concat(recursosEvents);
            setAllEvents(allEvents);
        }
    }, [keycloakEvents, comunidadesEvents, recursosEvents, loadingEvents]);

    return (
        allEvents &&
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={allEvents}
                columns={columns}
                pageSizeOptions={[10]}
                getRowId={(row) => {return row.id ? row.id : Math.floor(Math.random() * 999999999);}}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'time', sort: 'asc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>

    );

}

export default UserActivityTable;


