import axios from 'axios';

const getUserImage = async (api, username) => {
    try {

        const urlResponse = await axios.get(api.api_url + "/get_user_image_url/" + username);
        switch (urlResponse.data.status) {
            case 100:
                return { url: urlResponse.data.url, error: "" };
            case 105:
                return { url: "", error: "No se pudo obtener la imagen." };
            default:
                return { url: "", error: "Error desconocido." };
        }
    } catch (error) {
        return { url: "", error: "Error desconocido: " + error.message };
    }
}

export default getUserImage;