import axios from 'axios';

export const createGroup = async (api, groupData) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { groups: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const groupsResponse = await axios.post(api.api_url + "/create_group", groupData, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });


        switch (groupsResponse.data.status) {
            case 100:
                return { groups: groupsResponse.data.groups, error: "" };
            case 101:
                return { groups: null, error: "Error al conectar con Keycloak en back-end." };
            case 102:
                return { groups: null, error: "Input inválido." };    
            case 104:
                return { groups: null, error: "El grupo ya existe." };    
            case 105:
                return { groups: null, error: "Falló la solicitud para crear un grupo en Discourse." };
            default:
                return { groups: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { groups: [], error: "Error desconocido: " + error.message };
    }
}
