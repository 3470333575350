import React from 'react';
import { Grid, TextField, MenuItem } from '@mui/material';


const TallerWebinarForm = ({
    title,
    setTitle,
    kind,
    setKind,
    date,
    setDate,
    video_url,
    setVideoUrl,
    exposes,
    setExposes,
    priority,
    setPriority,
    published,
    setPublished,
    description,
    setDescription,
    associated,
    setAssociated,
    formLoading,
    errors,
    setErrors,
    events,
    entryId
}) => {

    return (
        <Grid container columnSpacing={2} alignItems={"center"}>
            <Grid item xs={12} sm={12} mt={"20px"}>
                <TextField
                    fullWidth
                    label="Título"
                    variant="outlined"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, title: "" }))
                    }}
                    error={!!errors.title}
                    helperText={errors.title}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} md={4} mt={"20px"}>
                <TextField
                    fullWidth
                    select
                    label="Tipo"
                    variant="outlined"
                    value={kind}
                    onChange={(e) => {
                        setKind(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, kind: "" }))
                    }}
                    error={!!errors.kind}
                    helperText={errors.kind}
                    disabled={formLoading}
                >
                    <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                    <MenuItem value="taller">Taller</MenuItem>
                    <MenuItem value="webinar">Webinar</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} md={8} mt={"20px"}>
                <TextField
                    select
                    label="Evento asociado"
                    variant="outlined"
                    fullWidth
                    value={associated}
                    onChange={(e) => {
                        setAssociated(e.target.value);
                        setErrors({ ...errors, associated: '' });
                    }}
                    error={!!errors.associated}
                    helperText={errors.associated}
                    disabled={formLoading}
                >
                    <MenuItem disabled value={"none"}>Por favor seleccione una opción...</MenuItem>
                    <MenuItem value={0}>Ninguno</MenuItem>
                    {entryId ?
                        events.filter(ev => (ev.associated === 0 || ev.associated === entryId)).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.title}
                            </MenuItem>
                        ))
                        :
                        events.filter(ev => ev.associated === 0).map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                                {option.title}
                            </MenuItem>
                        ))
                    }
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} mt={"20px"}>
                <TextField
                    fullWidth
                    label="Fecha"
                    variant="outlined"
                    value={date}
                    onChange={(e) => {
                        setDate(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, date: "" }))
                    }}
                    error={!!errors.date}
                    helperText={errors.date}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} mt={"20px"}>
                <TextField
                    fullWidth
                    label="URL del video"
                    variant="outlined"
                    value={video_url}
                    onChange={(e) => {
                        setVideoUrl(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, video_url: "" }))
                    }}
                    error={!!errors.video_url}
                    helperText={errors.video_url}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} mt={"20px"}>
                <TextField
                    fullWidth
                    label="Expositores"
                    variant="outlined"
                    value={exposes}
                    onChange={(e) => {
                        setExposes(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, exposes: "" }))
                    }}
                    error={!!errors.exposes}
                    helperText={errors.exposes}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                <TextField
                    fullWidth
                    label="Prioridad"
                    variant="outlined"
                    value={priority}
                    onChange={(e) => {
                        setPriority(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, priority: "" }))
                    }}
                    error={!!errors.priority}
                    helperText={errors.priority}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                <TextField
                    fullWidth
                    select
                    label="Publicado"
                    variant="outlined"
                    value={published}
                    onChange={(e) => {
                        setPublished(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, published: "" }))
                    }}
                    disabled={formLoading}
                    helperText={errors.published}
                    error={!!errors.published}
                >
                    <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                    <MenuItem value="yes">Sí</MenuItem>
                    <MenuItem value="no">No</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} mt={"20px"}>
                <TextField
                    fullWidth
                    label="Descripción"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={(e) => {
                        setDescription(e.target.value)
                        setErrors(prevErrors => ({ ...prevErrors, description: "" }))
                    }}
                    error={!!errors.description}
                    helperText={errors.description}
                    disabled={formLoading}
                />
            </Grid>
        </Grid>
    )
}

export default TallerWebinarForm;