import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { Typography, IconButton, Box, Link, Menu, MenuItem, Avatar } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import BlockIcon from '@mui/icons-material/Block';
import BoltIcon from '@mui/icons-material/Bolt';
import InfoIcon from '@mui/icons-material/Info';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import { esES } from '@mui/x-data-grid/locales';
import { formatDateFromUnix } from '../../../utils/formatters';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


const UsersTable = ({
  users,
  attributes,
  handleOpenCreateModal,
  handleOpenStatsModal,
  handleOpenDownloadCSVModal,
  handleOpenInfoModal,
  handleOpenActivityModal,
  handleOpenChangePasswordModal,
  handleOpenEditModal,
  handleOpenSuspendModal,
  handleOpenDeleteModal
}) => {

  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  const columns = [
    {
      field: 'actions',
      headerName: 'Acciones',
      sortable: false,
      width: 100,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <ActionsMenu user={params.row} />
      )
    },
    {
      field: 'picture',
      headerName: 'Foto',
      sortable: false,
      width: 60,
      disableColumnMenu: true,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        params.row.discourse_data == null ? <Avatar alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} /> : params.row.discourse_data.avatar_template == null ? <Avatar alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} /> : <Avatar src={process.env.REACT_APP_COMUNIDADES_URL + params.row.discourse_data.avatar_template.replace("{size}", "144")} alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} />
      )
    },
    {
      field: 'lastName',
      disableColumnMenu: true,
      headerName: 'Apellido',
      width: 150,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'firstName',
      disableColumnMenu: true,
      headerName: 'Nombre',
      width: 150,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'username',
      disableColumnMenu: true,
      headerName: 'Usuario',
      width: 120,
      align: 'left',
      headerAlign: 'left'
    },
    {
      field: 'enabled',
      disableColumnMenu: true,
      headerName: 'Activo',
      sortable: false,
      width: 80,
      headerAlign: 'center',
      align: 'center',
      sortComparator: (a, b) => {
        return a - b;
      },
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          {params.row.enabled
            ? <CheckCircleIcon sx={{ color: "#088408" }} />
            : <CancelIcon sx={{ color: "#ff0000" }} />
          }
        </Box>
      )
    },
    { 
      field: 'email', 
      disableColumnMenu: true, 
      headerName: 'Email', 
      flex: 1, 
      align: 'left', 
      headerAlign: 'left', 
      sortable: false,
      renderCell: (params) => <Link sx={{ "color": "#40b4ba" }} href={"mailto:" + params.row.email}>{params.row.email}</Link> 
    },
    {
      field: 'createdTimestamp',
      disableColumnMenu: true,
      headerName: 'Creado',
      width: 180,
      align: 'center',
      headerAlign: 'center',
      sortComparator: (a, b) => a - b,
      valueGetter: (params, row) => row.createdTimestamp,
      renderCell: (params) => formatDateFromUnix(params.row.createdTimestamp)
    },
    {
      field: 'is_formador',
      disableColumnMenu: true,
      headerName: 'Formador',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      sortComparator: (a, b) => {
        return a - b;
      },
      valueGetter: (params, row) => row.attributes == null ? "—" : row.attributes.is_formador == null ? "—" : row.attributes.is_formador[0],
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          {params.row.attributes == null ?
            <></>
            : params.row.attributes.is_formador == null ?
              "—"
              : params.row.attributes.is_formador[0] === "1" ?
                <CheckCircleIcon sx={{ color: "#088408" }} />
                : "—"
          }
        </Box>
      )
    },
    {
      field: 'is_staff',
      disableColumnMenu: true,
      headerName: 'Staff',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      sortComparator: (a, b) => {
        return a - b;
      },
      valueGetter: (params, row) => row.attributes == null ? "—" : row.attributes.is_staff == null ? "—" : row.attributes.is_staff[0],
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          {params.row.attributes == null ?
            <></>
            : params.row.attributes.is_staff == null ?
              "—"
              : params.row.attributes.is_staff[0] === "1" ?
                <CheckCircleIcon sx={{ color: "#088408" }} />
                : "—"
          }
        </Box>
      )
    },
    {
      field: 'is_admin',
      disableColumnMenu: true,
      headerName: 'Admin',
      width: 90,
      headerAlign: 'center',
      align: 'center',
      sortComparator: (a, b) => {
        return a - b;
      },
      valueGetter: (params, row) => row.attributes == null ? "—" : row.attributes.is_admin == null ? "—" : row.attributes.is_admin[0],
      renderCell: (params) => (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          {params.row.attributes == null ?
            <></>
            : params.row.attributes.is_admin == null ?
              "—"
              : params.row.attributes.is_admin[0] === "1" ?
                <CheckCircleIcon sx={{ color: "#088408" }} />
                : "—"
          }
        </Box>
      )
    }
  ];

  const ActionsMenu = ({ user }) => {
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    return (
      <div>
        <IconButton
          aria-controls="actions-menu"
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="actions-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              handleOpenInfoModal(user);
            }}
          >
            <InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenActivityModal(user);
            }}
          >
            <BoltIcon sx={{ "marginRight": "10px" }} /> Actividad
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenChangePasswordModal(user);
            }}
          >
            <VpnKeyIcon sx={{ "marginRight": "10px" }} /> Cambiar contraseña
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenEditModal(user);
            }}
          >
            <EditIcon sx={{ "marginRight": "10px" }} /> Editar
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenSuspendModal(user);
            }}
          >
            {user.enabled ? <BlockIcon sx={{ "marginRight": "10px" }} /> : <CheckCircleIcon sx={{ "marginRight": "10px" }} />} {user.enabled ? "Suspender" : "Activar"}
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleOpenDeleteModal(user);
            }}
          >
            <DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar
          </MenuItem>
        </Menu>
      </div>
    );
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
        <Typography variant='table-title'>Usuarios</Typography>
        <Box>
          <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
          <IconButton onClick={handleOpenStatsModal}><InsertChartIcon></InsertChartIcon></IconButton>
          <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
        </Box>
      </GridToolbarContainer>
    );
  }

  return (
    <div style={{ width: '100%', margin: "40px 0" }}>
      <DataGrid
        localeText={esES.components.MuiDataGrid.defaultProps.localeText}
        rows={users}
        columns={columns}
        pageSizeOptions={[10, 25, 50]}
        onPaginationModelChange={setPaginationModel}
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastName', sort: 'asc' }],
          },
          pagination: {
            paginationModel
          }
        }}
        slots={{
          toolbar: CustomToolbar,
        }}
      />
    </div>
  );
};

export default UsersTable;
