import axios from "axios";

export const toggleRecursoFeaturedMain = async (type, id) => {
    try {
        const responseToggleRecursoFeaturedMain = await axios.post(process.env.REACT_APP_RECURSOS_URL + "/admin/toggle_main_featured/" + type + "/" + id, {}, {
            headers: {
                "Authorization": process.env.REACT_APP_RECURSOS_TOKEN,
                "Content-Type": "application/json"
            }
        })

        switch (responseToggleRecursoFeaturedMain.status) {
            case 200:
                return { recursos: responseToggleRecursoFeaturedMain.data, error: "" };
            default:
                return { recursos: [], error: "Falló la solicitud para buscar recursos en RedFID Recursos." };
        }
    } catch (error) {
        return { recursos: [], error: "Error desconocido: " + error.message };
    }
}