import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const PieChart = ({ id, data, title, height }) => {
    useEffect(() => {
        const rawData = typeof data === 'function' ? data() : data;
        const formattedData = rawData.map(item => ({...item, color: am4core.color(item.color)}));

        let chart = am4core.create(id, am4charts.PieChart);
        chart.data = formattedData;

        let plotTitle = chart.titles.create();
        plotTitle.text = title;
        plotTitle.fill = am4core.color("#646464");

        let pieSeries = chart.series.push(new am4charts.PieSeries());
        pieSeries.dataFields.value = "value";
        pieSeries.dataFields.category = "label";
        pieSeries.slices.template.propertyFields.fill = "color";
        pieSeries.slices.template.propertyFields.stroke = "color";

        pieSeries.slices.template.tooltipText = "{category}: [bold]{value}[/]";
        pieSeries.labels.template.fill = am4core.color("#646464");
        
        chart.legend = new am4charts.Legend();
        chart.legend.position = "right";
        chart.legend.labels.template.fill = am4core.color("#646464"); 
        chart.legend.valueLabels.template.fill = am4core.color("#646464");
        chart.legend.labels.template.fontSize = 12; 
        chart.legend.valueLabels.template.fontSize = 12;
        pieSeries.labels.template.disabled = true;
        pieSeries.ticks.template.disabled = true;

        return () => {
            chart.dispose();
        };
    }, [data, id]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};

export default PieChart;
