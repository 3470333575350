import React, { useEffect } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

const BarChart = ({ id, data, limit, title, horizontal, height, labelAngle }) => {
    useEffect(() => {
        const rawData = typeof data === 'function' ? data() : data;
        var sortedData;
        if (limit) {
            sortedData = rawData.sort((a, b) => b.value - a.value).slice(0, limit);
        } else {
            sortedData = rawData.sort((a, b) => b.value - a.value);
        }
        const formattedData = sortedData.map(item => ({ ...item, color: am4core.color(item.color) }));

        let chart = am4core.create(id, am4charts.XYChart);
        chart.data = formattedData;

        let plotTitle = chart.titles.create();
        plotTitle.paddingBottom = 10;
        plotTitle.text = title;
        plotTitle.fill = am4core.color("#646464");

        let categoryAxis, valueAxis;

        if (horizontal) {
            categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'label';
            categoryAxis.renderer.inversed = true;
        } else {
            categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = 'label';
        }

        categoryAxis.renderer.grid.template.location = 0;
        categoryAxis.renderer.minGridDistance = 20;
        categoryAxis.renderer.labels.template.fill = am4core.color("#646464");
        categoryAxis.renderer.labels.template.truncate = true;
        categoryAxis.renderer.labels.template.fontSize = 12;
        categoryAxis.renderer.labels.template.maxWidth = 300;
        if (labelAngle && labelAngle !== 0) {
            categoryAxis.renderer.labels.template.rotation = labelAngle;
            categoryAxis.renderer.labels.template.horizontalCenter = "right";
            categoryAxis.renderer.labels.template.verticalCenter = "middle";
        } else {
            categoryAxis.renderer.labels.template.rotation = 0;
        }
        

        if (horizontal) {
            valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
        } else {
            valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        }

        valueAxis.min = 0;
        valueAxis.strictMinMax = true;
        valueAxis.renderer.minGridDistance = 30;
        valueAxis.renderer.ticks.template.length = 15;
        valueAxis.renderer.ticks.template.strokeOpacity = 1;
        valueAxis.renderer.ticks.template.stroke = am4core.color("#000");
        valueAxis.renderer.baseGrid.disabled = true;
        valueAxis.renderer.minGridDistance = 50;
        valueAxis.strictMinMax = true;
        valueAxis.min = 0;
        valueAxis.max = Math.max(...formattedData.map(item => item.value));
        valueAxis.strictMinMax = true;
        valueAxis.renderer.numberFormatter.numberFormat = "#";
        valueAxis.renderer.labels.template.fill = am4core.color("#646464");
        valueAxis.renderer.labels.template.fontSize = 12;

        let series = chart.series.push(new am4charts.ColumnSeries());
        if (horizontal) {
            series.dataFields.valueX = 'value';
            series.dataFields.categoryY = 'label';
            series.columns.template.tooltipText = '{categoryY}: [bold]{valueX}[/]';
        } else {
            series.dataFields.valueY = 'value';
            series.dataFields.categoryX = 'label';
            series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]';
        }

        series.columns.template.fillOpacity = 0.8;
        series.columns.template.propertyFields.fill = 'color';
        series.columns.template.propertyFields.stroke = 'color';

        let columnTemplate = series.columns.template;
        columnTemplate.strokeWidth = 2;
        columnTemplate.strokeOpacity = 1;

        return () => {
            chart.dispose();
        };
    }, [data, id, limit, horizontal, labelAngle]);

    return <div id={id} style={{ width: '100%', height: height }}></div>;
};

export default BarChart;