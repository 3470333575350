import axios from "axios";
import { formatDateFromISOtoUnix } from "../../utils/formatters";

export const getUserRecursosActivity = async (username) => {
    try {
        const response = await axios.get(process.env.REACT_APP_RECURSOS_URL + "/admin/get_user_data/" + username, {
            headers: {
                "Authorization": process.env.REACT_APP_RECURSOS_TOKEN,
                "Content-Type": "application/json"
            }
        })

        switch (response.status) {
            case 200:
                let activities = [];
                for(let action of response.data.academico_visits) {
                    activities.push({
                        time: formatDateFromISOtoUnix(action.timestamp),
                        platform: "Recursos",
                        eventType: "Visita recurso de investigación",
                        detail: {
                            "recurso_id": action.recurso_id,
                        }
                    });
                }
                for (let action of response.data.academico_downloads) {
                    activities.push({
                        time: formatDateFromISOtoUnix(action.timestamp),
                        platform: "Recursos",
                        eventType: "Descarga recurso de investigación",
                        detail: {
                            "recurso_id": action.recurso_id,
                        }
                    });
                }
                for (let action of response.data.docente_visits) {
                    activities.push({
                        time: formatDateFromISOtoUnix(action.timestamp),
                        platform: "Recursos",
                        eventType: "Visita material docente",
                        detail: {
                            "recurso_id": action.activity_id,
                        }
                    });
                }
                for (let action of response.data.docente_downloads) {
                    activities.push({
                        time: formatDateFromISOtoUnix(action.timestamp),
                        platform: "Recursos",
                        eventType: "Descarga recurso de material docente",
                        detail: {
                            "recurso_id": action.recurso_id,
                        }
                    });
                }
                return { activity: activities, error: "" };
            default:
                return { activity: [], error: "Falló la solicitud para obtener la actividad de un usuario en RedFID Recursos." };
        }
    } catch (error) {
        return { activity: [], error: "Error desconocido: " + error.message };
    }
}