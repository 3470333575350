import dayjs from 'dayjs';


export const formatMaterialDocenteAuthors = (authors) => {
    let authorsString = "";
    authors.forEach((author, index) => {
        if (index === authors.length - 1) {
            authorsString += author["author"] + " (" + author["institution"] + ")";
        } else {
            authorsString += author["author"] + " (" + author["institution"] + ")" + ", ";
        }
    });
    return authorsString;
}

export const formatFileSize = (size) => {
    if (size === 0) {
        return "0";
    }
    if (size < 1024) return size + ' Bytes';
    let i = Math.floor(Math.log(size) / Math.log(1024));
    let num = (size / Math.pow(1024, i));
    let round = Math.round(num);
    num = round < 10 ? num.toFixed(2) : round < 100 ? num.toFixed(1) : round;
    return `${num} ${['Bytes', 'kB', 'MB', 'GB', 'TB'][i]}`;
};

export const formatDOI = (doi) => {
    if (!doi.startsWith('http://') && !doi.startsWith('https://') && !doi.startsWith('doi.org') && !doi.startsWith("dx.doi.org")) {
        return doi.replace(doi, 'https://doi.org/' + doi);
    }
    if (doi.startsWith("doi:")) {
        return doi.replace('doi:', 'https://doi.org/');
    }
    if (doi.startsWith("DOI:")) {
        return doi.replace('DOI:', 'https://doi.org/');
    }
    return doi;
}

export const unformatDOI = (doi) => {
    return doi.replace("https://doi.org/", '');
}

export const formatDateFromUnixToISO = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toISOString();
}

export const formatTimestamp = (timestamp) => {
    const date = isNaN(Number(timestamp)) ? new Date(timestamp) : new Date(Number(timestamp));
    const formatter = new Intl.DateTimeFormat('es-ES', {
        year: 'numeric', month: 'long', day: 'numeric',
        hour: 'numeric', minute: 'numeric',
        hour12: false
    });
    return formatter.format(date);
}

export const formatDateFromDayJStoUnix = (date) => {
    return date.toDate().getTime();
}

export const formatDateFromUnixToDayJS = (timestamp) => {
    return dayjs.unix(timestamp/1000)
}

export const concatAuthors = (authors) => {
    var authorsString = '';
    for (var author of authors) {
        if (!author.name) {
            if (authorsString.length > 0) {
                authorsString += ', ';
            }
            authorsString += `${author.family}, ${author.given[0]}.`;
        }
    }
    return authorsString;
}

export const removeHtmlTags = (text) => {
    return text.replace(/<[^>]*>/g, '');
}

export const formatUsersIntoSelector = (users) => {
    if (!Array.isArray(users)) {
        return {
            username: users.username,
            email: users.email,
            firstName: users.firstName,
            lastName: users.lastName,
            label: users.lastName + ", " + users.firstName
        }
    } else {
        let usersArray = [];
        users.forEach(user => {
            usersArray.push({ 
                username: user.username, 
                email: user.email, 
                firstName: user.firstName,
                lastName: user.lastName,
                label: user.lastName + ", " + user.firstName
            });
        });
        return usersArray.sort((a, b) => a.label.localeCompare(b.label));
    }
}

export const formatCourseIntoTable = (course) => {
    let courseSections = [];
    for (let sectionId of Object.keys(course.sections)) {
        if (sectionId !== "0") {
            if (course.sections[sectionId].data) {
                courseSections.push({
                    section: course.sections[sectionId],
                    id: sectionId,
                    sectionName: "Versión N°" + sectionId + " (" + course.sections[sectionId].data["course_id"].split("+")[2] + ")",
                    sectionNumberOfEnrolled: course.sections[sectionId].data.enrolled.length,
                    sectionNumberOfApproved: course.sections[sectionId].data.approved.length,
                    sectionStartDate: course.sections[sectionId].data.start,
                    sectionEndDate: course.sections[sectionId].data.end,
                    sectionCurrent: 0
                });
            } else {
                courseSections.push({
                    section: course.sections[sectionId],
                    id: sectionId,
                    sectionName: "",
                    sectionNumberOfEnrolled: 0,
                    sectionNumberOfApproved: 0,
                    sectionStartDate: "",
                    sectionEndDate: "",
                    sectionCurrent: 0
                });
            }
        }
    };
    return courseSections;
}

export const formatDateFromUnix = (timestamp) => {
    let date = new Date(timestamp);
    let formattedDate = date.toLocaleString('es-ES', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
    return formattedDate;
}

export const formatDateFromISO = (timestamp) => {
    let date = new Date(timestamp);
    let formattedDate = date.toLocaleString('es-ES', {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit',
        hour12: false
    });
    return formattedDate;
}


export const formatDateFromISOtoUnix = (timestamp) => {
    let milliseconds = Date.parse(timestamp);
    let unixTimestamp = Math.floor(milliseconds);
    return unixTimestamp;
}


export const translateCategoryRecursoInvestigacion = (category) => {
    switch (category) {
        case "educacion_matematica":
            return "Educación matemática";
        case "formacion_profesores":
            return "Formación de profesores";
        case "aprendizaje_formadores":
            return "Aprendizaje profesional del formador";
        case "cursos_talleres_redfid":
            return "Material RedFID";
        case "other":
            return "Otros";
    }
}

export const colorsCategoryRecursoInvestigacion = (category) => {
    switch (category) {
        case "educacion_matematica":
            return "#FFC107";
        case "formacion_profesores":
            return "#FF5722";
        case "aprendizaje_formadores":
            return "#4CAF50";
        case "cursos_talleres_redfid":
            return "#2196F3";
        case "other":
            return "#9E9E9E";
    }
}

export const translateCategoryActivity = (category) => {
    switch (category) {
        case "material_academico":
            return "Material académico";
        case "matematica":
            return "Matemática";
        case "didactica_de_la_matematica":
            return "Didáctica de la matemática";
        case "formacion_pedagogica":
            return "Formación pedagógica";
        case "formacion_practica":
            return "Formación práctica";
        case "otras_disciplinas":
            return "Otras disciplinas";
    }
}

export const colorsCategoryActivity = (category) => {
    switch (category) {
        case "material_academico":
            return "#FFC107";
        case "matematica":
            return "#FF5722";
        case "didactica_de_la_matematica":
            return "#4CAF50";
        case "formacion_pedagogica":
            return "#2196F3";
        case "formacion_practica":
            return "#9C27B0";
        case "otras_disciplinas":
            return "#9E9E9E";
    }
}

export const translateCapsulaKind = (kind) => {
    switch (kind) {
        case "rep":
            return "Estudio propio";
        case "rhii":
            return "Habilidades para la innovación e investigación";
        case "reac":
            return "Elementos a considerar para enseñar algo";
        case "reo":
            return "Estudio realizado por otro";
        case "rap":
            return "Actividad pedagógica";
        default:
            return "Otro";
    }
}

export const colorsCapsulaKind = (kind) => {
    switch (kind) {
        case "rep":
            return "#e36a5b";
        case "rhii":
            return "#83cce5";
        case "reac":
            return "#b282dc";
        case "reo":
            return "#83cce5";
        case "rap":
            return "#e1d883";
        default:
            return "#646464";
    }
}

export const parseDiscourseCategory = (categoryId) => {
    switch (categoryId) {
        case 4:
            return "Novedades";
        case 5:
            return "Aprendizaje Profesional";
        case 6:
            return "Innovación e Investigación";
        case 7:
            return "Otros";
        default:
            return "Discusión privada";
    }
}