import React, { useState, useEffect } from 'react';
import Modal from './components/Modal';
import { Grid, Box, CircularProgress } from '@mui/material';
import Indicator from './components/Indicator';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Typography from '@mui/material/Typography';
import NewsTable from './components/tables/NewsTable';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import NewsForm from './components/forms/NewsForm';
import { api } from '../API';
import { formatDateFromDayJStoUnix, formatDateFromUnixToDayJS } from '../utils/formatters';
import { validateNewsForm } from '../utils/validators';
import { createNews } from '../requests/news/createNews';
import { editNews } from '../requests/news/editNews';
import { toggleNews } from '../requests/news/toggleNews';
import { deleteNews } from '../requests/news/deleteNews';


const Noticias = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    news,
    setNews
}) => {

    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openStatsModal, setOpenStatsModal] = useState(false);
    const [openDownloadCSVModal, setOpenDownloadCSVModal] = useState(false);
    const [openInfoModal, setOpenInfoModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(false);
    const [openToggleModal, setOpenToggleModal] = useState(false);
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const [selectedNews, setSelectedNews] = useState(null);
    const [formLoading, setFormLoading] = useState(false);

    const [title, setTitle] = useState("");
    const [priority, setPriority] = useState("");
    const [published, setPublished] = useState("none");
    const [author, setAuthor] = useState("");
    const [externalUrl, setExternalUrl] = useState("");
    const [startTimestamp, setStartTimestamp] = useState(null);
    const [endTimestamp, setEndTimestamp] = useState(null);
    const [mainImage, setMainImage] = useState(null);
    const [squareImage, setSquareImage] = useState(null);
    const [content, setContent] = useState([{ type: 'none', block: '', file: null }]);
    const [errors, setErrors] = useState({ "content": [{ type: '', block: '', file: '' }] });

    const [loading, setLoading] = useState(false);


    const handleOpenCreateModal = () => {
        setPriority(news.length > 0 ? Math.max(...news.map(newsItem => newsItem.priority)) + 1 : 1);
        setOpenCreateModal(true);
    }

    const handleCloseCreateModal = () => {
        setOpenCreateModal(false);
    }

    const handleOpenStatsModal = () => {
        setOpenStatsModal(true);
    }

    const handleCloseStatsModal = () => {
        setOpenStatsModal(false);
    }

    const handleOpenDownloadCSVModal = () => {
        setOpenDownloadCSVModal(true);
    }

    const handleCloseDownloadCSVModal = () => {
        setOpenDownloadCSVModal(false);
    }

    const handleOpenInfoModal = (thisNews) => {
        setSelectedNews(thisNews);
        setOpenInfoModal(true);
    }

    const handleCloseInfoModal = () => {
        setSelectedNews(null);
        setOpenInfoModal(false);
    }

    const handleOpenEditModal = async (thisNews) => {
        setSelectedNews(thisNews);
        setTitle(thisNews.title);
        setPriority(thisNews.priority);
        setPublished(thisNews.published ? "yes" : "no");
        setAuthor(thisNews.author);
        setExternalUrl(thisNews.external_url);
        setStartTimestamp(formatDateFromUnixToDayJS(thisNews.start_timestamp));
        setEndTimestamp(formatDateFromUnixToDayJS(thisNews.end_timestamp));
        let mainImageFile = null;
        if (thisNews.main_image) {
            const mainImageUrl = `${process.env.REACT_APP_API_URL}/get_news_image/${thisNews.id}/${thisNews.main_image}`;
            const response = await fetch(mainImageUrl);
            const blob = await response.blob();
            mainImageFile = new File([blob], thisNews.main_image, { type: blob.type });
        }
        setMainImage(mainImageFile);
        let squareImageFile = null;
        if (thisNews.square_image) {
            const squareImageUrl = `${process.env.REACT_APP_API_URL}/get_news_image/${thisNews.id}/${thisNews.square_image}`;
            const response = await fetch(squareImageUrl);
            const blob = await response.blob();
            squareImageFile = new File([blob], thisNews.square_image, { type: blob.type });
        }
        setSquareImage(squareImageFile);
        const preloadedContent = await Promise.all(thisNews.content.map(async contentItem => {
            if (contentItem.type === 'image') {
                const fileUrl = `${process.env.REACT_APP_API_URL}/get_news_image/${thisNews.id}/${contentItem.image}`;
                const response = await fetch(fileUrl);
                const blob = await response.blob();
                const file = new File([blob], contentItem.image, { type: blob.type });
                return {
                    type: contentItem.type,
                    block: '',
                    file: {
                        name: file.name,
                        size: file.size,
                        file: file
                    }
                };
            } else {
                return {
                    type: contentItem.type,
                    block: contentItem.block,
                    file: null
                };
            }
        }));
        setContent(preloadedContent);
        setErrors({
            content: thisNews.content.map(() => ({
                type: '',
                block: '',
                file: ''
            }))
        });
        setOpenEditModal(true);
    };

    const handleCloseEditModal = () => {
        setSelectedNews(null);
        setOpenEditModal(false);
    }

    const handleOpenToggleModal = (thisNews) => {
        setSelectedNews(thisNews);
        setOpenToggleModal(true);
    }

    const handleCloseToggleModal = () => {
        setSelectedNews(null);
        setOpenToggleModal(false);
    }

    const handleOpenDeleteModal = (thisNews) => {
        setSelectedNews(thisNews);
        setOpenDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setSelectedNews(null);
        setOpenDeleteModal(false);
    }

    const resetForm = () => {
        setTitle("");
        setPriority("");
        setPublished("none");
        setAuthor("");
        setExternalUrl("");
        setStartTimestamp(null);
        setEndTimestamp(null);
        setMainImage(null);
        setSquareImage(null);
        setContent([{ type: 'none', block: '', file: null }]);
        setErrors({ "content": [{ type: '', block: '', file: '' }] });
    }

    const handleCreateNews = async () => {
        const [isValid, tempErrors] = validateNewsForm(title, priority, published, author, externalUrl, startTimestamp, endTimestamp, content, mainImage, squareImage);
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let createData = {
            title: title,
            priority: priority,
            published: published,
            author: author,
            externalUrl: externalUrl,
            startTimestamp: formatDateFromDayJStoUnix(startTimestamp),
            endTimestamp: formatDateFromDayJStoUnix(endTimestamp)
        }
        let formData = new FormData();
        for (let key in createData) {
            formData.append(key, createData[key]);
        }
        formData.append("mainImage", mainImage);
        formData.append("squareImage", squareImage);
        if (externalUrl === "") {
            for (let i = 0; i < content.length; i++) {
                formData.append(`content[${i}][type]`, content[i].type);
                formData.append(`content[${i}][block]`, content[i].block);
                formData.append(`content[${i}][file]`, content[i].file ? content[i].file.file : "");
            }
        } else {
            formData.append(`content[0][type]`, "none");
            formData.append(`content[0][block]`, "");
            formData.append(`content[0][file]`, "");
        }
        const response_create = await createNews(api, formData);
        if (response_create.news != null) {
            handleCloseCreateModal();
            setNews(response_create.news);
            resetForm();
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Noticia creada!`, "text": `La noticia se ha creado correctamente.` } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear noticia", "text": response_create.error } });
        }
        setFormLoading(false);
    }

    const handleEditNews = async (id) => {
        const [isValid, tempErrors] = validateNewsForm(title, priority, published, author, externalUrl, startTimestamp, endTimestamp, content, mainImage, squareImage);
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        let editData = {
            id: id,
            title: title,
            priority: priority,
            published: published,
            author: author,
            externalUrl: externalUrl,
            startTimestamp: formatDateFromDayJStoUnix(startTimestamp),
            endTimestamp: formatDateFromDayJStoUnix(endTimestamp)
        }
        let formData = new FormData();
        for (let key in editData) {
            formData.append(key, editData[key]);
        }
        formData.append("mainImage", mainImage);
        formData.append("squareImage", squareImage);
        if (externalUrl === "") {
            for (let i = 0; i < content.length; i++) {
                formData.append(`content[${i}][type]`, content[i].type);
                formData.append(`content[${i}][block]`, content[i].block);
                formData.append(`content[${i}][file]`, content[i].file ? content[i].file.file : "");
            }
        } else {
            formData.append(`content[0][type]`, "none");
            formData.append(`content[0][block]`, "");
            formData.append(`content[0][file]`, "");
        }
        const response_edit = await editNews(api, formData);
        if (response_edit.news != null) {
            handleCloseEditModal();
            setNews(response_edit.news);
            resetForm();
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": `¡Noticia editada!`, "text": `La noticia se ha editado correctamente.` } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al editar noticia", "text": response_edit.error } });
        }
        setFormLoading(false);
    }

    const handleToggleNews = async (id, published) => {
        setFormLoading(true);
        const response = await toggleNews(api, id);
        if (response.news !== null) {
            setNews(response.news);
            setAlertSuccess(true);
            setAlerts({
                ...alerts, "success": {
                    "title": published ? "¡Noticia ocultada!" : "¡Noticia publicada!",
                    "text": published ? "La noticia ha sido ocultada correctamente." : "La noticia ha sido publicada correctamente."
                }
            });
        } else {
            setAlertError(true);
            setAlerts({
                ...alerts, "error": {
                    "title": published ? "Error al ocultar noticia" : "Error al publicar noticia",
                    "text": response.error
                }
            });
        }
        setFormLoading(false);
        handleCloseToggleModal();
    }

    const handleDeleteNews = async (id) => {
        setFormLoading(true);
        const response = await deleteNews(api, id);
        if (response.news !== null) {
            setNews(response.news);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Noticia eliminada!", "text": "La noticia ha sido eliminada correctamente." } });
        } else {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al eliminar noticia", "text": response.error } });
        }
        setFormLoading(false);
        handleCloseDeleteModal();
    }

    const handleDownloadCSV = () => {
    }

    return (
        <>
            <Grid spacing={2} container justifyContent={"center"}>
                <Grid item xs={3}>
                    <Indicator title="Total noticias" number={
                        Object.keys(news).length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de noticias en RedFID (actuales y antiguas)."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title="Noticias en portada" number={
                        Object.keys(news).filter(newsItem => (news[newsItem]["published"] && news[newsItem]["start_timestamp"] <= Date.now() && news[newsItem]["end_timestamp"] >= Date.now())).length
                    } titleSize="small" subtitleSize="none" helpMessage={
                        "Número total de noticias activas en la portada de RedFID."
                    }></Indicator>
                </Grid>
            </Grid>
            <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} spacing={2} container>
                <NewsTable
                    news={news}
                    handleOpenCreateModal={handleOpenCreateModal}
                    handleOpenStatsModal={handleOpenStatsModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                    handleOpenInfoModal={handleOpenInfoModal}
                    handleOpenEditModal={handleOpenEditModal}
                    handleOpenToggleModal={handleOpenToggleModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                />
            </Grid>
            <Modal
                open={openCreateModal}
                handleClose={handleCloseCreateModal}
                title="Crear noticia"
                content={
                    <>
                        <NewsForm
                            title={title}
                            setTitle={setTitle}
                            priority={priority}
                            setPriority={setPriority}
                            published={published}
                            setPublished={setPublished}
                            author={author}
                            setAuthor={setAuthor}
                            externalUrl={externalUrl}
                            setExternalUrl={setExternalUrl}
                            startTimestamp={startTimestamp}
                            setStartTimestamp={setStartTimestamp}
                            endTimestamp={endTimestamp}
                            setEndTimestamp={setEndTimestamp}
                            content={content}
                            setContent={setContent}
                            mainImage={mainImage}
                            setMainImage={setMainImage}
                            squareImage={squareImage}
                            setSquareImage={setSquareImage}
                            loading={loading}
                            setLoading={setLoading}
                            errors={errors}
                            setErrors={setErrors}
                            editing={false}
                        ></NewsForm>
                    </>
                }
                onClickMainButton={handleCreateNews}
                mainButtonText="Crear"
                width={'lg'}
                cancelButton={true}
                mainButtonDisabled={formLoading}
                startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
            ></Modal>
            <Modal
                open={openStatsModal}
                handleClose={handleCloseStatsModal}
                title="Estadísticas de noticias"
                content={
                    <>
                    </>
                }
                onClickMainButton={handleCloseStatsModal}
                mainButtonText="OK"
                hideMainButton={true}
                width={'lg'}
                cancelButton={false}
                startIcon={null}
            ></Modal>
            <Modal
                open={openDownloadCSVModal}
                handleClose={handleCloseDownloadCSVModal}
                title="Descargar datos"
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todas las noticias de RedFID.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                    </>
                }
                onClickMainButton={handleDownloadCSV}
                mainButtonText="Descargar"
                mainButtonDisabled={formLoading}
                width={'md'}
                cancelButton={true}
                startIcon={<DownloadIcon />}
            ></Modal>
            {selectedNews &&
                <>
                    <Modal
                        open={openInfoModal}
                        handleClose={handleCloseInfoModal}
                        title="Información detallada"
                        content={
                            <>
                            </>
                        }
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText="OK"
                        width={'sm'}
                        cancelButton={false}
                        startIcon={null}
                    ></Modal>
                    <Modal
                        open={openEditModal}
                        handleClose={handleCloseEditModal}
                        title="Editar noticia"
                        content={
                            <>
                                <NewsForm
                                    title={title}
                                    setTitle={setTitle}
                                    priority={priority}
                                    setPriority={setPriority}
                                    published={published}
                                    setPublished={setPublished}
                                    author={author}
                                    setAuthor={setAuthor}
                                    externalUrl={externalUrl}
                                    setExternalUrl={setExternalUrl}
                                    startTimestamp={startTimestamp}
                                    setStartTimestamp={setStartTimestamp}
                                    endTimestamp={endTimestamp}
                                    setEndTimestamp={setEndTimestamp}
                                    content={content}
                                    setContent={setContent}
                                    mainImage={mainImage}
                                    setMainImage={setMainImage}
                                    squareImage={squareImage}
                                    setSquareImage={setSquareImage}
                                    loading={loading}
                                    setLoading={setLoading}
                                    errors={errors}
                                    setErrors={setErrors}
                                    editing={true}
                                ></NewsForm>
                            </>
                        }
                        onClickMainButton={() => handleEditNews(selectedNews.id)}
                        mainButtonText="Editar"
                        mainButtonDisabled={formLoading}
                        width={'lg'}
                        cancelButton={true}
                        startIcon={<EditIcon></EditIcon>}
                    ></Modal>
                    <Modal
                        open={openToggleModal}
                        handleClose={handleCloseToggleModal}
                        title={selectedNews.published ? "Ocultar noticia" : "Publicar noticia"}
                        content={
                            <>
                                <Typography variant="p" mb={"20px"}>
                                    ¿Está seguro de que desea {selectedNews.published ? "ocultar" : "publicar"} la noticia <b>{selectedNews.title}</b>?
                                </Typography>
                            </>
                        }
                        onClickMainButton={() => handleToggleNews(selectedNews.id, selectedNews.published)}
                        mainButtonText={selectedNews.published ? "Ocultar" : "Publicar"}
                        mainButtonDisabled={formLoading}
                        width={'md'}
                        cancelButton={true}
                        startIcon={selectedNews.published ? <VisibilityOffIcon /> : <RemoveRedEyeIcon />}
                    ></Modal>
                    <Modal
                        open={openDeleteModal}
                        handleClose={handleCloseDeleteModal}
                        title="Eliminar noticia"
                        content={
                            <>
                                <Typography variant="p" mb={"20px"}>
                                    ¿Está seguro de que desea eliminar la noticia <b>{selectedNews.title}</b>?
                                </Typography>
                            </>
                        }
                        onClickMainButton={() => handleDeleteNews(selectedNews.id)}
                        mainButtonText="Eliminar"
                        mainButtonDisabled={formLoading}
                        width={'md'}
                        cancelButton={true}
                        startIcon={<DeleteForeverIcon />}
                    ></Modal>
                </>
            }
        </>
    );

}

export default Noticias;
