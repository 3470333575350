import axios from "axios";

export const createActivity = async (formData, authors, files) => {
    try {
        const response = await axios.post(process.env.REACT_APP_RECURSOS_URL + "/admin/activity/", formData, {
            headers: {
                "Authorization": process.env.REACT_APP_RECURSOS_TOKEN
            }
        });

        if (response.status === 200) {
            let activityId = response.data.id;

            const authorPromises = authors.map(author => {
                const authorData = new FormData();
                authorData.append('author', author.author);
                authorData.append('institution', author.institution);
                authorData.append('activity_id', activityId);
                return axios.post(process.env.REACT_APP_RECURSOS_URL + '/admin/activity_author/', authorData, {
                    headers: {
                        "Authorization": process.env.REACT_APP_RECURSOS_TOKEN
                    }
                });
            });

            const filePromises = files.map(file => {
                const fileData = new FormData();
                fileData.append('type', file.type);
                fileData.append('reference', file.reference);
                fileData.append('url', file.url);
                fileData.append('activity_id', activityId);
                fileData.append('filename', file.file ? file.filename : "");
                fileData.append('filesize', file.file ? file.file.size.toString() : "");
                if (file.file) {
                    fileData.append('file', file.file);
                }
                return axios.post(process.env.REACT_APP_RECURSOS_URL + '/admin/recurso_docente/', fileData, {
                    headers: {
                        "Authorization": process.env.REACT_APP_RECURSOS_TOKEN
                    }
                });
            });

            try {
                await Promise.all([...authorPromises, ...filePromises]);
            } catch (error) {
                console.error(error);
                return { recursos: null, error: 'Ha ocurrido un error desconocido. Por favor contacte a administración.' };
            } finally {
                const lastResponse = await axios.get(process.env.REACT_APP_RECURSOS_URL + "/admin/get_all/", {
                    headers: {
                        "Authorization": process.env.REACT_APP_RECURSOS_TOKEN,
                        "Content-Type": "application/json",
                    }
                });

                if (lastResponse.status === 200) {
                    return { recursos: lastResponse.data, error: null };
                } else {
                    return { recursos: null, error: "Falló la solicitud para buscar recursos en RedFID Recursos." };
                }
            }

        } else if (response.status === 491) {
            return { recursos: null, error: "Ya existe una actividad con ese nombre." };
        } else {
            return { recursos: null, error: "Falló la solicitud para crear una actividad en RedFID Recursos." };
        }
    } catch (error) {
        if (error.response && error.response.status === 491) {
            return { recursos: null, error: "Ya existe una actividad con ese nombre." };
        } else {
            return { recursos: null, error: "Error desconocido: " + error.message };
        }
    }
};