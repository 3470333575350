import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useNavigate } from 'react-router-dom';
import logoImage from '../../assets/img/logo.png';

const HeaderLogo = ({ link }) => {

    const navigate = useNavigate();

    const handleClick = () => {
        if (link === "home") {
            window.location.href = "https://www.redfid.cl";
        } else {
            navigate("/");
        }
    }

    return (
        <Grid display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Box
                onClick={handleClick}
                component="img"
                sx={{
                    height: 60,
                    width: 65,
                    cursor: 'pointer',
                }}
                alt="RedFID"
                src={logoImage}
            />
        </Grid>
    );

};

export default HeaderLogo;