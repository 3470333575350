import React, { useState } from 'react';
import { Box, Typography, CircularProgress, Grid, Button, Link, Card, CardContent } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import Indicator from './components/Indicator';
import Modal from './components/Modal';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import urlIcon from '../assets/img/url.png';
import mp4Icon from '../assets/img/mp4.png';
import pngIcon from '../assets/img/png.png';
import fileIcon from '../assets/img/fileIcon.png';
import pdfIcon from '../assets/svg/pdf.svg';
import docxIcon from '../assets/svg/docx.svg';
import pptxIcon from '../assets/svg/pptx.svg';
import xlsxIcon from '../assets/svg/xlsx.svg';
import ytIcon from '../assets/svg/yt.svg';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import RecursosInvestigacionTable from './components/tables/RecursosInvestigacionTable';
import RecursosActivitiesTable from './components/tables/RecursosActivitiesTable';
import BarChart from './components/charts/BarChart';
import PieChart from './components/charts/PieChart';
import DownloadCSVRecursosAcademicosForm from './components/forms/DownloadCSVRecursosAcademicosForm';
import { recursosAcademicosCSV, activitiesCSV } from '../utils/csvDownload';
import { validateRecursosInvestigacionForm, validateActivityForm } from '../utils/validators';
import { createRecurso } from '../requests/recursos/createRecurso';
import { createActivity } from '../requests/recursos/createActivity';
import { toggleRecursoFeatured } from '../requests/recursos/toggleRecursoFeatured';
import { toggleRecursoFeaturedMain } from '../requests/recursos/toggleRecursoFeaturedMain';
import { feedbackRecurso } from '../requests/recursos/feedbackRecurso';
import { editRecurso } from '../requests/recursos/editRecurso';
import { hideRecurso } from '../requests/recursos/hideRecurso';
import { deleteRecurso } from '../requests/recursos/deleteRecurso';
import { formatDOI, unformatDOI, formatMaterialDocenteAuthors, formatFileSize } from '../utils/formatters';
import { getCategoryChip, getRecursoDocenteChip } from '../utils/chips';
import { formatUsersIntoSelector, translateCategoryActivity, translateCategoryRecursoInvestigacion, colorsCategoryRecursoInvestigacion, colorsCategoryActivity } from '../utils/formatters';
import RecursosInvestigacionForm from './components/forms/RecursosInvestigacionForm';
import RecursosActivityForm from './components/forms/RecursosActivityForm';
import { handleDownloadRecursos } from '../utils/download';
import { recursosApi } from '../API';
import DownloadCSVMaterialDocenteForm from './components/forms/DownloadCSVMaterialDocenteForm';

const Recursos = ({
    users,
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    recursos,
    setRecursos,
    activities,
    setActivities,
    recursosDocentes,
    setRecursosDocentes
}) => {

    const [selectedRecurso, setSelectedRecurso] = useState(null);
    const [selectedAction, setSelectedAction] = useState(null);
    const [selectedKind, setSelectedKind] = useState(null);
    const [formLoading, setFormLoading] = useState(false);
    const [createRecursoModal, setCreateRecursoModal] = useState(false);
    const [createActivityModal, setCreateActivityModal] = useState(false);
    const [downloadCSVModal, setDownloadCSVModal] = useState(false);
    const [statsModal, setStatsModal] = useState(false);
    const [infoModal, setInfoModal] = useState(false);
    const [editRecursoModal, setEditRecursoModal] = useState(false);
    const [editActivityModal, setEditActivityModal] = useState(false);
    const [approveModal, setApproveModal] = useState(false);
    const [hideModal, setHideModal] = useState(false);
    const [featureModal, setFeatureModal] = useState(false);
    const [mainModal, setMainModal] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);

    const [loadingDOI, setLoadingDOI] = useState(false);
    const [titleRecurso, setTitleRecurso] = useState('');
    const [subtitleRecurso, setSubtitleRecurso] = useState('');
    const [authorRecurso, setAuthorRecurso] = useState('');
    const [descriptionRecurso, setDescriptionRecurso] = useState('');
    const [visibilityRecurso, setVisibilityRecurso] = useState('none');
    const [fileRecurso, setFileRecurso] = useState(null);
    const [resourceUrlRecurso, setResourceUrlRecurso] = useState('');
    const [categoryRecurso, setCategoryRecurso] = useState('none');
    const [subcategoryRecurso, setSubcategoryRecurso] = useState('none');
    const [referenceRecurso, setReferenceRecurso] = useState('none');
    const [doiRecurso, setDOIRecurso] = useState('');
    const [editorRecurso, setEditorRecurso] = useState('');
    const [journalRecurso, setJournalRecurso] = useState('');
    const [yearRecurso, setYearRecurso] = useState('');
    const [volumeRecurso, setVolumeRecurso] = useState('');
    const [issueRecurso, setIssueRecurso] = useState('');
    const [pagesRecurso, setPagesRecurso] = useState('');
    const [justificationRecurso, setJustificationRecurso] = useState('');
    const [statusRecurso, setStatusRecurso] = useState('none');
    const [featuredStateRecurso, setFeaturedStateRecurso] = useState('none');
    const [userRecurso, setUserRecurso] = useState(null);
    const [errorsRecurso, setErrorsRecurso] = useState({});

    const [titleActivity, setTitleActivity] = useState('');
    const [descriptionActivity, setDescriptionActivity] = useState('');
    const [visibilityActivity, setVisibilityActivity] = useState('none');
    const [categoryActivity, setCategoryActivity] = useState('none');
    const [academicLevelActivity, setAcademicLevelActivity] = useState('none');
    const [stageActivity, setStageActivity] = useState('none');
    const [justificationActivity, setJustificationActivity] = useState('');
    const [statusActivity, setStatusActivity] = useState('none');
    const [featuredStateActivity, setFeaturedStateActivity] = useState('none');
    const [userActivity, setUserActivity] = useState(null);
    const [filesActivity, setFilesActivity] = useState([{ type: 'none', filename: '', file: null, reference: 'none', url: '' }]);
    const [authorsActivity, setAuthorsActivity] = useState([{ author: '', institution: '' }]);
    const [errorsActivity, setErrorsActivity] = useState({ "authors": [{ author: '', institution: '' }], "files": [{ type: '', reference: '', url: '', file: '' }] });

    const [editTitleRecurso, setEditTitleRecurso] = useState('');
    const [editSubtitleRecurso, setEditSubtitleRecurso] = useState('');
    const [editAuthorRecurso, setEditAuthorRecurso] = useState('');
    const [editDescriptionRecurso, setEditDescriptionRecurso] = useState('');
    const [editVisibilityRecurso, setEditVisibilityRecurso] = useState('none');
    const [editFileRecurso, setEditFileRecurso] = useState(null);
    const [editResourceUrlRecurso, setEditResourceUrlRecurso] = useState('');
    const [editCategoryRecurso, setEditCategoryRecurso] = useState('none');
    const [editSubcategoryRecurso, setEditSubcategoryRecurso] = useState('none');
    const [editReferenceRecurso, setEditReferenceRecurso] = useState('none');
    const [editDOIRecurso, setEditDOIRecurso] = useState('');
    const [editEditorRecurso, setEditEditorRecurso] = useState('');
    const [editJournalRecurso, setEditJournalRecurso] = useState('');
    const [editYearRecurso, setEditYearRecurso] = useState('');
    const [editVolumeRecurso, setEditVolumeRecurso] = useState('');
    const [editIssueRecurso, setEditIssueRecurso] = useState('');
    const [editPagesRecurso, setEditPagesRecurso] = useState('');
    const [editJustificationRecurso, setEditJustificationRecurso] = useState('');
    const [editStatusRecurso, setEditStatusRecurso] = useState('none');
    const [editFeaturedStateRecurso, setEditFeaturedStateRecurso] = useState('none');
    const [editUserRecurso, setEditUserRecurso] = useState(null);
    const [editErrorsRecurso, setEditErrorsRecurso] = useState({});

    const [editTitleActivity, setEditTitleActivity] = useState('');
    const [editDescriptionActivity, setEditDescriptionActivity] = useState('');
    const [editVisibilityActivity, setEditVisibilityActivity] = useState('none');
    const [editCategoryActivity, setEditCategoryActivity] = useState('none');
    const [editAcademicLevelActivity, setEditAcademicLevelActivity] = useState('none');
    const [editStageActivity, setEditStageActivity] = useState('none');
    const [editJustificationActivity, setEditJustificationActivity] = useState('');
    const [editStatusActivity, setEditStatusActivity] = useState('none');
    const [editFeaturedStateActivity, setEditFeaturedStateActivity] = useState('none');
    const [editUserActivity, setEditUserActivity] = useState(null);
    const [editFilesActivity, setEditFilesActivity] = useState([{ type: 'none', file: { name: "", size: 0, file: null }, reference: 'none', url: '' }]);
    const [editAuthorsActivity, setEditAuthorsActivity] = useState([{ author: '', institution: '' }]);
    const [editErrorsActivity, setEditErrorsActivity] = useState({ "authors": [{ author: '', institution: '' }], "files": [{ type: '', reference: '', url: '', file: '' }] });

    const [csvInvestigacionExcel, setCsvInvestigacionExcel] = useState(false);
    const [csvInvestigacionTitle, setCsvInvestigacionTitle] = useState(true);
    const [csvInvestigacionSubtitle, setCsvInvestigacionSubtitle] = useState(true);
    const [csvInvestigacionAuthor, setCsvInvestigacionAuthor] = useState(true);
    const [csvInvestigacionDescription, setCsvInvestigacionDescription] = useState(true);
    const [csvInvestigacionVisibility, setCsvInvestigacionVisibility] = useState(true);
    const [csvInvestigacionFilename, setCsvInvestigacionFilename] = useState(true);
    const [csvInvestigacionFilesize, setCsvInvestigacionFilesize] = useState(true);
    const [csvInvestigacionResourceUrl, setCsvInvestigacionResourceUrl] = useState(true);
    const [csvInvestigacionCategory, setCsvInvestigacionCategory] = useState(true);
    const [csvInvestigacionSubcategory, setCsvInvestigacionSubcategory] = useState(true);
    const [csvInvestigacionReference, setCsvInvestigacionReference] = useState(true);
    const [csvInvestigacionDOI, setCsvInvestigacionDOI] = useState(true);
    const [csvInvestigacionEditor, setCsvInvestigacionEditor] = useState(true);
    const [csvInvestigacionJournal, setCsvInvestigacionJournal] = useState(true);
    const [csvInvestigacionYear, setCsvInvestigacionYear] = useState(true);
    const [csvInvestigacionVolume, setCsvInvestigacionVolume] = useState(true);
    const [csvInvestigacionIssue, setCsvInvestigacionIssue] = useState(true);
    const [csvInvestigacionPages, setCsvInvestigacionPages] = useState(true);
    const [csvInvestigacionJustification, setCsvInvestigacionJustification] = useState(true);
    const [csvInvestigacionStatus, setCsvInvestigacionStatus] = useState(true);
    const [csvInvestigacionFeatured, setCsvInvestigacionFeatured] = useState(true);
    const [csvInvestigacionMainFeatured, setCsvInvestigacionMainFeatured] = useState(true);
    const [csvInvestigacionUser, setCsvInvestigacionUser] = useState(true);
    const [csvInvestigacionVisits, setCsvInvestigacionVisits] = useState(true);
    const [csvInvestigacionDownloads, setCsvInvestigacionDownloads] = useState(true);
    const [csvCreationDate, setCsvCreationDate] = useState(true);
    const [csvLastUpdate, setCsvLastUpdate] = useState(true);

    const [csvActivityExcel, setCsvActivityExcel] = useState(false);
    const [csvActivityTitle, setCsvActivityTitle] = useState(true);
    const [csvActivityDescription, setCsvActivityDescription] = useState(true);
    const [csvActivityVisibility, setCsvActivityVisibility] = useState(true);
    const [csvActivityCategory, setCsvActivityCategory] = useState(true);
    const [csvActivityAcademicLevel, setCsvActivityAcademicLevel] = useState(true);
    const [csvActivityStage, setCsvActivityStage] = useState(true);
    const [csvActivityJustification, setCsvActivityJustification] = useState(true);
    const [csvActivityStatus, setCsvActivityStatus] = useState(true);
    const [csvActivityFeatured, setCsvActivityFeatured] = useState(true);
    const [csvActivityMainFeatured, setCsvActivityMainFeatured] = useState(true);
    const [csvActivityUser, setCsvActivityUser] = useState(true);
    const [csvActivityAuthors, setCsvActivityAuthors] = useState(true);
    const [csvActivityFiles, setCsvActivityFiles] = useState(true);
    const [csvActivityVisits, setCsvActivityVisits] = useState(true);
    const [csvActivityDownloads, setCsvActivityDownloads] = useState(true);
    const [csvCreationDateActivity, setCsvCreationDateActivity] = useState(true);
    const [csvLastUpdateActivity, setCsvLastUpdateActivity] = useState(true);

    const handleOpenCreateRecursoModal = () => {
        setCreateRecursoModal(true);
    }

    const handleCloseCreateRecursoModal = () => {
        setCreateRecursoModal(false);
    }

    const handleOpenCreateActivityModal = () => {
        setCreateActivityModal(true);
    }

    const handleCloseCreateActivityModal = () => {
        setCreateActivityModal(false);
    }

    const handleOpenDownloadCSVModal = (kind) => {
        setSelectedKind(kind);
        setDownloadCSVModal(true);
    }

    const handleCloseDownloadCSVModal = () => {
        setSelectedKind(null);
        setDownloadCSVModal(false);
    }

    const handleOpenStatsModal = (kind) => {
        setSelectedKind(kind);
        setStatsModal(true);
    }

    const handleCloseStatsModal = () => {
        setSelectedKind(null);
        setStatsModal(false);
    }

    const handleOpenInfoModal = (recurso) => {
        setSelectedRecurso(recurso);
        setInfoModal(true);
    }

    const handleCloseInfoModal = () => {
        setSelectedRecurso(null);
        setInfoModal(false);
    }

    const handleOpenEditRecursoModal = (recurso) => {
        setSelectedRecurso(recurso);
        setEditCategoryRecurso(recurso.category);
        setEditSubcategoryRecurso(recurso.subcategory);
        setEditVisibilityRecurso(recurso.visibility);
        setEditReferenceRecurso(recurso.detail.reference);
        setEditDOIRecurso(recurso.detail.doi);
        setEditFileRecurso(recurso.detail.reference === "direct" ? { "name": recurso.detail.filename, "size": recurso.detail.filesize } : null);
        setEditResourceUrlRecurso(recurso.detail.url);
        setEditTitleRecurso(recurso.detail.title);
        setEditSubtitleRecurso(recurso.detail.subtitle);
        setEditAuthorRecurso(recurso.detail.author);
        setEditDescriptionRecurso(recurso.detail.abstract);
        setEditEditorRecurso(recurso.detail.editor);
        setEditJournalRecurso(recurso.detail.journal);
        setEditYearRecurso(recurso.detail.year);
        setEditVolumeRecurso(recurso.detail.volume);
        setEditIssueRecurso(recurso.detail.issue);
        setEditPagesRecurso(recurso.detail.pages);
        setEditJustificationRecurso(recurso.justification);
        setEditStatusRecurso(recurso.status);
        setEditFeaturedStateRecurso(recurso.featured ? (recurso.main_featured ? "main_featured" : "featured") : "not_featured");
        setEditUserRecurso(formatUsersIntoSelector(users.find(user => user.username === recurso.username)));
        setEditErrorsRecurso({});
        setEditRecursoModal(true);
    }

    const handleCloseEditRecursoModal = () => {
        setSelectedRecurso(null);
        setEditRecursoModal(false);
    }

    const handleOpenEditActivityModal = (recurso) => {
        setSelectedRecurso(recurso);
        setEditCategoryActivity(recurso.classification);
        setEditAcademicLevelActivity(recurso.level);
        setEditStageActivity(recurso.stage);
        setEditVisibilityActivity(recurso.visibility);
        setEditTitleActivity(recurso.title);
        setEditDescriptionActivity(recurso.description);
        setEditJustificationActivity(recurso.utility);
        setEditStatusActivity(recurso.status);
        setEditFeaturedStateActivity(recurso.featured ? (recurso.main_featured ? "main_featured" : "featured") : "not_featured");
        setEditUserActivity(formatUsersIntoSelector(users.find(user => user.username === recurso.username)));
        setEditAuthorsActivity(recurso.authors.map(author => ({ author: author.author, institution: author.institution })));
        setEditFilesActivity(recurso.recursos.map(file => ({ type: file.type, file: file.reference === "subir_archivo" ? { name: file.filename, size: file.filesize, file: null } : null, reference: file.reference, url: file.url })));
        let errors = {}
        errors.authors = recurso.authors.map(author => ({ author: "", institution: "" }));
        errors.files = recurso.recursos.map(file => ({ type: "", reference: "", url: "", file: "" }));
        setEditErrorsActivity(errors);
        setEditActivityModal(true);
    }

    const handleCloseEditActivityModal = () => {
        setSelectedRecurso(null);
        setEditActivityModal(false);
    }

    const handleOpenApproveModal = (recurso, action) => {
        setSelectedAction(action);
        setSelectedRecurso(recurso);
        setApproveModal(true);
    }

    const handleCloseApproveModal = () => {
        setSelectedAction(null);
        setSelectedRecurso(null);
        setApproveModal(false);
    }

    const handleOpenHideModal = (recurso) => {
        setSelectedRecurso(recurso);
        setHideModal(true);
    }

    const handleCloseHideModal = () => {
        setSelectedRecurso(null);
        setHideModal(false);
    }

    const handleOpenFeatureModal = (recurso) => {
        setSelectedRecurso(recurso);
        setFeatureModal(true);
    }

    const handleCloseFeatureModal = () => {
        setSelectedRecurso(null);
        setFeatureModal(false);
    }

    const handleOpenMainModal = (recurso) => {
        setSelectedRecurso(recurso);
        setMainModal(true);
    }

    const handleCloseMainModal = () => {
        setSelectedRecurso(null);
        setMainModal(false);
    }

    const handleOpenDeleteModal = (recurso) => {
        setSelectedRecurso(recurso);
        setDeleteModal(true);
    }

    const handleCloseDeleteModal = () => {
        setSelectedRecurso(null);
        setDeleteModal(false);
    }

    const handleCreateRecursoAcademico = async () => {
        const [isValid, tempErrors] = validateRecursosInvestigacionForm(
            categoryRecurso,
            subcategoryRecurso,
            visibilityRecurso,
            referenceRecurso,
            doiRecurso,
            fileRecurso,
            resourceUrlRecurso,
            titleRecurso,
            authorRecurso,
            subtitleRecurso,
            descriptionRecurso,
            editorRecurso,
            journalRecurso,
            yearRecurso,
            volumeRecurso,
            issueRecurso,
            pagesRecurso,
            justificationRecurso,
            statusRecurso,
            featuredStateRecurso,
            userRecurso
        );
        if (!isValid) {
            setErrorsRecurso(tempErrors);
            return;
        }
        const createData = {
            username: userRecurso && userRecurso.username,
            first_name: userRecurso && userRecurso.firstName,
            last_name: userRecurso && userRecurso.lastName,
            classification: "material_academico",
            category: categoryRecurso,
            subcategory: subcategoryRecurso,
            visibility: visibilityRecurso,
            reference: referenceRecurso,
            justification: justificationRecurso,
            doi: doiRecurso,
            url: resourceUrlRecurso,
            filename: fileRecurso ? fileRecurso.name : "",
            filesize: fileRecurso ? fileRecurso.size.toString() : "",
            title: titleRecurso,
            subtitle: subtitleRecurso,
            abstract: descriptionRecurso,
            author: authorRecurso,
            editor: editorRecurso,
            journal: journalRecurso,
            year: yearRecurso,
            volume: volumeRecurso,
            issue: issueRecurso,
            pages: pagesRecurso,
            status: statusRecurso,
            featured: (featuredStateRecurso === "featured" || featuredStateRecurso === "main_featured") ? "1" : "0",
            main_featured: featuredStateRecurso === "main_featured" ? "1" : "0"
        }
        let formData = new FormData();
        for (let key in createData) {
            formData.append(key, createData[key]);
        }
        if (fileRecurso) {
            formData.append("file", fileRecurso);
        }
        setFormLoading(true);
        const result_createRecurso = await createRecurso(formData);
        if (result_createRecurso.error) {
            setFormLoading(false);
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "¡Error al crear recurso!", "text": result_createRecurso.error } });
        } else {
            setRecursos(result_createRecurso.recursos.recursos);
            setActivities(result_createRecurso.recursos.activities);
            setRecursosDocentes(result_createRecurso.recursos.recursos_docentes);
            setFormLoading(false);
            handleCloseCreateRecursoModal();
            setCategoryRecurso('none');
            setSubcategoryRecurso('none');
            setVisibilityRecurso('none');
            setReferenceRecurso('none');
            setDOIRecurso('');
            setFileRecurso(null);
            setResourceUrlRecurso('');
            setTitleRecurso('');
            setSubtitleRecurso('');
            setAuthorRecurso('');
            setDescriptionRecurso('');
            setEditorRecurso('');
            setJournalRecurso('');
            setYearRecurso('');
            setVolumeRecurso('');
            setIssueRecurso('');
            setPagesRecurso('');
            setJustificationRecurso('');
            setStatusRecurso('none');
            setFeaturedStateRecurso('none');
            setUserRecurso(null);
            setErrorsRecurso({});
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Recurso creado!", "text": "El recurso ha sido creado correctamente." } });
        }
    }

    const handleDownloadCSVInvestigacion = () => {
        if (selectedKind === "recursos") {
            let downloadOptions = {
                "title": csvInvestigacionTitle,
                "subtitle": csvInvestigacionSubtitle,
                "author": csvInvestigacionAuthor,
                "description": csvInvestigacionDescription,
                "visibility": csvInvestigacionVisibility,
                "filename": csvInvestigacionFilename,
                "filesize": csvInvestigacionFilesize,
                "resourceUrl": csvInvestigacionResourceUrl,
                "category": csvInvestigacionCategory,
                "subcategory": csvInvestigacionSubcategory,
                "reference": csvInvestigacionReference,
                "doi": csvInvestigacionDOI,
                "editor": csvInvestigacionEditor,
                "journal": csvInvestigacionJournal,
                "year": csvInvestigacionYear,
                "volume": csvInvestigacionVolume,
                "issue": csvInvestigacionIssue,
                "pages": csvInvestigacionPages,
                "justification": csvInvestigacionJustification,
                "status": csvInvestigacionStatus,
                "featured": csvInvestigacionFeatured,
                "mainFeatured": csvInvestigacionMainFeatured,
                "user": csvInvestigacionUser,
                "visits": csvInvestigacionVisits,
                "downloads": csvInvestigacionDownloads,
                "creationDate": csvCreationDate,
                "lastUpdate": csvLastUpdate
            }
            recursosAcademicosCSV(csvInvestigacionExcel, csvInvestigacionExcel, recursos, downloadOptions);
        } else {
            let downloadOptions = {
                "title": csvActivityTitle,
                "description": csvActivityDescription,
                "visibility": csvActivityVisibility,
                "category": csvActivityCategory,
                "academicLevel": csvActivityAcademicLevel,
                "stage": csvActivityStage,
                "justification": csvActivityJustification,
                "status": csvActivityStatus,
                "featured": csvActivityFeatured,
                "mainFeatured": csvActivityMainFeatured,
                "user": csvActivityUser,
                "authors": csvActivityAuthors,
                "files": csvActivityFiles,
                "visits": csvActivityVisits,
                "downloads": csvActivityDownloads,
                "creationDate": csvCreationDateActivity,
                "lastUpdate": csvLastUpdateActivity
            }
            activitiesCSV(csvActivityExcel, activities, recursosDocentes, downloadOptions);
        }
    }

    const handleCreateActivity = async () => {
        const [isValid, tempErrors] = validateActivityForm(
            categoryActivity,
            academicLevelActivity,
            stageActivity,
            visibilityActivity,
            titleActivity,
            descriptionActivity,
            justificationActivity,
            statusActivity,
            featuredStateActivity,
            userActivity,
            filesActivity,
            authorsActivity
        );
        if (!isValid) {
            setErrorsActivity(tempErrors);
            return;
        }
        const createData = {
            username: userActivity && userActivity.username,
            first_name: userActivity && userActivity.firstName,
            last_name: userActivity && userActivity.lastName,
            classification: categoryActivity,
            level: academicLevelActivity,
            stage: stageActivity,
            visibility: visibilityActivity,
            title: titleActivity,
            description: descriptionActivity,
            utility: justificationActivity,
            status: statusActivity,
            featured: (featuredStateActivity === "featured" || featuredStateActivity === "main_featured") ? "1" : "0",
            main_featured: featuredStateActivity === "main_featured" ? "1" : "0"
        }
        let formData = new FormData();
        for (let key in createData) {
            formData.append(key, createData[key]);
        }
        setFormLoading(true);
        const result_createActivity = await createActivity(formData, authorsActivity, filesActivity);
        if (result_createActivity.error) {
            setFormLoading(false);
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "¡Error al crear actividad!", "text": result_createActivity.error } });
        } else {
            setRecursos(result_createActivity.recursos.recursos);
            setActivities(result_createActivity.recursos.activities);
            setRecursosDocentes(result_createActivity.recursos.recursos_docentes);
            setFormLoading(false);
            handleCloseCreateActivityModal();
            setCategoryActivity('none');
            setAcademicLevelActivity('none');
            setStageActivity('none');
            setVisibilityActivity('none');
            setTitleActivity('');
            setDescriptionActivity('');
            setJustificationActivity('');
            setStatusActivity('none');
            setFeaturedStateActivity('none');
            setUserActivity(null);
            setFilesActivity([{ type: 'none', file: { name: "", size: 0, file: null }, reference: 'none', url: '' }]);
            setAuthorsActivity([{ author: '', institution: '' }]);
            setErrorsActivity({ "authors": [{ author: '', institution: '' }, { author: '', institution: '' }], "files": [{ type: '', reference: '', url: '', file: '' }] });
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Actividad creada!", "text": "La actividad ha sido creada correctamente." } });
        }
    }

    const handleEditRecurso = async () => {
        const [isValid, tempErrors] = validateRecursosInvestigacionForm(
            editCategoryRecurso,
            editSubcategoryRecurso,
            editVisibilityRecurso,
            editReferenceRecurso,
            editDOIRecurso,
            editFileRecurso,
            editResourceUrlRecurso,
            editTitleRecurso,
            editAuthorRecurso,
            editSubtitleRecurso,
            editDescriptionRecurso,
            editEditorRecurso,
            editJournalRecurso,
            editYearRecurso,
            editVolumeRecurso,
            editIssueRecurso,
            editPagesRecurso,
            editJustificationRecurso,
            editStatusRecurso,
            editFeaturedStateRecurso,
            editUserRecurso
        );
        if (!isValid) {
            setEditErrorsRecurso(tempErrors);
            return;
        }
        const editData = {
            username: editUserRecurso && editUserRecurso.username,
            first_name: editUserRecurso && editUserRecurso.firstName,
            last_name: editUserRecurso && editUserRecurso.lastName,
            classification: "material_academico",
            category: editCategoryRecurso,
            subcategory: editSubcategoryRecurso,
            visibility: editVisibilityRecurso,
            reference: editReferenceRecurso,
            justification: editJustificationRecurso,
            doi: editDOIRecurso,
            url: editResourceUrlRecurso,
            filename: editFileRecurso ? editFileRecurso.name : "",
            filesize: editFileRecurso ? editFileRecurso.size.toString() : "",
            title: editTitleRecurso,
            subtitle: editSubtitleRecurso,
            abstract: editDescriptionRecurso,
            author: editAuthorRecurso,
            editor: editEditorRecurso,
            journal: editJournalRecurso,
            year: editYearRecurso,
            volume: editVolumeRecurso,
            issue: editIssueRecurso,
            pages: editPagesRecurso,
            status: editStatusRecurso,
            featured: (editFeaturedStateRecurso === "featured" || editFeaturedStateRecurso === "main_featured") ? "1" : "0",
            main_featured: editFeaturedStateRecurso === "main_featured" ? "1" : "0"
        }
        let formData = new FormData();
        for (let key in editData) {
            formData.append(key, editData[key]);
        }
        if (editFileRecurso && (editFileRecurso instanceof File)) {
            formData.append("file", editFileRecurso);
        }
        setFormLoading(true);
        const result_editRecurso = await editRecurso(selectedRecurso.id, formData);
        if (result_editRecurso.error) {
            setFormLoading(false);
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "¡Error al editar recurso!", "text": result_editRecurso.error } });
        } else {
            setRecursos(result_editRecurso.recursos.recursos);
            setActivities(result_editRecurso.recursos.activities);
            setRecursosDocentes(result_editRecurso.recursos.recursos_docentes);
            setFormLoading(false);
            handleCloseEditRecursoModal();
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Recurso editado!", "text": "El recurso ha sido editado correctamente." } });
        }
    }

    const handleEditActivity = async () => {
        const [isValid, tempErrors] = validateActivityForm(
            editCategoryActivity,
            editAcademicLevelActivity,
            editStageActivity,
            editVisibilityActivity,
            editTitleActivity,
            editDescriptionActivity,
            editJustificationActivity,
            editStatusActivity,
            editFeaturedStateActivity,
            editUserActivity,
            editFilesActivity,
            editAuthorsActivity
        );
        if (!isValid) {
            setEditErrorsActivity(tempErrors);
            return;
        }
        const editData = {
            username: editUserActivity && editUserActivity.username,
            first_name: editUserActivity && editUserActivity.firstName,
            last_name: editUserActivity && editUserActivity.lastName,
            classification: editCategoryActivity,
            level: editAcademicLevelActivity,
            stage: editStageActivity,
            visibility: editVisibilityActivity,
            title: editTitleActivity,
            description: editDescriptionActivity,
            utility: editJustificationActivity,
            status: editStatusActivity,
            featured: (editFeaturedStateActivity === "featured" || editFeaturedStateActivity === "main_featured") ? "1" : "0",
            main_featured: editFeaturedStateActivity === "main_featured" ? "1" : "0"
        }
        let formData = new FormData();
        for (let key in editData) {
            formData.append(key, editData[key]);
        }
        setFormLoading(true);
        console.log(formData);
        console.log(editAuthorsActivity);
        console.log(editFilesActivity);
        setFormLoading(false);
        handleCloseEditActivityModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Actividad editada!", "text": "La actividad ha sido editada correctamente." } });
    }

    const handleToggleRecursoFeatured = async () => {
        const result_toggleRecursoFeatured = await toggleRecursoFeatured(selectedRecurso.classification === "material_academico" ? "recurso" : "activity", selectedRecurso.id);
        setRecursos(result_toggleRecursoFeatured.recursos.recursos);
        setActivities(result_toggleRecursoFeatured.recursos.activities);
        setRecursosDocentes(result_toggleRecursoFeatured.recursos.recursos_docentes);
        handleCloseFeatureModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Recurso destacado actualizado!", "text": "El recurso destacado ha sido actualizado correctamente." } });
    }

    const handleToggleRecursoFeaturedMain = async () => {
        const result_toggleRecursoFeaturedMain = await toggleRecursoFeaturedMain(selectedRecurso.classification === "material_academico" ? "recurso" : "activity", selectedRecurso.id);
        setRecursos(result_toggleRecursoFeaturedMain.recursos.recursos);
        setActivities(result_toggleRecursoFeaturedMain.recursos.activities);
        setRecursosDocentes(result_toggleRecursoFeaturedMain.recursos.recursos_docentes);
        handleCloseMainModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Recurso principal actualizado!", "text": "El recurso principal ha sido actualizado correctamente." } });
    }

    const handleFeedback = async (action, feedback) => {
        const result_feedbackRecurso = await feedbackRecurso(action, selectedRecurso.classification === "material_academico" ? "recurso" : "activity", selectedRecurso.id, feedback);
        setRecursos(result_feedbackRecurso.recursos.recursos);
        setActivities(result_feedbackRecurso.recursos.activities);
        setRecursosDocentes(result_feedbackRecurso.recursos.recursos_docentes);
        handleCloseApproveModal();
        setAlertSuccess(true);
        setAlerts({
            ...alerts, "success": {
                "title": (action === "approve" ? "¡Recurso aprobado!" : "¡Recurso rechazado!"), "text": "El recurso ha sido " + (action === "approve" ? "aprobado" : "rechazado") + " correctamente." + (action === "approve" ? " Ahora se encuentra disponible en RedFID Recursos." : "")
            }
        });
    }

    const handleHideRecurso = async () => {
        const result = await hideRecurso(selectedRecurso.classification === "material_academico" ? "recurso" : "activity", selectedRecurso.id);
        setRecursos(result.recursos.recursos);
        setActivities(result.recursos.activities);
        setRecursosDocentes(result.recursos.recursos_docentes);
        handleCloseHideModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Recurso ocultado!", "text": "El recurso ha sido ocultado correctamente. Ya no se encuentra visible en RedFID Recursos." } });
    }

    const handleDelete = async () => {
        const result_deleteRecurso = await deleteRecurso(selectedRecurso.classification === "material_academico" ? "recurso" : "activity", selectedRecurso.id);
        setRecursos(result_deleteRecurso.recursos.recursos);
        setActivities(result_deleteRecurso.recursos.activities);
        setRecursosDocentes(result_deleteRecurso.recursos.recursos_docentes);
        handleCloseDeleteModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": (selectedRecurso.classification === "material_academico" ? "¡Recurso eliminado!" : "¡Actividad eliminada!"), "text": (selectedRecurso.classification === "material_academico" ? "El recurso" : "La actividad") + " se ha eliminado correctamente y ya no está disponible en RedFID Recursos." } });
    }

    return (
        <>
            <Grid spacing={2} container justifyContent={"center"}>
                <Grid item xs={3}>
                    <Indicator title={"Investigación"} number={
                        recursos.length
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de recursos de investigación que se encuentran publicados en RedFID Recursos."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title={"Material docente"} number={
                        activities.length
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de conjuntos de material docente que se encuentran publicados en RedFID Recursos."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title={"Visitas totales"} number={
                        recursos.reduce((total, recurso) => total + recurso.visits.length, 0) + activities.reduce((total, activity) => total + activity.visits.length, 0)
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de visitas a recursos de investigación y material docente en RedFID Recursos."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title={"Descargas totales"} number={
                        recursos.reduce((total, recurso) => total + recurso.downloads_accesses.length, 0) + recursosDocentes.reduce((total, recurso) => total + recurso.downloads_accesses.length, 0)
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de descargas o accesos a recursos de investigación y material docente en RedFID Recursos."
                    }></Indicator>
                </Grid>
            </Grid>
            {(recursos) ?
                <RecursosInvestigacionTable
                    recursos={recursos}
                    handleOpenCreateModal={handleOpenCreateRecursoModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                    handleOpenStatsModal={handleOpenStatsModal}
                    handleOpenInfoModal={handleOpenInfoModal}
                    handleOpenEditModal={handleOpenEditRecursoModal}
                    handleOpenHideModal={handleOpenHideModal}
                    handleOpenApproveModal={handleOpenApproveModal}
                    handleOpenFeatureModal={handleOpenFeatureModal}
                    handleOpenMainModal={handleOpenMainModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                ></RecursosInvestigacionTable>
                :
                <Box textAlign={"center"}>
                    <CircularProgress sx={{ "color": "#40b4ba" }} />
                </Box>
            }
            {(!activities) ?
                <RecursosActivitiesTable
                    activities={activities}
                    handleOpenCreateModal={handleOpenCreateActivityModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVModal}
                    handleOpenStatsModal={handleOpenStatsModal}
                    handleOpenInfoModal={handleOpenInfoModal}
                    handleOpenEditModal={handleOpenEditActivityModal}
                    handleOpenHideModal={handleOpenHideModal}
                    handleOpenApproveModal={handleOpenApproveModal}
                    handleOpenFeatureModal={handleOpenFeatureModal}
                    handleOpenMainModal={handleOpenMainModal}
                    handleOpenDeleteModal={handleOpenDeleteModal}
                ></RecursosActivitiesTable>
                :
                <Box textAlign={"center"}>
                    <CircularProgress sx={{ "color": "#40b4ba" }} />
                </Box>
            }
            <Modal
                open={createRecursoModal}
                handleClose={handleCloseCreateRecursoModal}
                title={"Crear recurso"}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            En esta sección puedes crear recursos de investigación para ser publicados en RedFID Recursos.
                        </Typography>
                        <RecursosInvestigacionForm
                            users={users}
                            category={categoryRecurso}
                            setCategory={setCategoryRecurso}
                            subcategory={subcategoryRecurso}
                            setSubcategory={setSubcategoryRecurso}
                            visibility={visibilityRecurso}
                            setVisibility={setVisibilityRecurso}
                            reference={referenceRecurso}
                            setReference={setReferenceRecurso}
                            doi={doiRecurso}
                            setDOI={setDOIRecurso}
                            editor={editorRecurso}
                            setEditor={setEditorRecurso}
                            journal={journalRecurso}
                            setJournal={setJournalRecurso}
                            year={yearRecurso}
                            setYear={setYearRecurso}
                            volume={volumeRecurso}
                            setVolume={setVolumeRecurso}
                            issue={issueRecurso}
                            setIssue={setIssueRecurso}
                            pages={pagesRecurso}
                            setPages={setPagesRecurso}
                            title={titleRecurso}
                            setTitle={setTitleRecurso}
                            subtitle={subtitleRecurso}
                            setSubtitle={setSubtitleRecurso}
                            author={authorRecurso}
                            setAuthor={setAuthorRecurso}
                            description={descriptionRecurso}
                            setDescription={setDescriptionRecurso}
                            file={fileRecurso}
                            setFile={setFileRecurso}
                            resourceUrl={resourceUrlRecurso}
                            setResourceUrl={setResourceUrlRecurso}
                            justification={justificationRecurso}
                            setJustification={setJustificationRecurso}
                            status={statusRecurso}
                            setStatus={setStatusRecurso}
                            featuredState={featuredStateRecurso}
                            setFeaturedState={setFeaturedStateRecurso}
                            user={userRecurso}
                            setUser={setUserRecurso}
                            loadingDOI={loadingDOI}
                            setLoadingDOI={setLoadingDOI}
                            errors={errorsRecurso}
                            setErrors={setErrorsRecurso}
                            formLoading={formLoading}
                        ></RecursosInvestigacionForm>
                    </>
                }
                onClickMainButton={handleCreateRecursoAcademico}
                mainButtonText={"Crear"}
                startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                cancelButton={true}
                width={"lg"}
            ></Modal>
            <Modal
                open={createActivityModal}
                handleClose={handleCloseCreateActivityModal}
                title={"Crear material docente"}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            En esta sección puedes crear material docente para ser publicado en RedFID Recursos.
                        </Typography>
                        <RecursosActivityForm
                            users={users}
                            category={categoryActivity}
                            setCategory={setCategoryActivity}
                            academicLevel={academicLevelActivity}
                            setAcademicLevel={setAcademicLevelActivity}
                            stage={stageActivity}
                            setStage={setStageActivity}
                            visibility={visibilityActivity}
                            setVisibility={setVisibilityActivity}
                            title={titleActivity}
                            setTitle={setTitleActivity}
                            description={descriptionActivity}
                            setDescription={setDescriptionActivity}
                            justification={justificationActivity}
                            setJustification={setJustificationActivity}
                            authors={authorsActivity}
                            setAuthors={setAuthorsActivity}
                            files={filesActivity}
                            setFiles={setFilesActivity}
                            status={statusActivity}
                            setStatus={setStatusActivity}
                            featuredState={featuredStateActivity}
                            setFeaturedState={setFeaturedStateActivity}
                            user={userActivity}
                            setUser={setUserActivity}
                            errors={errorsActivity}
                            setErrors={setErrorsActivity}
                            formLoading={formLoading}
                            editing={false}
                        ></RecursosActivityForm>
                    </>
                }
                onClickMainButton={handleCreateActivity}
                mainButtonText={"Crear"}
                startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                cancelButton={true}
                width={"lg"}
            ></Modal>
            <Modal
                open={downloadCSVModal}
                handleClose={handleCloseDownloadCSVModal}
                title={"Descargar datos"}
                content={
                    selectedKind === "recurso" ?
                        <>
                            <Typography variant="p" mb={"20px"}>
                                Se descargará un archivo en formato .csv con la información de todos los recursos de investigación de RedFID.
                                Por favor, seleccione los campos que desea incluir:
                            </Typography>
                            <DownloadCSVRecursosAcademicosForm
                                csvTitle={csvInvestigacionTitle}
                                setCsvTitle={setCsvInvestigacionTitle}
                                csvSubtitle={csvInvestigacionSubtitle}
                                setCsvSubtitle={setCsvInvestigacionSubtitle}
                                csvAuthor={csvInvestigacionAuthor}
                                setCsvAuthor={setCsvInvestigacionAuthor}
                                csvDescription={csvInvestigacionDescription}
                                setCsvDescription={setCsvInvestigacionDescription}
                                csvVisibility={csvInvestigacionVisibility}
                                setCsvVisibility={setCsvInvestigacionVisibility}
                                csvFilename={csvInvestigacionFilename}
                                setCsvFilename={setCsvInvestigacionFilename}
                                csvFilesize={csvInvestigacionFilesize}
                                setCsvFilesize={setCsvInvestigacionFilesize}
                                csvResourceUrl={csvInvestigacionResourceUrl}
                                setCsvResourceUrl={setCsvInvestigacionResourceUrl}
                                csvCategory={csvInvestigacionCategory}
                                setCsvCategory={setCsvInvestigacionCategory}
                                csvSubcategory={csvInvestigacionSubcategory}
                                setCsvSubcategory={setCsvInvestigacionSubcategory}
                                csvReference={csvInvestigacionReference}
                                setCsvReference={setCsvInvestigacionReference}
                                csvDOI={csvInvestigacionDOI}
                                setCsvDOI={setCsvInvestigacionDOI}
                                csvEditor={csvInvestigacionEditor}
                                setCsvEditor={setCsvInvestigacionEditor}
                                csvJournal={csvInvestigacionJournal}
                                setCsvJournal={setCsvInvestigacionJournal}
                                csvYear={csvInvestigacionYear}
                                setCsvYear={setCsvInvestigacionYear}
                                csvVolume={csvInvestigacionVolume}
                                setCsvVolume={setCsvInvestigacionVolume}
                                csvIssue={csvInvestigacionIssue}
                                setCsvIssue={setCsvInvestigacionIssue}
                                csvPages={csvInvestigacionPages}
                                setCsvPages={setCsvInvestigacionPages}
                                csvJustification={csvInvestigacionJustification}
                                setCsvJustification={setCsvInvestigacionJustification}
                                csvStatus={csvInvestigacionStatus}
                                setCsvStatus={setCsvInvestigacionStatus}
                                csvFeatured={csvInvestigacionFeatured}
                                setCsvFeatured={setCsvInvestigacionFeatured}
                                csvMainFeatured={csvInvestigacionMainFeatured}
                                setCsvMainFeatured={setCsvInvestigacionMainFeatured}
                                csvUser={csvInvestigacionUser}
                                setCsvUser={setCsvInvestigacionUser}
                                csvVisits={csvInvestigacionVisits}
                                setCsvVisits={setCsvInvestigacionVisits}
                                csvDownloads={csvInvestigacionDownloads}
                                setCsvDownloads={setCsvInvestigacionDownloads}
                                csvCreationDate={csvCreationDate}
                                setCsvCreationDate={setCsvCreationDate}
                                csvLastUpdate={csvLastUpdate}
                                setCsvLastUpdate={setCsvLastUpdate}
                                csvExcel={csvInvestigacionExcel}
                                setCsvExcel={setCsvInvestigacionExcel}
                            ></DownloadCSVRecursosAcademicosForm>
                        </>
                        :
                        <>
                            <Typography variant="p" mb={"20px"}>
                                Se descargará un archivo en formato .csv con la información de todo el material docente de RedFID.
                                Por favor, seleccione los campos que desea incluir:
                            </Typography>
                            <DownloadCSVMaterialDocenteForm
                                csvTitle={csvActivityTitle}
                                setCsvTitle={setCsvActivityTitle}
                                csvDescription={csvActivityDescription}
                                setCsvDescription={setCsvActivityDescription}
                                csvVisibility={csvActivityVisibility}
                                setCsvVisibility={setCsvActivityVisibility}
                                csvCategory={csvActivityCategory}
                                setCsvCategory={setCsvActivityCategory}
                                csvAcademicLevel={csvActivityAcademicLevel}
                                setCsvAcademicLevel={setCsvActivityAcademicLevel}
                                csvStage={csvActivityStage}
                                setCsvStage={setCsvActivityStage}
                                csvJustification={csvActivityJustification}
                                setCsvJustification={setCsvActivityJustification}
                                csvStatus={csvActivityStatus}
                                setCsvStatus={setCsvActivityStatus}
                                csvFeatured={csvActivityFeatured}
                                setCsvFeatured={setCsvActivityFeatured}
                                csvMainFeatured={csvActivityMainFeatured}
                                setCsvMainFeatured={setCsvActivityMainFeatured}
                                csvUser={csvActivityUser}
                                setCsvUser={setCsvActivityUser}
                                csvAuthors={csvActivityAuthors}
                                setCsvAuthors={setCsvActivityAuthors}
                                csvFiles={csvActivityFiles}
                                setCsvFiles={setCsvActivityFiles}
                                csvVisits={csvActivityVisits}
                                setCsvVisits={setCsvActivityVisits}
                                csvDownloads={csvActivityDownloads}
                                setCsvDownloads={setCsvActivityDownloads}
                                csvCreationDate={csvCreationDateActivity}
                                setCsvCreationDate={setCsvCreationDateActivity}
                                csvLastUpdate={csvLastUpdateActivity}
                                setCsvLastUpdate={setCsvLastUpdateActivity}
                                csvExcel={csvActivityExcel}
                                setCsvExcel={setCsvActivityExcel}
                            ></DownloadCSVMaterialDocenteForm>
                        </>
                }
                onClickMainButton={handleDownloadCSVInvestigacion}
                mainButtonText={"Descargar"}
                startIcon={<DownloadIcon></DownloadIcon>}
                cancelButton={true}
                width={"lg"}
            ></Modal>
            <Modal
                open={statsModal}
                handleClose={handleCloseStatsModal}
                title={"Estadísticas"}
                content={
                    <>
                        {((selectedKind === "recurso" && recursos) || (selectedKind === "activity" && activities && recursosDocentes)) ?
                            <Grid display={"flex"} alignItems={"center"} justifyContent={"space-between"} columnSpacing={2} container>
                                <Grid item xs={12} sm={7} mt="10px">
                                    <BarChart
                                        id={selectedKind + "-users"}
                                        title={"Usuarios con mayor colaboración"}
                                        limit={10}
                                        horizontal={true}
                                        height={"400px"}
                                        data={() => {
                                            let data = [];
                                            for (let r of (selectedKind === "recurso" ? recursos : activities)) {
                                                let user = data.find(user => user.label === (r.first_name + " " + r.last_name));
                                                if (user) {
                                                    user.value++;
                                                } else {
                                                    data.push({ "label": (r.first_name + " " + r.last_name), "value": 1, "color": "#40b4ba" });
                                                }
                                            }
                                            return data;
                                        }}
                                    ></BarChart>
                                </Grid>
                                <Grid item xs={12} sm={5} mt="10px">
                                    <PieChart
                                        id={selectedKind + "-visibility"}
                                        title={"Visibilidad de " + (selectedKind === "recurso" ? "recursos" : "material docente")}
                                        limit={10}
                                        height={"400px"}
                                        data={() => {
                                            let data = [{
                                                "label": "Público",
                                                "value": (selectedKind === "recurso" ? recursos : activities).filter(r => r.visibility === "public").length,
                                                "color": "limegreen"
                                            },
                                            {
                                                "label": "Privado",
                                                "value": (selectedKind === "recurso" ? recursos : activities).filter(r => r.visibility === "private").length,
                                                "color": "tomato"
                                            },
                                            {
                                                "label": "Semi-público",
                                                "value": (selectedKind === "recurso" ? recursos : activities).filter(r => r.visibility === "semipublic").length,
                                                "color": "gold"
                                            }];
                                            return data;
                                        }}
                                    ></PieChart>
                                </Grid>
                                <Grid item xs={12} sm={7} mt="10px">
                                    <BarChart
                                        id={selectedKind + "-visits"}
                                        title={selectedKind === "recurso" ? "Recursos de investigación con mayor visitas" : "Material docente con mayor visitas"}
                                        limit={10}
                                        horizontal={true}
                                        height={"400px"}
                                        data={() => {
                                            let data = [];
                                            for (let r of (selectedKind === "recurso" ? recursos : activities)) {
                                                data.push({ "label": selectedKind === "recurso" ? r.detail.title : r.title, "value": r.visits.length, "color": "#40b4ba" });
                                            }
                                            return data;
                                        }}
                                    ></BarChart>
                                </Grid>
                                {selectedKind === "activity" &&
                                    <Grid item xs={12} sm={5} mt="10px">
                                        <PieChart
                                            id="recursos-activities-stage"
                                            title={"Etapas de material docente"}
                                            limit={10}
                                            height={"400px"}
                                            data={() => {
                                                let data = [];
                                                for (let activity of activities) {
                                                    let stage = data.find(stage => stage.label === (activity.stage === "formacion_inicial" ? "Formación inicial" : activity.stage === "formacion_continua" ? "Formación continua" : ""));
                                                    if (stage) {
                                                        stage.value++;
                                                    } else {
                                                        data.push({
                                                            "label": activity.stage === "formacion_inicial" ? "Formación inicial" : activity.stage === "formacion_continua" ? "Formación continua" : "",
                                                            "value": 1,
                                                            "color": activity.stage === "formacion_inicial" ? "#eb947e" : activity.stage === "formacion_continua" ? "#40b4ba" : ""
                                                        });
                                                    }
                                                }
                                                return data;
                                            }}
                                        ></PieChart>
                                    </Grid>
                                }
                                {selectedKind === "recurso" &&
                                    <>
                                        <Grid item xs={12} sm={5} mt="10px">
                                            <PieChart
                                                id="recursos-investigacion-category"
                                                title={"Categorías de recursos de investigación"}
                                                limit={10}
                                                height={"400px"}
                                                data={() => {
                                                    let data = [];
                                                    for (let recurso of recursos) {
                                                        let type = data.find(type => type.label === translateCategoryRecursoInvestigacion(recurso.category));
                                                        if (type) {
                                                            type.value++;
                                                        } else {
                                                            data.push({
                                                                "label": translateCategoryRecursoInvestigacion(recurso.category),
                                                                "value": 1,
                                                                "color": colorsCategoryRecursoInvestigacion(recurso.category)
                                                            });
                                                        }
                                                    }
                                                    return data;

                                                }}
                                            ></PieChart>
                                        </Grid>

                                        <Grid item xs={12} sm={5} mt="10px">
                                            <PieChart
                                                id="recursos-investigacion-subcategory"
                                                title={"Tipos de recursos de investigación"}
                                                limit={10}
                                                height={"400px"}
                                                data={() => {
                                                    let data = [
                                                        {
                                                            "label": "Artículo",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "article").length,
                                                            "color": "#7285e4"
                                                        },
                                                        {
                                                            "label": "Acta de congreso",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "congress_minute").length,
                                                            "color": "#bc7ee1"
                                                        },
                                                        {
                                                            "label": "Reporte técnico",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "technical_report").length,
                                                            "color": "#e4d876"
                                                        },
                                                        {
                                                            "label": "Póster",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "poster").length,
                                                            "color": "#40b4ba"
                                                        },
                                                        {
                                                            "label": "Libro",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "book").length,
                                                            "color": "#e7706c"
                                                        },
                                                        {
                                                            "label": "Capítulo de libro",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "book_chapter").length,
                                                            "color": "#eb947e"
                                                        },
                                                        {
                                                            "label": "Sitio web",
                                                            "value": recursos.filter(recurso => recurso.subcategory === "website").length,
                                                            "color": "#595959"
                                                        }
                                                    ];
                                                    return data;
                                                }}
                                            ></PieChart>
                                        </Grid>
                                    </>
                                }
                                {selectedKind === "activity" &&
                                    <>
                                        <Grid item xs={12} sm={5} mt="10px">
                                            <PieChart
                                                id="recursos-activities-category"
                                                title={"Categorías de material docente"}
                                                limit={10}
                                                height={"400px"}
                                                data={() => {
                                                    let data = [];
                                                    for (let activity of activities) {
                                                        let type = data.find(type => type.label === translateCategoryActivity(activity.classification));
                                                        if (type) {
                                                            type.value++;
                                                        } else {
                                                            data.push({
                                                                "label": translateCategoryActivity(activity.classification),
                                                                "value": 1,
                                                                "color": colorsCategoryActivity(activity.classification)
                                                            });
                                                        }
                                                    }
                                                    return data;
                                                }}
                                            ></PieChart>
                                        </Grid>
                                        <Grid item xs={12} sm={5} mt="10px">
                                            <PieChart
                                                id="recursos-activities-level"
                                                title={"Niveles de material docente"}
                                                limit={10}
                                                height={"400px"}
                                                data={() => {
                                                    let data = [];
                                                    for (let activity of activities) {
                                                        let level = data.find(level => level.label === (activity.level === "educacion_parvularia" ? "Educación parvularia" : activity.level === "educacion_basica" ? "Educación básica" : activity.level === "educacion_media" ? "Educación media" : ""));
                                                        if (level) {
                                                            level.value++;
                                                        } else {
                                                            data.push({
                                                                "label": activity.level === "educacion_parvularia" ? "Educación parvularia" : activity.level === "educacion_basica" ? "Educación básica" : activity.level === "educacion_media" ? "Educación media" : "",
                                                                "value": 1,
                                                                "color": activity.level === "educacion_parvularia" ? "#e4d876" : activity.level === "educacion_basica" ? "#bc7ee1" : activity.level === "educacion_media" ? "#7285e4" : ""
                                                            });
                                                        }
                                                    }
                                                    return data;
                                                }}
                                            ></PieChart>
                                        </Grid>
                                    </>
                                }

                            </Grid>
                            :
                            <Box textAlign={"center"}>
                                <CircularProgress sx={{ "color": "#40b4ba" }} />
                            </Box>
                        }
                    </>
                }
                onClickMainButton={handleCloseStatsModal}
                mainButtonText={"OK"}
                hideMainButton={true}
                startIcon={null}
                cancelButton={false}
                width={"lg"}
            ></Modal>
            {selectedRecurso &&
                <>
                    <Modal
                        open={infoModal}
                        handleClose={handleCloseInfoModal}
                        title={"Información detallada"}
                        content={
                            selectedRecurso.classification === "material_academico" ?
                                <>
                                    <Typography variant="p" fontSize={"1.3em"}>{selectedRecurso.detail.title}</Typography>
                                    <Typography variant="p" fontSize={"1em"}>{selectedRecurso.detail.author}</Typography>
                                    <Grid p={2} container columnSpacing={2} display={"flex"} justifyContent={"center"} alignItems={"start"} mt="20px">
                                        <Grid item xs={12} sm={(selectedRecurso.detail.descripcion || selectedRecurso.detail.abstract) ? 6 : 12} mb={"20px"} pr={"10px"}>
                                            <Box display={"flex"} margin={"10px 0"} justifyContent={"left"}>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"100px"}>Categoría: </Typography>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='p'>{translateCategoryRecursoInvestigacion(selectedRecurso.category)}</Typography>
                                            </Box>
                                            <Box display={"flex"} margin={"10px 0"} justifyContent={"left"}>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"100px"}>Visibilidad: </Typography>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='p'>{selectedRecurso.visibility === "public" ? "Público" : selectedRecurso.visibility === "semipublic" ? "Semipúblico" : "Privado"}</Typography>
                                            </Box>
                                            <Box display={"flex"} margin={"10px 0"} justifyContent={"left"}>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"100px"}>Compartido por: </Typography>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='p'>{selectedRecurso.first_name + " " + selectedRecurso.last_name + " (" + selectedRecurso.username + ")"}</Typography>
                                            </Box>
                                            <Box display={"flex"} margin={"10px 0"} alignItems={"center"}>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"100px"}>Tipo de recurso: </Typography>
                                                {getCategoryChip(selectedRecurso.subcategory)}
                                            </Box>
                                            {(selectedRecurso.detail.reference === "doi") &&
                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>DOI: </Typography>
                                                    <Link href={formatDOI(selectedRecurso.detail.doi)} target="_blank"><Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='small-a'>{unformatDOI(formatDOI(selectedRecurso.detail.doi))}</Typography></Link>
                                                </Box>
                                            }
                                            {(selectedRecurso.detail.reference === "url") &&
                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>URL: </Typography>
                                                    <Link href={selectedRecurso.detail.url} target="_blank"><Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='small-a'>{selectedRecurso.detail.url}</Typography></Link>
                                                </Box>
                                            }
                                            {selectedRecurso.detail.journal &&
                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Revista: </Typography>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{selectedRecurso.detail.journal}</Typography>
                                                </Box>
                                            }
                                            {selectedRecurso.detail.editor &&
                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Editor: </Typography>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{selectedRecurso.detail.editor}</Typography>
                                                </Box>
                                            }
                                            {selectedRecurso.detail.volume &&
                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Volumen: </Typography>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{selectedRecurso.detail.volume}</Typography>
                                                </Box>
                                            }
                                            {selectedRecurso.detail.issue &&

                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Número: </Typography>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{selectedRecurso.detail.issue}</Typography>
                                                </Box>
                                            }
                                            {selectedRecurso.detail.pages &&
                                                <Box display={"flex"} justifyContent={"left"}>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Páginas: </Typography>
                                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{selectedRecurso.detail.pages}</Typography>
                                                </Box>
                                            }
                                            {selectedRecurso.detail.reference === "direct" &&
                                                <Box mt={"20px"} textAlign={"center"}>
                                                    <Button variant="orange" onClick={() => handleDownloadRecursos("academico", selectedRecurso, recursosApi, "admin")} startIcon={<DownloadIcon></DownloadIcon>}>Descargar recurso</Button>
                                                </Box>
                                            }
                                        </Grid>
                                        {(selectedRecurso.detail.descripcion || selectedRecurso.detail.abstract) &&
                                            <Grid item xs={12} sm={6}>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant='p'>{selectedRecurso.detail.descripcion ? selectedRecurso.detail.descripcion : selectedRecurso.detail.abstract}</Typography>
                                            </Grid>
                                        }
                                    </Grid>
                                    <Grid mt={"20px"} mr={"20px"}>
                                        {selectedRecurso.justification &&
                                            <Box display={"flex"} justifyContent={"left"}>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" fontWeight={"bold"} mr={"10px"} minWidth={"70px"}>Para citar: </Typography>
                                                <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text" textAlign={"justify"}>{selectedRecurso.justification}</Typography>
                                            </Box>
                                        }
                                    </Grid>

                                </>
                                :
                                <>
                                    <Typography fontSize={{ "xs": "0.9em", "sm": "1em" }} textAlign={"justify"} fontWeight={"bold"}>{selectedRecurso.title}</Typography>
                                    <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="small-text">{formatMaterialDocenteAuthors(selectedRecurso.authors)}</Typography>
                                    <Grid columnSpacing={2} mt="20px">
                                        <Box mb={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="p" textAlign={"justify"}>{selectedRecurso.description}</Typography>
                                        </Box>
                                        <Card sx={{ "boxShadow": "none" }}>
                                            <CardContent sx={{ "padding": 0 }}>
                                                <Typography variant="subtitle" fontSize={"1em"}>Recursos</Typography>
                                                {selectedRecurso.recursos.map((recurso, index) => {
                                                    return (
                                                        recurso.reference === "subir_archivo" ?
                                                            <Grid container key={recurso.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                                                                <Grid item xs={12} sm={8} display={"flex"} alignItems={"center"}>
                                                                    {recurso.filename.endsWith(".pdf") ? (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 65,
                                                                            }}
                                                                            alt="PDF"
                                                                            src={pdfIcon}
                                                                        />
                                                                    ) : (recurso.filename.endsWith(".docx") || recurso.filename.endsWith(".doc") || recurso.filename.endsWith(".odt")) ? (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 65,
                                                                            }}
                                                                            alt="DOCX"
                                                                            src={docxIcon}
                                                                        />
                                                                    ) : (recurso.filename.endsWith(".pptx") || recurso.filename.endsWith(".ppt") || recurso.filename.endsWith(".odp")) ? (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 65,
                                                                            }}
                                                                            alt="PPTX"
                                                                            src={pptxIcon}
                                                                        />
                                                                    ) : (recurso.filename.endsWith(".xlsx") || recurso.filename.endsWith(".xls") || recurso.filename.endsWith(".ods")) ? (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 65,
                                                                            }}
                                                                            alt="XLSX"
                                                                            src={xlsxIcon}
                                                                        />
                                                                    ) : (recurso.filename.endsWith(".png") || recurso.filename.endsWith(".jpg") || recurso.filename.endsWith(".jpeg") || recurso.filename.endsWith(".odi") || recurso.filename.endsWith(".gif")) ? (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                marginLeft: "8px",
                                                                                marginRight: "8px"
                                                                            }}
                                                                            alt="PNG"
                                                                            src={pngIcon}
                                                                        />
                                                                    ) : (recurso.filename.endsWith(".mp4")) ? (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 65,
                                                                            }}
                                                                            alt="MP4"
                                                                            src={mp4Icon}
                                                                        />
                                                                    ) : (
                                                                        <Box
                                                                            component="img"
                                                                            sx={{
                                                                                height: 50,
                                                                                width: 50,
                                                                                padding: "0px 9px"
                                                                            }}
                                                                            alt="Other"
                                                                            src={fileIcon}
                                                                        />
                                                                    )}
                                                                    <Grid m={"0px 4px"} fontSize={"1em"}>
                                                                        {getRecursoDocenteChip(recurso.type)}
                                                                        <Typography sx={{
                                                                            maxWidth: {
                                                                                "xs": "100px",
                                                                                "sm": "none"
                                                                            }
                                                                        }} variant="small-p">{recurso.filename + " (" + formatFileSize(recurso.filesize) + ")"}</Typography>
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid item xs={12} sm={4} textAlign={"center"} alignSelf="flex-end">
                                                                    <Button onClick={() => handleDownloadRecursos("docente", recurso, recursosApi, "admin")} variant="orange" style={{ margin: "30px 0 20px 0", paddingLeft: "20px", fontSize: "0.7em" }} startIcon={<DownloadIcon />}>
                                                                        Descargar
                                                                    </Button>
                                                                </Grid>
                                                            </Grid> :
                                                            <Grid key={recurso.id} display={"flex"} justifyContent={"space-between"} alignItems={"center"} minHeight={"72px"}>
                                                                <Grid display={"flex"}>
                                                                    <Box
                                                                        component="img"
                                                                        sx={{
                                                                            marginBottom: "5px",
                                                                            marginLeft: "8px",
                                                                            marginRight: "10px",
                                                                            height: 50,
                                                                            width: 50,
                                                                        }}
                                                                        alt="URL"
                                                                        src={recurso.url.includes("youtube") ? ytIcon : urlIcon}
                                                                    />
                                                                    <Box m={"0px 4px"} fontSize={"1em"}>
                                                                        {getRecursoDocenteChip(recurso.type)}
                                                                        <Typography fontSize={{ "xs": "0.85em", "sm": "1em" }} onClick={() => handleDownloadRecursos("docente", recurso, recursosApi, "admin")} variant="a">{recurso.url}</Typography>
                                                                    </Box>
                                                                </Grid>
                                                            </Grid>
                                                    );
                                                })}
                                            </CardContent>
                                        </Card>
                                        <Box mt={"20px"} display={"flex"} justifyContent={"space-between"} alignItems={"start"}>
                                            <Typography fontSize={{ "xs": "0.8em", "sm": "0.85em" }} variant="p" textAlign={"justify"}>{selectedRecurso.utility}</Typography>
                                        </Box>
                                    </Grid>
                                </>
                        }
                        onClickMainButton={handleCloseInfoModal}
                        mainButtonText={"OK"}
                        hideMainButton={true}
                        startIcon={null}
                        cancelButton={false}
                        width={"lg"}
                    ></Modal>
                    <Modal
                        open={editRecursoModal}
                        handleClose={handleCloseEditRecursoModal}
                        title={"Editar recurso"}
                        content={
                            <>
                                <Typography variant="p" mb={"20px"}>
                                    En esta sección puede editar la información del recurso de investigación seleccionado.
                                </Typography>
                                <RecursosInvestigacionForm
                                    users={users}
                                    category={editCategoryRecurso}
                                    setCategory={setEditCategoryRecurso}
                                    subcategory={editSubcategoryRecurso}
                                    setSubcategory={setEditSubcategoryRecurso}
                                    visibility={editVisibilityRecurso}
                                    setVisibility={setEditVisibilityRecurso}
                                    reference={editReferenceRecurso}
                                    setReference={setEditReferenceRecurso}
                                    doi={editDOIRecurso}
                                    setDOI={setEditDOIRecurso}
                                    editor={editEditorRecurso}
                                    setEditor={setEditEditorRecurso}
                                    journal={editJournalRecurso}
                                    setJournal={setEditJournalRecurso}
                                    year={editYearRecurso}
                                    setYear={setEditYearRecurso}
                                    volume={editVolumeRecurso}
                                    setVolume={setEditVolumeRecurso}
                                    issue={editIssueRecurso}
                                    setIssue={setEditIssueRecurso}
                                    pages={editPagesRecurso}
                                    setPages={setEditPagesRecurso}
                                    title={editTitleRecurso}
                                    setTitle={setEditTitleRecurso}
                                    subtitle={editSubtitleRecurso}
                                    setSubtitle={setEditSubtitleRecurso}
                                    author={editAuthorRecurso}
                                    setAuthor={setEditAuthorRecurso}
                                    description={editDescriptionRecurso}
                                    setDescription={setEditDescriptionRecurso}
                                    file={editFileRecurso}
                                    setFile={setEditFileRecurso}
                                    resourceUrl={editResourceUrlRecurso}
                                    setResourceUrl={setEditResourceUrlRecurso}
                                    justification={editJustificationRecurso}
                                    setJustification={setEditJustificationRecurso}
                                    status={editStatusRecurso}
                                    setStatus={setEditStatusRecurso}
                                    featuredState={editFeaturedStateRecurso}
                                    setFeaturedState={setEditFeaturedStateRecurso}
                                    user={editUserRecurso}
                                    setUser={setEditUserRecurso}
                                    loadingDOI={loadingDOI}
                                    setLoadingDOI={setLoadingDOI}
                                    errors={editErrorsRecurso}
                                    setErrors={setEditErrorsRecurso}
                                    formLoading={formLoading}
                                    editing={true}
                                ></RecursosInvestigacionForm>
                            </>
                        }
                        onClickMainButton={handleEditRecurso}
                        mainButtonText={"Editar"}
                        startIcon={<EditIcon></EditIcon>}
                        cancelButton={true}
                        width={"lg"}
                    ></Modal>
                    <Modal
                        open={editActivityModal}
                        handleClose={handleCloseEditActivityModal}
                        title={"Editar material docente"}
                        content={
                            <>
                                <Typography variant="p" mb={"20px"}>
                                    En esta sección puede editar la información del material docente seleccionado.
                                </Typography>
                                <RecursosActivityForm
                                    users={users}
                                    category={editCategoryActivity}
                                    setCategory={setEditCategoryActivity}
                                    academicLevel={editAcademicLevelActivity}
                                    setAcademicLevel={setEditAcademicLevelActivity}
                                    stage={editStageActivity}
                                    setStage={setEditStageActivity}
                                    visibility={editVisibilityActivity}
                                    setVisibility={setEditVisibilityActivity}
                                    title={editTitleActivity}
                                    setTitle={setEditTitleActivity}
                                    description={editDescriptionActivity}
                                    setDescription={setEditDescriptionActivity}
                                    justification={editJustificationActivity}
                                    setJustification={setEditJustificationActivity}
                                    authors={editAuthorsActivity}
                                    setAuthors={setEditAuthorsActivity}
                                    files={editFilesActivity}
                                    setFiles={setEditFilesActivity}
                                    status={editStatusActivity}
                                    setStatus={setEditStatusActivity}
                                    featuredState={editFeaturedStateActivity}
                                    setFeaturedState={setEditFeaturedStateActivity}
                                    user={editUserActivity}
                                    setUser={setEditUserActivity}
                                    errors={editErrorsActivity}
                                    setErrors={setEditErrorsActivity}
                                    formLoading={formLoading}
                                    editing={true}
                                ></RecursosActivityForm>
                            </>
                        }
                        onClickMainButton={handleEditActivity}
                        mainButtonText={"Editar"}
                        startIcon={<EditIcon></EditIcon>}
                        cancelButton={true}
                        width={"lg"}
                    ></Modal>
                    <Modal
                        open={approveModal}
                        handleClose={handleCloseApproveModal}
                        title={(selectedAction === "approve" ? "Aprobar" : selectedAction === "reject" ? "Rechazar" : "") + (selectedRecurso.classification === "material_academico" ? " recurso" : " material docente")}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea {selectedAction === "approve" ? "aprobar" : selectedAction === "reject" ? "rechazar" : ""} {selectedRecurso.classification === "material_academico" ? "el recurso seleccionado?" : "la actividad seleccionada?"}
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedRecurso.classification === "material_academico" ? selectedRecurso.detail.title : selectedRecurso.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedRecurso.classification === "material_academico" ? "El recurso seleccionado" : "La actividad seleccionada"} {selectedAction === "approve" ? "aparecerá en RedFID Recursos" : selectedAction === "reject" ? "no aparecerá en RedFID Recursos" : ""}. Esta acción es reversible.
                                </Typography>
                            </>
                        }
                        onClickMainButton={() => handleFeedback(selectedAction, "approved")}
                        mainButtonText={(selectedAction === "approve" ? "Aprobar" : selectedAction === "reject" ? "Rechazar" : "")}
                        startIcon={selectedAction === "approve" ? <CheckCircleIcon></CheckCircleIcon> : selectedAction === "reject" ? <CloseIcon></CloseIcon> : null}
                        cancelButton={true}
                        width={"md"}
                    ></Modal>
                    <Modal
                        open={hideModal}
                        handleClose={handleCloseHideModal}
                        title={"Ocultar " + (selectedRecurso.classification === "material_academico" ? "recurso" : "material docente")}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea ocultar {selectedRecurso.classification === "material_academico" ? "el recurso seleccionado?" : "la actividad seleccionada?"}
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedRecurso.classification === "material_academico" ? selectedRecurso.detail.title : selectedRecurso.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedRecurso.classification === "material_academico" ? "El recurso seleccionado" : "La actividad seleccionada"} dejará de aparecer en RedFID Recursos. Esta acción es reversible.
                                </Typography>
                            </>
                        }
                        onClickMainButton={handleHideRecurso}
                        mainButtonText={"Ocultar"}
                        startIcon={<VisibilityOffIcon></VisibilityOffIcon>}
                        cancelButton={true}
                        width={"md"}
                    ></Modal>
                    <Modal
                        open={featureModal}
                        handleClose={handleCloseFeatureModal}
                        title={selectedRecurso.featured ? "Quitar de destacados" : "Fijar en destacados"}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea {selectedRecurso.featured ? "quitar de destacados" : "fijar en destacados"} {selectedRecurso.classification === "material_academico" ? "el recurso seleccionado?" : "la actividad seleccionada?"}
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedRecurso.classification === "material_academico" ? selectedRecurso.detail.title : selectedRecurso.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedRecurso.featured ?
                                        (selectedRecurso.classification === "material_academico" ? "El recurso seleccionado" : "La actividad seleccionada") + " dejará de aparecer en la sección de destacados de RedFID Recursos."
                                        :
                                        (selectedRecurso.classification === "material_academico" ? "El recurso seleccionado" : "La actividad seleccionada") + " aparecerá en la sección de destacados de RedFID Recursos, al costado en la portada."}
                                </Typography>
                            </>
                        }
                        onClickMainButton={handleToggleRecursoFeatured}
                        mainButtonText={selectedRecurso.featured ? "Quitar de destacados" : "Fijar en destacados"}
                        startIcon={selectedRecurso.featured ? <StarBorderIcon></StarBorderIcon> : <StarIcon></StarIcon>}
                        cancelButton={true}
                        width={"md"}
                    ></Modal>
                    <Modal
                        open={mainModal}
                        handleClose={handleCloseMainModal}
                        title={selectedRecurso.main_featured ? "Quitar de portada" : "Fijar en portada"}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea {selectedRecurso.main_featured ? "quitar de portada" : "fijar en portada"} {selectedRecurso.classification === "material_academico" ? "el recurso seleccionado?" : "la actividad seleccionada?"}
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedRecurso.classification === "material_academico" ? selectedRecurso.detail.title : selectedRecurso.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    {selectedRecurso.main_featured ? "La portada de RedFID Recursos quedará vacía, no se olvide de seleccionar un nuevo recurso o actividad para que ocupe su lugar."
                                        :
                                        (selectedRecurso.classification === "material_academico" ? "El recurso seleccionado" : "La actividad seleccionada") + " ocupará la portada de RedFID Recursos, desplazando al recurso o actividad actualmente en portada, en caso de haber."}
                                </Typography>
                            </>
                        }
                        onClickMainButton={handleToggleRecursoFeaturedMain}
                        mainButtonText={selectedRecurso.main_featured ? "Quitar de portada" : "Fijar en portada"}
                        startIcon={selectedRecurso.main_featured ? <FavoriteBorderIcon></FavoriteBorderIcon> : <FavoriteIcon></FavoriteIcon>}
                        cancelButton={true}
                        width={"md"}
                    ></Modal>
                    <Modal
                        open={deleteModal}
                        handleClose={handleCloseDeleteModal}
                        title={"Eliminar " + (selectedRecurso.classification === "material_academico" ? "recurso" : "material docente")}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea eliminar {selectedRecurso.classification === "material_academico" ? "el recurso seleccionado?" : "la actividad seleccionada?"}
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedRecurso.classification === "material_academico" ? selectedRecurso.detail.title : selectedRecurso.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    Esta acción no se puede deshacer y eliminará permanentemente {selectedRecurso.classification === "material_academico" ? "el recurso seleccionado" : "la actividad seleccionada"} de RedFID Recursos.
                                </Typography>
                            </>
                        }
                        onClickMainButton={handleDelete}
                        mainButtonText={"Eliminar"}
                        startIcon={<DeleteForeverIcon></DeleteForeverIcon>}
                        cancelButton={true}
                        width={"md"}
                    ></Modal>
                </>
            }
        </>
    )
}

export default Recursos;