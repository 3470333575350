import React from 'react';
import { Grid, MenuItem, TextField, Typography, Autocomplete } from '@mui/material';
import { formatUsersIntoSelector } from '../../../utils/formatters';
import MaterialDocenteAuthors from './MaterialDocenteAuthors';
import MaterialDocenteFiles from './MaterialDocenteFiles';


const RecursosActivityForm = ({
    users,
    category,
    setCategory,
    academicLevel,
    setAcademicLevel,
    stage,
    setStage,
    visibility,
    setVisibility,
    title,
    setTitle,
    description,
    setDescription,
    justification,
    setJustification,
    authors,
    setAuthors,
    files,
    setFiles,
    status,
    setStatus,
    featuredState,
    setFeaturedState,
    user,
    setUser,
    errors,
    setErrors,
    formLoading,
    editing
}) => {

    return (
        <>
            <Typography sx={{ "fontSize": { "xs": "1.2em", "sm": "1.4em" } }} variant='subtitle'>Generalidades</Typography>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Categoría"
                        value={category}
                        onChange={(e) => {
                            setCategory(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, category: '' }));
                        }}
                        error={!!errors.category}
                        helperText={errors.category}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="matematica">Matemática</MenuItem>
                        <MenuItem value="didactica_de_la_matematica">Didáctica de la matemática</MenuItem>
                        <MenuItem value="formacion_pedagogica">Formación pedagógica</MenuItem>
                        <MenuItem value="formacion_practica">Formación práctica</MenuItem>
                        <MenuItem value="otras_disciplinas">Otras disciplinas</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Formación docente para..."
                        value={academicLevel}
                        onChange={(e) => {
                            setAcademicLevel(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, academicLevel: '' }));
                        }}
                        error={!!errors.academicLevel}
                        helperText={errors.academicLevel}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="educacion_parvularia">Educación parvularia</MenuItem>
                        <MenuItem value="educacion_basica">Educación básica</MenuItem>
                        <MenuItem value="educacion_media">Educación media</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Etapa formativa"
                        value={stage}
                        onChange={(e) => {
                            setStage(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, stage: '' }));
                        }}
                        error={!!errors.stage}
                        helperText={errors.stage}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="formacion_inicial">Formación inicial</MenuItem>
                        <MenuItem value="formacion_continua">Formación continua</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Visibilidad"
                        value={visibility}
                        onChange={(e) => {
                            setVisibility(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, visibility: '' }));
                        }}
                        error={!!errors.visibility}
                        helperText={errors.visibility}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="public">Público</MenuItem>
                        <MenuItem value="semipublic">Semi-Público</MenuItem>
                        <MenuItem value="private">Privado</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="Título"
                        variant="outlined"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, title: '' }));
                        }}
                        error={!!errors.title}
                        helperText={errors.title}
                        disabled={formLoading}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} mt={"20px"}>
                    <TextField
                        fullWidth
                        label={"Descripción"}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, description: '' }));
                        }}
                        error={!!errors.description}
                        helperText={errors.description}
                        disabled={formLoading}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="¿Para qué sirve este material?"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={justification}
                        onChange={(e) => {
                            setJustification(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, justification: '' }));
                        }}
                        error={!!errors.justification}
                        helperText={errors.justification}
                        disabled={formLoading}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Estado"
                        value={status}
                        onChange={(e) => {
                            setStatus(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, status: '' }));
                        }}
                        error={!!errors.status}
                        helperText={errors.status}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="proposed">Pendiente</MenuItem>
                        <MenuItem value="approved">Aprobado</MenuItem>
                        <MenuItem value="hidden">Oculto</MenuItem>
                        <MenuItem value="rejected">Rechazado</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="¿Destacado?"
                        value={featuredState}
                        onChange={(e) => {
                            setFeaturedState(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, featuredState: '' }));
                        }}
                        error={!!errors.featuredState}
                        helperText={errors.featuredState}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="main_featured">Portada</MenuItem>
                        <MenuItem value="featured">Destacado</MenuItem>
                        <MenuItem value="not_featured">Sin destacar</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <Autocomplete
                        value={user}
                        onChange={(event, newValue) => {
                            setUser(newValue != null ? { email: newValue.email, label: newValue.label, username: newValue.username, firstName: newValue.firstName, lastName: newValue.lastName } : null);
                            setErrors({ ...errors, user: '' });

                        }}
                        id="tags-outlined"
                        options={formatUsersIntoSelector(users)}
                        ChipProps={{ variant: "orange" }}
                        getOptionLabel={(u) => u.label + " (" + u.username + ")"}
                        getOptionKey={(u) => u.username}
                        isOptionEqualToValue={(option, value) => { return option.username === value.username }}
                        defaultValue={undefined}
                        disabled={formLoading}
                        filterSelectedOptions
                        noOptionsText="No se encontraron usuarios."
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Usuario que comparte la actividad"
                                placeholder="Usuario que comparte la actividad"
                                error={!!errors.user}
                                helperText={errors.user}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Typography sx={{ "fontSize": { "xs": "1.2em", "sm": "1.4em" } }} variant='subtitle' marginTop="20px">Autores</Typography>
            <MaterialDocenteAuthors authors={authors} setAuthors={setAuthors} errors={errors} setErrors={setErrors} formLoading={formLoading} editing={editing} />
            <Typography sx={{ "fontSize": { "xs": "1.2em", "sm": "1.4em" } }} variant='subtitle' marginTop="20px">Recursos</Typography>
            <MaterialDocenteFiles files={files} setFiles={setFiles} errors={errors} setErrors={setErrors} formLoading={formLoading} editing={editing} />

        </>
    );
}

export default RecursosActivityForm;