import React from 'react';
import { Grid, TextField, MenuItem, Autocomplete } from '@mui/material';
import { formatUsersIntoSelector } from '../../../utils/formatters';


const CapsulaForm = ({
    users,
    title,
    setTitle,
    kind,
    setKind,
    date,
    setDate,
    video_url,
    setVideoUrl,
    exposes,
    setExposes,
    priority,
    setPriority,
    published,
    setPublished,
    description,
    setDescription,
    exposesSubtitle,
    setExposesSubtitle,
    user,
    setUser,
    formLoading,
    errors,
    setErrors
}) => {

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="Título"
                        variant="outlined"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, title: "" }))
                        }}
                        error={!!errors.title}
                        helperText={errors.title}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        select
                        label="Tipo"
                        variant="outlined"
                        value={kind}
                        onChange={(e) => {
                            setKind(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, kind: "" }))
                        }}
                        error={!!errors.kind}
                        helperText={errors.kind}
                        disabled={formLoading}
                    >
                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="rep">Estudio propio</MenuItem>
                        <MenuItem value="rhii">Habilidades para la innovación e investigación</MenuItem>
                        <MenuItem value="reac">Elementos a considerar para enseñar algo</MenuItem>
                        <MenuItem value="reo">Estudio realizado por otro</MenuItem>
                        <MenuItem value="rap">Actividad pedagógica</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={"5px"}>
                <Grid item xs={12} sm={4}>
                    <TextField
                        fullWidth
                        label="Fecha"
                        variant="outlined"
                        value={date}
                        onChange={(e) => {
                            setDate(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, date: "" }))
                        }}
                        error={!!errors.date}
                        helperText={errors.date}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="URL del vídeo"
                        variant="outlined"
                        value={video_url}
                        onChange={(e) => {
                            setVideoUrl(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, video_url: "" }))
                        }}
                        error={!!errors.video_url}
                        helperText={errors.video_url}
                        disabled={formLoading}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={"5px"}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Expositor"
                        variant="outlined"
                        value={exposes}
                        onChange={(e) => {
                            setExposes(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, exposes: "" }))
                        }}
                        error={!!errors.exposes}
                        helperText={errors.exposes}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        fullWidth
                        label="Prioridad"
                        variant="outlined"
                        value={priority}
                        onChange={(e) => {
                            setPriority(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, priority: "" }))
                        }}
                        error={!!errors.priority}
                        helperText={errors.priority}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={6} sm={3}>
                    <TextField
                        fullWidth
                        select
                        label="Publicada"
                        variant="outlined"
                        value={published}
                        onChange={(e) => {
                            setPublished(e.target.value)
                        }}
                        disabled={formLoading}
                        helperText={errors.published}
                        error={!!errors.published}
                    >
                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="yes">Sí</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={"5px"}>
                <Grid item xs={12} sm={8}>
                    <TextField
                        fullWidth
                        label="Título del expositor"
                        variant="outlined"
                        value={exposesSubtitle}
                        onChange={(e) => {
                            setExposesSubtitle(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, exposes: "" }))
                        }}
                        error={!!errors.exposes}
                        helperText={errors.exposes}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                <Autocomplete
                        value={user}
                        onChange={(event, newValue) => {
                            setUser(newValue != null ? { email: newValue.email, label: newValue.label, username: newValue.username, firstName: newValue.firstName, lastName: newValue.lastName } : null);
                            setErrors({ ...errors, user: '' });

                        }}
                        id="tags-outlined"
                        options={formatUsersIntoSelector(users)}
                        ChipProps={{ variant: "orange" }}
                        getOptionLabel={(u) => u.label + " (" + u.username + ")"}
                        getOptionKey={(u) => u.username}
                        isOptionEqualToValue={(option, value) => { return option.username === value.username }}
                        defaultValue={undefined}
                        disabled={formLoading}
                        filterSelectedOptions
                        noOptionsText="No se encontraron usuarios."
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Usuario que comparte la cápsula"
                                placeholder="Usuario que comparte la cápsula"
                                error={!!errors.user}
                                helperText={errors.user}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2} marginTop={"5px"}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Descripción"
                        variant="outlined"
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, description: "" }))
                        }}
                        error={!!errors.description}
                        helperText={errors.description}
                        disabled={formLoading}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default CapsulaForm;