import React from 'react';
import { Box, Button, CircularProgress, Grid, IconButton, MenuItem, TextField, Typography, Autocomplete } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../../utils/fileUpload';
import { concatAuthors, formatFileSize, removeHtmlTags, formatUsersIntoSelector } from '../../../utils/formatters';


const RecursosInvestigacionForm = ({
    users,
    category,
    setCategory,
    subcategory,
    setSubcategory,
    visibility,
    setVisibility,
    reference,
    setReference,
    doi,
    setDOI,
    resourceUrl,
    setResourceUrl,
    file,
    setFile,
    title,
    setTitle,
    author,
    setAuthor,
    subtitle,
    setSubtitle,
    description,
    setDescription,
    editor,
    setEditor,
    year,
    setYear,
    pages,
    setPages,
    journal,
    setJournal,
    volume,
    setVolume,
    issue,
    setIssue,
    justification,
    setJustification,
    status,
    setStatus,
    featuredState,
    setFeaturedState,
    user,
    setUser,
    loadingDOI,
    setLoadingDOI,
    formLoading,
    errors,
    setErrors,
    editing
}) => {


    async function handleDOI(doi) {
        const Cite = require('citation-js');
        if (!doi) {
            return;
        }
        setLoadingDOI(true);
        try {
            const data = await Cite.async(doi);
            const authors = data["data"][0].author
            setTitle(data["data"][0].title ? data["data"][0].title : "");
            setAuthor(authors ? concatAuthors(authors) : "");
            setDescription(removeHtmlTags(data["data"][0].abstract ? data["data"][0].abstract : ""));
            setEditor(data["data"][0].publisher ? data["data"][0].publisher : "");
            setJournal(data["data"][0]["container-title"] ? data["data"][0]["container-title"] : "");
            setYear(data["data"][0].issued["date-parts"][0][0] ? data["data"][0].issued["date-parts"][0][0] : "");
            setVolume(data["data"][0].volume ? data["data"][0].volume : "");
            setIssue(data["data"][0].issue ? data["data"][0].issue : "");
            setPages(data["data"][0].page ? data["data"][0].page : "");
            var justificacion = "";
            if (authors) {
                justificacion += concatAuthors(authors) + " ";
            }
            if (data["data"][0].issued["date-parts"][0][0]) {
                justificacion += "(" + data["data"][0].issued["date-parts"][0][0] + "). ";
            }
            if (data["data"][0].title) {
                justificacion += data["data"][0].title + ". ";
            }
            if (data["data"][0]["container-title"]) {
                justificacion += data["data"][0]["container-title"] + ", ";
            }
            if (data["data"][0].volume) {
                justificacion += data["data"][0].volume;
            }
            if (data["data"][0].issue) {
                if (data["data"][0].volume) {
                    justificacion += "(" + data["data"][0].issue + "), ";
                }
            }
            if (data["data"][0].page) {
                justificacion += data["data"][0].page + ".";
            }
            if (justificacion.endsWith(",")) {
                justificacion = justificacion.slice(0, -1) + ".";
            }
            if (justificacion.endsWith(", ")) {
                justificacion = justificacion.slice(0, -2) + ".";
            }
            setJustification(justificacion);
            setErrors(prevErrors => ({ ...prevErrors, doi: '' }));
        } catch (error) {
            if (error.message.includes('Server responded with status code 404')) {
                setErrors(prevErrors => ({ ...prevErrors, doi: 'Artículo no encontrado.' }));
            } else if (error.message.includes('This format is not supported or recognized')) {
                setErrors(prevErrors => ({ ...prevErrors, doi: 'DOI inválido.' }));
            } else {
                console.error(error);
                setErrors(prevErrors => ({ ...prevErrors, doi: 'Ha ocurrido un error, no se pudo obtener la información del DOI.' }));
            }
            setTitle('');
            setAuthor('');
            setDescription('');
            setEditor('');
            setJournal('');
            setVolume('');
            setIssue('');
            setPages('');
        }
        setLoadingDOI(false);
    }

    const handleFileChange = (event) => {
        setFile(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, file: '' }));
    };

    const resetFileInput = () => {
        setFile(null);
        setErrors(prevErrors => ({ ...prevErrors, file: '' }));
    };

    return (
        <>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Categoría"
                        value={category}
                        onChange={(e) => {
                            setCategory(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, category: '', subcategory: '' }));
                        }}
                        error={!!errors.category}
                        helperText={errors.category}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="educacion_matematica">Educación matemática</MenuItem>
                        <MenuItem value="formacion_profesores">Formación de profesores</MenuItem>
                        <MenuItem value="aprendizaje_formadores">Aprendizaje profesional de formadores</MenuItem>
                        <MenuItem value="talleres_redfid">Material de cursos y talleres RedFID</MenuItem>
                        <MenuItem value="other">Otros</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Tipo de material"
                        value={subcategory}
                        onChange={(e) => {
                            setReference("none");
                            setSubcategory(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, subcategory: '' }));
                        }}
                        error={!!errors.subcategory}
                        helperText={errors.subcategory}
                        variant="outlined"
                        disabled={formLoading || editing}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="article">Artículo</MenuItem>
                        <MenuItem value="congress_minute">Acta de congreso</MenuItem>
                        <MenuItem value="technical_report">Reporte técnico</MenuItem>
                        <MenuItem value="poster">Póster</MenuItem>
                        <MenuItem value="book">Libro</MenuItem>
                        <MenuItem value="book_chapter">Capítulo de libro</MenuItem>
                        <MenuItem value="website">Página web</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Visibilidad"
                        value={visibility}
                        onChange={(e) => {
                            setVisibility(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, visibility: '' }));
                        }}
                        error={!!errors.visibility}
                        helperText={errors.visibility}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="public">Público</MenuItem>
                        <MenuItem value="semipublic">Semi-Público</MenuItem>
                        <MenuItem value="private">Privado</MenuItem>
                    </TextField>
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                {(subcategory !== "website" && subcategory !== "none") && (
                    <Grid item xs={12} sm={4} mt={"20px"}>
                        <TextField
                            select
                            fullWidth
                            label="¿Como acceder a este material?"
                            value={reference}
                            onChange={(e) => {
                                if (e.target.value === 'doi') {
                                    setFile(null);
                                    setResourceUrl('');
                                } else if (e.target.value === 'url') {
                                    setDOI('');
                                    setFile(null);
                                } else if (e.target.value === 'direct') {
                                    setDOI('');
                                    setResourceUrl('');
                                }
                                setReference(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, reference: '' }));
                            }}
                            error={!!errors.reference}
                            helperText={errors.reference}
                            variant="outlined"
                            disabled={formLoading}
                        >
                            <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                            {(subcategory !== "website" && subcategory !== "technical_report") && <MenuItem value="doi">DOI</MenuItem>}
                            <MenuItem value="url">URL</MenuItem>
                            {(subcategory === "congress_minute" || subcategory === "technical_report" || subcategory === "poster") && <MenuItem value="direct">Archivo</MenuItem>}
                        </TextField>
                    </Grid>
                )}
                {(reference === "doi" && subcategory !== "website") && (
                    <Grid item xs={loadingDOI ? 11 : 12} sm={loadingDOI ? 7 : 8} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="DOI"
                            value={doi}
                            onBlur={(e) => {
                                handleDOI(e.target.value);
                            }}
                            onChange={(e) => {
                                setDOI(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, doi: '' }));
                            }}
                            error={!!errors.doi}
                            helperText={errors.doi}
                            variant="outlined"
                            disabled={formLoading || loadingDOI}
                        >
                        </TextField>
                    </Grid>
                )}
                {(reference === "doi" && subcategory !== "website" && loadingDOI) && (
                    <Grid item xs={1} textAlign={"right"} mt={"20px"}>
                        <CircularProgress sx={{ "color": "#40b4ba" }} />
                    </Grid>
                )}
                {(subcategory === "website" || reference === "url") && (
                    <Grid item xs={12} sm={(reference === "url" && subcategory !== "website") ? 8 : 12} mt={"20px"}>
                        <TextField
                            label="URL del recurso"
                            component="label"
                            variant="outlined"
                            fullWidth
                            value={resourceUrl}
                            tabIndex={-1}
                            onChange={(e) => setResourceUrl(e.target.value)}
                            error={!!errors.resourceUrl}
                            helperText={errors.resourceUrl}
                            disabled={formLoading}
                        />
                    </Grid>
                )}
                {(subcategory !== "website" && reference === "direct") && (
                    <Grid item xs={12} sm={4} mt={"20px"}>
                        <Grid>
                            <Button
                                component="label"
                                role={undefined}
                                variant="orange"
                                tabIndex={-1}
                                onChange={handleFileChange}
                                startIcon={<CloudUploadIcon />}
                                disabled={formLoading}
                            >
                                Subir archivo
                                <FileUpload type="file" onChange={handleFileChange} accept={".pdf"} />
                            </Button>
                            <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                {file && (
                                    <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                        <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                            {file ? `${file.name} (${formatFileSize(file.size)})` : "Por favor seleccione un archivo..."}
                                        </Typography>
                                        <IconButton disabled={formLoading} sx={{ marginLeft: "10px" }} size="small" onClick={resetFileInput}>
                                            <CloseIcon fontSize="inherit" />
                                        </IconButton>
                                    </Box>
                                )}
                            </Typography>
                            {errors.file ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.file}</Typography> : <></>}
                        </Grid>
                    </Grid>
                )}
            </Grid>
            <Grid container columnSpacing={2} alignItems="center" marginTop="5px">
                <Grid item xs={12} sm={8} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="Título"
                        variant="outlined"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, title: '' }));
                        }}
                        error={!!errors.title}
                        helperText={errors.title}
                        disabled={loadingDOI || formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="Autor(es)"
                        variant="outlined"
                        value={author}
                        onChange={(e) => {
                            setAuthor(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, author: '' }));
                        }}
                        error={!!errors.author}
                        helperText={errors.author}
                        disabled={loadingDOI || formLoading}
                    />
                </Grid>
            </Grid>
            {(subcategory === 'book' || subcategory === 'book_chapter') && (
                <Grid container columnSpacing={2} alignItems="center">
                    <Grid item xs={12} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Subtítulo"
                            variant="outlined"
                            value={subtitle}
                            onChange={(e) => {
                                setSubtitle(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, subtitle: '' }));
                            }}
                            error={!!errors.subtitle}
                            helperText={errors.subtitle}
                            disabled={loadingDOI || formLoading}
                        />
                    </Grid>
                </Grid>
            )}
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} mt={"20px"}>
                    <TextField
                        fullWidth
                        label={subcategory === 'article' ? "Resumen" : "Descripción"}
                        variant="outlined"
                        multiline
                        rows={6}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, description: '' }));
                        }}
                        error={!!errors.description}
                        helperText={errors.description}
                        disabled={loadingDOI || formLoading}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                {subcategory !== "poster" &&
                    <Grid item xs={12} sm={6} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Editor"
                            variant="outlined"
                            value={editor}
                            onChange={(e) => {
                                setEditor(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, editor: '' }));
                            }}
                            error={!!errors.editor}
                            helperText={errors.editor}
                            disabled={loadingDOI || formLoading}
                        />
                    </Grid>
                }
                <Grid item xs={6} sm={3} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="Año"
                        variant="outlined"
                        value={year}
                        onChange={(e) => {
                            setYear(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, year: '' }));
                        }}
                        error={!!errors.year}
                        helperText={errors.year}
                        disabled={loadingDOI || formLoading}
                    />
                </Grid>
                {(subcategory === 'article' || subcategory === 'book_chapter') && (
                    <Grid item xs={6} sm={3} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Páginas"
                            variant="outlined"
                            value={pages}
                            onChange={(e) => {
                                setPages(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, pages: '' }));
                            }}
                            error={!!errors.pages}
                            helperText={errors.pages}
                            disabled={loadingDOI || formLoading}
                        />
                    </Grid>
                )}
            </Grid>
            {(subcategory !== "website" && subcategory !== "poster") &&
                <Grid container columnSpacing={2} alignItems="center">
                    <Grid item xs={12} sm={6} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Revista"
                            variant="outlined"
                            value={journal}
                            onChange={(e) => {
                                setJournal(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, journal: '' }));
                            }}
                            error={!!errors.journal}
                            helperText={errors.journal}
                            disabled={loadingDOI || formLoading}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Volumen"
                            variant="outlined"
                            value={volume}
                            onChange={(e) => {
                                setVolume(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, volume: '' }));
                            }}
                            error={!!errors.volume}
                            helperText={errors.volume}
                            disabled={loadingDOI || formLoading}
                        />
                    </Grid>
                    <Grid item xs={6} sm={3} mt={"20px"}>
                        <TextField
                            fullWidth
                            label="Número"
                            variant="outlined"
                            value={issue}
                            onChange={(e) => {
                                setIssue(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, issue: '' }));
                            }}
                            error={!!errors.issue}
                            helperText={errors.issue}
                            disabled={loadingDOI || formLoading}
                        />
                    </Grid>
                </Grid>
            }
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="¿Cómo citar este recurso?"
                        variant="outlined"
                        multiline
                        rows={6}
                        value={justification}
                        onChange={(e) => {
                            setJustification(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, justification: '' }));
                        }}
                        error={!!errors.justification}
                        helperText={errors.justification}
                        disabled={formLoading || loadingDOI}
                    />
                </Grid>
            </Grid>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="Estado"
                        value={status}
                        onChange={(e) => {
                            setStatus(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, status: '' }));
                        }}
                        error={!!errors.status}
                        helperText={errors.status}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="proposed">Pendiente</MenuItem>
                        <MenuItem value="approved">Aprobado</MenuItem>
                        <MenuItem value="hidden">Oculto</MenuItem>
                        <MenuItem value="rejected">Rechazado</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <TextField
                        select
                        fullWidth
                        label="¿Destacado?"
                        value={featuredState}
                        onChange={(e) => {
                            setFeaturedState(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, featuredState: '' }));
                        }}
                        error={!!errors.featuredState}
                        helperText={errors.featuredState}
                        variant="outlined"
                        disabled={formLoading}
                    >
                        <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="main_featured">Portada</MenuItem>
                        <MenuItem value="featured">Destacado</MenuItem>
                        <MenuItem value="not_featured">Sin destacar</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={4} mt={"20px"}>
                    <Autocomplete
                        value={user}
                        onChange={(event, newValue) => {
                            setUser(newValue != null ? { email: newValue.email, label: newValue.label, username: newValue.username, firstName: newValue.firstName, lastName: newValue.lastName } : null);
                            setErrors({ ...errors, user: '' });

                        }}
                        id="tags-outlined"
                        options={formatUsersIntoSelector(users)}
                        ChipProps={{ variant: "orange" }}
                        getOptionLabel={(u) => u.label + " (" + u.username + ")"}
                        getOptionKey={(u) => u.username}
                        isOptionEqualToValue={(option, value) => { return option.username === value.username }}
                        defaultValue={undefined}
                        disabled={formLoading}
                        filterSelectedOptions
                        noOptionsText="No se encontraron usuarios."
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Usuario que comparte el recurso"
                                placeholder="Usuario que comparte el recurso"
                                error={!!errors.user}
                                helperText={errors.user}
                                variant="outlined"
                            />
                        )}
                    />
                </Grid>
            </Grid>
        </>
    );

}

export default RecursosInvestigacionForm;