import axios from "axios";
import { getYoutubeData } from '../other/getYoutubeData';

export const updateCapsulaTemplate = async (api, formData) => {
    try {

        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { capsulas: null, events: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const response = await axios.post(api.api_url + "/update_capsula_template", formData, {
            headers: {
                "Authorization": "Bearer " + access_token
            },
        });

        switch (response.data.status) {
            case 100:
                let cap = response.data.capsulas.capsulas;
                cap.forEach(async (capsula) => {
                    const statistics = await getYoutubeData(capsula.video_url);
                    capsula.viewCount = statistics.viewCount;
                    capsula.likeCount = statistics.likeCount;
                    capsula.favoriteCount = statistics.favoriteCount;
                    capsula.commentCount = statistics.commentCount;
                });
                return { capsulas: response.data.capsulas, error: "" };
            case 110:
                return { capsulas: null, error: "Acceso denegado. Revise la conexión con la API." };
            case 111:
                return { capsulas: null, error: "Por favor proporcione un archivo." };
            case 112:
                return { capsulas: null, error: "Formato inválido." };
            case 113:
                return { capsulas: null, error: "'kind' y 'university' son requeridos." };
            default:
                return { capsulas: null, error: "Falló la solicitud para actualizar una plantilla de cápsulas." };
        }
    } catch (error) {
        return { capsulas: null, error: "Error desconocido: " + error.message };
    }
}