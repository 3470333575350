import { formatDateFromDayJStoUnix } from './formatters';

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

const validateHTML = (message) => {
    const stack = [];
    const tagRegex = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
    let match;
    while ((match = tagRegex.exec(message)) !== null) {
        const tag = match[1];
        if (match[0][1] !== '/') {
            stack.push(tag);
        } else {
            if (stack.length === 0 || stack.pop() !== tag) {
                return false;
            }
        }
    }
    return stack.length === 0;
}

const validateUrl = (url) => {
    const urlPattern = new RegExp(
        "^" +
          // protocol identifier (optional)
          // short syntax // still required
          "(?:(?:(?:https?|ftp):)?\\/\\/)" +
          // user:pass BasicAuth (optional)
          "(?:\\S+(?::\\S*)?@)?" +
          "(?:" +
            // IP address exclusion
            // private & local networks
            "(?!(?:10|127)(?:\\.\\d{1,3}){3})" +
            "(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})" +
            "(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})" +
            // IP address dotted notation octets
            // excludes loopback network 0.0.0.0
            // excludes reserved space >= 224.0.0.0
            // excludes network & broadcast addresses
            // (first & last IP address of each class)
            "(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])" +
            "(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}" +
            "(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))" +
          "|" +
            // host & domain names, may end with dot
            // can be replaced by a shortest alternative
            // (?![-_])(?:[-\\w\\u00a1-\\uffff]{0,63}[^-_]\\.)+
            "(?:" +
              "(?:" +
                "[a-z0-9\\u00a1-\\uffff]" +
                "[a-z0-9\\u00a1-\\uffff_-]{0,62}" +
              ")?" +
              "[a-z0-9\\u00a1-\\uffff]\\." +
            ")+" +
            // TLD identifier name, may end with dot
            "(?:[a-z\\u00a1-\\uffff]{2,}\\.?)" +
          ")" +
          // port number (optional)
          "(?::\\d{2,5})?" +
          // resource path (optional)
          "(?:[/?#]\\S*)?" +
        "$", "i"
      );
    return urlPattern.test(url);
};

export const validateUserForm = (
    username,
    email, 
    password, 
    passwordConfirmation, 
    name, 
    lastName,
    active, 
    university, 
    alternateEmail,
    universityCode,
    universityName
) => {
    let tempErrors = {};
    let isValid = true;
    if (username.length < 3 || username.length > 50) {
        tempErrors.username = "El nombre de usuario debe tener entre 3 y 50 caracteres.";
        isValid = false;
    }
    if (username === '') {
        tempErrors.username = "Por favor ingrese un nombre de usuario.";
        isValid = false;
    }
    if (!validateEmail(email)) {
        tempErrors.email = "Por favor ingrese un email válido.";
        isValid = false;
    }
    if (email === '') {
        tempErrors.email = "Por favor ingrese un email.";
        isValid = false;
    }
    if (password.length < 6 || password.length > 20) {
        tempErrors.password = "La contraseña debe tener entre 6 y 20 caracteres.";
        isValid = false;
    }
    if (password === '') {
        tempErrors.password = "Por favor ingrese una contraseña.";
        isValid = false;
    }
    if (passwordConfirmation !== password) {
        tempErrors.passwordConfirm = "Las contraseñas no coinciden.";
        isValid = false;
    }
    if (passwordConfirmation === '') {
        tempErrors.passwordConfirm = "Por favor confirme la contraseña.";
        isValid = false;
    }
    if (name.length < 3 || name.length > 50) {
        tempErrors.firstName = "El nombre debe tener entre 3 y 50 caracteres.";
        isValid = false;
    }
    if (name === '') {
        tempErrors.firstName = "Por favor ingrese un nombre.";
        isValid = false;
    }
    if (lastName.length < 3 || lastName.length > 50) {
        tempErrors.lastName = "El apellido debe tener entre 3 y 50 caracteres.";
        isValid = false;
    }
    if (lastName === '') {
        tempErrors.lastName = "Por favor ingrese un apellido.";
        isValid = false;
    }
    if (active === 'none') {
        tempErrors.active = "Por favor seleccione un estado.";
        isValid = false;
    }
    if (alternateEmail && !validateEmail(alternateEmail)) {
        tempErrors.alternateEmail = "Por favor ingrese un email válido.";
        isValid = false;
    }
    if (university === 'none') {
        tempErrors.university = "Por favor seleccione una universidad.";
        isValid = false;
    }
    if (university === 'other'){
        if (universityCode.length > 10 || universityCode.length < 2) {
            tempErrors.universityCode = "El código de universidad debe tener entre 2 y 10 caracteres.";
            isValid = false;
        }
        if (universityCode === '') {
            tempErrors.universityCode = "Por favor ingrese un código de universidad.";
            isValid = false;
        }
        if (universityName.length > 100) {
            tempErrors.universityName = "El nombre de universidad debe tener menos de 50 caracteres.";
            isValid = false;
        }
        if (universityName === '') {
            tempErrors.universityName = "Por favor ingrese un nombre de universidad.";
            isValid = false;
        }
    }
    return [isValid, tempErrors];
}

export const validateUserPassword = (password) => {
    let tempErrors = {};
    let isValid = true;
    if (password.length < 6 || password.length > 20) {
        tempErrors.newPassword = "La contraseña debe tener entre 6 y 20 caracteres.";
        isValid = false;
    }
    if (password === '') {
        tempErrors.newPassword = "Por favor ingrese una contraseña.";
        isValid = false;
    }
    return [isValid, tempErrors];
}

export const validateTalleresYWebinarsForm = (title, kind, date, video_url, exposes, description, priority, published, associated) => {
    let tempErrors = {};
    let isValid = true;
    if (title.length < 3 || title.length > 200) {
        tempErrors.title = "El título debe tener entre 3 y 200 caracteres.";
        isValid = false;
    }
    if (title === '') {
        tempErrors.emailFrom = "Por favor ingrese un título.";
        isValid = false;
    }
    if (kind === 'none') {
        tempErrors.kind = "Por favor seleccione un tipo.";
        isValid = false;
    }
    if (date === '') {
        tempErrors.date = "Por favor ingrese una fecha.";
        isValid = false;
    }
    if (video_url === '') {
        tempErrors.video_url = "Por favor ingrese una URL.";
        isValid = false;
    }
    if (exposes === '') {
        tempErrors.exposes = "Por favor ingrese un expositor.";
        isValid = false;
    }
    if (description === '') {
        tempErrors.description = "Por favor ingrese una descripción.";
        isValid = false;
    }
    if (priority === '') {
        tempErrors.priority = "Por favor ingrese una prioridad.";
        isValid = false;
    }
    if (published === 'none') {
        tempErrors.published = "Por favor seleccione una opción.";
        isValid = false;
    }
    if (associated === 'none') {
        tempErrors.associated = "Por favor seleccione una opción.";
        isValid = false;
    }
    return [isValid, tempErrors];
}

export const validateCapsulasForm = (title, kind, date, video_url, exposes, exposesSubtitle, description, priority, published, user) => {
    let tempErrors = {};
    let isValid = true;
    if (title.length < 3 || title.length > 200) {
        tempErrors.title = "El título debe tener entre 3 y 200 caracteres.";
        isValid = false;
    }
    if (title === '') {
        tempErrors.title = "Por favor ingrese un título.";
        isValid = false;
    }
    if (kind === 'none') {
        tempErrors.kind = "Por favor seleccione un tipo.";
        isValid = false;
    }
    if (date === '') {
        tempErrors.date = "Por favor ingrese una fecha.";
        isValid = false;
    }
    if (video_url === '') {
        tempErrors.video_url = "Por favor ingrese una URL.";
        isValid = false;
    }
    if (exposes === '') {
        tempErrors.exposes = "Por favor ingrese un expositor.";
        isValid = false;
    }
    if (exposesSubtitle === '') {
        tempErrors.exposesSubtitle = "Por favor ingrese un título para el expositor.";
        isValid = false;
    }
    if (description === '') {
        tempErrors.description = "Por favor ingrese una descripción.";
        isValid = false;
    }
    if (priority === '') {
        tempErrors.priority = "Por favor ingrese una prioridad.";
        isValid = false;
    }
    if (published === 'none') {
        tempErrors.published = "Por favor seleccione una opción.";
        isValid = false;
    }
    if (user == null) {
        tempErrors.user = "Por favor seleccione un usuario.";
        isValid = false;
    }
    return [isValid, tempErrors];
}

export const validateNewsForm = (title, priority, published, author, externalUrl, startTimestamp, endTimestamp, content, mainImage, squareImage) => {
    let tempErrors = {};
    let isValid = true;
    if (title.length < 10 || title.length > 200) {
        tempErrors.title = "El título debe tener entre 10 y 200 caracteres.";
        isValid = false;
    }
    if (title === '') {
        tempErrors.title = "Por favor ingrese un título.";
        isValid = false;
    }
    if (priority === '') {
        tempErrors.priority = "Por favor ingrese una prioridad.";
        isValid = false;
    } else if (isNaN(parseInt(priority, 10)) || !Number.isInteger(parseInt(priority, 10))) {
        tempErrors.priority = "La prioridad debe ser un número entero.";
        isValid = false;
    } else if (parseInt(priority, 10) <= 0) {
        tempErrors.priority = "La prioridad debe ser un número mayor que cero.";
        isValid = false;
    }
    if (published === 'none') {
        tempErrors.published = "Por favor seleccione una opción.";
        isValid = false;
    }
    if (author !== '' && (author.length < 3 || author.length > 100)) {
        tempErrors.author = "El autor debe tener entre 3 y 100 caracteres.";
        isValid = false;
    }
    if (startTimestamp === null) {
        tempErrors.startTimestamp = "Por favor ingrese una fecha de inicio.";
        isValid = false;
    }
    if (endTimestamp === null) {
        tempErrors.endTimestamp = "Por favor ingrese una fecha de término.";
        isValid = false;
    }
    if (startTimestamp !== null && endTimestamp !== null) {
        if (formatDateFromDayJStoUnix(startTimestamp) >= formatDateFromDayJStoUnix(endTimestamp)) {
            tempErrors.endTimestamp = "La fecha de término debe ser mayor a la fecha de inicio.";
            isValid = false;
        }
    }
    if (mainImage === null) {
        tempErrors.mainImage = "Por favor seleccione una imagen principal.";
        isValid = false;
    } else if (!mainImage.name.endsWith(".jpg") && !mainImage.name.endsWith(".jpeg") && !mainImage.name.endsWith(".png")) {
        tempErrors.mainImage = "Por favor seleccione una imagen en formato JPG, JPEG o PNG.";
        isValid = false;
    } else if (mainImage.size > 1024 * 1024 * 10) {
        tempErrors.mainImage = "El tamaño máximo de la imagen principal es de 10 MB.";
        isValid = false;
    }
    if (squareImage === null) {
        tempErrors.squareImage = "Por favor seleccione una imagen cuadrada.";
        isValid = false;
    } else if (!squareImage.name.endsWith(".jpg") && !squareImage.name.endsWith(".jpeg") && !squareImage.name.endsWith(".png")) {
        tempErrors.squareImage = "Por favor seleccione una imagen en formato JPG, JPEG o PNG.";
        isValid = false;
    } else if (squareImage.size > 1024 * 1024 * 10) {
        tempErrors.squareImage = "El tamaño máximo de la imagen cuadrada es de 10 MB.";
        isValid = false;
    }
    if (externalUrl !== '') {
        tempErrors.content = [{ type: '', block: '', file: '' }];
        if (!validateUrl(externalUrl)) {
            tempErrors.externalUrl = "Por favor ingrese una URL válida.";
            isValid = false;
        }
    } else {
        tempErrors.content = [];
        content.map((section, index) => {
            tempErrors["content"][index] = { type: '', block: '', file: '' };
            if (section.type === 'none') {
                tempErrors["content"][index].type = "Por favor seleccione un tipo de bloque.";
                isValid = false;
            } else if (section.type === 'image') {
                if (section.file === null) {
                    tempErrors["content"][index].file = "Por favor seleccione una imagen.";
                    isValid = false;
                } else if (!section.file.name.endsWith(".jpg") && !section.file.name.endsWith(".jpeg") && !section.file.name.endsWith(".png")) {
                    tempErrors["content"][index].file = "Por favor seleccione una imagen en formato JPG, JPEG o PNG.";
                    isValid = false;
                } else if (section.file.size > 1024 * 1024 * 10) {
                    tempErrors["content"][index].file = "El tamaño máximo de la imagen es de 10 MB.";
                    isValid = false;
                }
            } else {
                if (section.block.length < 3) {
                    tempErrors["content"][index].block = "El bloque de texto debe tener al menos 3 caracteres.";
                    isValid = false;
                }
                if (section.block === '') {
                    tempErrors["content"][index].block = "Por favor ingrese un bloque de texto.";
                    isValid = false;
                }
            }
        });
    }
    return [isValid, tempErrors];
}

export const validateSendEmailForm = (emailFrom, template, subject, emailTo, courseTo, courseSectionTo, careerTo, regionTo, eventTo, universityTo, groupTo, usersTo, customEmailsTo, message, attachment) => {
    let tempErrors = {"emails": [{ email: '' }]};
    let isValid = true;
    if (emailFrom === 'none') {
        tempErrors.emailFrom = "Por favor seleccione un email de origen.";
        isValid = false;
    }
    if (template === 'none') {
        tempErrors.template = "Por favor seleccione una plantilla.";
        isValid = false;
    }
    if (subject.length < 3 || subject.length > 255) {
        tempErrors.subject = "El asunto debe tener entre 3 y 255 caracteres.";
        isValid = false;
    }
    if (subject === '') {
        tempErrors.subject = "Por favor ingrese un asunto.";
        isValid = false;
    }
    if (emailTo === 'none') {
        tempErrors.emailTo = "Por favor seleccione destinatarios.";
        isValid = false;
    }
    if (emailTo === 'inscritos' || emailTo === 'no_inscritos' || emailTo === 'terminaron' || emailTo === 'no_terminaron') {
        if (courseTo === 'none') {
            tempErrors.courseTo = "Por favor seleccione un curso.";
            isValid = false;
        }
        if (emailTo !== "none" && courseTo !== "none" && emailTo !== "no_inscritos") {
            if (courseSectionTo === 'none') {
                tempErrors.courseSectionTo = "Por favor seleccione una sección.";
                isValid = false;
            }
        }
    } else if (emailTo === 'users') {
        if (usersTo.length === 0) {
            tempErrors.usersTo = "Por favor seleccione usuarios.";
            isValid = false;
        }
    } else if (emailTo === 'other') {
        if (customEmailsTo.length === 0) {
            tempErrors.customEmailsTo = "Por favor ingrese al menos un email.";
            isValid = false;
        } else {
            for (let email of customEmailsTo) {
                if (!validateEmail(email.text)) {
                    tempErrors.customEmailsTo = "Email inválido: " + email.text;
                    isValid = false;
                }
            }
        }
    }
    if (emailTo === 'carrera' && careerTo === 'none') {
        tempErrors.careerTo = "Por favor seleccione una carrera.";
        isValid = false;
    }
    if (emailTo === 'region' && regionTo === 'none') {
        tempErrors.regionTo = "Por favor seleccione una región.";
        isValid = false;
    }
    if ((emailTo === 'asistieron' || emailTo === "no_asistieron") && eventTo === 'none') {
        tempErrors.eventTo = "Por favor seleccione un evento.";
        isValid = false;
    }
    if (emailTo === 'universidad' && universityTo === 'none') {
        tempErrors.universityTo = "Por favor seleccione una universidad.";
        isValid = false;
    }
    if (emailTo === 'group' && groupTo === 'none') {
        tempErrors.groupTo = "Por favor seleccione un grupo.";
        isValid = false;
    }
    if (message.length < 3 || message.length > 10000) {
        tempErrors.message = "El mensaje debe tener entre 3 y 10000 caracteres.";
        isValid = false;
    }
    if (message.trim() === '') {
        tempErrors.message = "Por favor ingrese un mensaje.";
        isValid = false;
    }
    if (!validateHTML(message)) {
        tempErrors.message = "El mensaje contiene etiquetas HTML no válidas o sin cerrar.";
        isValid = false;
    }
    if (attachment !== null) {
        if (attachment.size > 1024 * 1024 * 4) {
            tempErrors.attachment = "El tamaño máximo del archivo adjunto es de 4 MB.";
            isValid = false;
        }
    }
    return [isValid, tempErrors];
}


export const validateEventForm = (
    title,
    startDate,
    endDate,
    place,
    description,
    associated
) => {
    let tempErrors = {};
    let isValid = true;
    if (title.length < 3 || title.length > 200) {
        tempErrors.title = "El título debe tener entre 3 y 200 caracteres.";
        isValid = false;
    }
    if (title === '') {
        tempErrors.title = "Por favor ingrese un título.";
        isValid = false;
    }
    if (startDate == null) {
        tempErrors.startDate = "Por favor ingrese una fecha de inicio.";
        isValid = false;
    }
    if (endDate == null) {
        tempErrors.endDate = "Por favor ingrese una fecha de término.";
        isValid = false;
    }
    if (place === '') {
        tempErrors.place = "Por favor ingrese un lugar.";
        isValid = false;
    }
    if (description !== '') {
        if (description.length > 10000) {
            tempErrors.description = "La descripción debe tener menos de 10000 caracteres.";
            isValid = false;
        }
    }
    if (associated === 'none') {
        tempErrors.associated = "Por favor seleccione una opción";
        isValid = false;
    }
    return [isValid, tempErrors];
}


export const validateRecursosInvestigacionForm = (
    category,
    subcategory,
    visibility,
    reference,
    doi,
    file,
    resourceUrl,
    title,
    author,
    subtitle,
    description,
    editor,
    journal,
    year,
    volume,
    issue,
    pages,
    justification,
    status,
    featured_status,
    user
) => {
    let tempErrors = {};
    let isValid = true;
    if (category === 'none') {
        tempErrors.category = "Por favor indique la categoría del recurso.";
        isValid = false;
    }
    if (subcategory === 'none') {
        tempErrors.subcategory = "Por favor indique el tipo del recurso.";
        isValid = false;
    }
    if (visibility === 'none') {
        tempErrors.visibility = "Por favor seleccione una visibilidad para el recurso.";
        isValid = false;
    }
    if (subcategory !== "website" && reference === 'none') {
        tempErrors.reference = "Por favor seleccione una referencia para el recurso.";
        isValid = false;
    }
    if (subcategory !== "website" && reference === 'doi' && !doi) {
        tempErrors.doi = "Por favor ingrese un DOI válido.";
        isValid = false;
    }
    if (subcategory !== "website" && reference === 'direct') {
        if (!file) {
            tempErrors.file = "Por favor suba el archivo del recurso.";
            isValid = false;
        } else if (!file.name.endsWith(".pdf")) {
            tempErrors.file = "Por favor seleccione un archivo PDF.";
            isValid = false;
        } else if (file.size > 1024 * 1024 * 1024) {
            tempErrors.file = "El tamaño máximo del archivo es de 1 GB.";
            isValid = false;
        }
    }
    if (reference === 'url') {
        if (!validateUrl(resourceUrl)) {
            tempErrors.resourceUrl = "Por favor ingrese una URL válida.";
            isValid = false;
        }
        if (!resourceUrl) {
            tempErrors.resourceUrl = "Por favor ingrese una URL.";
            isValid = false;
        }
    }
    if (title.length > 500 || title.length < 5) {
        tempErrors.title = "El título debe tener entre 5 y 500 caracteres (actual: " + title.length + ").";
        isValid = false;
    }
    if (!title) {
        tempErrors.title = "Por favor indique el título del recurso.";
        isValid = false;
    }
    if (author && (author.length > 500 || author.length < 3)) {
        tempErrors.author = "El autor debe tener entre 3 y 500 caracteres (actual: " + author.length + ").";
        isValid = false;
    }
    if (!author) {
        tempErrors.author = "Por favor indique el autor de este material.";
        isValid = false;
    }
    if (subtitle && (subtitle.length > 500 || subtitle.length < 5)) {
        tempErrors.subtitle = "El subtítulo debe tener entre 5 y 500 caracteres (actual: " + subtitle.length + ").";
        isValid = false;
    }
    if (description && (description.length > 10000 || description.length < 10)) {
        tempErrors.description = "La descripción/abstract debe tener entre 10 y 10000 caracteres (actual: " + description.length + ").";
        isValid = false;
    }
    if (editor && (editor.length > 500 || editor.length < 3)) {
        tempErrors.editor = "El editor debe tener entre 3 y 500 caracteres (actual: " + editor.length + ").";
        isValid = false;
    }
    if (journal && (journal.length > 500 || journal.length < 3)) {
        tempErrors.journal = "La revista debe tener entre 3 y 500 caracteres (actual: " + journal.length + ").";
        isValid = false;
    }
    if (year && (year.length > 4 || year.length < 4)) {
        tempErrors.year = "El año debe tener 4 caracteres.";
        isValid = false;
    }
    if (!year) {
        tempErrors.year = "Por favor indique el año de publicación del recurso.";
        isValid = false;
    }
    if (volume && (volume.length > 20 || volume.length < 1)) {
        tempErrors.volume = "El volumen debe tener entre 1 y 20 caracteres (actual: " + volume.length + ").";
        isValid = false;
    }
    if (issue && (issue.length > 20 || issue.length < 1)) {
        tempErrors.issue = "El número debe tener entre 1 y 20 caracteres (actual: " + issue.length + ").";
        isValid = false;
    }
    if (pages && (pages.length > 20 || pages.length < 1)) {
        tempErrors.pages = "Las páginas deben tener entre 1 y 20 caracteres (actual: " + pages.length + ").";
        isValid = false;
    }
    if (justification.length > 10000 || justification.length < 10) {
        tempErrors.justification = "La forma de citar debe tener entre 10 y 10000 caracteres (actual: " + justification.length + ").";
        isValid = false;
    }
    if (!justification) {
        tempErrors.justification = "Por favor indique cómo citar este recurso.";
        isValid = false;
    }
    if (status === 'none') {
        tempErrors.status = "Por favor seleccione un estado.";
        isValid = false;
    }
    if (featured_status === 'none') {
        tempErrors.featuredState = "Por favor seleccione un nivel de destacado.";
        isValid = false;
    }
    if (user == null) {
        tempErrors.user = "Por favor seleccione un usuario.";
        isValid = false;
    }
    return [isValid, tempErrors];
};

export const validateActivityForm = (
    category,
    academicLevel,
    stage,
    visibility,
    title,
    description,
    justification,
    status,
    featuredState,
    user,
    files,
    authors
) => {
    let tempErrors = {
        "authors": [{ author: '', institution: '' }],
        "files": [{ type: '', file: '', reference: '', url: '' }],
    };
    const urlPattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    let isValid = true;
    if (category === 'none') {
        tempErrors.category = "Por favor seleccione una categoría.";
        isValid = false;
    }
    if (academicLevel === 'none') {
        tempErrors.academicLevel = "Por favor seleccione un nivel académico.";
        isValid = false;
    }
    if (stage === 'none') {
        tempErrors.stage = "Por favor seleccione una etapa.";
        isValid = false;
    }
    if (visibility === 'none') {
        tempErrors.visibility = "Por favor seleccione una visibilidad.";
        isValid = false;
    }
    if (title.length > 200 || title.length < 3) {
        tempErrors.title = "El título debe tener entre 3 y 200 caracteres.";
        isValid = false;
    }
    if (!title) {
        tempErrors.title = "Por favor indique un título.";
        isValid = false;
    }
    if (description.length > 10000 || description.length < 10) {
        tempErrors.description = "La descripción debe tener entre 10 y 10000 caracteres.";
        isValid = false;
    }
    if (!description) {
        tempErrors.description = "Por favor indique una descripción.";
        isValid = false;
    }
    if (justification.length > 10000 || justification.length < 10) {
        tempErrors.justification = "La justificación debe tener entre 10 y 10000 caracteres.";
        isValid = false;
    }
    if (!justification) {
        tempErrors.justification = "Por favor indique una justificación.";
        isValid = false;
    }
    if (status === 'none') {
        tempErrors.status = "Por favor seleccione un estado.";
        isValid = false;
    }
    if (featuredState === 'none') {
        tempErrors.featuredState = "Por favor seleccione un nivel de destacado.";
        isValid = false;
    }
    if (user == null) {
        tempErrors.user = "Por favor seleccione un usuario.";
        isValid = false;
    }
    authors.map((author, index) => {
        tempErrors.authors[index] = {}
        if (author.author.length > 100 || author.author.length < 3) {
            tempErrors.authors[index].author = "El nombre del autor debe tener entre 3 y 100 caracteres (actual: " + author.author.length + ").";
            isValid = false;
        }
        if (author.author === "") {
            tempErrors.authors[index].author = "Por favor indique el nombre del autor.";
            isValid = false;
        }
        if (tempErrors.authors[index].author === "") {
            tempErrors.authors[index].author = "";
        }
        if (author.institution.length > 100 || author.institution.length < 2) {
            tempErrors.authors[index].institution = "La afiliación del autor debe tener entre 2 y 100 caracteres (actual: " + author.institution.length + ").";
            isValid = false;
        }
        if (author.institution === "") {
            tempErrors.authors[index].institution = "Por favor indique la afiliación del autor.";
            isValid = false;
        }
        if (tempErrors.authors[index].institution === "") {
            tempErrors.authors[index].institution = "";
        }
    });
    files.map((file, index) => {
        tempErrors.files[index] = {}
        if (file.type === 'none') {
            tempErrors.files[index].type = "Por favor seleccione un tipo de archivo.";
            isValid = false;
        }
        if (file.reference === 'none') {
            tempErrors.files[index].reference = "Por favor seleccione una referencia.";
            isValid = false;
        }
        if (file.reference === 'subir_archivo') {
            if (file.file == null) {
                tempErrors.files[index].file = "Por favor adjunte un archivo.";
                isValid = false;
            }
            if (file.file && file.file.size > 1024 * 1024 * 1024) {
                tempErrors.files[index].file = "El tamaño máximo del archivo es de 1 GB.";
                isValid = false;
            }
        }
        if (file.reference === 'url') {
            if (file.url.length > 500 || file.url.length < 5) {
                tempErrors.files[index].url = "La URL debe tener entre 5 y 500 caracteres (actual: " + file.url.length + ").";
                isValid = false;
            }
            if (!urlPattern.test(file.url)) {
                tempErrors.files[index].url = "La URL no es válida.";
                isValid = false;
            }
            if (file.url === "") {
                tempErrors.files[index].url = "Por favor indique la URL del recurso.";
                isValid = false;
            }
        }
    });
    if (Object.keys(files).length === 0) {
        tempErrors.files = "Por favor adjunte al menos un archivo.";
        isValid = false;
    }
    return [isValid, tempErrors];
}

export const validateGroupForm = (
    groupName,
    groupVisibilityLevel,
    groupOwner
) => {
    let tempErrors = {};
    let isValid = true;
    if (groupName.length < 8 || groupName.length > 50) {
        tempErrors.groupName = "El nombre del grupo debe tener entre 8 y 50 caracteres.";
        isValid = false;
    }
    if (groupName === '') {
        tempErrors.groupName = "Por favor ingrese un nombre de grupo.";
        isValid = false;
    }
    if (groupVisibilityLevel === 'none') {
        tempErrors.groupVisibilityLevel = "Por favor seleccione una visibilidad.";
        isValid = false;
    }
    if (groupOwner == null) {
        tempErrors.groupOwner = "Por favor seleccione un dueño.";
        isValid = false;
    }
    return [isValid, tempErrors];
}
