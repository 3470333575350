import axios from 'axios';

export const getKeycloakUserActivity = async (api, user_id) => {

    function parseClientId(clientId) {
        switch (clientId) {
            case "account":
                return "Cuenta";
            case "comunidades":
                return "Comunidades";
            case "https://comunidades.redfid.cl":
                return "Comunidades"
            case "comunidades.redfid.cl/mahara":
                return "Comunidades (Mahara)"
            case "comunidades2":
                return "Comunidades"
            case "php-saml":
                return "Home"
            case "https://www.redfid.cl/wp-content/plugins/miniorange-saml-20-single-sign-on/":
                return "Home"
            case "https://cursos.redfid.cl":
                return "Aprendizaje Profesional"
            case "recursos":
                return "Recursos"
            case "https://recursos.vdaiep.cl/apps/user_saml/saml/metadata":
                return "Recursos"
            case "adminpanel":
                return "Panel de administración"
            case "https://backup.redfid.cl":
                return "Respaldo edX (antiguo)"
            default:
                return clientId;
        }
    }
    
    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { activity: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const activityResponse = await axios.get(api.api_url + "/get_user_sessions?user_id=" + user_id, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (activityResponse.data.status) {
            case 100:
                let activities = [];
                for(let action of activityResponse.data.result) {
                    activities.push({
                        time: action.time,
                        platform: parseClientId(action.clientId),
                        eventType: "Login",
                        detail: {}
                    });
                }
                return { activity: activities, error: "" };
            case 110:
                return { activity: null, error: "Acceso denegado. Revise la conexión con la API." };    
            case 111:
                return { activity: null, error: "Ha ocurrido un error accediendo a Keycloak." };
            case 113:
                return { activity: null, error: "Por favor indique un usuario." };
            case 115:
                return { activity: null, error: "Falló la solicitud para obtener sesiones de Keycloak." };
            default:
                return { activity: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { activity: null, error: "Error desconocido: " + error.message };
    }
}
