import React, { useState } from 'react';
import { Grid, Box, Button, Link, Typography } from '@mui/material';
import SendEmailForm from './components/forms/SendEmailForm';
import SendIcon from '@mui/icons-material/Send';
import Modal from './components/Modal';
import { validateSendEmailForm } from '../utils/validators';
import { sendEmail } from '../requests/other/sendEmail';
import { getEmailPreview } from '../requests/other/getEmailPreview';
import { api } from '../API';
import redfidLogoWhite from '../assets/img/logo_white.png';

const Email = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    users,
    attributes,
    groups,
    courses,
    events
}) => {

    const [errors, setErrors] = useState({ "emails": [{ email: '' }] });
    const [formLoading, setFormLoading] = useState(false);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [emailPreview, setEmailPreview] = useState(null);
    const [emailFrom, setEmailFrom] = useState('none');
    const [emailTo, setEmailTo] = useState('none');
    const [usersTo, setUsersTo] = useState([]);
    const [careerTo, setCareerTo] = useState('none');
    const [regionTo, setRegionTo] = useState('none');
    const [eventTo, setEventTo] = useState('none');
    const [universityTo, setUniversityTo] = useState('none');
    const [groupTo, setGroupTo] = useState('none');
    const [courseTo, setCourseTo] = useState('none');
    const [courseSectionTo, setCourseSectionTo] = useState('none');
    const [customEmailsTo, setCustomEmailsTo] = useState([]);
    const [template, setTemplate] = useState('none');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [attachment, setAttachment] = useState(null);
    const [fileError, setFileError] = useState(false);

    const handleOpenPreviewModal = () => {
        setShowPreviewModal(true);
    }

    const handleClosePreviewModal = () => {
        setShowPreviewModal(false);
    }

    const resetForm = () => {
        setEmailFrom('none');
        setEmailTo('none');
        setUsersTo([]);
        setGroupTo('none');
        setCourseTo('none');
        setCourseSectionTo('none');
        setTemplate('none');
        setSubject('');
        setMessage('');
        setAttachment(null);
        setErrors({ "emails": [{ email: '' }] });
        setFileError(false);
        setFormLoading(false);
    };

    const handlePreview = async () => {
        const [isValid, tempErrors] = validateSendEmailForm(emailFrom, template, subject, emailTo, courseTo, courseSectionTo, careerTo, regionTo, eventTo, universityTo, groupTo, usersTo, customEmailsTo, message, attachment);
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        var to = '';
        if (emailTo === 'users') {
            to = usersTo.map(user => user.email);
        } else if (emailTo === 'group') {
            to = users.filter(user => groups.find(group => group.id === groupTo).users.includes(user.username)).map(user => user.email);
        } else if (emailTo === 'all') {
            to = users.map(user => user.email);
        } else if (emailTo === 'staff') {
            to = users.filter(user => user.attributes && user.attributes.is_staff && user.attributes.is_staff[0] === "1").map(user => user.email);
        } else if (emailTo === 'formadores') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1").map(user => user.email);
        } else if (emailTo === 'asistieron') {
            to = users.filter(user => events.find(event => event.id === eventTo).attendants.includes(user.username)).map(user => user.email);
        } else if (emailTo === 'no_asistieron') {
            to = users.filter(user => !events.find(event => event.id === eventTo).attendants.includes(user.username)).map(user => user.email);
        } else if (emailTo === 'carrera') {
            to = users.filter(user => user.attributes && user.attributes.career && user.attributes.career[0] === careerTo).map(user => user.email);
        } else if (emailTo === 'region') {
            to = users.filter(user => user.attributes && user.attributes.region && user.attributes.region[0] === regionTo).map(user => user.email);
        } else if (emailTo === 'universidad') {
            to = users.filter(user => user.attributes && user.attributes.university && user.attributes.university[0] === universityTo).map(user => user.email);
        } else if (emailTo === 'inscritos') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            if (courseSectionTo !== 'all') {
                to = to.filter(user => courses[courseTo]["sections"][courseSectionTo]["data"]["enrolled"].includes(user.username))
            } else {
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if (sections[sectionKey]["data"].enrolled.includes(user.username)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                });
            }
            to = to.map(user => user.email);
        } else if (emailTo === 'no_inscritos') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            to = to.filter(user => {
                for (let courseKey of Object.keys(courses)) {
                    const sections = courses[courseKey].sections;
                    for (let sectionKey of Object.keys(sections)) {
                        if (sectionKey !== "0") {
                            if (sections[sectionKey]["data"].enrolled.includes(user.username)) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            });
            to = to.map(user => user.email);
        } else if (emailTo === 'terminaron') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            if (courseSectionTo !== 'all') {
                to = to.filter(user => courses[courseTo]["sections"][courseSectionTo]["data"]["approved"].includes(user.username))
            } else {
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if (sections[sectionKey]["data"].approved.includes(user.username)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                });
            }
            to = to.map(user => user.email);
        } else if (emailTo === 'no_terminaron') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            if (courseSectionTo !== 'all') {
                to = to.filter(user => courses[courseTo]["sections"][courseSectionTo]["data"]["enrolled"].includes(user.username))
                to = to.filter(user => !courses[courseTo]["sections"][courseSectionTo]["data"]["approved"].includes(user.username))
            } else {
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if ((sections[sectionKey]["data"].enrolled.includes(user.username) && sections[sectionKey]["data"].approved.includes(user.username))) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                });
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if (sections[sectionKey]["data"].enrolled.includes(user.username)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                });
            }
            to = to.map(user => user.email);
        } else if (emailTo === 'other') {
            to = customEmailsTo.map(email => email.text);
        }
        setFormLoading(true);
        const formData = new FormData();
        formData.append('fromEmail', emailFrom);
        formData.append('toEmails', JSON.stringify(to));
        formData.append('template', template);
        formData.append('subject', subject);
        formData.append('templateArgs', JSON.stringify({
            message_title: subject,
            message_body: message
        }));
        const response = await getEmailPreview(api, formData);
        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "¡Error al generar vista previa!", "text": response.error } });
        } else {
            setEmailPreview(response.result);
            handleOpenPreviewModal();
        }
        setFormLoading(false);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const [isValid, tempErrors] = validateSendEmailForm(emailFrom, template, subject, emailTo, courseTo, courseSectionTo, careerTo, regionTo, eventTo, universityTo, groupTo, usersTo, customEmailsTo, message, attachment);
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        var to = '';
        if (emailTo === 'users') {
            to = usersTo.map(user => user.email);
        } else if (emailTo === 'group') {
            to = users.filter(user => groups.find(group => group.id === groupTo).users.includes(user.username)).map(user => user.email);
        } else if (emailTo === 'all') {
            to = users.map(user => user.email);
        } else if (emailTo === 'staff') {
            to = users.filter(user => user.attributes && user.attributes.is_staff && user.attributes.is_staff[0] === "1").map(user => user.email);
        } else if (emailTo === 'formadores') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1").map(user => user.email);
        } else if (emailTo === 'asistieron') {
            to = users.filter(user => events.find(event => event.id === eventTo).attendants.includes(user.username)).map(user => user.email);
        } else if (emailTo === 'no_asistieron') {
            to = users.filter(user => !events.find(event => event.id === eventTo).attendants.includes(user.username)).map(user => user.email);
        } else if (emailTo === 'carrera') {
            to = users.filter(user => user.attributes && user.attributes.career && user.attributes.career[0] === careerTo).map(user => user.email);
        } else if (emailTo === 'region') {
            to = users.filter(user => user.attributes && user.attributes.region && user.attributes.region[0] === regionTo).map(user => user.email);
        } else if (emailTo === 'universidad') {
            to = users.filter(user => user.attributes && user.attributes.university && user.attributes.university[0] === universityTo).map(user => user.email);
        } else if (emailTo === 'inscritos') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            if (courseSectionTo !== 'all') {
                to = to.filter(user => courses[courseTo]["sections"][courseSectionTo]["data"]["enrolled"].includes(user.username))
            } else {
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if (sections[sectionKey]["data"].enrolled.includes(user.username)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                });
            }
            to = to.map(user => user.email);
        } else if (emailTo === 'no_inscritos') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            to = to.filter(user => {
                for (let courseKey of Object.keys(courses)) {
                    const sections = courses[courseKey].sections;
                    for (let sectionKey of Object.keys(sections)) {
                        if (sectionKey !== "0") {
                            if (sections[sectionKey]["data"].enrolled.includes(user.username)) {
                                return false;
                            }
                        }
                    }
                }
                return true;
            });
            to = to.map(user => user.email);
        } else if (emailTo === 'terminaron') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            if (courseSectionTo !== 'all') {
                to = to.filter(user => courses[courseTo]["sections"][courseSectionTo]["data"]["approved"].includes(user.username))
            } else {
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if (sections[sectionKey]["data"].approved.includes(user.username)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                });
            }
            to = to.map(user => user.email);
        } else if (emailTo === 'no_terminaron') {
            to = users.filter(user => user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1")
            if (courseSectionTo !== 'all') {
                to = to.filter(user => courses[courseTo]["sections"][courseSectionTo]["data"]["enrolled"].includes(user.username))
                to = to.filter(user => !courses[courseTo]["sections"][courseSectionTo]["data"]["approved"].includes(user.username))
            } else {
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if ((sections[sectionKey]["data"].enrolled.includes(user.username) && sections[sectionKey]["data"].approved.includes(user.username))) {
                                    return false;
                                }
                            }
                        }
                    }
                    return true;
                });
                to = to.filter(user => {
                    for (let courseKey of Object.keys(courses)) {
                        const sections = courses[courseKey].sections;
                        for (let sectionKey of Object.keys(sections)) {
                            if (sectionKey !== "0") {
                                if (sections[sectionKey]["data"].enrolled.includes(user.username)) {
                                    return true;
                                }
                            }
                        }
                    }
                    return false;
                });
            }
            to = to.map(user => user.email);
        } else if (emailTo === 'other') {
            to = customEmailsTo.map(email => email.text);
        }
        setFormLoading(true);
        const formData = new FormData();
        formData.append('fromEmail', emailFrom);
        formData.append('toEmails', JSON.stringify(to));
        formData.append('template', template);
        formData.append('subject', subject);
        formData.append('templateArgs', JSON.stringify({
            message_title: subject,
            message_body: message
        }));
        formData.append(`attachment`, attachment);
        const response = await sendEmail(api, formData);
        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "¡Envío fallido!", "text": response.error } });
        } else {
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "¡Email enviado!", "text": "El email se ha enviado exitosamente." } });
            resetForm();
        }
        setFormLoading(false);
    };

    return (
        <>
            <Grid sx={{ width: '100%', padding: '20px' }}>
                <Typography variant="h6">Nuevo email</Typography>
                <SendEmailForm
                    emailFrom={emailFrom}
                    setEmailFrom={setEmailFrom}
                    emailTo={emailTo}
                    setEmailTo={setEmailTo}
                    usersTo={usersTo}
                    setUsersTo={setUsersTo}
                    careerTo={careerTo}
                    setCareerTo={setCareerTo}
                    regionTo={regionTo}
                    setRegionTo={setRegionTo}
                    eventTo={eventTo}
                    setEventTo={setEventTo}
                    universityTo={universityTo}
                    setUniversityTo={setUniversityTo}
                    groupTo={groupTo}
                    setGroupTo={setGroupTo}
                    courseTo={courseTo}
                    setCourseTo={setCourseTo}
                    courseSectionTo={courseSectionTo}
                    setCourseSectionTo={setCourseSectionTo}
                    customEmailsTo={customEmailsTo}
                    setCustomEmailsTo={setCustomEmailsTo}
                    template={template}
                    setTemplate={setTemplate}
                    subject={subject}
                    setSubject={setSubject}
                    message={message}
                    setMessage={setMessage}
                    attachment={attachment}
                    setAttachment={setAttachment}
                    errors={errors}
                    setErrors={setErrors}
                    formLoading={formLoading}
                    users={users}
                    attributes={attributes}
                    groups={groups}
                    courses={courses}
                    events={events}
                    setFileError={setFileError}
                />
                {fileError && <Typography mt={"20px"} textAlign={"center"} variant="p" color="#d32f2f">El tamaño máximo de los archivos adjuntos es de máximo 25 MB en total.</Typography>}
                <Box textAlign={"center"}>
                    <Button onClick={handlePreview} sx={{ "fontSize": { "xs": "0.7em", "sm": "0.9em" } }} variant="cancel" disabled={formLoading} style={{ margin: "30px 10px 20px 0" }} >
                        Vista previa
                    </Button>
                    <Button onClick={handleSubmit} sx={{ "fontSize": { "xs": "0.7em", "sm": "0.9em" } }} variant="green" disabled={formLoading} style={{ margin: "30px 0 20px 0" }} startIcon={<SendIcon />}>
                        {formLoading ? 'Enviando...' : 'Enviar'}
                    </Button>
                </Box>
            </Grid>
            <Modal
                open={showPreviewModal}
                handleClose={handleClosePreviewModal}
                title={"Vista previa"}
                onClickMainButton={handleClosePreviewModal}
                mainButtonText={"OK"}
                hideMainButton={true}
                width={'md'}
                cancelButton={false}
                content={
                    <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
                        <Typography variant="p" sx={{ fontSize: "1em", marginBottom: '20px' }}>Asunto: {subject}</Typography>
                        <Typography variant="p" sx={{ fontSize: "1em", marginBottom: '20px' }}>
                            Destinatarios: {
                                emailTo === 'users' ?
                                    usersTo
                                    : emailTo === 'group' ?
                                        groupTo
                                        : emailTo === 'all' ?
                                            'Todos los usuarios'
                                            : emailTo === 'staff' ?
                                                'Staff'
                                                : emailTo === 'formadores' ?
                                                    'Formadores'
                                                    : emailTo === 'asistieron' ?
                                                        'Asistentes al evento: ' + eventTo
                                                        : emailTo === 'no_asistieron' ?
                                                            'No asistentes al evento: ' + eventTo
                                                            : emailTo === 'carrera' ?
                                                                careerTo
                                                                : emailTo === 'region' ?
                                                                    regionTo
                                                                    : emailTo === 'universidad' ?
                                                                        universityTo
                                                                        : emailTo === 'inscritos' ?
                                                                            'Inscritos en el curso'
                                                                            : emailTo === 'no_inscritos' ?
                                                                                'No inscritos en el curso'
                                                                                : emailTo === 'terminaron' ?
                                                                                    'Terminaron el curso'
                                                                                    : emailTo === 'no_terminaron' ?
                                                                                        'No terminaron el curso'
                                                                                        : emailTo === 'other' ?
                                                                                            customEmailsTo.map(email => email.text).join(', ')
                                                                                            : 'Ninguno'
                            }
                        </Typography>
                        {attachment && <Typography variant="p" sx={{ fontSize: "1em", marginBottom: '20px' }}>Adjunto: {attachment.name}</Typography>}
                        {emailPreview && (
                            <iframe
                                title="email-preview"
                                srcDoc={emailPreview.replace('cid:logo', redfidLogoWhite)}
                                style={{ width: '100%', height: "700px", flexGrow: 1 }}
                            />
                        )}
                    </Box>
                }
            >
            </Modal>
        </>
    )
}

export default Email;
