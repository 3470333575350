import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Box, Typography, Link } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import PeopleIcon from '@mui/icons-material/People';
import DownloadIcon from '@mui/icons-material/Download';
import { formatFileSize } from '../../../utils/formatters';
import { getGroupVisibilityChip,getDiscourseCategoryChip } from '../../../utils/chips';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { sort } from '@amcharts/amcharts4/.internal/core/utils/Iterator';


const GroupsTable = ({
    groups,
    driveData,
    handleOpenCreateModal,
    handleOpenDownloadCSVModal,
    handleOpenStatsModal,
    handleOpenInfoModal,
    handleOpenGroupMembersModal,
    handleOpenDeleteModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu group={params.row} />
            )
        },
        { 
            field: 'full_name', 
            disableColumnMenu: true, 
            headerName: 'Nombre', 
            flex: 1, 
            align: 'left', 
            headerAlign: 'left', 
            sortComparator: (v1, v2) => v2.localeCompare(v1),
            renderCell: (params) => <Typography variant="a"><Link href={process.env.REACT_APP_COMUNIDADES_URL + "/g/" + params.row.name} target="_blank" color={"#40b4ba"}>{params.row.full_name}</Link></Typography> 
        },
        { 
            field: 'user_count', 
            disableColumnMenu: true,
            headerName: 'Miembros', 
            width: 120, 
            align: 'center', 
            headerAlign: 'center',
            sortComparator: (v1, v2) => v2 - v1,
            renderCell: (params) => params.row.user_count - 1
        },
        { 
            field: 'visibility_level', 
            disableColumnMenu: true, 
            headerName: 'Visibilidad', 
            width: 150, 
            align: 'center', 
            headerAlign: 'center',
            valueGetter: (params, row) => row.visibility_level,
            sortComparator: (v1, v2) => v2 - v1,
            renderCell: (params) => getGroupVisibilityChip(params.row.visibility_level)
        },
        { 
            field: 'drive', 
            disableColumnMenu: true, 
            headerName: 'Unidad compartida en Drive', 
            width: 450, 
            align: 'center', 
            headerAlign: 'center',
            sortable: false,
            renderCell: (params) => <Typography variant="a"><Link href={"https://drive.google.com/drive/folders/" + params.row.name} target="_blank" color={"#40b4ba"}>{"https://drive.google.com/drive/folders/" + params.row.name}</Link></Typography>
        },
        { 
            field: 'drive_size',
            disableColumnMenu: true, 
            headerName: 'Uso de Drive', 
            width: 200, align: 'center',
            headerAlign: 'center', 
            sortComparator: (v1, v2) => v2 - v1,
            valueGetter: (params, row) => driveData["sharedDrives"][row.full_name]["size"],
            valueFormatter: (params) => formatFileSize(params)
        }
    ];

    const ActionsMenu = ({ group }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            handleOpenInfoModal(group);
                            handleClose();
                        }}
                    >
                        <InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleOpenGroupMembersModal(group);
                            handleClose();
                        }}
                    >
                        <PeopleIcon sx={{ "marginRight": "10px" }} /> Miembros
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleOpenDeleteModal(group);
                            handleClose();
                        }}
                    >
                        <DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Grupos</Typography>
                <Box>
                    <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={handleOpenStatsModal}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={groups}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'full_name', sort: 'asc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default GroupsTable;
