import React from 'react';
import { Grid, MenuItem, TextField, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import "dayjs/locale/es";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const EventForm = ({
    title,
    setTitle,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    place,
    setPlace,
    description,
    setDescription,
    associated,
    setAssociated,
    formLoading,
    errors,
    setErrors,
    talleresYWebinars,
    entryId
}) => {

    return (
        <>
            <Grid container columnSpacing={2} alignItems="center">
                <Grid item xs={12} sm={12} mt={"20px"}>
                    <TextField
                        label="Título"
                        component="label"
                        variant="outlined"
                        fullWidth
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value);
                            setErrors({ ...errors, title: '' });
                        }}
                        error={!!errors.title}
                        helperText={errors.title}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DateTimePicker
                            label="Inicio"
                            sx={{
                                "width": "100%"
                            }}
                            value={startDate}
                            onChange={(date) => {
                                setStartDate(date);
                                setErrors({ ...errors, startDate: '' });
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.startDate,
                                    helperText: errors.startDate,
                                    disabled: formLoading
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DateTimePicker
                            label="Término"
                            sx={{
                                "width": "100%"
                            }}
                            value={endDate}
                            onChange={(date) => {
                                setEndDate(date);
                                setErrors({ ...errors, endDate: '' });
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.endDate,
                                    helperText: errors.endDate,
                                    disabled: formLoading
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={12} md={6} mt={"20px"}>
                    <TextField
                        label="Ubicación"
                        component="label"
                        variant="outlined"
                        fullWidth
                        value={place}
                        onChange={(e) => {
                            setPlace(e.target.value);
                            setErrors({ ...errors, place: '' });
                        }}
                        error={!!errors.place}
                        helperText={errors.place}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} mt={"20px"}>
                    <TextField
                        label="Descripción"
                        component="label"
                        variant="outlined"
                        fullWidth
                        multiline
                        rows={4}
                        value={description}
                        onChange={(e) => {
                            setDescription(e.target.value);
                            setErrors({ ...errors, description: '' });
                        }}
                        error={!!errors.description}
                        helperText={errors.description}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={"20px"}>
                    <TextField
                        select
                        label="Taller/webinar asociado"
                        variant="outlined"
                        fullWidth
                        value={associated}
                        onChange={(e) => {
                            setAssociated(e.target.value);
                            setErrors({ ...errors, associated: '' });
                        }}
                        error={!!errors.associated}
                        helperText={errors.associated}
                        disabled={formLoading}
                    >
                        <MenuItem disabled value={"none"}>Por favor seleccione una opción...</MenuItem>
                        <MenuItem value={0}>Ninguno</MenuItem>
                        {entryId ?
                            talleresYWebinars.filter(tw => (tw.associated === 0 || tw.associated === entryId)).map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.title}
                                </MenuItem>
                            ))
                            :
                            talleresYWebinars.filter(tw => tw.associated === 0).map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.title}
                                </MenuItem>
                            ))
                        }
                    </TextField>
                </Grid>
            </Grid>
        </>
    )

}

export default EventForm;