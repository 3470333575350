import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';


const DownloadCSVActivityForm = ({
    csvEventPlatform,
    setCsvEventPlatform,
    csvEventType,
    setCsvEventType,
    csvExcel,
    setCsvExcel
}) => {


    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } disabled label="Fecha" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvEventPlatform}
                                onChange={(e) => setCsvEventPlatform(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Plataforma" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvEventType}
                                onChange={(e) => setCsvEventType(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Tipo" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={(e) => setCsvExcel(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );

}

export default DownloadCSVActivityForm;