import axios from "axios";

export const feedbackRecurso = async (action, type, id, feedback) => {
    try {
        const formData = new FormData();
        formData.append("feedback", feedback);
        const responseFeedbackRecurso = await axios.post(process.env.REACT_APP_RECURSOS_URL + "/admin/" + action + "_" + type + "/" + id, formData, {
            headers: {
                "Authorization": process.env.REACT_APP_RECURSOS_TOKEN
            }
        })

        switch (responseFeedbackRecurso.status) {
            case 200:
                return { recursos: responseFeedbackRecurso.data, error: "" };
            default:
                return { recursos: [], error: "Falló la solicitud para eliminar un recurso en RedFID Recursos." };
        }
    } catch (error) {
        return { recursos: [], error: "Error desconocido: " + error.message };
    }
}