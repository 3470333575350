import axios from "axios";

export const editRecurso = async (recursoId, formData) => {
    try {
        const response = await axios.post(process.env.REACT_APP_RECURSOS_URL + "/admin/edit_" + formData.get("subcategory") + "/" + recursoId, formData, {
            headers: {
                "Authorization": process.env.REACT_APP_RECURSOS_TOKEN
            }
        });
        switch (response.status) {
            case 200:
                return { recursos: response.data, error: "" };
            case 404:
                return { recursos: null, error: "El recurso ya existe." };
            default:
                return { recursos: null, error: "Falló la solicitud para editar un recurso académico en RedFID Recursos." };
        }
    } catch (error) {
        if (error.response && error.response.status && error.response.status === 491){
            return { recursos: null, error: "Este recurso ya existe." };
        } else {
            return { recursos: null, error: "Error desconocido: " + error.message };
        }
    }
}