import React from 'react';
import { Typography } from '@mui/material';
import Grid from '@mui/material/Grid';
import Indicator from './components/Indicator';
import LineChart from './components/charts/LineChart.js';
import BarChart from './components/charts/BarChart.js';
import PieChart from './components/charts/PieChart.js';
import MapChile from './components/MapChile/MapChile.js'


const Dashboard = ({
    users,
    attributes,
    analytics,
    sessions
}) => {

    return (
        <>
            <Grid spacing={2} container>
                <Grid item container mt={"5px"} xs={12} md={3}>
                    <Grid textAlign={"center"} item xs={12}>
                        {(attributes && users) && <MapChile data={Object.keys(attributes.region.options).reduce((acc, r) => (acc[r] = users.filter(u => u.attributes.region && u.attributes.region[0] === r).length, acc), {})}></MapChile>}
                    </Grid>
                </Grid>
                <Grid item container mt={"5px"} xs={12} md={9}>
                    <Grid sx={{ "height": "fit-content" }} textAlign={"center"} item xs={3}>
                        <Indicator title="Formadores" number={
                            users.filter(user => user.enabled && user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1").length
                        } titleSize="small" subtitleSize="small" helpMessage={
                            "Número total de formadores que son miembros de la red y tienen acceso a la plataforma."
                        }></Indicator>
                    </Grid>
                    <Grid sx={{ "height": "fit-content" }} textAlign={"center"} item xs={3}>
                        <Indicator title="Universidades" number={
                            users.filter(
                                user => user.enabled && user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1" && user.attributes.university && user.attributes.university[0] !== "none" && user.attributes.university[0] !== "no"
                            ).map(user => user.attributes.university && user.attributes.university[0]).filter((value, index, self) => self.indexOf(value) === index).length
                        } titleSize="small" subtitleSize="small" helpMessage={
                            "Número total de universidades con formadores participando en la red."
                        }></Indicator>
                    </Grid>
                    <Grid sx={{ "height": "fit-content" }} textAlign={"center"} item xs={3}>
                        <Indicator title="Sesiones (7d)" number={
                            sessions.filter(session => users.filter(user => user.enabled && user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1").map(user => user.username).includes(session.details.username)).length
                        } subtitle="Últimos 7 días" titleSize="small" subtitleSize="small" helpMessage={
                            "Número total de sesiones de formadores en la plataforma durante los últimos 7 días."
                        }></Indicator>
                    </Grid>
                    <Grid sx={{ "height": "fit-content" }} textAlign={"center"} item xs={3}>
                        <Indicator title="Visitas (30d)" number={
                            Object.values(analytics.users_per_day).reduce((sum, val) => sum + val, 0)
                        } subtitle="Últimos 30 días" titleSize="small" subtitleSize="small" helpMessage={
                            "Número total de visitas a la plataforma durante los últimos 30 días."
                        }></Indicator>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h6">Visitas por día</Typography>
                        <LineChart
                            id="line-chart"
                            data={Object.keys(analytics.users_per_day).map(key => ({ date: key, value: analytics.users_per_day[key], color: "#eb947e" }))}
                            timeInterval={"day"}
                            tooltipFormatter={(value) => value + " visitas"}
                            limit={7}
                            horizontal={false}
                            height={300}
                            labelAngle={0}
                        ></LineChart>
                    </Grid>
                </Grid>
                <Grid container mt={"5px"}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6">Visitas por ambiente</Typography>
                        <PieChart
                            id={"visits-by-platform"}
                            height={"300px"}
                            data={() => {
                                let out = [];
                                var addedOther = false;
                                for (let key of Object.keys(analytics.users_per_hostname)) {
                                    switch (key) {
                                        case "www.redfid.cl":
                                            out.push({ label: "Home", value: analytics.users_per_hostname[key], color: "#40b4ba" });
                                            break;
                                        case "cursos.redfid.cl":
                                            out.push({ label: "Aprendizaje Profesional", value: analytics.users_per_hostname[key], color: "#eb947e" });
                                            break;
                                        case "comunidades.redfid.cl":
                                            out.push({ label: "Comunidades", value: analytics.users_per_hostname[key], color: "gold" });
                                            break;
                                        case "":
                                            out.push({ label: "Recursos", value: analytics.users_per_hostname[key], color: "tomato" });
                                            break;
                                        default:
                                            if (!addedOther) {
                                                out.push({ label: "Otro", value: analytics.users_per_hostname[key], color: "#595959" });
                                                addedOther = true;
                                            } else {
                                                for (let item of out) {
                                                    if (item.label === "Otro") {
                                                        item.value += analytics.users_per_hostname[key];
                                                    }
                                                }
                                            }
                                            break;
                                    }
                                }
                                return out;
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6">Visitas por país</Typography>
                        <BarChart
                            id={"visits-by-country"}
                            height={"300px"}
                            horizontal={true}
                            limit={10}
                            data={() => {
                                let out = [];
                                for (let key of Object.keys(analytics.users_per_country)) {
                                    out.push({ label: key, value: analytics.users_per_country[key], color: "#40b4ba" });
                                }
                                return out;
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6">Visitas por ciudad</Typography>
                        <BarChart
                            id={"visits-by-city"}
                            height={"300px"}
                            horizontal={true}
                            limit={10}
                            data={() => {
                                let out = [];
                                for (let key of Object.keys(analytics.users_per_city)) {
                                    if (key !== "(not set)") {
                                        out.push({ label: key, value: analytics.users_per_city[key], color: "#40b4ba" });
                                    }
                                }
                                return out;
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>

    );
}

export default React.memo(Dashboard);