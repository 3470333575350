import axios from 'axios';
import { getYoutubeData } from '../other/getYoutubeData';

export const deleteTalleresYWebinars = async (api, id) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { talleresYWebinars: null, events: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const talleresYWebinarsResponse = await axios.post(api.api_url + "/delete_talleresywebinars", {"id": id}, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (talleresYWebinarsResponse.data.status) {
            case 100:
                let tw = talleresYWebinarsResponse.data.talleresywebinars;
                tw.forEach(async (taller) => {
                    const statistics = await getYoutubeData(taller.video_url);
                    taller.viewCount = statistics.viewCount;
                    taller.likeCount = statistics.likeCount;
                    taller.favoriteCount = statistics.favoriteCount;
                    taller.commentCount = statistics.commentCount;
                });
                return { talleresYWebinars: talleresYWebinarsResponse.data.talleresywebinars, events: talleresYWebinarsResponse.data.events, error: "" };
            case 110:
                return { talleresYWebinars: null, events: null, error: "Acceso denegado. Revise la conexión con la API." };
            case 111:
                return { talleresYWebinars: null, events: null, error: "Se requiere ID de taller/webinar." };
            case 112:
                return { talleresYWebinars: null, events: null, error: "Taller/Webinar no encontrado." };
            default:
                return { talleresYWebinars: null, events: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { talleresYWebinars: null, events: null, error: "Error desconocido: " + error.message };
    }
}
