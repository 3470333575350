import axios from 'axios';

export const mainCategories = {
    "4": "Novedades",
    "5": "Aprendizaje Profesional",
    "6": "Innovación e Investigación",
    "7": "Otros"
}

export const adminCategories = [0, 1, 2, 3]

export const getDiscussions = async (api) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { discussions: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const discussionsResponse = await axios.get(api.api_url + "/get_discourse_discussions", {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });


        switch (discussionsResponse.data.status) {
            case 100:
                let discussions = []
                for(let discussion of discussionsResponse.data.discussions.topic_list.topics) {
                    discussions.push({ 
                        ...discussion,
                        is_main: discussion.category_id in mainCategories && !adminCategories.includes(discussion.category_id),
                        is_group: !(discussion.category_id in mainCategories) && !adminCategories.includes(discussion.category_id)
                    });
                }
                return { discussions: discussions, error: "" };
            case 105:
                return { discussions: null, error: "Falló la solicitud para obtener discusiones de Discourse." };
            default:
                return { discussions: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { discussions: [], error: "Error desconocido: " + error.message };
    }
}
