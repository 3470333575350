import React from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import Link from '@mui/material/Link';

const NotFound = () => {
  return (
    <Box style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Header />
      <Box
        component="div"
        p={"0 20px"}
        sx={{
          flexGrow: 1,
          m: '30px auto',
          maxWidth: '1400px',
          textAlign: 'center',
        }}
      >
        <Typography variant='title'>Página no encontrada</Typography>
        <Link href="/"><Typography variant='a'>Volver al Dashboard</Typography></Link>
      </Box>
      <Footer />
    </Box>
  );
}

export default NotFound;