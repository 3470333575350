import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Menu, MenuItem, Typography, Box, Link } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DownloadIcon from '@mui/icons-material/Download';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDateFromISO } from '../../../utils/formatters';


const NewsTable = ({
    news,
    handleOpenCreateModal,
    handleOpenStatsModal,
    handleOpenDownloadCSVModal,
    handleOpenInfoModal,
    handleOpenEditModal,
    handleOpenToggleModal,
    handleOpenDeleteModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu selectedNews={params.row} />
            )
        },
        {
            field: 'priority',
            headerName: 'Prioridad',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'left',
            width: 120,
            sortComparator: (a, b) => {
                return parseInt(a) - parseInt(b);
            }
        },
        {
            field: 'title',
            headerName: 'Título',
            align: 'left',
            disableColumnMenu: true,
            headerAlign: 'left',
            flex: 1
        },
        {
            field: 'slug',
            headerName: 'URL',
            align: 'left',
            disableColumnMenu: true,
            headerAlign: 'left',
            flex: 1,
            renderCell: (params) => (
                params.row.external_url === "" ?
                    <Link href={`${process.env.REACT_APP_HOME_URL}/events/${params.row.slug}`} color={"#40b4ba"} target="_blank" rel="noopener noreferrer">{`${process.env.REACT_APP_HOME_URL}/events/${params.row.slug}`}</Link>
                    :
                    <Link href={params.row.external_url} color={"#40b4ba"} target="_blank" rel="noopener noreferrer">{params.row.external_url}</Link>
            )
        },
        {
            field: 'published',
            headerName: 'Publicada',
            width: 120,
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {params.row.published
                        ? <CheckCircleIcon sx={{ color: "#088408" }} />
                        : "—"
                    }
                </Box>
            )
        },
        {
            field: 'active',
            headerName: 'En portada',
            width: 120,
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {(params.row.published) && (params.row.start_timestamp <= Date.now()) && (params.row.end_timestamp >= Date.now())
                        ? <CheckCircleIcon sx={{ color: "#088408" }} />
                        : "—"
                    }
                </Box>
            )
        },
        {
            field: 'start_timestamp',
            headerName: 'Inicio',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 180,
            valueGetter: (params, row) => row.start_timestamp,
            valueFormatter: (params, row) => formatDateFromISO(row.start_timestamp)
        },
        {
            field: 'end_timestamp',
            headerName: 'Término',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 180,
            valueGetter: (params, row) => row.end_timestamp,
            valueFormatter: (params, row) => formatDateFromISO(row.end_timestamp)
        }
    ];

    const ActionsMenu = ({ selectedNews }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleOpenInfoModal(selectedNews)}><InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada</MenuItem>
                    <MenuItem onClick={async () => handleOpenEditModal(selectedNews)}><EditIcon sx={{ "marginRight": "10px" }} /> Editar</MenuItem>
                    {selectedNews.published ?
                        (
                            <MenuItem onClick={() => handleOpenToggleModal(selectedNews)}><VisibilityOffIcon sx={{ "marginRight": "10px" }} /> Ocultar</MenuItem>
                        ) : (
                            <MenuItem onClick={() => handleOpenToggleModal(selectedNews)}><RemoveRedEyeIcon sx={{ "marginRight": "10px" }} /> Publicar</MenuItem>
                        )}
                    <MenuItem onClick={() => handleOpenDeleteModal(selectedNews)}><DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar</MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Noticias</Typography>
                <Box>
                    <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={handleOpenStatsModal}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={news}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'priority', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );

}

export default NewsTable;