import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { Box, IconButton, MenuItem, Menu, Typography } from '@mui/material';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import InfoIcon from '@mui/icons-material/Info';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { translateCategoryActivity } from '../../../utils/formatters';
import { getPendingChip, getLevelChip, getStageChip } from '../../../utils/chips';


const RecursosActivitiesTable = ({
    activities,
    handleOpenCreateModal,
    handleOpenDownloadCSVModal,
    handleOpenStatsModal,
    handleOpenInfoModal,
    handleOpenEditModal,
    handleOpenHideModal,
    handleOpenApproveModal,
    handleOpenFeatureModal,
    handleOpenMainModal,
    handleOpenDeleteModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 80,
            headerAlign: 'center',
            disableColumnMenu: true,
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu recurso={params.row} />
            )
        },
        {
            field: 'status',
            headerName: 'Estado',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 120,
            sortComparator: (a, b) => {
                return a - b;
            },
            renderCell: (params) => {
                return getPendingChip(params.row.status)
            }
        },
        {
            field: 'title',
            headerName: 'Título',
            align: 'left',
            disableColumnMenu: true,
            headerAlign: 'center',
            flex: 1,
        },
        {
            field: 'recursos',
            headerName: 'Recursos',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 100,
            valueGetter: (params, row) => {
                return row.recursos.length
            }
        },
        {
            field: 'stage',
            headerName: 'Etapa',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 170,
            valueFormatter: (params) => getStageChip(params).props.label, 
            renderCell: (params) => {
                return getStageChip(params.row.stage)
            }
        },
        {
            field: 'level',
            headerName: 'Nivel',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 170,
            valueFormatter: (params) => getLevelChip(params).props.label, 
            renderCell: (params) => {
                return getLevelChip(params.row.level)
            }
        },
        {
            field: 'classification',
            headerName: 'Categoría',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 200,
            valueGetter: (params, row) => {
                return translateCategoryActivity(row.classification)
            }
        },
        {
            field: 'visits',
            headerName: 'Visitas',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 100,
            valueGetter: (params, row) => {
                return row.visits.length
            }
        },
        {
            field: 'featured',
            headerName: 'Destacado',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 120,
            sortComparator: (a, b) => {
                return a - b;
            },
            renderCell: (params) => {
                if (params.row.featured) {
                    return <Box display={"flex"} height={"100%"} justifyContent={"center"} alignItems={"center"}><CheckCircleIcon sx={{color: "green"}}></CheckCircleIcon></Box>
                } else {
                    return "—"
                }
            }
        },
        {
            field: 'main_featured',
            headerName: 'Portada',
            align: 'center',
            disableColumnMenu: true,
            headerAlign: 'center',
            width: 120,
            sortComparator: (a, b) => {
                return a - b;
            },
            renderCell: (params) => {
                if (params.row.main_featured) {
                    return <Box display={"flex"} height={"100%"} justifyContent={"center"} alignItems={"center"}><CheckCircleIcon sx={{color: "green"}}></CheckCircleIcon></Box>
                } else {
                    return "—"
                }
            }
        }
    ];


    const ActionsMenu = ({ recurso }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleOpenInfoModal(recurso)}><InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada</MenuItem>
                    <MenuItem onClick={() => handleOpenEditModal(recurso)}><EditIcon sx={{ "marginRight": "10px" }} /> Editar</MenuItem>
                    {recurso.status === "approved" &&
                        <MenuItem onClick={() => handleOpenHideModal(recurso)}><VisibilityOffIcon sx={{ "marginRight": "10px" }} /> Ocultar</MenuItem>
                    }
                    {(recurso.status === "proposed" || recurso.status === "rejected" || recurso.status === "hidden") &&
                        <MenuItem onClick={() => handleOpenApproveModal(recurso, "approve")}><CheckCircleIcon sx={{ "marginRight": "10px" }} /> Aprobar</MenuItem>
                    }
                    {(recurso.status === "proposed" || recurso.status === "hidden") &&
                        <MenuItem onClick={() => handleOpenApproveModal(recurso, "reject")}><CloseIcon sx={{ "marginRight": "10px" }} /> Rechazar</MenuItem>
                    }
                    {(recurso.status === "approved" && recurso.featured) &&
                        <MenuItem onClick={() => handleOpenFeatureModal(recurso)}><StarBorderIcon sx={{ "marginRight": "10px" }} /> Quitar de destacados</MenuItem>
                    }
                    {(recurso.status === "approved" && !recurso.featured) &&
                        <MenuItem onClick={() => handleOpenFeatureModal(recurso)}><StarIcon sx={{ "marginRight": "10px" }} /> Fijar en destacados</MenuItem>
                    }
                    {(recurso.status === "approved" && recurso.main_featured) &&
                        <MenuItem onClick={() => handleOpenMainModal(recurso)}><FavoriteBorderIcon sx={{ "marginRight": "10px" }} /> Quitar de portada</MenuItem>
                    }
                    {(recurso.status === "approved" && !recurso.main_featured) &&
                        <MenuItem onClick={() => handleOpenMainModal(recurso)}><FavoriteIcon sx={{ "marginRight": "10px" }} /> Fijar en portada</MenuItem>
                    }
                    <MenuItem onClick={() => handleOpenDeleteModal(recurso)}><DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar</MenuItem>
                </Menu>
            </div>
        );
    };


    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Material docente</Typography>
                <Box>
                    <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={() => handleOpenStatsModal("activity")}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={() => handleOpenDownloadCSVModal("activity")}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "20px 0" }}>
            <DataGrid
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                rows={activities}
                columns={columns}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'status', sort: 'asc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}  
            />
        </div>
    );
};

export default RecursosActivitiesTable;
