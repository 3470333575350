import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Avatar, Box, Typography, Link } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import BlockIcon from '@mui/icons-material/Block';


const EnrolledUsersTable = ({
    users,
    courseSection,
    handleOpenEnrollModal,
    handleOpenDownloadCSVModal,
    handleOpenUnenrollModal,
    handleOpenApproveModal,
    handleOpenRevokeModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu user={params.row} />
            )
        },
        {
            field: 'picture',
            headerName: 'Foto',
            sortable: false,
            width: 60,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                params.row.discourse_data == null ? <Avatar alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} /> : params.row.discourse_data.avatar_template == null ? <Avatar alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} /> : <Avatar src={process.env.REACT_APP_COMUNIDADES_URL + params.row.discourse_data.avatar_template.replace("{size}", "144")} alt="Foto" style={{ marginTop: "5px", width: 40, height: 40, borderRadius: 50 }} />
            )
        },
        { 
            field: 'lastName', 
            disableColumnMenu: true, 
            headerName: 'Apellido', 
            width: 120, 
            align: 'left', 
            headerAlign: 'left' 
        },
        { 
            field: 'firstName', 
            disableColumnMenu: true, 
            headerName: 'Nombre', 
            width: 120, 
            align: 'left', 
            headerAlign: 'left' 
        },
        { 
            field: 'username', 
            disableColumnMenu: true, 
            headerName: 'Usuario', 
            width: 120, align: 'left', 
            headerAlign: 'left' 
        },
        {
            field: 'approved',
            disableColumnMenu: true,
            headerName: 'Aprobado',
            sortable: true,
            width: 100,
            headerAlign: 'center',
            align: 'center',
            sortComparator: (a, b) => {
                return a - b;
            },
            valueGetter: (params, row) => courseSection.data.approved.includes(row.username),
            renderCell: (params) => (
                <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                    {courseSection.data.approved.includes(params.row.username)
                        ? <CheckCircleIcon sx={{ color: "#088408" }} />
                        : <CancelIcon sx={{ color: "#ff0000" }} />
                    }
                </Box>
            )
        },
        {
            field: 'certificate',
            disableColumnMenu: true,
            headerName: 'Certificado',
            sortable: true,
            flex: 1,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <Typography variant='a' sx={{"overflow": "auto"}}>
                    {courseSection.data.certificates ? (courseSection.data.certificates.filter(c => c.username === params.row.username).length > 0 ? <Link sx={{"color": "#40b4ba"}} href={process.env.REACT_APP_CURSOS_URL + "/certificates/" + courseSection.data.certificates.filter(c => c.username === params.row.username)[0].verify_uuid} target="_blank">{process.env.REACT_APP_CURSOS_URL + "/certificates/" + courseSection.data.certificates.filter(c => c.username === params.row.username)[0].verify_uuid}</Link> : "—") : "—"}
                </Typography>
            )
        }
    ];

    const ActionsMenu = ({ user }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            handleOpenUnenrollModal(user);
                        }}
                    >
                        <BlockIcon sx={{ "marginRight": "10px" }} /> Desinscribir
                    </MenuItem>
                    {!courseSection.data.approved.includes(user.username) ?
                        <MenuItem
                            onClick={() => {
                                handleOpenApproveModal(user);
                            }}
                        >
                            <CheckCircleIcon sx={{ "marginRight": "10px" }} /> Aprobar
                        </MenuItem>
                    :
                    <MenuItem
                        onClick={() => {
                            handleOpenRevokeModal(user);
                        }}
                    >
                        <CancelIcon sx={{ "marginRight": "10px" }} /> Reprobar
                    </MenuItem>
                    }
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Formadores inscritos</Typography>
                <Box>
                    <IconButton onClick={handleOpenEnrollModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={courseSection.data.enrolled.map((username) => {
                    const user = users.find(user => user.username === username);
                    return {
                        id: username,
                        username: username,
                        discourse_data: user.discourse_data,
                        lastName: user.lastName,
                        firstName: user.firstName
                    };
                })}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'lastName', sort: 'asc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default EnrolledUsersTable;
