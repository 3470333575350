import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";


const DownloadCSVEventsForm = ({
    csvTitle,
    setCsvTitle,
    csvStartDate,
    setCsvStartDate,
    csvEndDate,
    setCsvEndDate,
    csvStartTime,
    setCsvStartTime,
    csvEndTime,
    setCsvEndTime,
    csvPlace,
    setCsvPlace,
    csvDescription,
    setCsvDescription,
    csvCalendarId,
    setCsvCalendarId,
    csvAssociated,
    setCsvAssociated,
    csvTotalAttendants,
    setCsvTotalAttendants,
    csvAttendants,
    setCsvAttendants
}) => {


    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="ID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTitle}
                                onChange={
                                    (e) => {
                                        setCsvTitle(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Título" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvStartDate}
                                onChange={
                                    (e) => {
                                        setCsvStartDate(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Fecha de inicio" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvEndDate}
                                onChange={
                                    (e) => {
                                        setCsvEndDate(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Fecha de término" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvPlace}
                                onChange={
                                    (e) => {
                                        setCsvPlace(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Lugar" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvDescription}
                                onChange={
                                    (e) => {
                                        setCsvDescription(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descripción" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCalendarId}
                                onChange={
                                    (e) => {
                                        setCsvCalendarId(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="ID en Calendar" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvAssociated}
                                onChange={
                                    (e) => {
                                        setCsvAssociated(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Taller/webinar asociado" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTotalAttendants}
                                onChange={
                                    (e) => {
                                        setCsvTotalAttendants(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Total de asistentes" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvAttendants}
                                onChange={
                                    (e) => {
                                        setCsvAttendants(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Lista de asistentes" />
                </FormGroup>
            </Grid>
        </Grid>
    );

}

export default DownloadCSVEventsForm;