import axios from 'axios';

export const getAnalytics = async (api) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { analytics: [], error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const analyticsResponse = await axios.get(api.api_url + "/get_google_analytics", {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (analyticsResponse.data.status) {
            case 100:
                return { analytics: analyticsResponse.data.analytics, error: "" };
            case 101:
                return { analytics: [], error: "Ha ocurrido un error accediendo a Keycloak." };
            case 105:
                return { analytics: [], error: "Falló la solicitud para obtener sesiones de Keycloak." };
            case 110:
                return { analytics: [], error: "Acceso denegado. Revise la conexión con la API." };
            default:
                return { analytics: [], error: "Error desconocido." };
        }
    } catch (error) {
        return { analytics: [], error: "Error desconocido: " + error.message };
    }
}
