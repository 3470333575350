import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";


const DownloadCSVEnrolledUsersForm = ({
    csvFirstName,
    setCsvFirstName,
    csvLastName,
    setCsvLastName,
    csvCompleted,
    setCsvCompleted,
    csvCertificate,
    setCsvCertificate,
    csvExcel,
    setCsvExcel
}) => {

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="Nombre de usuario" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvFirstName}
                                onChange={(e) => setCsvFirstName(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Nombre" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvLastName}
                                onChange={(e) => setCsvLastName(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Apellido" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCompleted}
                                onChange={(e) => setCsvCompleted(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Completado" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCertificate}
                                onChange={(e) => setCsvCertificate(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Certificado" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={(e) => setCsvExcel(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );
}

export default DownloadCSVEnrolledUsersForm;