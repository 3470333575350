import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import PersonIcon from '@mui/icons-material/Person';
import CircularProgress from '@mui/material/CircularProgress';
import { Avatar, IconButton } from '@mui/material';
import getUserImage from '../../requests/users/getUserImage';
import { api } from '../../API';

const HeaderUser = ({ keycloak, mobile, userButton, handleDisplayMobileMenu }) => {

    const [loadingImage, setLoadingImage] = useState(true);
    const [image, setImage] = useState(null);


    const fetchUserImage = async () => {
        setImage(null);
        const result_url = await getUserImage(api, keycloak.tokenParsed.preferred_username);
        setImage(result_url.url);
        setLoadingImage(false);
    }

    useEffect(() => {
        if (keycloak.didInitialize && keycloak.authenticated){
            fetchUserImage();
        }
    }, [keycloak.authenticated]);

    return (
        <>
            {(keycloak.didInitialize && keycloak.authenticated) ? 
                <Grid sx={{"marginLeft": "auto"}} display="flex" alignItems="center" justifyContent={"end"}>
                    <IconButton ref={userButton} onClick={() => handleDisplayMobileMenu("user")}>
                        <Avatar sx={{ width: 40, height: 40 }} color={"#40b4ba"} src={loadingImage ? null : image} />
                    </IconButton>
                </Grid>
            : 
            keycloak.didInitialize && !keycloak.authenticated ?
            <Grid sx={{"marginLeft": "auto"}} display="flex" alignItems="center" justifyContent={"end"}>
                <IconButton ref={userButton} onClick={() => handleDisplayMobileMenu("user")} sx={{"backgroundColor": "#40b4ba", "&:hover": {"backgroundColor": "#40b4ba"}}}>
                    <PersonIcon sx={{"color": "white"}} />
                </IconButton>
            </Grid> 
            : <Box ml="auto" display="flex" alignItems="end" justifyContent="end"><CircularProgress sx={{ color: "#40b4ba" }} /></Box>}
        </>
    );
};

export default HeaderUser;