import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Box, Button } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';


const Modal = ({ open, handleClose, title, content, onClickMainButton, mainButtonText, mainButtonDisabled, width, cancelButton, startIcon, hideMainButton }) => {


    return (
        <Dialog
            PaperProps={{
                sx: {
                    width: "100%",
                    maxHeight: "80%",
                    padding: "10px 20px"
                }
            }}
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            fullWidth={true}
            maxWidth={width}
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle sx={{ m: 0, p: 3 }}>
                {title}
            </DialogTitle>
            <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{"textAlign": "justify"}}>
                {content}
            </DialogContent>
            <DialogActions sx={{ "padding": "10px 0 30px 0" }}>
                <Box sx={{ "display": "flex", "justifyContent": "center", "width": "100%" }}>
                    {cancelButton &&
                        <Box sx={{ "marginRight": "10px" }}>
                            <Button onClick={handleClose} variant="cancel">
                                Cancelar
                            </Button>
                        </Box>
                    }
                    {!hideMainButton &&
                        <Box>
                            <Button onClick={() => onClickMainButton()} disabled={mainButtonDisabled ? mainButtonDisabled : false} startIcon={startIcon} variant='confirm'>
                                {mainButtonText}
                            </Button>
                        </Box>
                    }
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default Modal;