import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';


const DownloadCSVTalleresYWebinarsForm = ({
    csvTitle,
    setCsvTitle,
    csvDescription,
    setCsvDescription,
    csvExposes,
    setCsvExposes,
    csvDate,
    setCsvDate,
    csvKind,
    setCsvKind,
    csvPriority,
    setCsvPriority,
    csvPublished,
    setCsvPublished,
    csvVideoUrl,
    setCsvVideoUrl,
    csvUrl,
    setCsvUrl,
    csvViewCount,
    setCsvViewCount,
    csvLikeCount,
    setCsvLikeCount,
    csvCommentCount,
    setCsvCommentCount,
    csvFavoriteCount,
    setCsvFavoriteCount,
    csvAttendants,
    setCsvAttendants,
    csvExcel,
    setCsvExcel
}) => {


    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="ID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTitle}
                                onChange={(e) => setCsvTitle(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Título" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvDescription}
                                onChange={(e) => setCsvDescription(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Descripción" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExposes}
                                onChange={(e) => setCsvExposes(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Expositores" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvDate}
                                onChange={(e) => setCsvDate(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Fecha" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvKind}
                                onChange={(e) => setCsvKind(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Tipo" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvPriority}
                                onChange={(e) => setCsvPriority(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Prioridad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvPublished}
                                onChange={(e) => setCsvPublished(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="¿Publicado?" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvVideoUrl}
                                onChange={(e) => setCsvVideoUrl(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="URL en YouTube" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvUrl}
                                onChange={(e) => setCsvUrl(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="URL en RedFID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvViewCount}
                                onChange={(e) => setCsvViewCount(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Vistas" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvLikeCount}
                                onChange={(e) => setCsvLikeCount(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Likes" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCommentCount}
                                onChange={(e) => setCsvCommentCount(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Comentarios" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvFavoriteCount}
                                onChange={(e) => setCsvFavoriteCount(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Favoritos" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvAttendants}
                                onChange={(e) => setCsvAttendants(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Asistentes" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={(e) => setCsvExcel(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );

}


export default DownloadCSVTalleresYWebinarsForm;