import React, { useState } from 'react';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { IconButton, Menu, MenuItem, Typography, Box, Link } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import InfoIcon from '@mui/icons-material/Info';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import EditIcon from '@mui/icons-material/Edit';
import UserIcon from '@mui/icons-material/Person';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { formatDateFromISO } from '../../../utils/formatters';


const EventsTable = ({ 
    users,
    events,
    handleOpenCreateModal,
    handleOpenStatsModal,
    handleOpenDownloadCSVModal,
    handleOpenInfoModal,
    handleOpenAttendantsModal,
    handleOpenEditModal,
    handleOpenDeleteModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            headerAlign: 'center',
            disableColumnMenu: true,
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu selectedEvent={params.row} />
            )
        },
        { 
            field: 'title', 
            headerName: 'Título', 
            align: 'left', 
            disableColumnMenu: true, 
            headerAlign: 'left', 
            flex: 1 
        },
        { 
            field: 'startDate', 
            headerName: 'Inicio', 
            align: 'center', 
            disableColumnMenu: true, 
            headerAlign: 'center', 
            width: 180,
            valueGetter: (params, row) => row.startDate,
            valueFormatter: (params, row) => formatDateFromISO(row.startDate)
        },
        { 
            field: 'place', 
            headerName: 'Ubicación', 
            align: 'center', 
            disableColumnMenu: true, 
            headerAlign: 'center', 
            width: 300,
            sortable: false,
            renderCell: (params) => params.row.place.startsWith("http") ? <Link color="#40b4ba" href={params.row.place} target="_blank">{params.row.place}</Link> : params.row.place
        },
        { 
            field: 'attendants', 
            headerName: 'Asistentes', 
            align: 'center', 
            disableColumnMenu: true, 
            headerAlign: 'center', 
            width: 120, 
            valueGetter: (params, row) => {
                let uniqueAttendants = new Set();
                for (let attendant of row.attendants) {
                    if (!uniqueAttendants.has(attendant)) {
                        if (users.find(user => (user.username === attendant && user.attributes && user.attributes.is_formador && user.attributes.is_formador[0] === "1"))) {
                            uniqueAttendants.add(attendant);
                        }
                    }
                }
                return uniqueAttendants.size;
            }
        },
    ];
    
    const ActionsMenu = ({ selectedEvent }) => {
        const [anchorEl, setAnchorEl] = useState(null);
    
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
    
        const handleClose = () => {
            setAnchorEl(null);
        };
    
        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleOpenInfoModal(selectedEvent)}><InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada</MenuItem>
                    <MenuItem onClick={() => handleOpenAttendantsModal(selectedEvent)}><UserIcon sx={{ "marginRight": "10px" }} /> Asistencia</MenuItem>
                    <MenuItem onClick={() => handleOpenEditModal(selectedEvent)}><EditIcon sx={{ "marginRight": "10px" }} /> Editar</MenuItem>
                    <MenuItem onClick={() => handleOpenDeleteModal(selectedEvent)}><DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar</MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Eventos</Typography>
                <Box>
                    <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={handleOpenStatsModal}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={events}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'startDate', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default EventsTable;
