import axios from 'axios';
import { formatFileSize } from '../../utils/formatters';

export const getDriveData = async (api) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { driveData: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const driveDataResponse = await axios.get(api.api_url + "/get_drives_data", {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (driveDataResponse.data.status) {
            case 100:
                return { driveData: driveDataResponse.data.drives, error: "" };
            case 110:
                return { driveData: null, error: "No hay respaldos." };
            default:
                return { driveData: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { driveData: null, error: "Error desconocido: " + error.message };
    }
}
