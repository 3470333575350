import React from 'react';
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import InfoIcon from '@mui/icons-material/Info';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';


const Indicator = ({ title, number, titleSize, helpMessage, color, fontSize }) => {

    const sizes = {
        "none": "0px",
        "small": "40px",
        "medium": "50px",
        "big": "70px",
    }

    const colors = {
        "orange": "#eb947e",
        "green": "#40b4ba"
    }

    const fontSizes = {
        "small": "1.2em",
        "medium": "1.5em",
        "big": "2em"
    }

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
        ))(({ theme }) => ({
            [`& .${tooltipClasses.tooltip}`]: {
                backgroundColor: '#f5f5f9',
                color: 'rgba(0, 0, 0, 0.87)',
                maxWidth: 300,
                color: "#646464",
                fontSize: theme.typography.pxToRem(12),
                fontFamily: "Avenir Regular",
                textAlign: 'justify',
                padding: "15px",
                border: '1px solid #dadde9',
            },
      }));

    return (
        <Card sx={{"margin": "10px 10px"}}>
            <Grid display={"flex"} justifyContent={"center"} alignItems={"center"} sx={{"height": sizes[titleSize], "backgroundColor": Object.keys(colors).includes(color) ? colors[color] : "#40b4ba", "paddingTop": "10px" }}>
                <Typography color="white" fontWeight={"bold"} gutterBottom width={"100%"} textAlign={"center"}>
                    {title}
                    <HtmlTooltip
                        title={
                        <React.Fragment>
                            <Typography mb={"10px"} color="inherit">{title}</Typography>
                            {helpMessage}
                        </React.Fragment>
                        }
                    >
                        <IconButton>
                            <InfoIcon sx={{fontSize: "0.8em", marginBottom: "5px", color: "white"}} />
                        </IconButton>
                    </HtmlTooltip>
                </Typography>
            </Grid>
            <Typography variant="main-title" mt={"10px"} mb={"10px"} fontSize={Object.keys(fontSizes).includes(fontSize) ? fontSizes[fontSize] : fontSizes["medium"]} textAlign={"center"}>
                {number === -1 ? 
                    "—"
                : number
                }
            </Typography>
        </Card>
    );

}

export default Indicator;