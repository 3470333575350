import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { formatFileSize } from '../../../utils/formatters';
import FileUpload from '../../../utils/fileUpload';

const MaterialDocenteFiles = ({ files, setFiles, errors, setErrors, formLoading }) => {

    const handleFileChange = (e, index) => {
        const newFiles = files.map((file, idx) => {
            if (idx === index) {
                return { ...file, file: { name: e.target.files[0].name, size: e.target.files[0].size, file: e.target.files[0] } };
            }
            return file;
        });
        setFiles(newFiles);

        const newErrors = {
            ...errors,
            files: [
                ...errors.files
            ]
        };
        newErrors.files[index] = {
            ...errors.files[index],
            file: ""
        };
        setErrors(newErrors);
    }

    const resetFileInput = (index) => {
        const newFiles = files.map((file, idx) => {
            if (idx === index) {
                return { ...file, file : { name: "", size: 0, file: null } };
            }
            return file;
        });
        setFiles(newFiles);

        const newErrors = {
            ...errors,
            files: [
                ...errors.files
            ]
        };
        newErrors.files[index] = {
            ...errors.files[index],
            url: ""
        };
        setErrors(newErrors);
    };


    const handleAddFile = () => {
        const newFiles = [
            ...files,
            {
                type: "none",
                reference: "none",
                file: {
                    name: "",
                    size: 0,
                    file: null
                },
                url: "",
            }
        ];
        const newErrors = {
            ...errors,
            files: [
                ...errors.files,
                {
                    type: "",
                    reference: "",
                    file: "",
                    url: ""
                }
            ]
        };
        setFiles(newFiles);
        setErrors(newErrors);
    }

    const handleRemoveFile = (index) => {
        const newFiles = files.filter((file, idx) => idx !== index);
        const newErrors = {
            ...errors,
            files: errors.files.filter((file, idx) => idx !== index)
        };
        setFiles(newFiles);
        setErrors(newErrors);
    }

    return (
        <>
            {files.map((file, index) => (

                <Grid container key={index} justifyContent={"left"}>
                    <Grid item xs={12} sm={3.5} pr={{"xs": "0", "sm": "20px"}} mt={"20px"}>
                        <TextField
                            fullWidth
                            select
                            variant='outlined'
                            label="Tipo de recurso"
                            name={"classification-" + index}
                            value={files[index].type}
                            onChange={(e) => {
                                const newFiles = files.map((file, idx) => {
                                    if (idx === index) {
                                        return { ...file, type: e.target.value };
                                    }
                                    return file;
                                });
                                setFiles(newFiles);

                                const newErrors = {
                                    ...errors,
                                    files: [
                                        ...errors.files
                                    ]
                                };
                                newErrors.files[index] = {
                                    ...errors.files[index],
                                    type: ""
                                };
                                setErrors(newErrors);
                            }}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="programa_de_cursos">Programa de un curso</MenuItem>
                            <MenuItem value="apuntes_de_cursos">Apuntes de un curso</MenuItem>
                            <MenuItem value="presentacion">Planificación</MenuItem>
                            <MenuItem value="libro">Texto de estudio</MenuItem>
                            <MenuItem value="unidad_de_aprendizaje">Unidad de aprendizaje</MenuItem>
                            <MenuItem value="presentacion">Presentación de clases</MenuItem>
                            <MenuItem value="video_de_clases">Vídeo de clases</MenuItem>
                            <MenuItem value="evaluacion">Evaluación</MenuItem>
                            <MenuItem value="rubrica">Rúbrica</MenuItem>
                            <MenuItem value="pauta_de_observación">Pauta de observación</MenuItem>
                            <MenuItem value="encuesta">Encuesta</MenuItem>
                            <MenuItem value="recurso_multimodal">Recurso multimodal</MenuItem>
                            <MenuItem value="other">Otros</MenuItem>
                        </TextField>
                        {errors.files[index]["type"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.files[index]["type"]}</span>}
                    </Grid>
                    <Grid item xs={12} sm={3.5} pr={{"xs": "0", "sm": "20px"}} mt={"20px"}>
                        <TextField
                            fullWidth
                            select
                            variant='outlined'
                            label="Acceso"
                            name={"reference-" + index}
                            value={files[index].reference}
                            onChange={(e) => {
                                const newFiles = files.map((file, idx) => {
                                    if (idx === index) {
                                        return { ...file, reference: e.target.value };
                                    }
                                    return file;
                                });
                                setFiles(newFiles);

                                const newErrors = {
                                    ...errors,
                                    files: [
                                        ...errors.files
                                    ]
                                };
                                newErrors.files[index] = {
                                    ...errors.files[index],
                                    reference: ""
                                };
                                setErrors(newErrors);
                            }}
                            disabled={formLoading}
                        >
                            <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="url">URL</MenuItem>
                            <MenuItem value="subir_archivo">Archivo</MenuItem>
                        </TextField>
                        {errors.files[index]["reference"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.files[index]["reference"]}</span>}
                    </Grid>
                    {files[index].reference === "subir_archivo" &&
                        <Grid item xs={12} sm={3.5} mt={"20px"}>
                            <Grid textAlign={"center"}>
                                <Button
                                    component="label"
                                    role={undefined}
                                    variant="orange"
                                    tabIndex={-1}
                                    onChange={(e) => handleFileChange(e, index)}
                                    startIcon={<CloudUploadIcon />}
                                    disabled={formLoading}
                                >
                                    Subir archivo
                                    <FileUpload type="file" onChange={(e) => handleFileChange(e, index)} />
                                </Button>
                                <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                    {files[index].reference === "subir_archivo" && (
                                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                            <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                                {`${files[index].file.name} (${formatFileSize(files[index].file.size)})`}
                                            </Typography>
                                            <IconButton disabled={formLoading} sx={{ marginLeft: "10px" }} size="small" onClick={() => resetFileInput(index)}>
                                                <CloseIcon fontSize="inherit" />
                                            </IconButton>
                                        </Box>
                                    )}
                                </Typography>
                                {errors.files[index]["file"] ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.files[index]["file"]}</Typography> : <></>}
                            </Grid>
                        </Grid>
                    }
                    {files[index].reference === "url" &&
                        <Grid item xs={12} sm={3.5} mt={"20px"}>
                            <TextField
                                label="URL del recurso"
                                component="label"
                                variant="outlined"
                                fullWidth
                                value={files[index].url}
                                tabIndex={-1}
                                onChange={(e) => {
                                    const newFiles = files.map((file, idx) => {
                                        if (idx === index) {
                                            return { ...file, url: e.target.value };
                                        }
                                        return file;
                                    });
                                    setFiles(newFiles);
    
                                    const newErrors = {
                                        ...errors,
                                        files: [
                                            ...errors.files
                                        ]
                                    };
                                    newErrors.files[index] = {
                                        ...errors.files[index],
                                        url: ""
                                    };
                                    setErrors(newErrors);
                                }}
                                error={!!errors.resourceUrl}
                                helperText={errors.resourceUrl}
                                disabled={formLoading}
                            />
                            {errors.files[index]["url"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.files[index]["url"]}</span>}
                        </Grid>
                    }
                    <Grid item xs={6} sm={0.5} ml={"auto"} mt={"20px"} textAlign={"center"}>
                        {files.length > 1 && (
                            <IconButton onClick={() => handleRemoveFile(index)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={0.5} mt={"20px"} textAlign={"center"}>
                        {(files.length < 20 && (index + 1) === files.length) && (
                            <IconButton onClick={() => handleAddFile()}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    );

};

export default MaterialDocenteFiles;