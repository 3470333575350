import axios from 'axios';

export const downloadCapsulaTemplate = async (api, type, university) => {
    try {
        let url = `${api.api_url}/get_capsula_template/${type}/${university}`;

        const response = await axios.get(url, { responseType: 'blob' });

        if (response.status === 200) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${type}_${university}.potx`);
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            window.URL.revokeObjectURL(url);
            return { success: true, error: "" };
        }
    } catch (error) {
        if (error.response && error.response.status === 404) {
            return { success: false, error: "Plantilla no encontrada. Por favor, actualícela." };
        }
        return { success: false, error: "Error desconocido: " + error.message };
    }
};
