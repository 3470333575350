import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";


const DownloadCSVMaterialDocenteForm = ({
    csvTitle,
    setCsvTitle,
    csvDescription,
    setCsvDescription,
    csvVisibility,
    setCsvVisibility,
    csvCategory,
    setCsvCategory,
    csvAcademicLevel,
    setCsvAcademicLevel,
    csvStage,
    setCsvStage,
    csvJustification,
    setCsvJustification,
    csvStatus,
    setCsvStatus,
    csvFeatured,
    setCsvFeatured,
    csvMainFeatured,
    setCsvMainFeatured,
    csvUser,
    setCsvUser,
    csvAuthors,
    setCsvAuthors,
    csvFiles,
    setCsvFiles,
    csvVisits,
    setCsvVisits,
    csvDownloads,
    setCsvDownloads,
    csvCreationDate,
    setCsvCreationDate,
    csvLastUpdate,
    setCsvLastUpdate,
    csvExcel,
    setCsvExcel
}) => {

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="ID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTitle}
                                onChange={(e) => setCsvTitle(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Título" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvDescription}
                                onChange={(e) => setCsvDescription(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descripción" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvVisibility}
                                onChange={(e) => setCsvVisibility(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Visibilidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCategory}
                                onChange={(e) => setCsvCategory(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Categoría" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvAcademicLevel}
                                onChange={(e) => setCsvAcademicLevel(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Nivel académico" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvStage}
                                onChange={(e) => setCsvStage(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Etapa" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvJustification}
                                onChange={(e) => setCsvJustification(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Justificación" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvStatus}
                                onChange={(e) => setCsvStatus(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Estado en RedFID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvFeatured}
                                onChange={(e) => setCsvFeatured(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="¿Destacado?" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvMainFeatured}
                                onChange={(e) => setCsvMainFeatured(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="¿Portada?" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvUser}
                                onChange={(e) => setCsvUser(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Usuario" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvAuthors}
                                onChange={(e) => setCsvAuthors(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Autores" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvFiles}
                                onChange={(e) => setCsvFiles(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Número de recursos" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvVisits}
                                onChange={(e) => setCsvVisits(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Visitas" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvDownloads}
                                onChange={(e) => setCsvDownloads(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descargas" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCreationDate}
                                onChange={(e) => setCsvCreationDate(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Fecha de creación" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvLastUpdate}
                                onChange={(e) => setCsvLastUpdate(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Última actualización" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={
                                    (e) => {
                                        setCsvExcel(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );
}

export default DownloadCSVMaterialDocenteForm;