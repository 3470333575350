import axios from 'axios';
import { getYoutubeData } from '../other/getYoutubeData';

export const getCapsulas = async (api) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { capsulas: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const capsulasResponse = await axios.get(api.api_url + "/get_capsulas", {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (capsulasResponse.data.status) {
            case 100:
                let cap = capsulasResponse.data.capsulas.capsulas;
                cap.forEach(async (capsula) => {
                    const statistics = await getYoutubeData(capsula.video_url);
                    capsula.viewCount = statistics.viewCount;
                    capsula.likeCount = statistics.likeCount;
                    capsula.favoriteCount = statistics.favoriteCount;
                    capsula.commentCount = statistics.commentCount;
                });
                return { capsulas: capsulasResponse.data.capsulas, error: "" };
            case 110:
                return { capsulas: null, error: "Acceso denegado. Revise la conexión con la API." };
            default:
                return { capsulas: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { capsulas: null, error: "Error desconocido: " + error.message };
    }
}
