import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import { formatMaterialDocenteAuthors, formatDateFromUnixToISO } from './formatters';


export const enrolledUsersCSV = async (excel, users, courseSection, options) => {
    let tableUsers = courseSection.data.enrolled.map((username) => {
        const user = users.find(user => user.username === username);
        return {
            username: user.username,
            lastName: user.lastName,
            firstName: user.firstName,
            completed: courseSection.data.approved.includes(user.username),
            certificate: (courseSection.data.certificates && courseSection.data.certificates.filter(c => c.username === user.username).length > 0) ? process.env.REACT_APP_CURSOS_URL + "/certificates/" + courseSection.data.certificates.filter(c => c.username === user.username)[0].verify_uuid : ""
        };
    })
    let header = ["username"];
    let lines = [];
    let output = [];
    var first = true;
    for (let user of tableUsers) {
        var thisLine = [];
        thisLine.push(user.username);
        if (options.csvFirstName) {
            if (first) {
                header.push("first_name");
            }
            thisLine.push(user.firstName);
        }
        if (options.csvLastName) {
            if (first) {
                header.push("last_name");
            }
            thisLine.push(user.lastName);
        }
        if (options.csvCompleted) {
            if (first) {
                header.push("completed");
            }
            thisLine.push(user.completed ? "1" : "0");
        }
        if (options.csvCertificate) {
            if (first) {
                header.push("certificate");
            }
            thisLine.push(user.certificate);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const titlePart = courseSection.data.course_id.split("+")[1];
    const fileName = titlePart.toLowerCase() + "_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Usuarios inscritos");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const usersCSV = async (excel, users, events, groups, courses, capsulas, keycloakActivity, comunidadesActivity, recursosActivity,  options) => {
    let header = ["username"];
    let lines = [];
    let output = [];
    var first = true;
    for (let user of users) {
        var thisLine = [];
        thisLine.push(user.username);
        if (options.firstName) {
            if (first) {
                header.push("first_name");
            }
            thisLine.push(user.firstName);
        }
        if (options.lastName) {
            if (first) {
                header.push("last_name");
            }
            thisLine.push(user.lastName);
        }
        if (options.email) {
            if (first) {
                header.push("email");
            }
            thisLine.push(user.email);
        }
        if (options.alternateEmail) {
            if (first) {
                header.push("alternate_email");
            }
            thisLine.push((user.attributes && user.attributes.alternateEmail) ? user.attributes.alternateEmail[0] : "");
        }
        if (options.profileImage) {
            if (first) {
                header.push("profile_image");
            }
            thisLine.push((user.discourse_data && user.discourse_data.avatar_template) ? process.env.REACT_APP_COMUNIDADES_URL + user.discourse_data.avatar_template : "");
        }
        if (options.gender) {
            if (first) {
                header.push("gender");
            }
            thisLine.push((user.attributes && user.attributes.gender) ? user.attributes.gender[0] : "");
        }
        if (options.isFormador) {
            if (first) {
                header.push("formador");
            }
            thisLine.push((user.attributes && user.attributes.is_formador) ? user.attributes.is_formador[0] === "1" : "0");
        }
        if (options.isStaff) {
            if (first) {
                header.push("staff");
            }
            thisLine.push((user.attributes && user.attributes.is_staff) ? user.attributes.is_staff[0] === "1" : "0");
        }
        if (options.isAdmin) {
            if (first) {
                header.push("admin");
            }
            thisLine.push((user.attributes && user.attributes.is_admin) ? user.attributes.is_admin[0] === "1" : "0");
        }
        if (options.university) {
            if (first) {
                header.push("university");
            }
            thisLine.push((user.attributes && user.attributes.university) ? user.attributes.university[0] : "");
        }
        if (options.region) {
            if (first) {
                header.push("region");
            }
            thisLine.push((user.attributes && user.attributes.region) ? user.attributes.region[0] : "");
        }
        if (options.career) {
            if (first) {
                header.push("career");
            }
            thisLine.push((user.attributes && user.attributes.career) ? user.attributes.career[0] : "");
        }
        if (options.active) {
            if (first) {
                header.push("is_active");
            }
            thisLine.push(user.enabled ? "1" : "0");
        }
        if (options.createdAt) {
            if (first) {
                header.push("created_at");
            }
            thisLine.push(formatDateFromUnixToISO(user.createdTimestamp/1000));
        }
        if (options.events){
            if (first) {
                header.push("events");
            }
            let userEvents = events.filter(event => event.attendants.includes(user.username));
            let eventIds = userEvents.map(event => event.id);
            thisLine.push(eventIds.join(";"));
        }
        if (options.totalEvents){
            if (first) {
                header.push("total_events");
            }
            let userEvents = events.filter(event => event.attendants.includes(user.username));
            thisLine.push(userEvents.length);
        }
        if (options.coursesMetrics) {
            let courseKeys = Object.keys(courses);
            if (first) {
                for (let key of courseKeys) {
                    let sections = Object.keys(courses[key].sections);
                    for (let section of sections){
                        if (section !== "0") {
                            header.push(key + "_" + section + "_enrolled");
                            header.push(key + "_" + section + "_completed");
                            header.push(key + "_" + section + "_certificate");
                        }
                    }
                }
            }
            for (let key of courseKeys) {
                let sections = Object.keys(courses[key].sections);
                for (let section of sections){
                    if (section !== "0") {
                        let enrolled = courses[key].sections[section].data.enrolled.includes(user.username);
                        let completed = courses[key].sections[section].data.approved.includes(user.username);
                        let certificate = (courses[key].sections[section].data.certificates && courses[key].sections[section].data.certificates.filter(c => c.username === user.username).length > 0) ? process.env.REACT_APP_CURSOS_URL + "/certificates/" + courses[key].sections[section].data.certificates.filter(c => c.username === user.username)[0].verify_uuid : "";
                        thisLine.push(enrolled ? "1" : "0");
                        thisLine.push(completed ? "1" : "0");
                        thisLine.push(certificate);
                    }
                }
            }
        } 
        if (options.capsulasMetrics) {
            if (first) {
                header.push("capsulas_rep");
                header.push("capsulas_reac");
                header.push("capsulas_rhii");
                header.push("capsulas_reo");
                header.push("capsulas_rap");
            }
            let userCapsulas = capsulas.capsulas.filter(capsula => capsula.username === user.username);
            let rep = userCapsulas.filter(capsula => capsula.kind === "rep").length;
            let reac = userCapsulas.filter(capsula => capsula.kind === "reac").length;
            let rhii = userCapsulas.filter(capsula => capsula.kind === "rhii").length;
            let reo = userCapsulas.filter(capsula => capsula.kind === "reo").length;
            let rap = userCapsulas.filter(capsula => capsula.kind === "rap").length;
            thisLine.push(rep);
            thisLine.push(reac);
            thisLine.push(rhii);
            thisLine.push(reo);
            thisLine.push(rap);
        }
        if (options.discourseMetrics) {
            if (first) {
                header.push("discourse_posts");
                header.push("discussions_entered")
                header.push("discussions_read_seconds")
                header.push("groups")
            }
            thisLine.push(user.discourse_data.post_count);
            thisLine.push(user.discourse_data.topics_entered);
            thisLine.push(user.discourse_data.time_read);
            thisLine.push(groups.filter(group => group.users.includes(user.username)).length);
        }
        if (options.recursosMetrics) {
            if (first) {
                header.push("recursos_academicos");
                header.push("activities");
                header.push("recursos_academicos_seen");
                header.push("activities_seen");
                header.push("recursos_academicos_accessed");
                header.push("recursos_docentes_accessed");
            }
            thisLine.push("");
            thisLine.push("");
            thisLine.push("");
            thisLine.push("");
            thisLine.push("");
            thisLine.push("");
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_usuarios_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Usuarios");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const universitiesCSV = async (excel, users, attributes, options) => {
    let header = ["code"];
    let lines = [];
    let output = [];
    var first = true;
    for (let code of Object.keys(attributes.university.options)) {
        var thisLine = [];
        thisLine.push(code);
        if (options.name) {
            if (first) {
                header.push("name");
            }
            thisLine.push(attributes.university.options[code]);
        }
        if (options.members) {
            if (first) {
                header.push("members");
            }
            let members = users.filter(user => user.attributes && user.attributes.university && user.attributes.university[0] === code && user.attributes.is_formador && user.attributes.is_formador[0] === "1").length;
            thisLine.push(members);
        }
        if (options.membersList) {
            if (first) {
                header.push("members_list");
            }
            let membersList = users.filter(user => user.attributes && user.attributes.university && user.attributes.university[0] === code && user.attributes.is_formador && user.attributes.is_formador[0] === "1").map(user => user.username).join(",");
            thisLine.push(membersList);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_universidades_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Universidades");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const eventsCSV = async (excel, events, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let e of events) {
        var thisLine = [];
        thisLine.push(e.id);
        if (options.title) {
            if (first) {
                header.push("title");
            }
            thisLine.push(e.title);
        }
        if (options.startDate) {
            if (first) {
                header.push("start_date");
            }
            thisLine.push(formatDateFromUnixToISO(e.startDate/1000));
        }
        if (options.endDate) {
            if (first) {
                header.push("end_date");
            }
            thisLine.push(formatDateFromUnixToISO(e.endDate/1000));
        }
        if (options.place) {
            if (first) {
                header.push("place");
            }
            thisLine.push(e.place);
        }
        if (options.description) {
            if (first) {
                header.push("description");
            }
            thisLine.push(e.description);
        }
        if (options.calendarId) {
            if (first) {
                header.push("calendar_id");
            }
            thisLine.push(e.calendar_id);
        }
        if (options.attendants) {
            if (first) {
                header.push("attendants");
            }
            thisLine.push(e.attendants.join(";"));
        }
        if (options.associated) {
            if (first) {
                header.push("associated");
            }
            thisLine.push(e.associated);
        }
        if (options.totalAttendants) {
            if (first) {
                header.push("total_attendants");
            }
            thisLine.push(e.attendants.length);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_eventos_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Actividad");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const talleresYWebinarsCSV = async (excel, talleresYWebinars, events, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let tallerYWebinar of talleresYWebinars) {
        var thisLine = [];
        thisLine.push(tallerYWebinar.id);
        if (options.title) {
            if (first) {
                header.push("title");
            }
            thisLine.push(tallerYWebinar.title);
        }
        if (options.description) {
            if (first) {
                header.push("description");
            }
            thisLine.push(tallerYWebinar.description);
        }
        if (options.exposes) {
            if (first) {
                header.push("exposes");
            }
            thisLine.push(tallerYWebinar.exposes);
        }
        if (options.date) {
            if (first) {
                header.push("date");
            }
            thisLine.push(tallerYWebinar.date);
        }
        if (options.kind) {
            if (first) {
                header.push("kind");
            }
            thisLine.push(tallerYWebinar.kind);
        }
        if (options.priority) {
            if (first) {
                header.push("priority");
            }
            thisLine.push(tallerYWebinar.priority);
        }
        if (options.published) {
            if (first) {
                header.push("published");
            }
            thisLine.push(tallerYWebinar.published ? "1" : "0");
        }
        if (options.video_url) {
            if (first) {
                header.push("video_url");
            }
            thisLine.push(tallerYWebinar.video_url);
        }
        if (options.url) {
            if (first) {
                header.push("redfid_url");
            }
            thisLine.push(process.env.REACT_APP_CURSOS_URL + "/dashboard?display=1&displayId=" + tallerYWebinar.id);
        }
        if (options.viewCount) {
            if (first) {
                header.push("view_count");
            }
            thisLine.push(tallerYWebinar.viewCount);
        }
        if (options.likeCount) {
            if (first) {
                header.push("like_count");
            }
            thisLine.push(tallerYWebinar.likeCount);
        }
        if (options.commentCount) {
            if (first) {
                header.push("comment_count");
            }
            thisLine.push(tallerYWebinar.commentCount);
        }
        if (options.favoriteCount) {
            if (first) {
                header.push("favorite_count");
            }
            thisLine.push(tallerYWebinar.favoriteCount);
        }
        if (options.attendants) {
            if (first) {
                header.push("attendants");
            }
            let thisEvent = events.find(event => event.associated === tallerYWebinar.id);
            let attendants = thisEvent ? thisEvent.attendants.length : "";
            thisLine.push(attendants);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_talleres_y_webinars_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Talleres y Webinars");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const capsulasCSV = async (excel, capsulas, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let capsula of capsulas) {
        var thisLine = [];
        thisLine.push(capsula.id);
        if (options.title) {
            if (first) {
                header.push("title");
            }
            thisLine.push(capsula.title);
        }
        if (options.description) {
            if (first) {
                header.push("description");
            }
            thisLine.push(capsula.description);
        }
        if (options.exposes) {
            if (first) {
                header.push("exposes");
            }
            thisLine.push(capsula.exposes);
        }
        if (options.date) {
            if (first) {
                header.push("date");
            }
            thisLine.push(capsula.date);
        }
        if (options.kind) {
            if (first) {
                header.push("kind");
            }
            thisLine.push(capsula.kind);
        }
        if (options.priority) {
            if (first) {
                header.push("priority");
            }
            thisLine.push(capsula.priority);
        }
        if (options.published) {
            if (first) {
                header.push("published");
            }
            thisLine.push(capsula.published ? "1" : "0");
        }
        if (options.video_url) {
            if (first) {
                header.push("video_url");
            }
            thisLine.push(capsula.video_url);
        }
        if (options.url) {
            if (first) {
                header.push("redfid_url");
            }
            thisLine.push(process.env.REACT_APP_CURSOS_URL + "/dashboard?display=2&displayId=" + capsula.id);
        }
        if (options.viewCount) {
            if (first) {
                header.push("view_count");
            }
            thisLine.push(capsula.viewCount);
        }
        if (options.likeCount) {
            if (first) {
                header.push("like_count");
            }
            thisLine.push(capsula.likeCount);
        }
        if (options.commentCount) {
            if (first) {
                header.push("comment_count");
            }
            thisLine.push(capsula.commentCount);
        }
        if (options.favoriteCount) {
            if (first) {
                header.push("favorite_count");
            }
            thisLine.push(capsula.favoriteCount);
        }
        if (options.user) {
            if (first) {
                header.push("user");
            }
            thisLine.push(capsula.username);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_capsulas_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Cápsulas");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const recursosAcademicosCSV = async (excel, recursos, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let recurso of recursos) {
        var thisLine = [];
        thisLine.push(recurso.id);
        if (options.title) {
            if (first) {
                header.push("title");
            }
            thisLine.push(recurso.detail.title);
        }
        if (options.subtitle) {
            if (first) {
                header.push("subtitle");
            }
            thisLine.push(recurso.detail.subtitle);
        }
        if (options.author) {
            if (first) {
                header.push("author");
            }
            thisLine.push(recurso.detail.author);
        }
        if (options.description) {
            if (first) {
                header.push("description");
            }
            thisLine.push(recurso.detail.description ? recurso.detail.description : recurso.detail.abstract);
        }
        if (options.visibility) {
            if (first) {
                header.push("visibility");
            }
            thisLine.push(recurso.visibility);
        }
        if (options.filename) {
            if (first) {
                header.push("filename");
            }
            thisLine.push(recurso.detail.filename);
        }
        if (options.filesize) {
            if (first) {
                header.push("filesize");
            }
            thisLine.push(recurso.detail.filesize);
        }
        if (options.resourceUrl) {
            if (first) {
                header.push("resource_url");
            }
            thisLine.push(recurso.detail.resourceUrl);
        }
        if (options.category) {
            if (first) {
                header.push("category");
            }
            thisLine.push(recurso.category);
        }
        if (options.subcategory) {
            if (first) {
                header.push("subcategory");
            }
            thisLine.push(recurso.subcategory);
        }
        if (options.reference) {
            if (first) {
                header.push("reference");
            }
            thisLine.push(recurso.detail.reference);
        }
        if (options.DOI) {
            if (first) {
                header.push("doi");
            }
            thisLine.push(recurso.detail.DOI);
        }
        if (options.editor) {
            if (first) {
                header.push("editor");
            }
            thisLine.push(recurso.detail.editor);
        }
        if (options.journal) {
            if (first) {
                header.push("journal");
            }
            thisLine.push(recurso.detail.journal);
        }
        if (options.year) {
            if (first) {
                header.push("year");
            }
            thisLine.push(recurso.detail.year);
        }
        if (options.volume) {
            if (first) {
                header.push("volume");
            }
            thisLine.push(recurso.detail.volume);
        }
        if (options.issue) {
            if (first) {
                header.push("issue");
            }
            thisLine.push(recurso.detail.issue);
        }
        if (options.pages) {
            if (first) {
                header.push("pages");
            }
            thisLine.push(recurso.detail.pages);
        }
        if (options.justification) {
            if (first) {
                header.push("justification");
            }
            thisLine.push(recurso.justification);
        }
        if (options.status) {
            if (first) {
                header.push("status");
            }
            thisLine.push(recurso.status);
        }
        if (options.featured) {
            if (first) {
                header.push("featured");
            }
            thisLine.push(recurso.featured ? "1" : "0");
        }
        if (options.mainFeatured) {
            if (first) {
                header.push("main_featured");
            }
            thisLine.push(recurso.main_featured ? "1" : "0");
        }
        if (options.user) {
            if (first) {
                header.push("user");
            }
            thisLine.push(recurso.username);
        }
        if (options.visits) {
            if (first) {
                header.push("visits");
            }
            thisLine.push(recurso.visits.length);
        }
        if (options.downloads) {
            if (first) {
                header.push("downloads");
            }
            thisLine.push(recurso.downloads_accesses.length);
        }
        if (options.creationDate) {
            if (first) {
                header.push("creation_date");
            }
            thisLine.push(formatDateFromUnixToISO(recurso.creation_date));
        }
        if (options.lastUpdate) {
            if (first) {
                header.push("last_update");
            }
            thisLine.push(formatDateFromUnixToISO(recurso.last_modified_date));
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_recursos_academicos_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Recursos académicos");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const activitiesCSV = async (excel, activities, recursos, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let activity of activities) {
        var thisLine = [];
        thisLine.push(activity.id);
        if (options.title) {
            if (first) {
                header.push("title");
            }
            thisLine.push(activity.title);
        }
        if (options.description) {
            if (first) {
                header.push("description");
            }
            thisLine.push(activity.description);
        }
        if (options.visibility) {
            if (first) {
                header.push("visibility");
            }
            thisLine.push(activity.visibility);
        }
        if (options.category) {
            if (first) {
                header.push("category");
            }
            thisLine.push(activity.classification);
        }
        if (options.academicLevel) {
            if (first) {
                header.push("academic_level");
            }
            thisLine.push(activity.level);
        }
        if (options.stage) {
            if (first) {
                header.push("stage");
            }
            thisLine.push(activity.stage);
        }
        if (options.justification) {
            if (first) {
                header.push("justification");
            }
            thisLine.push(activity.utility);
        }
        if (options.status) {
            if (first) {
                header.push("status");
            }
            thisLine.push(activity.status);
        }
        if (options.featured) {
            if (first) {
                header.push("featured");
            }
            thisLine.push(activity.featured ? "1" : "0");
        }
        if (options.mainFeatured) {
            if (first) {
                header.push("main_featured");
            }
            thisLine.push(activity.main_featured ? "1" : "0");
        }
        if (options.user) {
            if (first) {
                header.push("user");
            }
            thisLine.push(activity.username);
        }
        if (options.authors) {
            if (first) {
                header.push("authors");
            }
            thisLine.push(formatMaterialDocenteAuthors(activity.authors));
        }
        if (options.files) {
            if (first) {
                header.push("files");
            }
            thisLine.push(activity.recursos.length);
        }
        if (options.visits) {
            if (first) {
                header.push("visits");
            }
            thisLine.push(activity.visits.length);
        }
        if (options.downloads) {
            if (first) {
                header.push("downloads");
            }
            thisLine.push(recursos.filter(r => r.activity_id === activity.id).reduce((acc, curr) => acc + curr.downloads_accesses.length, 0));
        }
        if (options.creationDate) {
            if (first) {
                header.push("creation_date");
            }
            thisLine.push(formatDateFromUnixToISO(activity.creation_date));
        }
        if (options.lastUpdate) {
            if (first) {
                header.push("last_update");
            }
            thisLine.push(formatDateFromUnixToISO(activity.last_modified_date));
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_actividades_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Actividades");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const specialComponentCSV = async (excel, component, username, data) => {
    let header = ["id", "label", "value"];
    let lines = [];
    let output = [];
    for (let d of data) {
        var thisLine = [];
        thisLine.push(d.id);
        thisLine.push(d.label);
        thisLine.push(d.value);
        lines.push(thisLine);
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_" + component + "_" + username + "_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, component);
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const discussionsCSV = async (excel, discussions, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let discussion of discussions) {
        var thisLine = [];
        thisLine.push(discussion.id);
        if (options.title) {
            if (first) {
                header.push("title");
            }
            thisLine.push(discussion.title);
        }
        if (options.url) {
            if (first) {
                header.push("url");
            }
            thisLine.push(process.env.REACT_APP_COMUNIDADES_URL + "/t/" + discussion.slug + "/" + discussion.id);
        }
        if (options.category) {
            if (first) {
                header.push("category");
            }
            thisLine.push(discussion.category_id === 4 ? "novedades" : discussion.category_id === 5 ? "aprendizaje_profesional" : discussion.category_id === 6 ? "innovacion_e_investigacion" : discussion.category_id === 7 ? "otros" : "discusion_privada");
        }
        if (options.totalPosters) {
            if (first) {
                header.push("total_posters");
            }
            thisLine.push(discussion.total_posters);
        }
        if (options.totalPosts) {
            if (first) {
                header.push("total_posts");
            }
            thisLine.push(discussion.posts_count);
        }
        if (options.totalViews) {
            if (first) {
                header.push("total_views");
            }
            thisLine.push(discussion.views);
        }
        if (options.totalLikes) {
            if (first) {
                header.push("total_likes");
            }
            thisLine.push(discussion.like_count);
        }
        if (options.createdAt) {
            if (first) {
                header.push("created_at");
            }
            thisLine.push(discussion.created_at);
        }
        if (options.lastPost) {
            if (first) {
                header.push("last_posted_at");
            }
            thisLine.push(discussion.last_posted_at);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_discusiones_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Discusiones");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const groupsCSV = async (excel, groups, discussions, options) => {
    let header = ["id"];
    let lines = [];
    let output = [];
    var first = true;
    for (let group of groups) {
        var thisLine = [];
        thisLine.push(group.id);
        if (options.name) {
            if (first) {
                header.push("name");
            }
            thisLine.push(group.full_name);
        }
        if (options.description) {
            if (first) {
                header.push("description");
            }
            thisLine.push(group.bio_raw);
        }
        if (options.members) {
            if (first) {
                header.push("members");
            }
            thisLine.push(group.users.length);
        }
        if (options.membersList) {
            if (first) {
                header.push("members_list");
            }
            thisLine.push(group.users.join(","));
        }
        if (options.visibility) {
            if (first) {
                header.push("visibility");
            }
            thisLine.push(group.visibility_level === 0 ? "public" : group.visibility_level === 1 ? "semi-public" : "private");
        }
        if (options.drive) {
            if (first) {
                header.push("drive");
            }
            thisLine.push("https://drive.google.com/drive/folders/" + group.name);
        }
        if (options.driveUsage) {
            if (first) {
                header.push("drive_usage");
            }
            thisLine.push(group.drive_usage);
        }
        if (options.discussions) {
            if (first) {
                header.push("discussions");
            }
            thisLine.push(discussions.filter(d => d.is_group && d.category_id === group.category_id).length);
        }
        if (options.publicAdmission) {
            if (first) {
                header.push("public_admission");
            }
            thisLine.push(group.public_admission ? "1" : "0");
        }
        if (options.publicExit) {
            if (first) {
                header.push("public_exit");
            }
            thisLine.push(group.public_exit ? "1" : "0");
        }
        if (options.allowMembershipRequests) {
            if (first) {
                header.push("allow_membership_requests");
            }
            thisLine.push(group.allow_membership_requests ? "1" : "0");
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_grupos_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Grupos");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}

export const userActivityCSV = async (excel, keycloakActivity, comunidadesActivity, recursosActivity, username, options) => {
    const allEvents = keycloakActivity.concat(comunidadesActivity).concat(recursosActivity).sort((a, b) => a.time - b.time);
    let header = ["time"];
    let lines = [];
    let output = [];
    var first = true;
    for (let action of allEvents) {
        var thisLine = [];
        thisLine.push(action.time);
        if (options.platform) {
            if (first) {
                header.push("platform");
            }
            thisLine.push(action.platform);
        }
        if (options.type) {
            if (first) {
                header.push("type");
            }
            thisLine.push(action.eventType);
        }
        lines.push(thisLine);
        first = false;
    }
    output.push(header);
    for (let line of lines) {
        output.push(line);
    }
    const fileName = "redfid_actividad_" + username + "_" + new Date().getTime();
    if (excel) {
        const worksheet = XLSX.utils.aoa_to_sheet(output);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Actividad de usuario");
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8;' });
        saveAs(blob, fileName + ".xlsx");
    } else {
        const csv = Papa.unparse(output);
        const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        saveAs(blob, fileName + ".csv");
    }
}
