import React, { useState, useEffect } from 'react';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Home from '../Home/Home';
import { Grid, Typography } from '@mui/material';


const HomeWrapper = ({keycloak}) => {

    const [canSeeHome, setCanSeeHome] = useState(false);

    useEffect(() => {
        if (keycloak.didInitialize && keycloak.authenticated){
            keycloak.loadUserProfile().then(function(profile) {
                if (profile.attributes && profile.attributes.is_admin && profile.attributes.is_admin[0] === "1") {
                    setCanSeeHome(true);
                }
            });
        }
      }, [keycloak.authenticated]);

    return (
        <Grid style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
          <Header keycloak={keycloak} />
          <Grid
            p={"0 20px"}
            component="div"
            sx={{
              flexGrow: 1,
              m: '30px auto',
              maxWidth: '1400px',
              width: '100%',
            }}
          >
            {canSeeHome ? <Home></Home> : <Typography variant='title' textAlign={"center"}>No tienes permiso para ver esta página.</Typography>}
          </Grid>
          <Footer />
        </Grid>
      );

}

export default HomeWrapper;