import React from 'react';
import { Checkbox, FormControlLabel, FormGroup, Grid } from '@mui/material';

const DownloadCSVDiscussionsForm = ({
    csvTitle,
    setCsvTitle,
    csvURL,
    setCsvURL,
    csvCategory,
    setCsvCategory,
    csvTotalPosters,
    setCsvTotalPosters,
    csvTotalPosts,
    setCsvTotalPosts,
    csvTotalViews,
    setCsvTotalViews,
    csvTotalLikes,
    setCsvTotalLikes,
    csvCreatedAt,
    setCsvCreatedAt,
    csvLastPost,
    setCsvLastPost
}) => {


    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="ID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTitle}
                                onChange={(e) => setCsvTitle(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Título" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={   
                            <Checkbox
                                checked={csvURL}
                                onChange={(e) => setCsvURL(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="URL" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCategory}
                                onChange={(e) => setCsvCategory(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Categoría" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTotalPosters}
                                onChange={(e) => setCsvTotalPosters(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Número de participantes" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTotalPosts}
                                onChange={(e) => setCsvTotalPosts(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Posts" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTotalViews}
                                onChange={(e) => setCsvTotalViews(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Visitas" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvTotalLikes}
                                onChange={(e) => setCsvTotalLikes(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Likes" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvCreatedAt}
                                onChange={(e) => setCsvCreatedAt(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Fecha de creación" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvLastPost}
                                onChange={(e) => setCsvLastPost(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label="Fecha del último post" />
                </FormGroup>
            </Grid>
        </Grid>
    );

}

export default DownloadCSVDiscussionsForm;