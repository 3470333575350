import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, TextField, MenuItem, Checkbox, FormGroup, FormControlLabel, Typography, Tooltip, tooltipClasses, IconButton } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';



const UserForm = ({
    username,
    setUsername,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    email,
    setEmail,
    alternateEmail,
    setAlternateEmail,
    isFormador,
    setIsFormador,
    isStaff,
    setIsStaff,
    isAdmin,
    setIsAdmin,
    active,
    setActive,
    gender,
    setGender,
    career,
    setCareer,
    region,
    setRegion,
    university,
    setUniversity,
    universityName,
    setUniversityName,
    universityCode,
    setUniversityCode,
    password,
    setPassword,
    passwordConfirm,
    setPasswordConfirm,
    formLoading,
    errors,
    setErrors,
    attributes,
    editing
}) => {

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 300,
            color: "#646464",
            fontSize: theme.typography.pxToRem(12),
            fontFamily: "Avenir Regular",
            textAlign: 'justify',
            padding: '15px',
            border: '1px solid #dadde9',
        },
    }));

    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12}>
                <Typography variant="h6" mt={"20px"}>Información básica</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={4} mt={"20px"}>
                <TextField
                    label="Nombre de usuario"
                    component="label"
                    variant="outlined"
                    fullWidth
                    value={username}
                    tabIndex={-1}
                    onChange={(e) => {
                        setUsername(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, username: "" }))
                    }}
                    error={!!errors.username}
                    helperText={errors.username}
                    disabled={editing || formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mt={"20px"}>
                <TextField
                    label="Nombre(s)"
                    component="label"
                    variant="outlined"
                    fullWidth
                    value={firstName}
                    onChange={(e) => {
                        setFirstName(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, firstName: "" }))
                    }}
                    error={!!errors.firstName}
                    helperText={errors.firstName}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mt={"20px"}>
                <TextField
                    label="Apellido(s)"
                    component="label"
                    variant="outlined"
                    fullWidth
                    value={lastName}
                    onChange={(e) => {
                        setLastName(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, lastName: "" }))
                    }}
                    error={!!errors.lastName}
                    helperText={errors.lastName}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} mt={"20px"}>
                <TextField
                    label="Email"
                    component="label"
                    variant="outlined"
                    fullWidth
                    value={email}
                    onChange={(e) => {
                        setEmail(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, email: "" }))
                    }}
                    error={!!errors.email}
                    helperText={errors.email}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} mt={"20px"}>
                <TextField
                    label="Email alternativo"
                    component="label"
                    variant="outlined"
                    fullWidth
                    value={alternateEmail}
                    onChange={(e) => {
                        setAlternateEmail(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, alternateEmail: "" }))
                    }}
                    error={!!errors.alternateEmail}
                    helperText={errors.alternateEmail}
                    disabled={formLoading}
                />
            </Grid>
            <Grid item xs={12} sm={6} md={4} mt={"20px"}>
                <TextField
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            }
                        }
                    }}
                    label="¿Activo?"
                    component="label"
                    variant="outlined"
                    select
                    fullWidth
                    value={active}
                    onChange={(e) => {
                        setActive(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, active: "" }))
                    }}
                    error={!!errors.active}
                    helperText={errors.active}
                    disabled={formLoading}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    <MenuItem value={"active"}>Activo</MenuItem>
                    <MenuItem value={"suspended"}>Suspendido</MenuItem>
                </TextField>
            </Grid>
            {!editing &&
                <Grid item xs={12} sm={6} md={4} mt={"20px"}>
                    <TextField
                        label="Contraseña"
                        component="label"
                        variant="outlined"
                        type="password"
                        fullWidth
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, password: "" }))
                        }}
                        error={!!errors.password}
                        helperText={errors.password}
                        disabled={formLoading}
                    />
                </Grid>
            }
            {!editing &&
                <Grid item xs={12} sm={6} md={4} mt={"20px"}>
                    <TextField
                        label="Confirmar contraseña"
                        component="label"
                        variant="outlined"
                        type="password"
                        fullWidth
                        value={passwordConfirm}
                        onChange={(e) => {
                            setPasswordConfirm(e.target.value);
                            setErrors(prevErrors => ({ ...prevErrors, passwordConfirm: "" }))
                        }}
                        error={!!errors.passwordConfirm}
                        helperText={errors.passwordConfirm}
                        disabled={formLoading}
                    />
                </Grid>
            }

            <Grid item xs={12}>
                <Typography variant="h6" mt={"20px"}>Atributos</Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={6} mt={"20px"}>
                <TextField
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            }
                        }
                    }}
                    label="Programa"
                    component="label"
                    variant="outlined"
                    select
                    fullWidth
                    value={career}
                    onChange={(e) => {
                        setCareer(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, career: "" }))
                    }}
                    error={!!errors.career}
                    helperText={errors.career}
                    disabled={formLoading}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {
                        Object.keys(attributes.career.options).map((key) => {
                            return <MenuItem key={key} value={key}>{attributes.career.options[key]}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={6} mt={"20px"}>
                <TextField
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            }
                        }
                    }}
                    label="Región"
                    component="label"
                    variant="outlined"
                    select
                    fullWidth
                    value={region}
                    onChange={(e) => {
                        setRegion(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, region: "" }))
                    }}
                    error={!!errors.region}
                    helperText={errors.region}
                    disabled={formLoading}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {
                        Object.keys(attributes.region.options).map((key) => {
                            return <MenuItem key={key} value={key}>{attributes.region.options[key]}</MenuItem>
                        })
                    }
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} md={6} mt={"20px"}>
                <TextField
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            }
                        }
                    }}
                    label="Género"
                    component="label"
                    variant="outlined"
                    select
                    fullWidth
                    value={gender}
                    onChange={(e) => {
                        setGender(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, gender: "" }))
                    }}
                    error={!!errors.gender}
                    helperText={errors.gender}
                    disabled={formLoading}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    <MenuItem value="male">Masculino</MenuItem>
                    <MenuItem value="female">Femenino</MenuItem>
                    <MenuItem value="other">Otro</MenuItem>
                </TextField>
            </Grid>
            <Grid item xs={12} sm={6} mt={"20px"}>
                <TextField
                    SelectProps={{
                        MenuProps: {
                            PaperProps: {
                                style: {
                                    maxHeight: 300,
                                },
                            }
                        }
                    }}
                    label="Universidad"
                    component="label"
                    variant="outlined"
                    select
                    fullWidth
                    value={university}
                    onChange={(e) => {
                        setUniversity(e.target.value);
                        setErrors(prevErrors => ({ ...prevErrors, university: "" }))
                    }}
                    error={!!errors.university}
                    helperText={errors.university}
                    disabled={formLoading}
                >
                    <MenuItem disabled value="none">Por favor seleccione una opción...</MenuItem>
                    {
                        Object.keys(attributes.university.options).map((key) => {
                            return <MenuItem key={key} value={key}>{attributes.university.options[key]}</MenuItem>
                        })
                    }
                    <MenuItem value="no">Ninguna</MenuItem>
                    <MenuItem value="other">Otra</MenuItem>
                </TextField>
            </Grid>

            {university === "other" &&
                <>
                    <Grid item xs={12} sm={8} mt={"20px"}>
                        <TextField
                            label="Nombre de universidad"
                            component="label"
                            variant="outlined"
                            fullWidth
                            value={universityName}
                            onChange={(e) => {
                                setUniversityName(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, universityName: "" }))
                            }}
                            error={!!errors.universityName}
                            helperText={errors.universityName}
                            disabled={formLoading}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4} mt={"20px"}>
                        <TextField
                            label="Código de universidad"
                            component="label"
                            variant="outlined"
                            fullWidth
                            value={universityCode}
                            onChange={(e) => {
                                setUniversityCode(e.target.value);
                                setErrors(prevErrors => ({ ...prevErrors, universityCode: "" }))
                            }}
                            error={!!errors.universityCode}
                            helperText={errors.universityCode}
                            disabled={formLoading}
                        />
                    </Grid>

                </>
            }
            <Grid item xs={12}>
                <Typography variant="h6" mt={"20px"}>Roles</Typography>
            </Grid>
            <Grid item xs={12} sm={4} md={4} ml={"20px"} mt={"20px"}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isFormador}
                                onChange={(e) => setIsFormador(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label={
                        <>
                            <Typography fontSize={"1.1em"}>
                                {"Formador"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Formador"}</Typography>
                                            Los usuarios con este rol son considerados formadores de profesores, por lo que son incluidos
                                            en todas las estadísticas correspondientes.
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                        </>
                    } />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isStaff}
                                onChange={(e) => setIsStaff(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label={
                        <>
                            <Typography fontSize={"1.1em"}>
                                {"Staff"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Staff"}</Typography>
                                            Los usuarios con este rol son considerados miembros del staff de la plataforma, por lo que tienen
                                            acceso a Studio, a todos los datos de los cursos, y son moderadores en Discourse. 
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                        </>
                    } />
                </FormGroup>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={isAdmin}
                                onChange={(e) => setIsAdmin(e.target.checked)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } label={
                        <>
                            <Typography fontSize={"1.1em"}>
                                {"Administrador"}
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            <Typography mb={"10px"} color="inherit">{"Administrador"}</Typography>
                                            Los usuarios con este rol tienen acceso a todas las funcionalidades de la plataforma. Pueden acceder
                                            a este panel de administración, junto al de Open edX (/admin). Además,
                                            cuentan con el rol de administradores en Discourse.
                                        </React.Fragment>
                                    }
                                >
                                    <IconButton>
                                        <InfoIcon sx={{ fontSize: "0.6em", marginBottom: "5px" }} />
                                    </IconButton>
                                </HtmlTooltip>
                            </Typography>
                        </>
                    } />
                </FormGroup>
            </Grid>
        </Grid>
    );
}

export default UserForm;