import React, { useState, useEffect } from 'react';
import { api, recursosApi } from '../API';
import { Typography, Grid, Box, Tab, Tabs, CircularProgress } from '@mui/material';
import TalleresYWebinars from '../Content/TalleresYWebinars';
import Recursos from '../Content/Recursos';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Mail from '../Content/Mail';
import Dashboard from '../Content/Dashboard';
import Usuarios from '../Content/Usuarios';
import Noticias from '../Content/Noticias';
import Cursos from '../Content/Cursos';
import Capsulas from '../Content/Capsulas';
import Events from '../Content/Events.js';
import Comunidades from '../Content/Comunidades';
import StatusIndicator from '../Content/components/StatusIndicator';
import { getUsers } from '../requests/users/getUsers';
import { getAttributes } from '../requests/users/getAttributes';
import { getCourses } from '../requests/cursos/getCourses';
import { getEvents } from '../requests/events/getEvents';
import { getSessions } from '../requests/users/getSessions';
import { getRecursos } from '../requests/recursos/getRecursos';
import { getDiscussions } from '../requests/comunidades/getDiscussions';
import { getGroups } from '../requests/comunidades/getGroups';
import { getNews } from '../requests/news/getNews';
import { getCapsulas } from '../requests/capsulas/getCapsulas';
import { getAnalytics } from '../requests/other/getAnalytics';
import { getLastBackup } from '../requests/other/getLastBackup';
import { getDriveData } from '../requests/other/getDriveData';
import { getDomainExpirationDate } from '../requests/other/getDomainExpirationDate.js';
import { getTalleresYWebinars } from '../requests/talleresywebinars/getTalleresYWebinars';
import { tryAPI } from '../requests/other/tryAPI';


function CustomTabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}


const Home = ({ }) => {

  const [alertSuccess, setAlertSuccess] = useState(false);
  const [alertWarning, setAlertWarning] = useState(false);
  const [alertError, setAlertError] = useState(false);
  const [alerts, setAlerts] = useState({ "success": { "title": "", "text": "" }, "warning": { "title": "", "text": "" }, "error": { "title": "", "text": "" } });

  const [apiRedfid, setApiRedfid] = useState(null);
  const [loadingApiRedfid, setLoadingApiRedfid] = useState(true);

  const [tab, setTab] = useState("dashboard")
  const [domainExpiration, setDomainExpiration] = useState(null);
  const [loadingDomainExpiration, setLoadingDomainExpiration] = useState(true);
  const [analytics, setAnalytics] = useState(null);
  const [lastBackup, setLastBackup] = useState(null);
  const [loadingLastBackup, setLoadingLastBackup] = useState(true);
  const [driveData, setDriveData] = useState(null);
  const [loadingDriveData, setLoadingDriveData] = useState(true);
  const [users, setUsers] = useState(null);
  const [sessions, setSessions] = useState(null);
  const [attributes, setAttributes] = useState(null);
  const [events, setEvents] = useState(null);
  const [groups, setGroups] = useState(null);
  const [discussions, setDiscussions] = useState(null);
  const [courses, setCourses] = useState(null);
  const [loadingCourses, setLoadingCourses] = useState(true);
  const [news, setNews] = useState(null);
  const [talleresYWebinars, setTalleresYWebinars] = useState(null);
  const [capsulas, setCapsulas] = useState(null);
  const [loadingRecursos, setLoadingRecursos] = useState(true);
  const [recursos, setRecursos] = useState(null);
  const [activities, setActivities] = useState(null);
  const [recursosDocentes, setRecursosDocentes] = useState(null);


  const handleTabs = (event, newValue) => {
    setTab(newValue);
  }

  const handleCloseAlert = (event, reason, type) => {
    if (reason === 'clickaway') {
      return;
    }
    if (type === "success") {
      setAlertSuccess(false);
      setAlerts({ ...alerts, "success": { "title": "", "text": "" } });
    } else if (type === "warning") {
      setAlertWarning(false);
      setAlerts({ ...alerts, "warning": { "title": "", "text": "" } });
    } else if (type === "error") {
      setAlertError(false);
      setAlerts({ ...alerts, "error": { "title": "", "text": "" } });
    }
  };

  const createHandleCloseAlert = (type) => {
    return (event, reason) => {
      handleCloseAlert(event, reason, type);
    };
  };

  const fetchUsers = async () => {
    setUsers(null);
    const result_users = await getUsers(api);
    const result_attributes = await getAttributes(api);
    setUsers(result_users.users);
    setAttributes(result_attributes.attributes);
    fetchCourses(result_users.users);
  };

  const forbiddenGroups = [
    "nivel_de_confianza_0",
    "nivel_de_confianza_1",
    "nivel_de_confianza_2",
    "nivel_de_confianza_3",
    "nivel_de_confianza_4",
    "administradores",
    "moderadores",
    "personal"
  ]

  const fetchGroups = async () => {
    setGroups(null);
    const result_groups = await getGroups(api);
    setGroups(result_groups.groups.filter((group) => !forbiddenGroups.includes(group.name)));
  }

  const fetchEvents = async () => {
    setEvents(null);
    const result_events = await getEvents(api);
    setEvents(result_events.events);
  }

  const fetchDiscussions = async () => {
    setDiscussions(null);
    const result_discussions = await getDiscussions(api);
    setDiscussions(result_discussions.discussions);
  }

  const fetchAnalytics = async () => {
    setAnalytics(null);
    const result_analytics = await getAnalytics(api);
    setAnalytics(result_analytics.analytics);
  }

  const fetchTalleresYWebinars = async () => {
    setTalleresYWebinars(null);
    const result_talleresYWebinars = await getTalleresYWebinars(api);
    setTalleresYWebinars(result_talleresYWebinars.talleresYWebinars);
  }

  const fetchCapsulas = async () => {
    setCapsulas(null);
    const result_capsulas = await getCapsulas(api);
    setCapsulas(result_capsulas.capsulas);
  }

  const fetchCourses = async (users) => {
    setCourses(null);
    var result_courses = await getCourses(api);
    for (let course of Object.keys(result_courses.courses)) {
      for (let section of Object.keys(result_courses.courses[course]["sections"])) {
        if (section !== "0") {
          if (!result_courses.courses[course]["sections"][section].data.course_id) {
            delete result_courses.courses[course]["sections"][section];
          } else if (result_courses.courses[course]["sections"][section].data) {
            result_courses.courses[course]["sections"][section].data["enrolled"] = result_courses.courses[course]["sections"][section].data["enrolled"].filter((user) => users.find((u) => u.username === user && u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"));
            result_courses.courses[course]["sections"][section].data["approved"] = result_courses.courses[course]["sections"][section].data["approved"].filter((user) => users.find((u) => u.username === user && u.attributes && u.attributes.is_formador && u.attributes.is_formador[0] === "1"));
          }
        }
      }
    }
    setCourses(result_courses.courses);
    setLoadingCourses(false);
  }

  const fetchSessions = async () => {
    setSessions(null);
    const result_sessions = await getSessions(api);
    setSessions(result_sessions.sessions);
  }

  const fetchDomainExpiration = async () => {
    setLoadingDomainExpiration(true);
    const result_domainExpiration = await getDomainExpirationDate(api);
    setDomainExpiration(result_domainExpiration.domainExpiration);
    setLoadingDomainExpiration(false);
  }

  const fetchLastBackup = async () => {
    setLoadingLastBackup(true);
    const result_lastBackup = await getLastBackup(api);
    if (result_lastBackup.error) {
      setLoadingLastBackup(false);
      setAlertError(true);
      setAlerts({ ...alerts, "error": { "title": "Error al obtener la fecha del último respaldo", "text": result_lastBackup.error } });
    } else {
      setLastBackup(result_lastBackup.lastBackup.timestamp + " — " + result_lastBackup.lastBackup.size);
      setLoadingLastBackup(false);
    }
  }

  const fetchDriveData = async () => {
    setLoadingDriveData(true);
    const result_driveData = await getDriveData(api);
    setDriveData(result_driveData.driveData);
    setLoadingDriveData(false);
  }

  const fetchNews = async () => {
    setNews(null);
    const result_news = await getNews(api);
    setNews(result_news.news);
  }

  const fetchRecursos = async () => {
    setLoadingRecursos(true);
    const result_recursos = await getRecursos(recursosApi);
    if (result_recursos.recursos) {
      setRecursos(result_recursos.recursos.recursos);
      setActivities(result_recursos.recursos.activities);
      setRecursosDocentes(result_recursos.recursos.recursos_docentes);
    }
    setLoadingRecursos(false);
  }

  const checkAPI = async () => {
    setLoadingApiRedfid(true);
    const result = await tryAPI(api);
    if (result.error) {
      setAlertError(true);
      setAlerts({ ...alerts, "error": { "title": "Error al intentar acceder a la API RedFID", "text": result.error } });
    } else {
      setApiRedfid(result.services);
      fetchUsers();
      fetchGroups();
      fetchSessions();
      fetchEvents();
      fetchTalleresYWebinars();
      fetchCapsulas();
      fetchDiscussions();
      fetchNews();
      fetchAnalytics();
      fetchDomainExpiration();
      fetchLastBackup();
      fetchDriveData();
      fetchRecursos();
    }
    setLoadingApiRedfid(false);
  }

  useEffect(() => {
    checkAPI();
  }, []);

  return (
    <>
      <Grid mb={"20px"} display={"flex"} justifyContent={"center"}>
        <Typography fontSize={{ "xs": "1em", "sm": "1.2em", "md": "1.5em" }} mr={"10px"} textAlign={"center"}>RedFID | </Typography>
        <Typography fontSize={{ "xs": "1em", "sm": "1.2em", "md": "1.5em" }} fontWeight={"bolder"} fontFamily={"Avenir Heavy !important"} textAlign={"center"}>Panel de administración</Typography>
      </Grid>
      <Tabs TabIndicatorProps={{ sx: { display: 'none' } }} value={tab} onChange={handleTabs} centered variant='fullWidth' sx={{
        "&& .Mui-selected": {
          color: 'white',
          backgroundColor: '#40b4ba',
        },
        '& .MuiTabs-flexContainer': {
          flexWrap: 'wrap',
        }
      }}>
        <Tab sx={{ "minWidth": "250px" }} label={
          <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
            Información general
          </div>
        }
          value={"dashboard"} />
        <Tab sx={{ "minWidth": "250px" }} label={
          <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
            Usuarios
          </div>
        }
          value={"usuarios"} />
        <Tab sx={{ "minWidth": "250px" }} label={
          <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
            Eventos
          </div>
        }
          value={"events"} />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Noticias
            </div>
          }
          value={"noticias"}
        />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Cursos
            </div>
          }
          value={"cursos"}
        />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Talleres y webinars
            </div>
          }
          value={"talleres_y_webinars"}
        />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Cápsulas
            </div>
          }
          value={"capsulas"}
        />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Comunidades
            </div>
          }
          value={"comunidades"}
        />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Recursos
            </div>
          }
          value={"recursos"}
        />
        <Tab sx={{ "minWidth": "250px" }}
          label={
            <div style={{ "fontWeight": "bold", "textTransform": "none", "fontSize": "1.1em", "fontFamily": "Avenir Regular" }}>
              Mailing
            </div>
          }
          value={"mail"}
        />
      </Tabs>
      <CustomTabPanel value={tab} index={"dashboard"}>
        {(analytics && users && sessions) ?
          <>
            <Dashboard
              users={users}
              attributes={attributes}
              sessions={sessions}
              analytics={analytics}
            />
            <Typography variant="h6">Estado de las aplicaciones</Typography>
          </>
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
        <StatusIndicator
          apiRedfid={apiRedfid}
          loadingApiRedfid={loadingApiRedfid}
          domainExpiration={domainExpiration}
          loadingDomainExpiration={loadingDomainExpiration}
          lastBackup={lastBackup}
          loadingLastBackup={loadingLastBackup}
          driveData={driveData}
          loadingDriveData={loadingDriveData}
          courses={courses}
          loadingCourses={loadingCourses}
          recursos={recursos}
          activities={activities}
          recursosDocentes={recursosDocentes}
          loadingRecursos={loadingRecursos}
        ></StatusIndicator>
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"usuarios"}>
        {(users && sessions && attributes) ?
          <Usuarios
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            users={users}
            setUsers={setUsers}
            sessions={sessions}
            setSessions={setSessions}
            attributes={attributes}
            setAttributes={setAttributes}
            courses={courses}
            groups={groups}
            recursos={recursos}
            activities={activities}
            recursosDocentes={recursosDocentes}
            capsulas={capsulas}
            events={events}
            discussions={discussions}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"events"}>
        {(talleresYWebinars && events) ?
          <Events
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            events={events}
            setEvents={setEvents}
            users={users}
            talleresYWebinars={talleresYWebinars}
            setTalleresYWebinars={setTalleresYWebinars}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"noticias"}>
        {(news) ?
          <Noticias
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            news={news}
            setNews={setNews}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"cursos"}>
        {(courses) ?
          <Cursos
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            users={users}
            courses={courses}
            setCourses={setCourses}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"talleres_y_webinars"}>
        {(talleresYWebinars && events) ?
          <TalleresYWebinars
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            talleresYWebinars={talleresYWebinars}
            setTalleresYWebinars={setTalleresYWebinars}
            events={events}
            setEvents={setEvents}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"capsulas"}>
        {(capsulas && attributes) ?
          <Capsulas
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            attributes={attributes}
            capsulas={capsulas}
            setCapsulas={setCapsulas}
            users={users}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"comunidades"}>
        {(attributes && groups && driveData && discussions) ?
          <Comunidades
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            users={users}
            groups={groups}
            setGroups={setGroups}
            driveData={driveData}
            attributes={attributes}
            discussions={discussions}
            setDiscussions={setDiscussions}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"recursos"}>
        {(recursos && activities && recursosDocentes) ?
          <Recursos
            users={users}
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            recursos={recursos}
            activities={activities}
            recursosDocentes={recursosDocentes}
            setRecursos={setRecursos}
            setActivities={setActivities}
            setRecursosDocentes={setRecursosDocentes}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <CustomTabPanel value={tab} index={"mail"}>
        {(users && attributes && groups && courses && events) ?
          <Mail
            setAlertSuccess={setAlertSuccess}
            setAlertWarning={setAlertWarning}
            setAlertError={setAlertError}
            alerts={alerts}
            setAlerts={setAlerts}
            users={users}
            attributes={attributes}
            groups={groups}
            courses={courses}
            events={events}
          />
          :
          <Box textAlign="center" m={"50px 0"}>
            <CircularProgress sx={{ "color": "#40b4ba" }}></CircularProgress>
          </Box>
        }
      </CustomTabPanel>
      <Snackbar open={alertSuccess} autoHideDuration={6000} onClose={createHandleCloseAlert("success")}>
        <Alert
          onClose={createHandleCloseAlert("success")}
          severity="success"
          variant="filled"
          sx={{ width: '100%', fontFamily: 'Avenir Regular' }}
        >
          <AlertTitle sx={{ width: '100%', fontFamily: 'Avenir Regular' }}>{alerts.success.title}</AlertTitle>
          {alertSuccess ? alerts.success.text : ""}
        </Alert>
      </Snackbar>
      <Snackbar open={alertWarning} autoHideDuration={6000} onClose={createHandleCloseAlert("warning")}>
        <Alert
          onClose={createHandleCloseAlert("warning")}
          severity="warning"
          variant="filled"
          sx={{ width: '100%', fontFamily: 'Avenir Regular' }}
        >
          <AlertTitle sx={{ width: '100%', fontFamily: 'Avenir Regular' }}>{alerts.warning.title}</AlertTitle>
          {alertWarning ? alerts.warning.text : ""}
        </Alert>
      </Snackbar>
      <Snackbar open={alertError} autoHideDuration={6000} onClose={createHandleCloseAlert("error")}>
        <Alert
          onClose={createHandleCloseAlert("error")}
          severity="error"
          variant="filled"
          sx={{ width: '100%', fontFamily: 'Avenir Regular' }}
        >
          <AlertTitle sx={{ width: '100%', fontFamily: 'Avenir Regular' }}>{alerts.error.title}</AlertTitle>
          {alertError ? alerts.error.text : ""}
        </Alert>
      </Snackbar>
    </>
  );
}

export default Home;