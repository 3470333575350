import axios from 'axios';

export const createUser = async (api, formData) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { users: null, attributes: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const usersResponse = await axios.post(api.api_url + "/add_user", formData, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (usersResponse.data.status) {
            case 100:
                var users = usersResponse.data.users;
                var errors = usersResponse.data.errors;
                var attributes = usersResponse.data.attributes;
                return { users: users, attributes: attributes, errors: errors };
            case 104:
                return { users: null, attributes: null, error: "El usuario ya existe." };
            case 105:
                return { users: null, attributes: null, error: "Error al crear el usuario." };
            case 110:
                return { users: null, attributes: null, error: "Acceso denegado. Revise la conexión con la API." };
            case 111:
                return { users: null, attributes: null, error: "Error al iniciar sesión en Keycloak." };
            case 112:
                return { users: null, attributes: null, error: "Error de validación." };
            default:
                return { users: null, attributes: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { users: null, attributes: null, error: "Error desconocido: " + error.message };
    }
}
