import React from "react";
import { Checkbox, FormControlLabel, FormGroup, Grid } from "@mui/material";


const DownloadCSVGroupsForm = ({
    csvGroupName,
    setCsvGroupName,
    csvGroupMembers,
    setCsvGroupMembers,
    csvGroupMembersList,
    setCsvGroupMembersList,
    csvGroupVisibility,
    setCsvGroupVisibility,
    csvGroupDrive,
    setCsvGroupDrive,
    csvGroupDriveUsage,
    setCsvGroupDriveUsage,
    csvGroupDiscussions,
    setCsvGroupDiscussions,
    csvGroupPublicAdmission,
    setCsvGroupPublicAdmission,
    csvGroupPublicExit,
    setCsvGroupPublicExit,
    csvGroupAllowMembershipRequests,
    setCsvGroupAllowMembershipRequests,
    csvExcel,
    setCsvExcel
}) => {


    return (
        <Grid container columnSpacing={2} alignItems="center">
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={true}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} disabled label="ID" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupName}
                                onChange={
                                    (e) => {
                                        setCsvGroupName(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Nombre" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupMembers}
                                onChange={
                                    (e) => {
                                        setCsvGroupMembers(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Miembros" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupMembersList}
                                onChange={
                                    (e) => {
                                        setCsvGroupMembersList(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Lista de miembros" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupVisibility}
                                onChange={
                                    (e) => {
                                        setCsvGroupVisibility(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Visibilidad" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupDrive}
                                onChange={
                                    (e) => {
                                        setCsvGroupDrive(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Unidad compartida en Drive" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupDriveUsage}
                                onChange={
                                    (e) => {
                                        setCsvGroupDriveUsage(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Uso de Drive" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupDiscussions}
                                onChange={
                                    (e) => {
                                        setCsvGroupDiscussions(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Discusiones" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupPublicAdmission}
                                onChange={
                                    (e) => {
                                        setCsvGroupPublicAdmission(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Acceso libre" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupPublicExit}
                                onChange={
                                    (e) => {
                                        setCsvGroupPublicExit(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Salida libre" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvGroupAllowMembershipRequests}
                                onChange={
                                    (e) => {
                                        setCsvGroupAllowMembershipRequests(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Permitir solicitudes de membresía" />
                </FormGroup>
            </Grid>
            <Grid item xs={12} display="flex" justifyContent="center">
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={csvExcel}
                                onChange={
                                    (e) => {
                                        setCsvExcel(e.target.checked);
                                    }
                                }
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        } sx={{ "color": '#646464' }} label="Descargar en formato Excel (.xlsx)" />
                </FormGroup>
            </Grid>
        </Grid>
    );

}

export default DownloadCSVGroupsForm;