import axios from "axios";

export const deleteRecurso = async (type, id) => {
    try {
        const responseDeleteRecurso = await axios.post(process.env.REACT_APP_RECURSOS_URL + "/admin/delete_" + type + "/" + id, {}, {
            headers: {
                "Authorization": process.env.REACT_APP_RECURSOS_TOKEN,
                "Content-Type": "application/json"
            }
        })

        switch (responseDeleteRecurso.status) {
            case 200:
                return { recursos: responseDeleteRecurso.data, error: "" };
            default:
                return { recursos: [], error: "Falló la solicitud para eliminar un recurso en RedFID Recursos." };
        }
    } catch (error) {
        return { recursos: [], error: "Error desconocido: " + error.message };
    }
}