import React, { Component } from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4maps from "@amcharts/amcharts4/maps";
import regions from './regiones.json';

class MapChile extends Component {
  componentDidMount() {
    // Create map instance
    let chart = am4core.create("chartdiv", am4maps.MapChart);
  
    // Set projection to be used
    chart.projection = new am4maps.projections.Mercator();
  
    // Use the provided regions JSON as geodata
    chart.geodata = regions;
  
    // Merge passed data with regions data and calculate maximum value
    const maxValue = Math.max(...Object.values(this.props.data));
    const mergedData = regions.features.map(feature => {
      const regionId = feature.id;
      const dataRegion = this.props.data[regionId];
      const value = dataRegion ? dataRegion : 0;
      const opacity = value === 0 ? 0.25 : 0.5 + (value / maxValue) * 0.5;
      const color = `rgba(64, 180, 186, ${opacity})`; // Convert #40b4ba to rgba with dynamic opacity
      return {
        ...feature,
        value,
        fill: am4core.color(color)
      };
    });
  
    // Create map polygon series
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.useGeodata = true;
  
    // Add custom data to the polygon series
    polygonSeries.data = mergedData;
  
    // Configure series
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{Region}\nFormadores: {value}";
    polygonTemplate.fill = am4core.color("#e0e0e0");
  
    chart.chartContainer.wheelable = false;
    chart.chartContainer.draggable = false;
    chart.chartContainer.resizable = false;
  
    // Create hover state
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = am4core.color("#eb947e");
  
    // Apply color based on value
    polygonTemplate.propertyFields.fill = "fill";
  
    this.chart = chart;
  }
  
  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  render() {
    return (
      <div id="chartdiv" style={{ height: "500px" }}></div>
    );
  }
}

export default MapChile;
