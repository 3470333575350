import axios from 'axios';

export const emitOrRevokeCertificate = async (api, action, username, course_id) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { data: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const response = await axios.post(api.api_url + "/emit_or_revoke_certificate", {
            username: username,
            action: action,
            course_id: course_id
        }, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });
        switch (response.data.status) {
            case 100:
                return { data: response.data.courses, error: "" };
            case 111:
                return { data: null, error: "Falló la solicitud para " + (action === "emit" ? "emitir" : "revocar") + " un certificado." };
            case 112:
                return { data: null, error: "RedFID edX API no encontrada." };
            case 113:
                return { data: null, error: "Error al emitir o revocar el certificado." };
            default:
                return { data: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { data: null, error: "Error desconocido: " + error.message };
    }
}
