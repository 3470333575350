import axios from 'axios';

function youtubeURLToId(video_url) {
    const url = new URL(video_url);
    const searchParams = new URLSearchParams(url.search);
    return searchParams.get('v');

}

export const getYoutubeData = async (video_url) => {
    const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
    const endpoint = `https://www.googleapis.com/youtube/v3/videos?id=${youtubeURLToId(video_url)}&key=${API_KEY}&part=snippet,statistics`
    const response = await axios.get(endpoint);
    try {
        return response.data.items[0].statistics;
    } catch (error) {
        return { viewCount: 0, likeCount: 0, favoriteCount: 0, commentCount: 0};
    }
}