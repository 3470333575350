import axios from 'axios';

export const getSessions = async (api) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { sessions: [], error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const sessionsResponse = await axios.get(api.api_url + "/get_sessions", {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (sessionsResponse.data.status) {
            case 100:
                return { sessions: sessionsResponse.data.result, error: "" };
            case 101:
                return { sessions: [], error: "Ha ocurrido un error accediendo a Keycloak." };
            case 105:
                return { sessions: [], error: "Falló la solicitud para obtener sesiones de Keycloak." };
            case 110:
                return { sessions: [], error: "Acceso denegado. Revise la conexión con la API." };
            default:
                return { sessions: [], error: "Error desconocido." };
        }
    } catch (error) {
        return { sessions: [], error: "Error desconocido: " + error.message };
    }
}
