import axios from 'axios';

export const getEmailPreview = async (api, formData) => {

    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const response = await axios.post(api.api_url + "/get_email_preview", formData, {
            headers: {
                "Authorization": "Bearer " + access_token
            }
        });

        switch (response.data.status) {
            case 100:
                return { result: response.data.result, error: "" };
            case 110:
                return { error: "Acceso denegado. Revise la conexión con la API." };
            case 111:
                return { error: "Faltan argumentos." };
            case 112:
                return { error: "Dirección de origen inválida." };
            case 113:
                return { error: "Plantilla no encontrada." };
            default:
                return { error: "Error desconocido." };
        }
    } catch (error) {
        return { error: "Error desconocido: " + error.message };
    }
}
