import React, { useState, useEffect } from 'react';
import { CircularProgress, Grid, Typography, Link, Box } from '@mui/material';
import Modal from './components/Modal';
import Indicator from './components/Indicator';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DiscussionsTable from './components/tables/DiscussionsTable';
import DownloadIcon from '@mui/icons-material/Download';
import GroupsTable from './components/tables/GroupsTable';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CreateGroupForm from './components/forms/CreateGroupForm';
import PieChart from './components/charts/PieChart';
import BarChart from './components/charts/BarChart';
import { formatFileSize } from '../utils/formatters';
import { validateGroupForm } from '../utils/validators';
import { getDiscourseCategoryChip, getGroupVisibilityChip } from '../utils/chips';
import { mainCategories, adminCategories } from '../requests/comunidades/getDiscussions';
import { deleteDiscussion } from '../requests/comunidades/deleteDiscussion';
import { createGroup } from '../requests/comunidades/createGroup';
import { deleteGroup } from '../requests/comunidades/deleteGroup';
import DownloadCSVDiscussionsForm from './components/forms/DownloadCSVDiscussionsForm';
import DownloadCSVGroupsForm from './components/forms/DownloadCSVGroupsForm';
import { discussionsCSV, groupsCSV } from '../utils/csvDownload';
import { Check, DeleteForever } from '@mui/icons-material';
import { api } from '../API';


const Comunidades = ({
    setAlertSuccess,
    setAlertWarning,
    setAlertError,
    alerts,
    setAlerts,
    users,
    groups,
    driveData,
    setGroups,
    attributes,
    discussions,
    setDiscussions
}) => {

    const [selectedDiscussion, setSelectedDiscussion] = useState(null);
    const [openCreateDiscussionModal, setOpenCreateDiscussionModal] = useState(false);
    const [openDownloadCSVDiscussionModal, setOpenDownloadCSVDiscussionModal] = useState(false);
    const [openStatsDiscussionModal, setOpenStatsDiscussionModal] = useState(false);
    const [openInfoDiscussionModal, setOpenInfoDiscussionModal] = useState(false);
    const [openDeleteDiscussionModal, setOpenDeleteDiscussionModal] = useState(false);

    const [selectedGroup, setSelectedGroup] = useState(null);
    const [openCreateGroupModal, setOpenCreateGroupModal] = useState(false);
    const [openDownloadCSVGroupModal, setOpenDownloadCSVGroupModal] = useState(false);
    const [openStatsGroupModal, setOpenStatsGroupModal] = useState(false);
    const [openInfoGroupModal, setOpenInfoGroupModal] = useState(false);
    const [openGroupMembersModal, setOpenGroupMembersModal] = useState(false);
    const [openDeleteGroupModal, setOpenDeleteGroupModal] = useState(false);

    const [csvDiscussionTitle, setCsvDiscussionTitle] = useState(true);
    const [csvDiscussionURL, setCsvDiscussionURL] = useState(true);
    const [csvDiscussionCategory, setCsvDiscussionCategory] = useState(true);
    const [csvDiscussionTotalPosters, setCsvDiscussionTotalPosters] = useState(true);
    const [csvDiscussionTotalPosts, setCsvDiscussionTotalPosts] = useState(true);
    const [csvDiscussionTotalViews, setCsvDiscussionTotalViews] = useState(true);
    const [csvDiscussionTotalLikes, setCsvDiscussionTotalLikes] = useState(true);
    const [csvDiscussionCreatedAt, setCsvDiscussionCreatedAt] = useState(true);
    const [csvDiscussionLastPost, setCsvDiscussionLastPost] = useState(true);

    const [formLoading, setFormLoading] = useState(false);
    const [errors, setErrors] = useState({});
    const [groupName, setGroupName] = useState("");
    const [groupPublicAdmission, setGroupPublicAdmission] = useState(true);
    const [groupPublicExit, setGroupPublicExit] = useState(true);
    const [groupAllowMembershipRequests, setGroupAllowMembershipRequests] = useState(false);
    const [groupVisibilityLevel, setGroupVisibilityLevel] = useState("none");
    const [groupOwner, setGroupOwner] = useState(null);

    const [csvExcel, setCsvExcel] = useState(true);
    const [csvGroupName, setCsvGroupName] = useState(true);
    const [csvGroupMembers, setCsvGroupMembers] = useState(true);
    const [csvGroupMembersList, setCsvGroupMembersList] = useState(true);
    const [csvGroupVisibility, setCsvGroupVisibility] = useState(true);
    const [csvGroupDrive, setCsvGroupDrive] = useState(true);
    const [csvGroupDriveUsage, setCsvGroupDriveUsage] = useState(true);
    const [csvGroupDiscussions, setCsvGroupDiscussions] = useState(true);
    const [csvGroupPublicAdmission, setCsvGroupPublicAdmission] = useState(true);
    const [csvGroupPublicExit, setCsvGroupPublicExit] = useState(true);
    const [csvGroupAllowMembershipRequests, setCsvGroupAllowMembershipRequests] = useState(true);

    const handleOpenCreateDiscussionModal = () => {
        setOpenCreateDiscussionModal(true);
    }

    const handleCloseCreateDiscussionModal = () => {
        setOpenCreateDiscussionModal(false);
    }

    const handleOpenDownloadCSVDiscussionModal = () => {
        setOpenDownloadCSVDiscussionModal(true);
    }

    const handleCloseDownloadCSVDiscussionModal = () => {
        setOpenDownloadCSVDiscussionModal(false);
    }

    const handleOpenStatsDiscussionModal = () => {
        setOpenStatsDiscussionModal(true);
    }

    const handleCloseStatsDiscussionModal = () => {
        setOpenStatsDiscussionModal(false);
    }

    const handleOpenInfoDiscussionModal = (discussion) => {
        setSelectedDiscussion(discussion);
        setOpenInfoDiscussionModal(true);
    }

    const handleCloseInfoDiscussionModal = () => {
        setSelectedDiscussion(null);
        setOpenInfoDiscussionModal(false);
    }

    const handleOpenDeleteDiscussionModal = (discussion) => {
        setSelectedDiscussion(discussion);
        setOpenDeleteDiscussionModal(true);
    }

    const handleCloseDeleteDiscussionModal = () => {
        setSelectedDiscussion(null);
        setOpenDeleteDiscussionModal(false);
    }

    const handleOpenCreateGroupModal = () => {
        setOpenCreateGroupModal(true);
    }

    const handleCloseCreateGroupModal = () => {
        setOpenCreateGroupModal(false);
    }

    const handleOpenDownloadCSVGroupModal = () => {
        setOpenDownloadCSVGroupModal(true);
    }

    const handleCloseDownloadCSVGroupModal = () => {
        setOpenDownloadCSVGroupModal(false);
    }

    const handleOpenStatsGroupModal = () => {
        setOpenStatsGroupModal(true);
    }

    const handleCloseStatsGroupModal = () => {
        setOpenStatsGroupModal(false);
    }

    const handleOpenInfoGroupModal = (group) => {
        setSelectedGroup(group);
        setOpenInfoGroupModal(true);
    }

    const handleCloseInfoGroupModal = () => {
        setSelectedGroup(null);
        setOpenInfoGroupModal(false);
    }

    const handleOpenGroupMembersModal = (group) => {
        setSelectedGroup(group);
        setOpenGroupMembersModal(true);
    }

    const handleCloseGroupMembersModal = () => {
        setSelectedGroup(null);
        setOpenGroupMembersModal(false);
    }

    const handleOpenDeleteGroupModal = (group) => {
        setSelectedGroup(group);
        setOpenDeleteGroupModal(true);
    }

    const handleCloseDeleteGroupModal = () => {
        setSelectedGroup(null);
        setOpenDeleteGroupModal(false);
    }

    const handleCreateGroup = async () => {
        const [isValid, tempErrors] = validateGroupForm(
            groupName,
            groupVisibilityLevel,
            groupOwner
        );
        if (!isValid) {
            setErrors(tempErrors);
            return;
        }
        setFormLoading(true);
        const options = {
            "group_name": groupName,
            "public_admission": (groupVisibilityLevel === "2" || groupAllowMembershipRequests) ? false : groupPublicAdmission,
            "public_exit": groupPublicExit,
            "allow_membership_requests": (groupVisibilityLevel === "2" || groupPublicAdmission) ? false : groupAllowMembershipRequests,
            "visibility_level": groupVisibilityLevel,
            "username": groupOwner.username
        }
        const response = await createGroup(api, options);
        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al crear grupo", "text": response.error } });
        } else {
            setGroups(response.groups);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "Grupo creado!", "text": "El grupo se ha creado correctamente." } });
            handleCloseCreateGroupModal();
            setGroupName("");
            setGroupVisibilityLevel("none");
            setGroupOwner(null);
            setGroupAllowMembershipRequests(false);
            setGroupPublicAdmission(true);
            setGroupPublicExit(true);
            setErrors({});
        }
        setFormLoading(false);
    }

    const handleDownloadGroupsCSV = () => {
        let downloadOptions = {
            "name": csvGroupName,
            "members": csvGroupMembers,
            "membersList": csvGroupMembersList,
            "visibility": csvGroupVisibility,
            "drive": csvGroupDrive,
            "driveUsage": csvGroupDriveUsage,
            "discussions": csvGroupDiscussions,
            "publicAdmission": csvGroupPublicAdmission,
            "publicExit": csvGroupPublicExit,
            "allowMembershipRequests": csvGroupAllowMembershipRequests
        }
        groupsCSV(csvExcel, groups, discussions, downloadOptions);
        handleCloseDownloadCSVGroupModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleDownloadDiscussionsCSV = () => {
        let downloadOptions = {
            "title": csvDiscussionTitle,
            "url": csvDiscussionURL,
            "category": csvDiscussionCategory,
            "totalPosters": csvDiscussionTotalPosters,
            "totalPosts": csvDiscussionTotalPosts,
            "totalViews": csvDiscussionTotalViews,
            "totalLikes": csvDiscussionTotalLikes,
            "createdAt": csvDiscussionCreatedAt,
            "lastPostedAt": csvDiscussionLastPost
        }
        discussionsCSV(discussions.filter(d => (d.is_group === false && d.is_main === true)), downloadOptions);
        handleCloseDownloadCSVDiscussionModal();
        setAlertSuccess(true);
        setAlerts({ ...alerts, "success": { "title": "¡Archivo descargado!", "text": "Los datos se han descargado correctamente." } });
    }

    const handleDeleteDiscussion = async () => {
        setFormLoading(true);
        const response = await deleteDiscussion(attributes.api, selectedDiscussion.id);
        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al eliminar discusión", "text": response.error } });
        } else {
            setDiscussions(response.discussions);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "Discusión eliminada!", "text": "La discusión se ha eliminado correctamente." } });
        }
        setFormLoading(false);
        handleCloseDeleteDiscussionModal();
    }

    const handleDeleteGroup = async () => {
        setFormLoading(true);
        const response = await deleteGroup(api, selectedGroup.full_name, selectedGroup.name, selectedGroup.id);
        if (response.error) {
            setAlertError(true);
            setAlerts({ ...alerts, "error": { "title": "Error al eliminar grupo", "text": response.error } });
        } else {
            setGroups(response.groups);
            setAlertSuccess(true);
            setAlerts({ ...alerts, "success": { "title": "Grupo eliminado!", "text": "El grupo se ha eliminado correctamente." } });
        }
        setFormLoading(false);
        handleCloseDeleteGroupModal();
    }

    return (
        <>
            <Grid spacing={2} container justifyContent={"center"}>
                <Grid item xs={2}>
                    <Indicator title={"Discusiones"} number={
                        discussions.filter((discussion) => (discussion.is_main || discussion.is_group)).length
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de discusiones públicas creadas en RedFID Comunidades."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title={"Posts"} number={
                        discussions.filter((discussion) => (discussion.is_main || discussion.is_group)).reduce((acc, discussion) => acc + discussion.posts_count, 0)
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de posts a lo largo de todas las discusiones en RedFID Comunidades."
                    }></Indicator>
                </Grid>
                <Grid item xs={3}>
                    <Indicator title={"Visitas a discusiones"} number={
                        discussions.filter((discussion) => (discussion.is_main || discussion.is_group)).reduce((acc, discussion) => acc + discussion.views, 0)
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de visitas a discusiones en RedFID Comunidades."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title={"Likes"} number={
                        discussions.filter((discussion) => (discussion.is_main || discussion.is_group)).reduce((acc, discussion) => acc + discussion.like_count, 0)
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de likes a posts en discusiones en RedFID Comunidades."
                    }></Indicator>
                </Grid>
                <Grid item xs={2}>
                    <Indicator title={"Grupos"} number={
                        groups.length
                    } titleSize={"small"} subtitleSize={"none"} helpMessage={
                        "Número total de grupos creados en RedFID Comunidades."
                    }></Indicator>
                </Grid>
            </Grid>
            <Grid spacing={2} container>
                <DiscussionsTable
                    discussions={discussions.filter(d => !d.is_group)}
                    handleOpenCreateModal={handleOpenCreateDiscussionModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVDiscussionModal}
                    handleOpenStatsModal={handleOpenStatsDiscussionModal}
                    handleOpenInfoModal={handleOpenInfoDiscussionModal}
                    handleOpenDeleteModal={handleOpenDeleteDiscussionModal}
                />
            </Grid>
            <Grid spacing={2} container>
                <GroupsTable
                    groups={groups}
                    driveData={driveData}
                    handleOpenCreateModal={handleOpenCreateGroupModal}
                    handleOpenDownloadCSVModal={handleOpenDownloadCSVGroupModal}
                    handleOpenStatsModal={handleOpenStatsGroupModal}
                    handleOpenInfoModal={handleOpenInfoGroupModal}
                    handleOpenGroupMembersModal={handleOpenGroupMembersModal}
                    handleOpenDeleteModal={handleOpenDeleteGroupModal}
                />
            </Grid>
            <Modal
                open={openCreateDiscussionModal}
                handleClose={handleCloseCreateDiscussionModal}
                title="Crear discusión"
                onClickMainButton={handleCloseCreateDiscussionModal}
                mainButtonText="OK"
                cancelButton={false}
                width={"md"}
                content={
                    <>
                        <Typography variant='p' mb={"20px"}>Para crear una discusión en RedFID Comunidades, por favor ingrese a la plataforma:</Typography>
                        <Typography variant='p' textAlign={"center"} mb={"20px"}><Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_COMUNIDADES_URL}>{process.env.REACT_APP_COMUNIDADES_URL}</Link></Typography>
                    </>
                }
            >
            </Modal>
            <Modal
                open={openDownloadCSVDiscussionModal}
                handleClose={handleCloseDownloadCSVDiscussionModal}
                title="Descargar datos"
                onClickMainButton={handleDownloadDiscussionsCSV}
                startIcon={<DownloadIcon />}
                mainButtonText="Descargar"
                width={"lg"}
                cancelButton={true}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todas las discusiones públicas de RedFID Comunidades.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                        <DownloadCSVDiscussionsForm
                            csvTitle={csvDiscussionTitle}
                            setCsvTitle={setCsvDiscussionTitle}
                            csvURL={csvDiscussionURL}
                            setCsvURL={setCsvDiscussionURL}
                            csvCategory={csvDiscussionCategory}
                            setCsvCategory={setCsvDiscussionCategory}
                            csvTotalPosters={csvDiscussionTotalPosters}
                            setCsvTotalPosters={setCsvDiscussionTotalPosters}
                            csvTotalPosts={csvDiscussionTotalPosts}
                            setCsvTotalPosts={setCsvDiscussionTotalPosts}
                            csvTotalViews={csvDiscussionTotalViews}
                            setCsvTotalViews={setCsvDiscussionTotalViews}
                            csvTotalLikes={csvDiscussionTotalLikes}
                            setCsvTotalLikes={setCsvDiscussionTotalLikes}
                            csvCreatedAt={csvDiscussionCreatedAt}
                            setCsvCreatedAt={setCsvDiscussionCreatedAt}
                            csvLastPost={csvDiscussionLastPost}
                            setCsvLastPost={setCsvDiscussionLastPost}
                        ></DownloadCSVDiscussionsForm>
                    </>
                }
            >
            </Modal>
            <Modal
                open={openStatsDiscussionModal}
                handleClose={handleCloseStatsDiscussionModal}
                title="Estadísticas de discusiones"
                onClickMainButton={handleCloseStatsDiscussionModal}
                mainButtonText="OK"
                cancelButton={false}
                width={"lg"}
                content={
                    <>
                        <Grid container columnSpacing={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Discusiones más visitadas</Typography>
                                <BarChart
                                    id={"most-visited-discussions"}
                                    horizontal={true}
                                    height={400}
                                    data={discussions.filter(d => !d.is_group).sort((a, b) => b.views - a.views).slice(0, 10).map(d => { return { "label": d.title, "value": d.views, "color": "#40b4ba" } })}
                                ></BarChart>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Discusiones por categoría</Typography>
                                <PieChart
                                    id={"discussions-by-category"}
                                    height={300}
                                    data={() => {
                                        let out = [];
                                        for (let d of discussions) {
                                            if (!Object.keys(mainCategories).includes(d.category_id.toString())) {
                                                continue;
                                            }
                                            let category = out.find(category => category.label === mainCategories[d.category_id.toString()]);
                                            if (category) {
                                                category.value++;
                                            } else {
                                                out.push({
                                                    "label": mainCategories[d.category_id.toString()],
                                                    "value": 1,
                                                    "color": d.category_id === 4 ? "#3AB54A" : d.category_id === 5 ? "#0088CC" : d.category_id === 6 ? "#652D90" : d.category_id === 7 ? "#808281" : "#BF1E2E"
                                                });
                                            }
                                        }
                                        return out;
                                    }}
                                ></PieChart>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Usuarios con más posts</Typography>
                                <BarChart
                                    id={"users-with-most-posts"}
                                    height={300}
                                    horizontal={true}
                                    data={() => {
                                        let out = [];
                                        for (let u of users) {
                                            let userPosts = u.discourse_data.post_count;
                                            if (userPosts > 0) {
                                                out.push({ "label": u.firstName + " " + u.lastName, "value": userPosts, "color": "#40b4ba" });
                                            }
                                        }
                                        out = out.sort((a, b) => b.value - a.value).slice(0, 10);
                                        return out;
                                    }}
                                ></BarChart>
                            </Grid>
                        </Grid>
                    </>
                }
            >
            </Modal>
            {selectedDiscussion &&
                <>
                    <Modal
                        open={openInfoDiscussionModal}
                        handleClose={handleCloseInfoDiscussionModal}
                        title={selectedDiscussion.title}
                        onClickMainButton={handleCloseInfoDiscussionModal}
                        mainButtonText="OK"
                        cancelButton={false}
                        width={"md"}
                        content={
                            <Grid container columnSpacing={2}>
                                <Grid item mb={"20px"} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                    <Typography variant='p'><b>URL:</b> <Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_COMUNIDADES_URL + "/t/" + selectedDiscussion.slug + "/" + selectedDiscussion.id}>{process.env.REACT_APP_COMUNIDADES_URL + "/t/" + selectedDiscussion.slug + "/" + selectedDiscussion.id}</Link></Typography>
                                </Grid>
                                <Grid item mb={"20px"} xs={12} display={"flex"} justifyContent={"start"} alignItems={"center"}>
                                    <Typography variant='p' mr={"10px"}><b>Categoría: </b></Typography>
                                    {getDiscourseCategoryChip(selectedDiscussion.category_id)}
                                </Grid>
                                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                    <Typography variant='p' mb={"10px"}><b>Participantes: </b>{selectedDiscussion.posters.length}</Typography>
                                    <Typography variant='p' mb={"10px"}><b>Posts: </b>{selectedDiscussion.posts_count}</Typography>
                                    <Typography variant='p' mb={"10px"}><b>Likes: </b>{selectedDiscussion.like_count}</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                    <Typography variant='p' mb={"10px"}><b>Visitas: </b>{selectedDiscussion.views}</Typography>
                                    <Typography variant='p' mb={"10px"}><b>Fecha de creación: </b>{selectedDiscussion.created_at}</Typography>
                                    <Typography variant='p' mb={"10px"}><b>Último post: </b>{selectedDiscussion.last_posted_at}</Typography>
                                </Grid>
                            </Grid>
                        }
                    >
                    </Modal>
                    <Modal
                        open={openDeleteDiscussionModal}
                        handleClose={handleCloseDeleteDiscussionModal}
                        title="Eliminar discusión"
                        onClickMainButton={handleDeleteDiscussion}
                        mainButtonText="Eliminar"
                        startIcon={<DeleteForever></DeleteForever>}
                        mainButtonDisabled={formLoading}
                        cancelButton={true}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea eliminar la discusión seleccionada?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedDiscussion.title}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    Esta acción no se puede deshacer y eliminará todos los posts y datos asociados a esta discusión.
                                </Typography>
                            </>
                        }
                    >
                    </Modal>
                </>
            }

            <Modal
                open={openCreateGroupModal}
                handleClose={handleCloseCreateGroupModal}
                title="Crear grupo"
                onClickMainButton={handleCreateGroup}
                mainButtonText="Crear"
                startIcon={<AddCircleOutlineIcon></AddCircleOutlineIcon>}
                cancelButton={true}
                mainButtonDisabled={formLoading}
                width={"lg"}
                content={
                    users &&
                    <CreateGroupForm
                        users={users}
                        groupName={groupName}
                        setGroupName={setGroupName}
                        groupPublicAdmission={groupPublicAdmission}
                        setGroupPublicAdmission={setGroupPublicAdmission}
                        groupPublicExit={groupPublicExit}
                        setGroupPublicExit={setGroupPublicExit}
                        groupAllowMembershipRequests={groupAllowMembershipRequests}
                        setGroupAllowMembershipRequests={setGroupAllowMembershipRequests}
                        groupVisibilityLevel={groupVisibilityLevel}
                        setGroupVisibilityLevel={setGroupVisibilityLevel}
                        groupOwner={groupOwner}
                        setGroupOwner={setGroupOwner}
                        formLoading={formLoading}
                        errors={errors}
                        setErrors={setErrors}
                    />
                }
            >
            </Modal>
            <Modal
                open={openDownloadCSVGroupModal}
                handleClose={handleCloseDownloadCSVGroupModal}
                title="Descargar datos"
                onClickMainButton={handleDownloadGroupsCSV}
                mainButtonText="Descargar"
                startIcon={<DownloadIcon></DownloadIcon>}
                width={"lg"}
                cancelButton={true}
                content={
                    <>
                        <Typography variant="p" mb={"20px"}>
                            Se descargará un archivo en formato .csv con la información de todos los grupos de RedFID Comunidades.
                            Por favor, seleccione los campos que desea incluir:
                        </Typography>
                        <DownloadCSVGroupsForm
                            csvGroupName={csvGroupName}
                            setCsvGroupName={setCsvGroupName}
                            csvGroupMembers={csvGroupMembers}
                            setCsvGroupMembers={setCsvGroupMembers}
                            csvGroupMembersList={csvGroupMembersList}
                            setCsvGroupMembersList={setCsvGroupMembersList}
                            csvGroupVisibility={csvGroupVisibility}
                            setCsvGroupVisibility={setCsvGroupVisibility}
                            csvGroupDrive={csvGroupDrive}
                            setCsvGroupDrive={setCsvGroupDrive}
                            csvGroupDriveUsage={csvGroupDriveUsage}
                            setCsvGroupDriveUsage={setCsvGroupDriveUsage}
                            csvGroupDiscussions={csvGroupDiscussions}
                            setCsvGroupDiscussions={setCsvGroupDiscussions}
                            csvGroupPublicAdmission={csvGroupPublicAdmission}
                            setCsvGroupPublicAdmission={setCsvGroupPublicAdmission}
                            csvGroupPublicExit={csvGroupPublicExit}
                            setCsvGroupPublicExit={setCsvGroupPublicExit}
                            csvGroupAllowMembershipRequests={csvGroupAllowMembershipRequests}
                            setCsvGroupAllowMembershipRequests={setCsvGroupAllowMembershipRequests}
                            csvExcel={csvExcel}
                            setCsvExcel={setCsvExcel}
                        ></DownloadCSVGroupsForm>
                    </>
                }
            >
            </Modal>
            <Modal
                open={openStatsGroupModal}
                handleClose={handleCloseStatsGroupModal}
                title="Estadísticas de grupos"
                onClickMainButton={handleCloseStatsGroupModal}
                mainButtonText="OK"
                cancelButton={false}
                width={"lg"}
                content={
                    <>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Visibilidad de grupos</Typography>
                                <PieChart
                                    id={"groups-by-visibility"}
                                    height={300}
                                    data={() => {
                                        let out = [];
                                        for (let g of groups) {
                                            let visibility = out.find(v => v.label === (g.visibility_level === 0 ? "Público" : g.visibility_level === 1 ? "Semi-público" : "Privado"));
                                            if (visibility) {
                                                visibility.value++;
                                            } else {
                                                out.push({
                                                    "label": g.visibility_level === 0 ? "Público" : g.visibility_level === 1 ? "Semi-público" : "Privado",
                                                    "value": 1,
                                                    "color": g.visibility_level === 0 ? "#3AB54A" : g.visibility_level === 1 ? "#0088CC" : "#BF1E2E"
                                                });
                                            }
                                        }
                                        return out;
                                    }}
                                ></PieChart>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Grupos con más miembros</Typography>
                                <BarChart
                                    id={"groups-with-most-members"}
                                    height={300}
                                    horizontal={true}
                                    data={() => {
                                        let out = [];
                                        for (let g of groups) {
                                            let groupMembers = g.users.filter(u => u !== "redfid").length;
                                            if (groupMembers > 0) {
                                                out.push({ "label": g.full_name, "value": groupMembers, "color": "#40b4ba" });
                                            }
                                        }
                                        out = out.sort((a, b) => b.value - a.value).slice(0, 10);
                                        return out;
                                    }}
                                ></BarChart>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Grupos con más posts</Typography>
                                <BarChart
                                    id={"groups-with-most-posts"}
                                    height={300}
                                    horizontal={true}
                                    data={() => {
                                        let out = [];
                                        for (let g of groups) {
                                            let groupPosts = discussions.filter(d => d.is_group && d.category_id === g.category_id).reduce((acc, d) => acc + d.posts_count, 0);
                                            if (groupPosts > 0) {
                                                out.push({ "label": g.full_name, "value": groupPosts, "color": "#40b4ba" });
                                            }
                                        }
                                        out = out.sort((a, b) => b.value - a.value).slice(0, 10);
                                        return out;
                                    }}
                                ></BarChart>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6">Grupos con más uso de Drive (GB)</Typography>
                                <BarChart
                                    id={"groups-with-most-drive-usage"}
                                    height={300}
                                    horizontal={true}
                                    data={() => {
                                        let out = [];
                                        for (let g of groups) {
                                            let groupDriveUsage = (driveData["sharedDrives"][g.full_name]["size"] / 1024 / 1024 / 1024).toFixed(2);
                                            if (groupDriveUsage > 0) {
                                                out.push({ "label": g.full_name, "value": groupDriveUsage, "color": "#40b4ba" });
                                            }
                                        }
                                        out = out.sort((a, b) => b.value - a.value).slice(0, 10);
                                        return out;
                                    }}
                                ></BarChart>
                            </Grid>
                        </Grid>
                    </>
                }
            ></Modal>
            {selectedGroup &&
                <>
                    <Modal
                        open={openInfoGroupModal}
                        handleClose={handleCloseInfoGroupModal}
                        title={selectedGroup.full_name}
                        onClickMainButton={handleCloseInfoGroupModal}
                        mainButtonText="OK"
                        cancelButton={false}
                        width={"lg"}
                        content={
                            <>
                                <Grid container columnSpacing={2}>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>Descripción:</b> {selectedGroup.bio_raw}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Administración del grupo:</b> <Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_COMUNIDADES_URL + "/g/" + selectedGroup.name}>{process.env.REACT_APP_COMUNIDADES_URL + "/g/" + selectedGroup.name}</Link></Typography>
                                        <Typography variant='p' mb={"10px"}><b>Discusión grupal:</b> <Link color="#40b4ba" target="_blank" href={process.env.REACT_APP_COMUNIDADES_URL + "/c/" + selectedGroup.name.toLowerCase().replace("_", "")}>{process.env.REACT_APP_COMUNIDADES_URL + "/c/" + selectedGroup.name.toLowerCase().replace("_", "")}</Link></Typography>
                                        <Typography variant='p' mb={"10px"}><b>Unidad compartida en Drive:</b> <Link color="#40b4ba" target="_blank" href={"https://drive.google.com/drive/folders/" + selectedGroup.name}>{"https://drive.google.com/drive/folders/" + selectedGroup.name}</Link></Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} display={"flex"} alignItems={"center"}>
                                        <Typography variant='p' mr={"10px"}><b>Visibilidad:</b></Typography>{getGroupVisibilityChip(selectedGroup.visibility_level)}
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
                                        <Typography variant='p' mb={"10px"}><b>Miembros: </b>{selectedGroup.users.filter(u => u !== "redfid").length}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Discusiones: </b>{discussions.filter(d => d.is_group && d.category_id === selectedGroup.category_id).length}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Posts: </b>{discussions.filter(d => d.is_group && d.category_id === selectedGroup.category_id).reduce((acc, d) => acc + d.posts_count, 0)}</Typography>
                                        <Typography variant='p' mb={"10px"}><b>Uso de Drive: </b>{formatFileSize(driveData["sharedDrives"][selectedGroup.full_name]["size"])}</Typography>
                                    </Grid>
                                    <Grid item mb={"10px"} xs={12} md={6} display={"flex"} flexDirection={"column"}>
                                        <Box display={"flex"} mb={"10px"}>
                                            <Typography variant='p' mr={"10px"}><b>Entrada libre: </b></Typography>
                                            {selectedGroup.public_admission ? <CheckCircleIcon sx={{ "color": "green" }}></CheckCircleIcon> : <CancelIcon sx={{ "color": "red" }}></CancelIcon>}
                                        </Box>
                                        <Box display={"flex"} mb={"10px"}>
                                            <Typography variant='p' mr={"10px"}><b>Solicitud de membresía: </b></Typography>
                                            {selectedGroup.allow_membership_requests ? <CheckCircleIcon sx={{ "color": "green" }}></CheckCircleIcon> : <CancelIcon sx={{ "color": "red" }}></CancelIcon>}
                                        </Box>
                                        <Box display={"flex"} mb={"10px"}>
                                            <Typography variant='p' mr={"10px"}><b>Salida libre: </b></Typography> {selectedGroup.public_exit ? <CheckCircleIcon sx={{ "color": "green" }}></CheckCircleIcon> : <CancelIcon sx={{ "color": "red" }}></CancelIcon>}
                                        </Box>
                                    </Grid>
                                </Grid>
                            </>
                        }
                    >
                    </Modal>
                    <Modal
                        open={openGroupMembersModal}
                        handleClose={handleCloseGroupMembersModal}
                        title="Miembros del grupo"
                        onClickMainButton={handleCloseGroupMembersModal}
                        mainButtonText="OK"
                        cancelButton={false}
                        width={"lg"}
                        content={
                            <>
                            </>
                        }
                    >
                    </Modal>
                    <Modal
                        open={openDeleteGroupModal}
                        handleClose={handleCloseDeleteGroupModal}
                        title="Eliminar grupo"
                        onClickMainButton={handleDeleteGroup}
                        mainButtonText="Eliminar"
                        startIcon={<DeleteForever></DeleteForever>}
                        cancelButton={true}
                        mainButtonDisabled={formLoading}
                        content={
                            <>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    ¿Está segur@ que desea eliminar el grupo seleccionado?
                                </Typography>
                                <Typography variant="p" textAlign={"center"} mb={"20px"}>
                                    <b>{selectedGroup.full_name}</b>
                                </Typography>
                                <Typography variant="p" textAlign={"justify"} mb={"20px"}>
                                    Esta acción no se puede deshacer y eliminará todos los posts y datos asociados al grupo.
                                    Además, se eliminará la unidad compartida de Google Drive asociada al grupo, junto con todos sus archivos y carpetas.
                                </Typography>
                            </>
                        }
                    >
                    </Modal>
                </>
            }
        </>
    )
}

export default Comunidades;
