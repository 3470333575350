import { formatDOI } from './formatters';

export const handleDownloadRecursos = (type, recurso, api, username) => {
  if (type === "academico") {
    fetch(`${api.api_url}/register_download_recurso_academico/${recurso["id"]}` + `?username=${username}`)
    .then(response => {})
    .catch(error => console.error('Failed to register download.'));
    if (recurso["subcategory"] === "website") {
      window.open(recurso["detail"]["url"], '_blank');
      return;
    } else if (recurso["detail"]["reference"] === "url") {
      window.open(recurso["detail"]["url"], '_blank');
      return;
    } else if (recurso["detail"]["reference"] === "doi") {
      window.open(formatDOI(recurso["detail"]["doi"]), '_blank');
      return;
    } else if (recurso["detail"]["reference"] === "direct") {
      fetch(`${api.api_url}/files_academico/${recurso["id"]}/${recurso["detail"]["filename"]}`)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', recurso["detail"]["filename"]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));
    } else {
      console.log("Invalid request")
      return;
    }
  } else if (type === "docente") {
    fetch(`${api.api_url}/register_download_recurso_docente/${recurso["id"]}` + `?username=${username}`)
    .then(response => {})
    .catch(error => console.error('Failed to register download.'));
    if (recurso["reference"] === "subir_archivo") {
      fetch(`${api.api_url}/files_docente/${recurso["id"]}/${recurso["filename"]}` + `?username=${username}`)
        .then(response => response.blob())
        .then(blob => {
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', recurso["filename"]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
          window.URL.revokeObjectURL(url);
        })
        .catch(error => console.error('Error downloading file:', error));
    } else {
      window.open(recurso["url"], '_blank');
      return;
    }
  }
}