import axios from 'axios';
import { formatDateFromISOtoUnix } from '../../utils/formatters';

export const getComunidadesUserActivity = async (api, username) => {

    function parseActionType(actionType) {
        switch (actionType) {
            case 1:
                return "Emitió un like"
            case 2:
                return "Recibió un like"
            case 4:
                return "Creó una discusión"
            case 5: 
                return "Creó un post en una discusión"
            case 6:
                return "Respondió a un post"
            case 7:
                return "Mencionó a otro usuario"
            default:
                return actionType;
        }
    }
    
    try {
        const loginResponse = await axios.post(api.api_url + "/login", {
            username: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD
        });

        if (loginResponse.data.status !== 100) {
            return { activity: null, error: "Credenciales inválidas para acceder a la API." };
        }

        const access_token = loginResponse.data.access_token;
        const activityResponse = await axios.get(api.api_url + "/get_discourse_user_actions?username=" + username, {
            headers: {
                "Authorization": "Bearer " + access_token,
                "Content-Type": "application/json"
            }
        });

        switch (activityResponse.data.status) {
            case 100:
                let activities = [];
                for(let action of activityResponse.data.result) {
                    activities.push({
                        time: formatDateFromISOtoUnix(action.created_at),
                        platform: "Comunidades",
                        eventType: parseActionType(action.action_type),
                        detail: {}
                    });
                }
                return { activity: activities, error: "" };
            case 101:
                return { activity: null, error: "Por favor indique un usuario." };
            case 105:
                return { activity: null, error: "Falló la solicitud para obtener sesiones de Keycloak." };
            default:
                return { activity: null, error: "Error desconocido." };
        }
    } catch (error) {
        return { activity: null, error: "Error desconocido: " + error.message };
    }
}
