import React, { useState } from 'react';
import { Box, Typography, IconButton, Grid, TextField, MenuItem, Button } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import "dayjs/locale/es";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloseIcon from '@mui/icons-material/Close';
import FileUpload from '../../../utils/fileUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { formatFileSize } from '../../../utils/formatters';


const NewsForm = ({
    title,
    setTitle,
    priority,
    setPriority,
    published,
    setPublished,
    author,
    setAuthor,
    externalUrl,
    setExternalUrl,
    startTimestamp,
    setStartTimestamp,
    endTimestamp,
    setEndTimestamp,
    content,
    setContent,
    mainImage,
    setMainImage,
    squareImage,
    setSquareImage,
    formLoading,
    errors,
    setErrors,
    editing,
}) => {

    const handleMainImageChange = (event) => {
        setMainImage(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, mainImage: '' }));
    };

    const resetMainImageInput = () => {
        setMainImage(null);
        setErrors(prevErrors => ({ ...prevErrors, mainImage: '' }));
    };

    const handleSquareImageChange = (event) => {
        setSquareImage(event.target.files[0]);
        setErrors(prevErrors => ({ ...prevErrors, squareImage: '' }));
    }

    const resetSquareImageInput = () => {
        setSquareImage(null);
        setErrors(prevErrors => ({ ...prevErrors, squareImage: '' }));
    }

    const handleFileChange = (e, index) => {
        const newContent = content.map((c, idx) => {
            if (idx === index) {
                return { ...c, file: { name: e.target.files[0].name, size: e.target.files[0].size, file: e.target.files[0] } };
            }
            return c;
        });
        setContent(newContent);

        const newErrors = {
            ...errors,
            content: [
                ...errors.content
            ]
        };
        newErrors.content[index] = {
            ...errors.content[index],
            file: ""
        };
        setErrors(newErrors);
    }

    const resetFileInput = (index) => {
        const newContent = content.map((c, idx) => {
            if (idx === index) {
                return { ...c, file: null };
            }
            return c;
        });
        setContent(newContent);

        const newErrors = {
            ...errors,
            content: [
                ...errors.content
            ]
        };
        newErrors.content[index] = {
            ...errors.content[index],
            file: ""
        };
        setErrors(newErrors);
    };


    const handleAddContent = () => {
        const newContent = [
            ...content,
            {
                type: "none",
                block: "",
                file: null
            }
        ];
        const newErrors = {
            ...errors,
            content: [
                ...errors.content,
                {
                    type: "",
                    block: "",
                    file: ""
                }
            ]
        };
        setContent(newContent);
        setErrors(newErrors);
    }

    const handleRemoveContent = (index) => {
        const newContent = content.filter((thisContent, idx) => idx !== index);
        const newErrors = {
            ...errors,
            content: errors.content.filter((thisContent, idx) => idx !== index)
        };
        setContent(newContent);
        setErrors(newErrors);
    }

    return (
        <>
            <Grid container columnSpacing={2} alignItems={"center"}>
                <Grid item xs={12} mt={"20px"}>
                    <Typography variant="title-small">Datos de la noticia</Typography>
                </Grid>
                <Grid item xs={12} sm={12}>
                    <TextField
                        fullWidth
                        label="Título"
                        variant="outlined"
                        value={title}
                        onChange={(e) => {
                            setTitle(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, title: "" }))
                        }}
                        error={!!errors.title}
                        helperText={errors.title}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="Autor"
                        variant="outlined"
                        value={author}
                        onChange={(e) => {
                            setAuthor(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, author: "" }))
                        }}
                        error={!!errors.author}
                        helperText={errors.author}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DateTimePicker
                            label="Inicio"
                            sx={{
                                "width": "100%"
                            }}
                            value={startTimestamp}
                            onChange={(date) => {
                                setStartTimestamp(date);
                                setErrors({ ...errors, startTimestamp: '' });
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.startTimestamp,
                                    helperText: errors.startTimestamp,
                                    disabled: formLoading
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                        <DateTimePicker
                            label="Término"
                            sx={{
                                "width": "100%"
                            }}
                            value={endTimestamp}
                            onChange={(date) => {
                                setEndTimestamp(date);
                                setErrors({ ...errors, endTimestamp: '' });
                            }}
                            slotProps={{
                                textField: {
                                    error: !!errors.endTimestamp,
                                    helperText: errors.endTimestamp,
                                    disabled: formLoading
                                }
                            }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                    <TextField
                        fullWidth
                        select
                        label="Disponible en portada"
                        variant="outlined"
                        value={published}
                        onChange={(e) => {
                            setPublished(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, published: "" }))
                        }}
                        error={!!errors.published}
                        helperText={errors.published ? errors.published : "¿Desea mostrar la noticia en el Home?"}
                        disabled={formLoading}
                    >
                        <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                        <MenuItem value="yes">Sí</MenuItem>
                        <MenuItem value="no">No</MenuItem>
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} md={3} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="Prioridad"
                        variant="outlined"
                        value={priority}
                        onChange={(e) => {
                            setPriority(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, priority: "" }))
                        }}
                        error={!!errors.priority}
                        helperText={errors.priority ? errors.priority : "Prioridad de la noticia en el Home."}
                        disabled={formLoading}
                    >
                    </TextField>
                </Grid>
                <Grid item xs={12} sm={6} mt={"20px"}>
                    <TextField
                        fullWidth
                        label="URL externa"
                        variant="outlined"
                        value={externalUrl}
                        onChange={(e) => {
                            setExternalUrl(e.target.value)
                            setErrors(prevErrors => ({ ...prevErrors, externalUrl: "" }))
                        }}
                        error={!!errors.externalUrl}
                        helperText={errors.externalUrl ? errors.externalUrl : "En caso de proporcionarse, la noticia en Home redirigirá a esta URL."}
                        disabled={formLoading}
                    />
                </Grid>
                <Grid item xs={12} mt={"20px"}>
                    <Typography variant="title-small">Banner</Typography>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={"center"}>
                    <Grid>
                        <Button
                            component="label"
                            role={undefined}
                            variant="orange"
                            tabIndex={-1}
                            onChange={handleMainImageChange}
                            startIcon={<CloudUploadIcon />}
                            disabled={formLoading}
                        >
                            Imagen principal (3:1)
                            <FileUpload type="file" onChange={handleMainImageChange} accept={".png,.jpg,.jpeg"} />
                        </Button>
                        <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                            {mainImage && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, justifyContent: 'center' }}>
                                    <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                        {mainImage ? `${mainImage.name} (${formatFileSize(mainImage.size)})` : "Por favor seleccione un archivo..."}
                                    </Typography>
                                    <IconButton disabled={formLoading} sx={{ marginLeft: "10px" }} size="small" onClick={resetMainImageInput}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            )}
                        </Typography>
                        {mainImage && (
                            <Box mt={2}>
                                <img
                                    src={URL.createObjectURL(mainImage)}
                                    alt="Main Image Preview"
                                    style={{ width: "100%", height: "auto", maxWidth: "300px", objectFit: "contain" }}
                                />
                            </Box>
                        )}
                        {errors.mainImage ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.mainImage}</Typography> : <></>}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} textAlign={"center"}>
                    <Grid>
                        <Button
                            component="label"
                            role={undefined}
                            variant="orange"
                            tabIndex={-1}
                            onChange={handleSquareImageChange}
                            startIcon={<CloudUploadIcon />}
                            disabled={formLoading}
                        >
                            Imagen cuadrada (1:1)
                            <FileUpload type="file" onChange={handleSquareImageChange} accept={".png,.jpg,.jpeg"} />
                        </Button>
                        <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                            {squareImage && (
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, justifyContent: 'center' }}>
                                    <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                        {squareImage ? `${squareImage.name} (${formatFileSize(squareImage.size)})` : "Por favor seleccione un archivo..."}
                                    </Typography>
                                    <IconButton disabled={formLoading} sx={{ marginLeft: "10px" }} size="small" onClick={resetSquareImageInput}>
                                        <CloseIcon fontSize="inherit" />
                                    </IconButton>
                                </Box>
                            )}
                        </Typography>
                        {squareImage && (
                            <Box mt={2}>
                                <img
                                    src={URL.createObjectURL(squareImage)}
                                    alt="Square Image Preview"
                                    style={{ width: "100%", height: "auto", maxWidth: "300px", objectFit: "contain" }}
                                />
                            </Box>
                        )}
                        {errors.squareImage ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.squareImage}</Typography> : <></>}
                    </Grid>
                </Grid>
                {externalUrl === "" &&
                    <Grid item xs={12} mt={"20px"}>
                        <Typography variant="title-small">Contenido</Typography>
                    </Grid>
                }
            </Grid>
            {externalUrl === "" && content.map((thisContent, index) => (
                <Grid container key={index} alignItems={"center"}>
                    <Grid item xs={12} sm={3} mt={"20px"}>
                        <TextField
                            fullWidth
                            select
                            label="Tipo"
                            name={"type-" + index}
                            value={content[index].type}
                            onChange={(e) => {
                                const newContent = content.map((c, idx) => {
                                    if (idx === index) {
                                        return { ...c, type: e.target.value };
                                    }
                                    return c;
                                });
                                setContent(newContent);
                                const newErrors = {
                                    ...errors,
                                    content: [
                                        ...errors.content
                                    ]
                                };
                                newErrors.content[index] = {
                                    ...errors.content[index],
                                    type: ""
                                };
                                setErrors(newErrors);
                            }}
                            disabled={formLoading}
                        >
                            <MenuItem value="none" disabled>Por favor seleccione una opción...</MenuItem>
                            <MenuItem value="title">Título</MenuItem>
                            <MenuItem value="subtitle">Subtítulo</MenuItem>
                            <MenuItem value="paragraph">Párrafo</MenuItem>
                            <MenuItem value="image">Imagen</MenuItem>
                        </TextField>
                        {errors.content[index]["type"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.content[index]["type"]}</span>}
                    </Grid>
                    <Grid item xs={12} sm={8} mt={"20px"} pl={{ "xs": "0", "sm": "20px" }}>
                        {(content[index].type === "title" || content[index].type === "subtitle") ?
                            <>
                                <TextField
                                    fullWidth
                                    label={content[index].type === "title" ? "Título" : "Subtítulo"}
                                    name={"content-" + index}
                                    value={content[index].block}
                                    onChange={(e) => {
                                        const newContent = content.map((c, idx) => {
                                            if (idx === index) {
                                                return { ...c, block: e.target.value };
                                            }
                                            return c;
                                        });
                                        setContent(newContent);
                                        const newErrors = {
                                            ...errors,
                                            content: [
                                                ...errors.content
                                            ]
                                        };
                                        newErrors.content[index] = {
                                            ...errors.content[index],
                                            block: ""
                                        };
                                        setErrors(newErrors);
                                    }}
                                    disabled={formLoading}
                                />
                                {errors.content[index]["block"] && <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.content[index]["block"]}</span>}
                            </>
                            : content[index].type === "paragraph" ?
                                <>
                                    <TextField
                                        fullWidth
                                        multiline
                                        rows={4}
                                        label="Párrafo"
                                        name={"content-" + index}
                                        value={content[index].block}
                                        onChange={(e) => {
                                            const newContent = content.map((c, idx) => {
                                                if (idx === index) {
                                                    return { ...c, block: e.target.value };
                                                }
                                                return c;
                                            });
                                            setContent(newContent);
                                            const newErrors = {
                                                ...errors,
                                                content: [
                                                    ...errors.content
                                                ]
                                            };
                                            newErrors.content[index] = {
                                                ...errors.content[index],
                                                block: ""
                                            };
                                            setErrors(newErrors);
                                        }}
                                        disabled={formLoading}
                                    />
                                    {errors.content[index]["block"] ?
                                        <span style={{ color: '#d32f2f', fontSize: '0.75rem', textAlign: "center" }}>{errors.content[index]["block"]}</span>
                                        :
                                        <span style={{ color: '#646464', fontSize: '0.75rem', textAlign: "center" }}>
                                            Los párrafos soportan HTML. Puedes dejar texto en negrita envolviéndolo <code>&lt;b&gt;</code><b>así</b><code>&lt;/b&gt;</code>, y texto en cursiva envolviéndolo <code>&lt;i&gt;</code><i>así</i><code>&lt;/i&gt;</code>.
                                            También puedes agregar saltos de línea con <code>&lt;br&gt;</code>, y enlaces con <code>&lt;a href="URL"&gt;</code>link<code>&lt;/a&gt;</code>, entre otras funcionalidades.
                                        </span>
                                    }
                                </>
                                : content[index].type === "image" ?
                                    <Box display={"flex"} alignItems={"center"}>
                                        <Grid textAlign={"start"}>
                                            <Button
                                                component="label"
                                                role={undefined}
                                                variant="orange"
                                                tabIndex={-1}
                                                onChange={(e) => handleFileChange(e, index)}
                                                startIcon={<CloudUploadIcon />}
                                                disabled={formLoading}
                                            >
                                                Subir imagen
                                                <FileUpload type="file" onChange={(e) => handleFileChange(e, index)} />
                                            </Button>
                                            {content[index].file && <Typography ml={"8px"} sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                                    <Typography sx={{ "color": "#646464", "fontFamily": "Avenir Regular" }} variant="caption">
                                                        {`${content[index].file.name} (${formatFileSize(content[index].file.size)})`}
                                                    </Typography>
                                                    <IconButton disabled={formLoading} sx={{ marginLeft: "10px" }} size="small" onClick={() => resetFileInput(index)}>
                                                        <CloseIcon fontSize="inherit" />
                                                    </IconButton>
                                                </Box>
                                            </Typography>
                                            }
                                            {errors.content[index]["file"] ? <Typography sx={{ marginTop: "10px", fontSize: "0.75rem" }} width={"100%"} variant="p" color="error">{errors.content[index]["file"]}</Typography> : <></>}
                                        </Grid>
                                        {content[index].file && (
                                            <Box mt={2}>
                                                <img
                                                    src={URL.createObjectURL(content[index].file.file)}
                                                    alt="Content Image Preview"
                                                    style={{ width: "100%", height: "auto", maxWidth: "300px", objectFit: "contain" }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                    : <></>
                        }
                    </Grid>
                    <Grid item xs={6} sm={0.5} mt={"20px"} textAlign={"center"}>
                        {content.length > 1 && (
                            <IconButton onClick={() => handleRemoveContent(index)}>
                                <RemoveCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Grid>
                    <Grid item xs={6} sm={0.5} mt={"20px"} textAlign={"center"}>
                        {(content.length < 10 && (index + 1) === content.length) && (
                            <IconButton onClick={() => handleAddContent()}>
                                <AddCircleOutlineIcon />
                            </IconButton>
                        )}
                    </Grid>
                </Grid>
            ))}
        </>
    )

}

export default NewsForm;