import React, { useState } from 'react';
import { IconButton, Menu, MenuItem, Box, Typography, Link } from '@mui/material';
import { DataGrid, GridToolbarContainer } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';
import { getDiscourseCategoryChip } from '../../../utils/chips';
import DownloadIcon from '@mui/icons-material/Download';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { formatDateFromUnix } from '../../../utils/formatters';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import InfoIcon from '@mui/icons-material/Info';
import MoreVertIcon from '@mui/icons-material/MoreVert';


const DiscussionsTable = ({
    discussions,
    handleOpenCreateModal,
    handleOpenDownloadCSVModal,
    handleOpenStatsModal,
    handleOpenInfoModal,
    handleOpenDeleteModal
}) => {

    const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

    const columns = [
        {
            field: 'actions',
            headerName: 'Acciones',
            sortable: false,
            width: 100,
            disableColumnMenu: true,
            headerAlign: 'center',
            align: 'center',
            renderCell: (params) => (
                <ActionsMenu discussion={params.row} />
            )
        },
        { 
            field: 'title', 
            disableColumnMenu: true, 
            headerName: 'Título', 
            flex: 1, 
            align: 'left', 
            headerAlign: 'left', 
            sortComparator: (v1, v2) => v2.localeCompare(v1),
            renderCell: (params) => <Typography variant="a"><Link href={process.env.REACT_APP_COMUNIDADES_URL + "/t/" + params.row.slug} target="_blank" color={"#40b4ba"}>{params.row.title}</Link></Typography> 
        },
        { 
            field: 'category_id', 
            sortComparator: (v1, v2) => v2.localeCompare(v1),
            valueFormatter: (params) => getDiscourseCategoryChip(params.value).props.label, 
            disableColumnMenu: true, 
            headerName: 'Categoría', 
            width: 200, align: 'center', 
            headerAlign: 'center', 
            renderCell: (params) => <Box display="flex" alignItems="center" justifyContent="center" height="100%">{getDiscourseCategoryChip(params.row.category_id)}</Box> 
        },
        { 
            field: 'last_posted_at', 
            disableColumnMenu: true, 
            headerName: 'Última actualización', 
            width: 200, 
            align: 'center', 
            headerAlign: 'center',
            valueFormatter: (params) => { return formatDateFromUnix(new Date(params).getTime()) }
        },
        { 
            field: 'posts_count', 
            disableColumnMenu: true, 
            headerName: 'Posts', 
            width: 100, 
            align: 'center', 
            headerAlign: 'center' 
        },
        { 
            field: 'posters', 
            disableColumnMenu: true, 
            headerName: 'Participantes', 
            width: 120, 
            align: 'center', 
            headerAlign: 'center', 
            sortComparator: (v1, v2) => v2.length - v1.length,
            renderCell: (params) => params.row.posters.length 
        },
        { 
            field: 'views', 
            disableColumnMenu: true, 
            headerName: 'Visitas', 
            width: 100, 
            align: 'center', 
            headerAlign: 'center' 
        },
    ];

    const ActionsMenu = ({ discussion }) => {
        const [anchorEl, setAnchorEl] = useState(null);

        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        return (
            <div>
                <IconButton
                    aria-controls="actions-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                >
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    id="actions-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem
                        onClick={() => {
                            handleOpenInfoModal(discussion);
                            handleClose();
                        }}
                    >
                        <InfoIcon sx={{ "marginRight": "10px" }} /> Información detallada
                    </MenuItem>
                    <MenuItem
                        onClick={() => {
                            handleOpenDeleteModal(discussion);
                            handleClose();
                        }}
                    >
                        <DeleteForeverIcon sx={{ "marginRight": "10px" }} /> Eliminar
                    </MenuItem>
                </Menu>
            </div>
        );
    };

    function CustomToolbar() {
        return (
            <GridToolbarContainer sx={{ "display": "flex", "justifyContent": "space-between", "padding": "15px 10px" }}>
                <Typography variant='table-title'>Discusiones públicas</Typography>
                <Box>
                    <IconButton onClick={handleOpenCreateModal}><AddCircleOutlineIcon></AddCircleOutlineIcon></IconButton>
                    <IconButton onClick={handleOpenStatsModal}><InsertChartIcon></InsertChartIcon></IconButton>
                    <IconButton onClick={handleOpenDownloadCSVModal}><DownloadIcon></DownloadIcon></IconButton>
                </Box>
            </GridToolbarContainer>
        );
    }

    return (
        <div style={{ width: '100%', margin: "40px 0" }}>
            <DataGrid
                rows={discussions.filter(discussion => (discussion.is_main || discussion.is_group))}
                columns={columns}
                localeText={esES.components.MuiDataGrid.defaultProps.localeText}
                pageSizeOptions={[10]}
                onPaginationModelChange={setPaginationModel}
                initialState={{
                    sorting: {
                        sortModel: [{ field: 'last_posted_at', sort: 'desc' }],
                    },
                    pagination: {
                        paginationModel
                    }
                }}
                slots={{
                    toolbar: CustomToolbar,
                }}
            />
        </div>
    );
};

export default DiscussionsTable;
