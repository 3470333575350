import React, { cloneElement } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import theme from './theme';
import NotFound from './Errors/NotFound';
import HomeWrapper from './Auth/HomeWrapper';
import { ThemeProvider } from '@mui/material/styles';
import { KeycloakProvider, useKeycloak } from "keycloak-react-web"
import Keycloak from 'keycloak-js';
import SilentSSOCheck from './Auth/SilentSSOCheck';
import oidcConfiguration from './Auth/oidcConfig';

const keycloakSetting = oidcConfiguration;

const authInstance = new Keycloak(keycloakSetting)

function App() {
  return (
    <KeycloakProvider client={authInstance} initOptions={{
      onLoad: 'check-sso',
      silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
      pkceMethod: 'S256',
    }}>
      <ThemeProvider theme={theme}>
        <Router>
          <Routes>
            <Route path="/" element={<ProtectedRoute><HomeWrapper /></ProtectedRoute>} />
            <Route path="/silent-check-sso.html" element={<SilentSSOCheck  />} />
            <Route path="*" element={<ProtectedRoute><NotFound /></ProtectedRoute>} />
          </Routes>
        </Router>
      </ThemeProvider>
    </KeycloakProvider>
  );
}

const ProtectedRoute = ({ children }) => {
  const { keycloak } = useKeycloak();

  keycloak.onTokenExpired = () => {
    keycloak.updateToken(50);
  };

  const childWithProps = React.Children.map(children, child => {
      return cloneElement(child, { keycloak: keycloak });
  });

  return <>{childWithProps}</>;
};

export default App;